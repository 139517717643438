import { useEffect, useRef, useCallback } from 'react';

const useInfiniteScroll = (onScroll: () => void, delay = 200) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<number | null>(null);

  const handleScroll = useCallback(() => {
    if (!contentRef.current) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      const { current } = contentRef;
      if (current) {
        const { scrollTop, clientHeight, scrollHeight } = current;
        if (scrollTop + clientHeight >= scrollHeight * 0.9) {
          onScroll();
        }
      }
    }, delay);
  }, [onScroll, delay]);

  useEffect(() => {
    const listContent = contentRef.current;
    if (!listContent) return;

    listContent.addEventListener('scroll', handleScroll);
    return () => {
      listContent.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return contentRef;
};

export default useInfiniteScroll;
