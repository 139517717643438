import { useState } from 'react';
import api from '../../../../../../../services/api/api';
import { maskCpfCnpj } from '../../../../../../../services/helpers/mask';
import message from '../../../../../../../utils/message';
import { Input } from '../../../../../../../shared/Input';
import { Button } from '../../../../../../../shared/Button';
import styles from './forget-modal-finance.module.css';
import { Modal } from '../../../../../../../shared/Modal';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ForgetModalFinance = ({ isOpen, setIsOpen }: Props) => {
  const [Document, setDocument] = useState<string>('');

  const handleRecoveryPassword = async () => {
    try {
      await api
        .post('workshop/internal_password/recover', {
          document: Document,
        })
        .then(() => {
          setIsOpen(false);
          message(
            'Um SMS e uma mensagem no whatsapp foram enviados para o número telefone do usuário.',
            'success',
          );
        });
    } catch (error) {
      setIsOpen(false);
      message(error, 'error');
    }
    setDocument('');
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Informe o seu CPF/CNPJ"
      footer={
        <div className={styles.actions}>
          <Button
            variant="ghost"
            handleClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="register"
            handleClick={() => {
              handleRecoveryPassword();
            }}
          >
            Recuperar
          </Button>
        </div>
      }
    >
      <Input
        value={Document}
        onChange={e => {
          setDocument(maskCpfCnpj(e.target.value));
        }}
        label="CPF/CNPJ"
        name="document"
      />
    </Modal>
  );
};
