import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import ImageInput from '../../../../../../shared/ImageInput';
import { TextareaComponent } from '../../../../../../shared/TextareaComponent';
import { ServicesTable } from '../../../components/ServicesTable';
import styles from '../../create-order-service-form.module.css';
import { OrderServiceFormDTO } from '../../OrderServiceDTO';
import { currencyMask } from '../../../../../../services/helpers/mask';
import { parseNumber, parseOSForm } from '../../parseOrderServiceForm';
import { LoadingSpinner } from '../../../../../../shared/LoadingSpinner';
import { OrderServiceSuccessModal } from '../../../components/OrderServiceSuccessModal';
import {
  createRepairDraftController,
  transformRepairDraftController,
} from '../../../controller';

export const ServicesForm = () => {
  const { register, watch, setValue, getValues } =
    useFormContext<OrderServiceFormDTO>();

  const queryClient = useQueryClient();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const serviceImages = watch('services.images') || [];
  const productTable = watch('services.productTable') || [];
  const serviceTable = watch('services.serviceTable') || [];
  const discount = watch('services.discount') || '0';

  const [loadingStatus, setLoadingStatus] = useState<
    'in_progress' | 'finished' | null
  >(null);

  const serviceTotal = serviceTable.reduce((acc, currentService) => {
    return (
      acc +
      parseNumber(currentService.unitValue, true) *
        parseNumber(currentService.quantity)
    );
  }, 0);

  const productTotal = productTable.reduce((acc, currentProduct) => {
    return (
      acc +
      parseNumber(currentProduct.unitValue, true) *
        parseNumber(currentProduct.quantity)
    );
  }, 0);

  const total = serviceTotal + productTotal - parseNumber(discount, true);

  const handleImageChange = useCallback(
    (images: string[]) => {
      setValue(
        'services.images',
        images.map(img => ({ category: 'services', img_url: img })),
      );
    },
    [setValue],
  );

  const getServiceImages = () => serviceImages.map(img => img.img_url);

  const totalStyle = {
    color: total >= 0 ? '#000' : 'var(--error)',
  };

  const isFormValid = total > 0;

  const saveOSData = async () => {
    try {
      const formValues = getValues();
      const parsedForm = parseOSForm(formValues as OrderServiceFormDTO);
      const res = await createRepairDraftController(parsedForm);

      if (res) {
        setValue('id_repair', res.repair?.id_repair ?? '');
      }
    } catch (err) {
      console.error('Erro ao atualizar formulário:', err);
    }
  };

  const handleTransformDraft = async (status: 'finished' | 'in_progress') => {
    setLoadingStatus(status);
    try {
      // primeiro salva os dados da OS
      await saveOSData();

      // depois transforma a OS
      await transformRepairDraftController({
        changeVehicleOwner: getValues('customer.changeVehicleOwner'),
        identification: getValues('id_repair'),
        status: status === 'finished' ? 'finalizado' : 'em andamento',
      });

      // força atualização da lista de OS
      queryClient.invalidateQueries(['repairs']);
      setValue('isDraft', false);
      setShowSuccessModal(true);
    } catch (err) {
      setShowSuccessModal(false);
      setValue('isDraft', true);
    } finally {
      setLoadingStatus(null);
    }
  };

  return (
    <form className={`${styles.form} `}>
      {showSuccessModal && <OrderServiceSuccessModal />}
      <div className={styles['form-row']}>
        <TextareaComponent
          {...register('services.comment')}
          label="Observações: "
          hint="Informações adicionais sobre a sua O.S."
          className={styles.textarea}
        />
      </div>
      <div className={styles['form-row']}>
        <div className={styles['image-input-wrapper']}>
          <span>Enviar Imagem:</span>
          <ImageInput
            size={68}
            className={styles['image-input']}
            imageClassName={styles.image}
            imagesUrls={getServiceImages()}
            setImageUrls={handleImageChange}
            bucketPath="checklist"
          />
        </div>
      </div>
      <div className={`${styles['form-column']} ${styles['border-bottom']}`}>
        <div className={styles['services-table-wrapper']}>
          <ServicesTable
            {...register('services.productTable')}
            type="product"
          />
        </div>
        <div className={styles['services-table-wrapper']}>
          <ServicesTable
            {...register('services.serviceTable')}
            type="service"
          />
        </div>
      </div>
      <div className={`${styles['form-row']} ${styles.info}`}>
        <span>Subtotal de produtos</span>
        <span>
          {productTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
        </span>
      </div>
      <div className={`${styles['form-row']} ${styles.info}`}>
        <span>Subtotal de serviços</span>
        <span>
          {serviceTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
        </span>
      </div>
      <div className={`${styles['form-row']} ${styles.info}`}>
        <span>Desconto</span>
        <div className={styles['discount-input']}>
          <input
            {...register('services.discount')}
            onChange={e => {
              const newValue = currencyMask(e.target.value).replace('R$', '');
              setValue('services.discount', newValue);
            }}
            type="text"
            placeholder="R$ 00,00"
          />
        </div>
      </div>
      <div className={`${styles['form-row']} ${styles.info} ${styles.total}`}>
        <span>TOTAL</span>
        <span style={totalStyle}>
          {total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
        </span>
      </div>
      <div className={styles['save-form-container']}>
        <span>Status: </span>
        <div className={styles['save-form--row']}>
          <button
            type="button"
            className={styles['primary-btn']}
            disabled={!isFormValid}
            onClick={async () => {
              await handleTransformDraft('in_progress');
            }}
          >
            Em andamento
            {loadingStatus === 'in_progress' && <LoadingSpinner />}
          </button>
          <button
            type="button"
            className={styles['second-btn']}
            disabled={!isFormValid}
            onClick={async () => {
              await handleTransformDraft('finished');
            }}
          >
            Finalizado
            {loadingStatus === 'finished' && <LoadingSpinner />}
          </button>
        </div>
      </div>
    </form>
  );
};
