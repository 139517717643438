/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import { Dispatch, SetStateAction, useRef } from 'react';
import { Preventive, PreventiveMaintenanceVehicle } from '../../interface';
import { PreventiveCard } from '../PreventiveCard';
import styles from './list-items.module.css';
import { Carousel } from '../../../../../shared/Carousel';

interface Props {
  items: Partial<Preventive>[];
  handleAddPreventive: () => void;
  setSelectedPreventive: Dispatch<SetStateAction<Partial<Preventive> | null>>;
  vehicle: PreventiveMaintenanceVehicle | null;
}

export const ListItems = ({
  items,
  handleAddPreventive,
  setSelectedPreventive,
  vehicle,
}: Props) => {
  const itemsPerSlide = 6;
  const carouselRef = useRef<HTMLDivElement>(null);

  const renderPreventiveCard = (preventive: Partial<Preventive> | string) => {
    if (typeof preventive !== 'string')
      return (
        <PreventiveCard
          key={preventive.id_preventive}
          preventive={preventive}
          setSelectedPreventive={setSelectedPreventive}
          customer={vehicle && vehicle.user ? vehicle.user : undefined}
        />
      );

    return <PreventiveCard onClick={handleAddPreventive} variant="add" />;
  };

  const divideItemsIntoGroups = (itemsArray: any[], groupSize: number) => {
    const groups = [];
    for (let i = 0; i < itemsArray.length; i += groupSize) {
      groups.push(itemsArray.slice(i, i + groupSize));
    }
    return groups;
  };

  const groupedItems = divideItemsIntoGroups(
    [...items, 'addOtherMaintenance'],
    itemsPerSlide,
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.left} />

      <div className={styles.container}>
        <h2>Itens Preventivos</h2>
        {vehicle && vehicle.user && !vehicle.user.phone && (
          <h5>OBS: O dono deste veículo não possui um número cadastrado</h5>
        )}
        <div className={styles.carousel} ref={carouselRef} id="Carousel">
          <Carousel>
            {groupedItems.map((group, groupIndex) => (
              <div className="embla__slide" key={groupIndex}>
                {group.map((item, index) => (
                  <div key={index}>{renderPreventiveCard(item)}</div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
        <div className={styles.list}>
          {items.map(item => {
            return renderPreventiveCard(item);
          })}
          {renderPreventiveCard('addOtherMaintenance')}
        </div>
      </div>
      <div className={styles.right} />
    </div>
  );
};
