import * as yup from 'yup';
import axios from 'axios';
import { LoginInput } from './interface';
import { authenticateAgent, authenticateWorkshop } from './model';
import { handleError } from '../../../../../utils/handleError';

export const schema = yup.object({
  email: yup.string().required('Esse campo é obrigatório'),
  password: yup
    .string()
    .required('informe uma senha')
    .min(6, 'A senha deve ter no mínimo 6 digitos'),
});

export const authenticateWorkshopController = async (props: LoginInput) => {
  try {
    await schema.validate(props, {
      abortEarly: false,
    });
    const res = await authenticateWorkshop(props);
    return res;
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      error.response?.status &&
      error.response.status >= 500
    ) {
      throw new Error(
        'Ocorreu um erro inesperado durante a validação. Por favor, tente fazer login novamente.',
      );
    } else {
      handleError(error);
    }
  }
};

export const authenticateAgentController = async (props: LoginInput) => {
  try {
    await schema.validate(props, {
      abortEarly: false,
    });
    const res = await authenticateAgent(props);
    return res;
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      error.response?.status &&
      error.response.status >= 500
    ) {
      throw new Error(
        'Ocorreu um erro inesperado durante a validação. Por favor, tente fazer login novamente.',
      );
    } else {
      handleError(error);
    }
  }
};
