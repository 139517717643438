import { ComponentProps } from 'react';
import styles from './table-filter-cell.module.css';

type TableFilterCellProps = {
  value?: string;
  alignStart?: boolean;
} & ComponentProps<'td'>;

export const TableFilterCell = ({
  value,
  children,
  alignStart = false,
  ...props
}: TableFilterCellProps) => {
  return (
    // <td
    //   {...props}
    //   className={`${styles['filter-cell']} ${props.className} ${
    //     alignStart ? styles['filter-align-start'] : ''
    //   }`}
    // >
    //   {children}
    // </td>

    <td {...props} className={`${styles['filter-cell']} ${props.className}`}>
      <div
        className={`${styles['filter-cell-content']} ${
          alignStart ? styles['filter-align-start'] : ''
        }`}
      >
        {children}
        {value ? <span>{value}</span> : ''}
      </div>
    </td>
  );
};

/**
 * <td {...props} className={`${styles.cell} ${props.className}`}>
      <div
        className={`${styles['cell-content']} ${
          alignStart ? styles['align-start'] : ''
        }`}
      >
        {children}
        {value ? <span>{value}</span> : ''}
      </div>
    </td>
 */
