import { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import styles from './register-vehicle-modal.module.css';

import { Vehicle } from '../../../../../@types/interface';
import { fetchVehicleDataByLicensePlateController } from '../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import { numberWithCommas } from '../../../../../services/helpers/mask';
import { ListLoader } from '../../../../../shared/ListLoader';
import { LinkFleetVehicleController } from '../../controller';

interface Props {
  licensePlate: string;
  onCloseModal: () => void;
  isOpen: boolean;
  onRegister: (vehicle: Vehicle) => void;
}

export const RegisterVehicleModal = ({
  licensePlate,
  onCloseModal,
  isOpen,
  onRegister,
}: Props) => {
  const [newLicensePlate, setNewLicensePlate] = useState(
    licensePlate.trim().toUpperCase(),
  );

  useEffect(() => {
    setNewLicensePlate(licensePlate.trim().toUpperCase());
  }, [licensePlate]);

  const [vehicleResponse, setVehicleResponse] = useState<Vehicle | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchVehicle = async () => {
    setIsLoading(true);
    const res = await fetchVehicleDataByLicensePlateController(newLicensePlate);
    if (res) {
      setVehicleResponse(res);
    }
    setIsLoading(false);
  };

  const linkVehicle = async () => {
    if (vehicleResponse) {
      const res = await LinkFleetVehicleController(vehicleResponse.id_vehicle);
      if (res) {
        onRegister(res);
        onCloseModal();
      }
    }
  };

  useEffect(() => {
    if (newLicensePlate.length === 7) {
      fetchVehicle();
    }
  }, [newLicensePlate]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onCloseModal();
        setVehicleResponse(null);
      }}
      title="Registrar novo veículo"
    >
      <div className={styles.container}>
        <div className={styles['input-control']}>
          <span>Placa:</span>
          <input
            type="text"
            placeholder="informe a placa"
            onChange={e =>
              setNewLicensePlate(e.target.value.trim().toUpperCase())
            }
            value={newLicensePlate}
          />
        </div>
        {isLoading && <ListLoader />}
        {!isLoading && (
          <>
            <div className={styles.info}>
              <span>
                Nome: {(vehicleResponse && vehicleResponse.name) || ''}
              </span>
              <span>
                Modelo: {(vehicleResponse && vehicleResponse.model) || ''}
              </span>
              <span>
                Km:{' '}
                {vehicleResponse && vehicleResponse.current_km
                  ? numberWithCommas(`${vehicleResponse.current_km}`)
                  : ''}
              </span>
              <span>
                Ano: {(vehicleResponse && vehicleResponse.year) || ''}
              </span>
              <span>
                Combustível: {(vehicleResponse && vehicleResponse.fuel) || ''}
              </span>
            </div>
            <button
              type="button"
              className={styles.register}
              onClick={linkVehicle}
            >
              Registrar
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};
