import { IconProps } from './IconProps';

export const DashboardIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H11C11.7956 2 12.5587 2.31607 13.1213 2.87868C13.6839 3.44129 14 4.20435 14 5V11C14 11.7956 13.6839 12.5587 13.1213 13.1213C12.5587 13.6839 11.7956 14 11 14H5C4.20435 14 3.44129 13.6839 2.87868 13.1213C2.31607 12.5587 2 11.7956 2 11V5ZM18 5C18 4.20435 18.3161 3.44129 18.8787 2.87868C19.4413 2.31607 20.2044 2 21 2H27C27.7956 2 28.5587 2.31607 29.1213 2.87868C29.6839 3.44129 30 4.20435 30 5V11C30 11.7956 29.6839 12.5587 29.1213 13.1213C28.5587 13.6839 27.7956 14 27 14H21C20.2044 14 19.4413 13.6839 18.8787 13.1213C18.3161 12.5587 18 11.7956 18 11V5ZM2 21C2 20.2044 2.31607 19.4413 2.87868 18.8787C3.44129 18.3161 4.20435 18 5 18H11C11.7956 18 12.5587 18.3161 13.1213 18.8787C13.6839 19.4413 14 20.2044 14 21V27C14 27.7956 13.6839 28.5587 13.1213 29.1213C12.5587 29.6839 11.7956 30 11 30H5C4.20435 30 3.44129 29.6839 2.87868 29.1213C2.31607 28.5587 2 27.7956 2 27V21ZM18 21C18 20.2044 18.3161 19.4413 18.8787 18.8787C19.4413 18.3161 20.2044 18 21 18H27C27.7956 18 28.5587 18.3161 29.1213 18.8787C29.6839 19.4413 30 20.2044 30 21V27C30 27.7956 29.6839 28.5587 29.1213 29.1213C28.5587 29.6839 27.7956 30 27 30H21C20.2044 30 19.4413 29.6839 18.8787 29.1213C18.3161 28.5587 18 27.7956 18 27V21Z"
        fill="currentColor"
      />
    </svg>
  );
};
