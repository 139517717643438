import React from 'react';
import { Modal } from '../../../shared/Modal';
import styles from './order-service.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  url: string;
}

export const ViewImagesModal = ({ isOpen, onClose, url }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Visualizar Imagens">
      <div className={styles['modal-image']}>
        <img key={url} src={url} alt="Imagem da OS" />
      </div>
    </Modal>
  );
};
