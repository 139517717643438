import api from '../../../services/api/api';
import message from '../../../utils/message';
import {
  PreventiveMaintenanceInput,
  PreventiveMaintenanceType,
  PreventiveMaintenanceVehicle,
  preventiveMaintenanceResponse,
} from './interface';

export const fetchPreventiveMaintenanceByLicensePlate = async (
  licensePlate: string,
) => {
  const { data } = await api.get<PreventiveMaintenanceVehicle>(
    `/preventive/${licensePlate}`,
  );
  return data;
};

export const fetchPreventiveMaintenanceTypes = async () => {
  const { data } = await api.get<PreventiveMaintenanceType[]>(
    '/preventivetype',
  );

  return data;
};

export const createPreventiveMaintenance = async (
  preventiveMaintenance: PreventiveMaintenanceInput,
) => {
  const { data } = await api.post<preventiveMaintenanceResponse>(
    '/preventive',
    {
      ...preventiveMaintenance,
    },
  );
  return data;
};

export const deletePreventiveMaintenance = async (preventive_id: string) => {
  try {
    api.delete(`/preventive/${preventive_id}`);
    return true;
  } catch (error) {
    message(error, 'error');
    return false;
  }
};

export const updateVehicleCurrentKm = async (
  vehicleId: string,
  currentKm: number,
) => {
  const { data } = await api.put<PreventiveMaintenanceVehicle>('/vehicle/km', {
    vehicleId,
    currentKm,
  });
  return data;
};

export const createCustomer = async (document: string, name: string) => {
  const { data } = await api.post('/workshop/customer', {
    document,
    name,
  });
  return data;
};
export const createCustomerVehicle = async (
  licensePlate: string,
  usersWorkshopsId: string,
) => {
  const { data } = await api.post('/vehicle/customer', {
    licensePlate,
    usersWorkshopsId,
  });
  return data;
};
