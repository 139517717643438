import { Clipboard, WhatsappLogo } from 'phosphor-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalHeader } from '../ModalHeader';
import styles from './send-checklist-modal.module.css';
import { ModalContent } from '../../../Finance/components/Modal';
import { Button } from '../../../../../shared/Button';
import { Checklist } from '../../../../../@types/checklist';
import useSendChecklistModal from '../../hooks/useSendChecklistModal';
import { sendWhatsappMessage } from '../../../../../utils/sendWhatsappMessage';
import { FormValues } from '../CreateChecklistModal';
import {
  getChecklistByIdController,
  getChecklistDraftByIdController,
  transformDraftChecklistController,
} from '../../controller';
import { handleErrorMessage } from '../../../../../utils/handleErrorMessage';
import { ErrorModal } from '../ErrorModal';
import { FeedbackModal } from '../../../../../shared/FeedbackModal';

interface SendChecklistModalProps {
  onClose?: () => void;
  checklist: Checklist | null;
  onTransformChecklist?: () => void;
}

export const SendChecklistModal = ({
  onClose,
  checklist,
  onTransformChecklist,
}: SendChecklistModalProps) => {
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [updatedChecklist, setUpdatedChecklist] = useState<Checklist | null>(
    checklist,
  );

  const modal = useSendChecklistModal();

  const formattedForValidation: FormValues = useMemo(() => {
    return {
      checklist_category_details:
        updatedChecklist?.checklist_category_details ?? [],
      checklist_images: updatedChecklist?.checklist_vehicle_imgs ?? [],
      id_checklist_vehicle: updatedChecklist?.id_checklist_vehicle ?? '',
      name: updatedChecklist?.name ?? '',
      phone: updatedChecklist?.phone ?? '',
      plate: updatedChecklist?.plate ?? '',
    };
  }, [updatedChecklist]);

  const isFormEmpty = useCallback((formData: FormValues): boolean => {
    return (
      Object.entries({
        name: formData.name.trim(),
        phone: formData.phone.trim(),
        id_checklist_vehicle: formData.id_checklist_vehicle.trim(),
        plate: formData.plate.trim(),
      }).every(([_, value]) => value === '') &&
      formData.checklist_images.length === 0 &&
      formData.checklist_category_details.every(
        item => item.observations?.trim().length === 0,
      )
    );
  }, []);

  const validateChecklist = useCallback(
    (formData: FormValues) => {
      if (isFormEmpty(formData)) {
        throw new Error('O checklist está vazio');
      }

      const isDetailEmpty = formData?.checklist_category_details?.every(
        detail => detail.observations?.trim()?.length === 0,
      );

      const isImagesEmpty = formData.checklist_images.length === 0;

      if (isImagesEmpty && isDetailEmpty) {
        throw new Error('Adicione ao menos uma imagem ou uma observação');
      }
    },
    [isFormEmpty],
  );

  const handleTransformChecklist = useCallback(async () => {
    setIsLoading(true);
    try {
      validateChecklist(formattedForValidation);
      const res = await transformDraftChecklistController(
        updatedChecklist?.id_checklist_vehicle ?? '',
      );

      if (res) {
        onTransformChecklist?.();
        setUpdatedChecklist(res);
      }
      return true;
    } catch (err) {
      setError(handleErrorMessage(err));
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [
    formattedForValidation,
    onTransformChecklist,
    updatedChecklist?.id_checklist_vehicle,
    validateChecklist,
  ]);

  const handleClose = () => {
    onClose?.();
    modal.onClose();
  };

  const checklistLink = `https://oficinas.autocenterapp.com/checklist-view-report?checklistId=${
    updatedChecklist?.id_checklist_vehicle ?? ''
  }`;

  const handleSend = useCallback(async () => {
    if (updatedChecklist?.draft) {
      const transformationSuccess = await handleTransformChecklist();

      if (!transformationSuccess) return;
    }

    const message = `
      Olá, aqui é da oficina *${
        updatedChecklist?.workshop.fantasy_name ?? ''
      }*! 🛠️
      Segue o link para você acompanhar o checklist do seu veículo de placa *${
        updatedChecklist?.plate ?? ''
      }*:
      ${checklistLink}
      Agradecemos a sua preferência! 🚗✨
      Qualquer dúvida, estamos à disposição.
    `;

    sendWhatsappMessage(
      encodeURIComponent(message),
      updatedChecklist?.phone ?? '',
    );
  }, [
    updatedChecklist?.draft,
    updatedChecklist?.workshop.fantasy_name,
    updatedChecklist?.plate,
    updatedChecklist?.phone,
    checklistLink,
    handleTransformChecklist,
  ]);

  const handleCopy = async () => {
    if (updatedChecklist?.draft) {
      const transformationSuccess = await handleTransformChecklist();

      if (!transformationSuccess) return;
    }
    navigator.clipboard.writeText(checklistLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const fetchChecklist = useCallback(async () => {
    if (!checklist?.id_checklist_vehicle) return;
    setIsLoading(true);
    try {
      const res = checklist?.draft
        ? await getChecklistDraftByIdController(checklist.id_checklist_vehicle)
        : await getChecklistByIdController(checklist.id_checklist_vehicle);
      if (res) {
        setUpdatedChecklist(res);
      }
    } catch (err) {
      setError(handleErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, [checklist?.draft, checklist?.id_checklist_vehicle]);

  useEffect(() => {
    fetchChecklist();
  }, [fetchChecklist]);

  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Enviar Checklist" />

      <FeedbackModal isOpen={isLoading} variant="sending" />

      {error && (
        <ErrorModal isOpen message={error} onClose={() => setError(null)} />
      )}

      <div className={styles.container}>
        <span>
          Quer enviar o checklist <b>{updatedChecklist?.CHECKLIST ?? ''}</b>?
          Você também pode copiar o link e mandar por outro meio.
        </span>

        {updatedChecklist?.draft && (
          <span className={`${styles.advice}`}>
            Lembre-se: após o envio, o checklist não poderá ser editado.
          </span>
        )}

        {copied && (
          <div
            className={`${styles['copy-container']} ${
              copied ? styles.copied : ''
            }`}
          >
            <span>{copied && 'Link copiado!'}</span>
          </div>
        )}

        <footer className={styles.actions}>
          <Button className={styles['second-button']} handleClick={handleCopy}>
            <Clipboard size={32} />
            Copiar link
          </Button>
          <Button className={styles['primary-button']} handleClick={handleSend}>
            <WhatsappLogo size={32} />
            Whatsapp
          </Button>
        </footer>
      </div>
    </ModalContent>
  );
};
