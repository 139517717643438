/* eslint-disable no-nested-ternary */
import { ClipboardText } from '@phosphor-icons/react';
import {
  maskPhone,
  numberWithCommas,
} from '../../../../../services/helpers/mask';
import {
  dateOffset,
  formatDate,
  formatTime,
} from '../../../../../utils/dateFunctions';
import { Repair } from '../../interfaces';
import { InfoRow } from '../InfoRow';
import { ReviewServiceTable } from '../ReviewServiceTable';
import { SectionSeparator } from '../SectionSeparator';
import styles from './history-card.module.css';

interface Props {
  repair: Repair;
}

export const HistoryCard = ({ repair }: Props) => {
  const initialDate =
    repair?.date && repair?.time
      ? dateOffset(new Date(`${repair?.date}T${repair?.time}`))
      : null;
  let finishDate = null;
  if (repair?.finish_date && repair?.finish_time)
    finishDate = dateOffset(
      new Date(`${repair?.finish_date}T${repair?.finish_time}`),
    );

  const getSubtotal = (data: Repair) => {
    const total = data.child_services
      .map(el => el.total)
      .reduce((acc, next) => acc + next, 0);
    return total.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      useGrouping: true,
    });
  };
  const getTotal = (data: Repair) => {
    const total = data.child_services
      .map(el => el.total)
      .reduce((acc, next) => acc + next, 0);
    return (total - data.discount).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      useGrouping: true,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <SectionSeparator title="Cliente:" />

        <div className={styles.row}>
          <InfoRow
            label="Nome:"
            value={
              repair && repair.vehicle ? repair.user_workshop?.user?.name : null
            }
          />
          <InfoRow
            label="Telefone:"
            value={
              repair?.user_workshop?.user?.phone
                ? maskPhone(repair.user_workshop.user.phone)
                : null
            }
          />
        </div>
        <div className={styles.column}>
          <SectionSeparator title="Serviço" />

          <InfoRow
            label="Número de OS:"
            value={`${repair?.external_id || repair.OS}`}
          />
          <div className={styles.row}>
            <InfoRow
              label="Data de Início:"
              value={
                initialDate
                  ? `${formatDate(initialDate)} - ${formatTime(initialDate)}`
                  : null
              }
              breakLine
            />
            {repair.finish_date && finishDate && (
              <InfoRow
                label="Data de Término:"
                value={
                  repair.finish_date && finishDate
                    ? `${formatDate(finishDate)} - ${formatTime(finishDate)}`
                    : null
                }
                breakLine
              />
            )}
          </div>
          <div className={styles.row}>
            <InfoRow
              label="Km:"
              value={repair.km ? numberWithCommas(`${repair.km}`) : null}
            />
            <InfoRow
              label="Status:"
              value={
                typeof repair?.finished === 'boolean'
                  ? repair?.finished && repair.finished
                    ? 'Finalizado'
                    : 'Em andamento'
                  : null
              }
            />
          </div>
          <div className={styles.row}>
            <InfoRow
              label="Defeitos:"
              value={repair?.defect ? repair?.defect : null}
              breakLine
            />
          </div>
        </div>

        <hr />
        {repair?.child_services ? (
          <ReviewServiceTable services={repair?.child_services} />
        ) : (
          <p>
            <b>Não foi possível carregar os serviços prestados :-/</b>
          </p>
        )}

        <hr />
        <div className={styles.column}>
          <InfoRow label="Subtotal:" value={`R$ ${getSubtotal(repair)}`} />
          <InfoRow
            label="Desconto:"
            value={`R$
          ${
            repair?.discount
              ? repair.discount.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  useGrouping: true,
                })
              : '00,00'
          }`}
          />
          <InfoRow
            label="Total:"
            value={`
          R$ ${getTotal(repair)}`}
          />
        </div>
        {repair?.comment && (
          <>
            <hr />

            <div className={styles.column}>
              <InfoRow
                label="Diagnóstico:"
                value={repair?.comment ? repair.comment : null}
                breakLine
              />
            </div>
          </>
        )}

        {repair?.checklist && repair?.checklist?.banners?.length > 0 && (
          <div className={styles['checklist-container']}>
            <h3>
              <ClipboardText size={24} color="var(--primary)" />
              Checklist
            </h3>
            <ul className={styles['images-list']}>
              {repair.checklist?.banners.map(banner => (
                <li key={banner.id_banner}>
                  <img
                    src={banner.img_url}
                    alt={banner?.title ?? 'imagem do histórico'}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
