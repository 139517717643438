/* eslint-disable class-methods-use-this */
import { IRepair, Repair } from '../../../pages/common/History/interfaces';
import api from '../../api/api';

interface OrderServicesDataFetcherProps {
  fetchOrderServices: (limit: number, page: number) => Promise<IRepair | null>;
  fetchOrderServicesByLicensePlate: (
    licensePlate: string,
  ) => Promise<Repair[] | null>;
}

export class OrderServicesDataFetcher implements OrderServicesDataFetcherProps {
  async fetchOrderServices(
    limit: number,
    page: number,
  ): Promise<IRepair | null> {
    try {
      const res = await api.get('/repair', {
        params: {
          limit,
          page,
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async fetchOrderServicesByLicensePlate(licensePlate: string) {
    const formattedLicensePlate = licensePlate
      .replace('[^A-Z0-9]', '')
      .trim()
      .toUpperCase();
    try {
      const { data } = await api.get<Repair[]>('/users/repair/licensePlate', {
        params: {
          licensePlate: formattedLicensePlate,
        },
      });

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
