import { Dispatch, SetStateAction } from 'react';
import { Control, Controller, FieldArrayWithId } from 'react-hook-form';
import { PlusSquare } from '@phosphor-icons/react';
import { ProductTable } from '../../../../../../../../../../shared/ProductTable';
import { Textarea } from '../../../../../../../../../../shared/Textarea';
import styles from './items.module.css';
import { FormValues } from '../../useQuotationForm';
import { Button } from '../../../../../../../../../../shared/Button';
import { VehicleResponse } from '../../../../../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import { Separator } from '../../../../../../../../../../shared/Separator';
import { VehicleInfo } from '../../components/VehicleInfo';
import { ProductTableHeader } from '../../components/ProductTableHeader';
import { ProductTableRow } from '../../components/ProductTableRow';
import { PaymentTypes } from '../../../../../../interface';
import ImageInput from '../../../../../../../../../../shared/ImageInput';

interface Props {
  control: Control<FormValues>;
  quotes: FieldArrayWithId<FormValues, 'quotes', 'id'>[];
  handleAddRow: () => void;
  imagesUrls: string[];
  setImagesUrls: Dispatch<SetStateAction<string[]>>;
  handleVehicleFields: (vehicle: VehicleResponse) => void;
  remove: (index: number) => void;
  paymentTypes: PaymentTypes[];
}

export const Items = ({
  control,
  quotes,
  handleAddRow,
  handleVehicleFields,
  remove,
  paymentTypes,
  imagesUrls,
  setImagesUrls,
}: Props) => {
  return (
    <>
      <VehicleInfo
        control={control}
        handleVehicleFields={handleVehicleFields}
        paymentTypes={paymentTypes}
      />
      <ProductTable.Root
        heading={<h1 className={styles.label}>Itens para cotação:</h1>}
      >
        <ProductTableHeader />
        <ProductTable.Body>
          {quotes.map((quote, index) => (
            <ProductTableRow
              key={quote.id}
              control={control}
              index={index}
              onRemove={() => {
                // must be have at least one row
                if (quotes.length === 1) {
                  remove(index);
                  handleAddRow();
                } else {
                  remove(index);
                }
              }}
            />
          ))}
        </ProductTable.Body>
        <Separator theme="square" />

        <Button
          handleClick={handleAddRow}
          variant="register"
          style={{
            fontFamily: 'Bebas Neue',
            fontSize: '1rem',
            borderRadius: '4px',
            background: 'var(--primary)',
            padding: '0.35rem 0.5rem',
          }}
        >
          {' '}
          <PlusSquare size={24} weight="fill" /> Adicionar Item
        </Button>
      </ProductTable.Root>

      <div className={styles.containerListImages}>
        <ImageInput
          bucketPath="quotation"
          setImageUrls={setImagesUrls}
          imagesUrls={imagesUrls}
          maxImages={5}
          size={100}
        />
      </div>
      <div className={styles.observation} style={{ width: '100%' }}>
        <Controller
          name="comment"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Textarea
              name={name}
              handleChange={onChange}
              value={value}
              label="Observações:"
            />
          )}
        />
      </div>
    </>
  );
};
