import { QRCodeSVG } from 'qrcode.react';
import logo from '../../assets/icons/autocenter_logo.svg';

interface Props {
  url: string;
  className?: string;
  description?: boolean;
}

export const QRCode = ({ url, className, description = true }: Props) => {
  return (
    <div
      id="qrcode"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: '1rem',
      }}
      className={className}
    >
      {description && (
        <span
          style={{
            fontWeight: 700,
            fontSize: '1.2rem',
          }}
        >
          Acesse as imagens do checklist pelo QR CODE ou link abaixo:{' '}
        </span>
      )}
      <QRCodeSVG
        value={url}
        fgColor="#023704"
        size={200}
        imageSettings={{
          src: logo,
          height: 24,
          width: 24,
          excavate: true,
        }}
      />
      <a href={url}>Link de acesso</a>

      <br />
    </div>
  );
};
