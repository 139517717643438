import React from 'react';
import { PlusSquare } from '@phosphor-icons/react';
import styles from './image-input.module.css';
import { LoadingSpinner } from '../LoadingSpinner';

interface ImageUploaderProps {
  isSending: boolean;
  imageClassName?: string;
  size?: string | number;
  handleAddImage: () => void;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  handleAddImage,
  isSending,
  imageClassName,
  size,
}) => {
  return (
    <div className={`${styles['add-image-button']} ${imageClassName}`}>
      <button type="button" onClick={handleAddImage}>
        {isSending ? (
          <LoadingSpinner
            size={size === 'auto' ? 24 : parseInt(`${size}`, 10) / 4}
          />
        ) : (
          <PlusSquare size={32} />
        )}
      </button>
    </div>
  );
};
