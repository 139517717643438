import { SignOut, User } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

import styles from './account-control-header.module.css';
import { COLORS } from '../../../styles/colors';

interface AccountControlHeaderProps {
  username: string | undefined;
  route: string;
  handleSingOut: () => void;
}

export const AccountControlHeader = ({
  username,
  route,
  handleSingOut,
}: AccountControlHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles['account-control']}>
      <div className={styles['account-control-profile']}>
        <button
          type="button"
          className={styles['account-control-profile-name']}
          onClick={() => navigate(route)}
        >
          <User size={window.innerWidth > 480 ? 18 : 14} weight="bold" />
          <p>{username || 'Meu Perfil'}</p>
        </button>

        <button
          type="button"
          className={styles.signout}
          onClick={handleSingOut}
        >
          <SignOut size={20} color={COLORS.RED} weight="bold" />
        </button>
      </div>
    </div>
  );
};
