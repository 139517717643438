import { Button } from '../../../../../shared/Button';
import { ModalHeader } from '../../../Checklists/components/ModalHeader';
import { ModalContent } from '../../../Finance/components/Modal';
import styles from './add-existing-checklist-confirmation-modal.module.css';

interface Props {
  handleClose: () => void;
  handleConfirm: () => void;
}

export const AddExistingChecklistConfirmationModal = ({
  handleClose,
  handleConfirm,
}: Props) => {
  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Atenção" />
      <p className={styles.message}>
        Ao confirmar, você usará o último checklist no lugar,{' '}
        <span className={styles.advice}>o atual será excluído</span>
      </p>
      <div className={styles.actions}>
        <Button handleClick={handleClose} className={styles['second-button']}>
          Cancelar
        </Button>
        <Button
          handleClick={async () => {
            await handleConfirm();
          }}
          className={styles['primary-button']}
        >
          Confirmar
        </Button>
      </div>
    </ModalContent>
  );
};
