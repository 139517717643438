import { Pencil, Trash, X } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { Button } from '../../../../../../../shared/Button';
import { TransactionCodeResponse } from '../../../../interfaces';
import styles from './transaction-code-item.module.css';

interface Props {
  transactionCode: TransactionCodeResponse;
  onRemove: () => void;
  onEdit: (newName: string) => void;
}

export const TransactionCodeItem = ({
  transactionCode,
  onEdit,
  onRemove,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [transactionCodeName, setTransactionCodeName] = useState('');

  useEffect(() => {
    setTransactionCodeName(transactionCode.name);
  }, [transactionCode]);

  return (
    <li className={styles.item}>
      {isEditing ? (
        <div>
          {transactionCode.code} -{' '}
          <input
            type="text"
            value={transactionCodeName}
            onChange={e => setTransactionCodeName(e.target.value)}
          />
        </div>
      ) : (
        <span>
          {transactionCode.code} - {transactionCodeName}
        </span>
      )}

      {/* {transactionCode.editable && ( */}
      <div className={styles.actions}>
        <Button
          handleClick={() => onRemove()}
          variant="icon"
          icon={<Trash size={24} />}
        />

        <Button
          handleClick={() => {
            if (isEditing) {
              setIsEditing(false);
              onEdit(transactionCodeName);
            } else {
              setIsEditing(true);
            }
          }}
          variant="icon"
          icon={isEditing ? <BiSave size={24} /> : <Pencil size={24} />}
        />
      </div>
      {/* )} */}
    </li>
  );
};
