import { TransactionResponse } from '../../../interfaces';

export default function setSituation(transaction: TransactionResponse) {
  const { type, created_at, due_date, finished } = transaction;
  if (finished) {
    if (type === 'income')
      return {
        value: 'Recebida',
        color: '#0B5226',
      };
    return {
      value: 'Paga',
      color: '#C71D1A',
    };
  }
  if (type === 'income')
    return {
      value: 'A receber',
      color: '#5E5F60',
    };
  return {
    value: 'A pagar',
    color: '#5E5F60',
  };
}
