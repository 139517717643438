import { ButtonComponent } from '../../pages/Transactions/components/Button';

import styles from './create-transaction-message.module.css';

export interface CreateTransactionMessageModalProps {
  message: string;
  onClose: () => void;
}

export const CreateTransactionMessageModal = ({
  message,
  onClose,
}: CreateTransactionMessageModalProps) => {
  return (
    <div>
      <strong className={styles.subtitle}>{message}</strong>

      <ButtonComponent
        text="OK"
        style={{ margin: 'auto', width: 212 }}
        onClick={onClose}
      />
    </div>
  );
};
