import { MagnifyingGlass, PlusCircle } from 'phosphor-react';
import { useQuery } from 'react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { PageLayout } from '../../../shared/PageLayout';
import { Button } from '../../../shared/Button';
import styles from './checklists.module.css';
import { ChecklistTable } from './components/ChecklistTable';
import { InputComponent } from '../../../shared/InputComponent';
import { CreateChecklistModal } from './components/CreateChecklistModal';
import useCreateChecklistModal from './hooks/useCreateChecklistModal';
import {
  createChecklistController,
  getChecklistsController,
} from './controller';
import { Checklist } from '../../../@types/checklist';
import { Loading } from '../../../shared/Loading';
import useEditChecklistModal from './hooks/useEditChecklistModal';
import { DeleteChecklistModal } from './components/DeleteChecklistModal';
import useDeleteChecklistModal from './hooks/useDeleteChecklistModal';
import useSendChecklistModal from './hooks/useSendChecklistModal';
import { SendChecklistModal } from './components/SendChecklistModal';
import { ConfirmRedoChecklistModal } from './components/ConfirmRedoChecklistModal';
import { numberOnly } from '../../../services/helpers/mask';

export const Checklists = () => {
  const createModal = useCreateChecklistModal();
  const editModal = useEditChecklistModal();
  const deleteModal = useDeleteChecklistModal();
  const sendModal = useSendChecklistModal();

  const [selectedChecklist, setSelectedChecklist] = useState<Checklist | null>(
    null,
  );
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState('Agora');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const [searchedChecklist, setSearchedChecklist] = useState<Checklist[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const hasFetchedBefore = useRef(false);

  const fetchChecklists = useCallback(
    async (searchQuery = ''): Promise<Checklist[]> => {
      setIsLoading(true);
      const res = await getChecklistsController(searchQuery);
      setLastUpdated(new Date()); // Atualiza a data da última atualização
      setIsLoading(false);
      return Array.isArray(res) ? res : [];
    },
    [],
  );

  const {
    data: checklists,
    refetch,
    isFetched,
  } = useQuery({
    queryKey: ['checklists'],
    queryFn: () => fetchChecklists(),
    staleTime: 1000 * 60 * 5, // Cache por 5 minutos
    enabled: !hasFetchedBefore.current, // Evita chamada dupla
  });

  useEffect(() => {
    if (isFetched) {
      hasFetchedBefore.current = true; // 🔥 Define que já buscou antes
    }
  }, [isFetched]);
  const handleRefetch = async () => {
    await refetch();
    setLastUpdated(new Date()); // Atualiza a data da última atualização
  };

  const handleRowClick = useCallback((checklist: Checklist | null) => {
    setSelectedChecklist(previous => {
      if (
        previous &&
        checklist &&
        previous.id_checklist_vehicle === checklist.id_checklist_vehicle
      )
        return null;
      return checklist;
    });
  }, []);

  const calculateElapsedTime = useCallback((timestamp: Date | null) => {
    if (!timestamp) return 'Agora';

    const now = new Date();
    const diffInSeconds = Math.floor(
      (now.getTime() - timestamp.getTime()) / 1000,
    );

    if (diffInSeconds < 60) return 'Agora';
    if (diffInSeconds < 3600) return `Há ${Math.floor(diffInSeconds / 60)} min`;
    if (diffInSeconds < 86400)
      return `Há ${Math.floor(diffInSeconds / 3600)} h`;

    return `Há ${Math.floor(diffInSeconds / 86400)} d`;
  }, []);

  // Atualiza o tempo decorrido sempre que `lastUpdated` mudar
  useEffect(() => {
    if (lastUpdated) {
      setElapsedTime(calculateElapsedTime(lastUpdated));
    }
  }, [lastUpdated, calculateElapsedTime]);

  // Atualiza automaticamente a cada 60 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      if (lastUpdated) {
        setElapsedTime(calculateElapsedTime(lastUpdated));
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [lastUpdated, calculateElapsedTime]);

  const handleSearchChange = useCallback(async () => {
    const res = await fetchChecklists(debouncedQuery);
    if (res) {
      setSearchedChecklist(res);
    }
  }, [debouncedQuery, fetchChecklists]);

  useEffect(() => {
    handleSearchChange();
  }, [handleSearchChange]);

  const handleRedoChecklist = useCallback(async () => {
    if (selectedChecklist) {
      const formattedData = {
        plate: selectedChecklist.plate ?? '',
        checklist_category_details:
          selectedChecklist.checklist_category_details?.map(detail => ({
            category: detail.category ?? '',
            observations: detail.observations ?? '',
          })) ?? [],
        checklist_images:
          selectedChecklist.checklist_vehicle_imgs?.map(img => ({
            img_url: img.img_url ?? '',
            category: img.category ?? '',
          })) ?? [],
        name: selectedChecklist.name ?? '',
        phone: numberOnly(selectedChecklist.phone ?? ''),
      };
      const res = await createChecklistController(formattedData);
      if (res) {
        handleRowClick(res);
        createModal.onOpen();
        editModal.onClose();
      }
    }
  }, [createModal, editModal, handleRowClick, selectedChecklist]);

  return (
    <PageLayout.Root title="Checklists">
      {createModal.isOpen && (
        <CreateChecklistModal
          refetch={handleRefetch}
          checklist={selectedChecklist}
        />
      )}
      {editModal.isOpen && (
        <ConfirmRedoChecklistModal
          checklist={selectedChecklist}
          onCreate={() => handleRedoChecklist()}
          onClose={() => editModal.onClose()}
        />
      )}
      {deleteModal.isOpen && (
        <DeleteChecklistModal
          refetch={handleRefetch}
          checklist={selectedChecklist}
        />
      )}
      {sendModal.isOpen && (
        <SendChecklistModal
          checklist={selectedChecklist}
          onTransformChecklist={refetch}
        />
      )}

      <div className={styles.header}>
        <InputComponent
          placeholder="Buscar checklist"
          icon={<MagnifyingGlass />}
          onChange={e => setQuery(e.target.value)}
          className={styles['input-search']}
        />
        <div>
          <Button
            className={styles['new-checklist-button']}
            handleClick={() => {
              handleRowClick(null);
              createModal.onOpen();
            }}
          >
            <PlusCircle size={24} />
            Novo Checklist
          </Button>
        </div>
      </div>

      {/* <span className={styles['elapsed-time']}>
        Última atualização: {elapsedTime}
      </span> */}

      <div className={styles.content}>
        {isLoading ? (
          <div className={styles['loading-container']}>
            <Loading />
            <Loading />
            <Loading />
            <Loading />
          </div>
        ) : (
          <ChecklistTable
            checklists={
              debouncedQuery.trim().length > 0
                ? searchedChecklist
                : (checklists as Checklist[])
            }
            handleRowClick={handleRowClick}
            selectedChecklist={selectedChecklist}
          />
        )}
      </div>
    </PageLayout.Root>
  );
};
