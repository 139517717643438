import { ComponentProps, forwardRef } from 'react';
import styles from './table-root.module.css';
import responsive from './table-root-responsive.module.css';

type TableRootProps = ComponentProps<'table'> & {
  AutoResponsive?: boolean;
  height?: string;
  tableScrollClassName?: string;
};

export const TableRoot = forwardRef<HTMLDivElement, TableRootProps>(
  (
    {
      children,
      AutoResponsive = false,
      height = '100%',
      tableScrollClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={`${styles.scroll} ${tableScrollClassName}`}
        style={{ height }}
        ref={ref}
      >
        <table
          {...props}
          className={`${styles['table-container']} ${props.className} ${
            AutoResponsive && responsive.auto
          }`}
        >
          {children}
        </table>
      </div>
    );
  },
);
