export const transactionTypeMap: { [key: number]: string } = {
  0: 'receivable',
  1: 'payable',
};

export const dayOfWeekMap: { [key: number]: string } = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};
