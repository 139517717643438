import { ChartBar, Notebook, GearSix } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FinanceDashboard } from './pages/Dashboard';
import { Transactions } from './pages/Transactions';
import { Report } from './pages/Report';
import { Settings } from './pages/Settings';
import { HeaderComponent } from '../../../shared/HeaderComponent';
import { TransactionTypes } from './interfaces';

import styles from './finance.module.css';
import { MenuNavigation } from './components/MenuNavigation';
import { DashboardIcon } from '../../../shared/SvgToIcon/DashboardIcon';

export const Finance = () => {
  const [currentPage, setCurrentPage] = useState('Dashboard');
  const [selectedMonth, setSelectedMonth] = useState(
    (new Date().getMonth() + 1).toString(),
  );
  const [selectedYear, setSelectedYear] = useState('');
  const [filter, setFilter] = useState<TransactionTypes | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const afterRecovery = queryParams.get('afterRecovery');
  const navigate = useNavigate();

  useEffect(() => {
    if (afterRecovery) {
      setCurrentPage('Settings');
    }
  }, [afterRecovery]);

  const pages = {
    DASHBOARD: {
      tittle: 'Dashboard',
      value: 'Dashboard',
      icon: DashboardIcon,
    },
    TRANSACTIONS: {
      tittle: 'Lançamentos',
      value: 'Transactions',
      icon: Notebook,
    },
    REPORT: {
      tittle: 'Relatórios',
      value: 'Report',
      icon: ChartBar,
    },
    SETTINGS: {
      tittle: 'Configurações',
      value: 'Settings',
      icon: GearSix,
    },
  };

  const renderContent = () => {
    if (currentPage === pages.DASHBOARD.value) {
      return (
        <FinanceDashboard
          setCurrentPage={setCurrentPage}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
          setFilter={setFilter}
        />
      );
    }

    if (currentPage === 'Transactions') {
      return (
        <Transactions
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          filter={filter}
        />
      );
    }

    if (currentPage === 'Report') {
      return <Report />;
    }

    if (currentPage === 'Settings') {
      return <Settings />;
    }

    return <div>vazio</div>;
  };

  function handleNavigationHeader() {
    if (currentPage === pages.DASHBOARD.value) return navigate('/home');
    return setCurrentPage(pages.DASHBOARD.value);
  }

  return (
    <main className={styles['finance-main']}>
      <HeaderComponent
        title="FINANCEIRO"
        handleNavigation={handleNavigationHeader}
      />

      <div className={styles['main-content']}>
        <div className={styles['absolute-structure']}>
          <MenuNavigation.Root className={styles['menu-navigation']}>
            {Object.entries(pages).map(([, page]) => (
              <MenuNavigation.Item
                key={page.value}
                value={page.value}
                tittle={page.tittle}
                icon={page.icon}
                onClick={() => setCurrentPage(page.value)}
                isActive={currentPage === page.value}
              />
            ))}
          </MenuNavigation.Root>
          <div className={styles['finance-body']}>{renderContent()}</div>
        </div>
      </div>
    </main>
  );
};
