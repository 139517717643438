import { PlusCircle, Trash } from 'phosphor-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { Table } from '../../../../../shared/Table';
import styles from './services-table.module.css';
import { useResponsive } from '../../../../../hooks/useResponsive';
import { OrderServiceFormDTO } from '../../CreateOrderServiceForm/OrderServiceDTO';
import { currencyMask } from '../../../../../services/helpers/mask';

interface Props {
  type: 'service' | 'product';
}

export const ServicesTable = forwardRef<HTMLDivElement, Props>(
  ({ type }, ref) => {
    const { register, control, setValue, watch, getValues } =
      useFormContext<OrderServiceFormDTO>();

    const fieldName =
      type === 'product' ? 'services.productTable' : 'services.serviceTable';

    const { fields, append, remove } = useFieldArray({
      control,
      name: fieldName,
    });

    const [errors, setErrors] = useState<
      Record<
        number,
        { name?: boolean; quantity?: boolean; unitValue?: boolean }
      >
    >({});

    // Validação dos campos
    const validateFields = useCallback(() => {
      const services = getValues(fieldName) || [];
      const newErrors: Record<
        number,
        { name?: boolean; quantity?: boolean; unitValue?: boolean }
      > = {};

      services.forEach((item, index) => {
        const unitValue = parseFloat(
          (item.unitValue || '').trim().replace(/\./g, '').replace(',', '.'),
        );

        const quantity = parseFloat(
          (item.quantity || '').trim().replace(/\./g, '').replace(',', '.'),
        );

        newErrors[index] = {
          name: !item.name || item.name.trim() === '',
          quantity: quantity <= 0 || isNaN(quantity),
          unitValue: unitValue <= 0 || isNaN(unitValue),
        };
      });

      setErrors(newErrors);
      return Object.values(newErrors).every(
        err => !err.name && !err.quantity && !err.unitValue,
      );
    }, [getValues, fieldName]);

    const addRow = useCallback(() => {
      if (validateFields()) {
        append({ name: '', quantity: '1', unitValue: '0,00' });
      }
    }, [append, validateFields]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const input = e.currentTarget;
        const isNameField = input.name.includes('name');
        if (e.key === 'Enter') {
          e.preventDefault();
          addRow();
        }

        if (e.key === 'Backspace' && e.currentTarget.value === '') {
          if (fields.length > 1 && isNameField) {
            remove(index);
          } else {
            const inputs = document.querySelectorAll('input');
            const currentIndex = Array.from(inputs).indexOf(input);
            if (currentIndex > 0) {
              const previousInput = inputs[currentIndex - 1];
              previousInput.focus();
            }
          }
        }
        if (e.key === 'Tab' && e.currentTarget.value === '') {
          if (isNameField) {
            setValue(`${fieldName}.${index}.name`, input.placeholder);
          }
        }
      },
      [addRow, fields.length, remove, setValue, fieldName],
    );

    const services = watch(fieldName) || [];

    const totalArray = services.map(item => {
      const unitValue = parseFloat(item.unitValue?.replace(',', '.') || '0');
      const quantity = parseInt(item.quantity || '1', 10);
      return unitValue * quantity;
    });

    const { sizeMobile } = useResponsive();

    const productColumn = useMemo(() => {
      // if (sizeMobile) return 'auto';

      // return type === 'product' ? '30%' : '46%';
      return sizeMobile ? 'auto' : '30%';
    }, [sizeMobile]);

    const itemColumn = useMemo(() => {
      return sizeMobile ? 'auto' : '10%';
    }, [sizeMobile]);

    const quantityColumn = useMemo(() => {
      return sizeMobile ? 'auto' : '16%';
    }, [sizeMobile]);
    return (
      <div className={styles.container} ref={ref}>
        {fields.length > 0 ? (
          <>
            <Table.Root
              className={styles.table}
              tableScrollClassName={styles.scroll}
            >
              <Table.Header value="Item" width={itemColumn} />
              <Table.Header
                value={type === 'product' ? 'Produto' : 'Serviço'}
                width={productColumn}
              />
              <Table.Header value="qtd" width={quantityColumn} />
              {/* {!sizeMobile && ( */}
              <Table.Header value="v. unit." width={quantityColumn} />
              {/* )} */}
              {/* {type === 'product' && ( */}
              <Table.Header value="total" width={quantityColumn} />
              {/* )} */}
              {!sizeMobile && <Table.Header value="" width="24" />}

              {fields.map((field, index) => (
                <Table.Row key={field.id} className={styles['table-row']}>
                  <Table.Cell width={itemColumn}>{index + 1}</Table.Cell>
                  <Table.Cell
                    width={productColumn}
                    className={styles['table-cell']}
                  >
                    <input
                      type="text"
                      {...register(`${fieldName}.${index}.name`)}
                      placeholder={
                        type === 'product'
                          ? `Produto - ${index + 1}`
                          : `Mão de obra`
                      }
                      onKeyDown={e => handleKeyDown(e, index)}
                      onChange={e => {
                        setValue(`${fieldName}.${index}.name`, e.target.value);
                        validateFields();
                      }}
                      onBlur={() => validateFields()}
                      style={{
                        background: errors[index]?.name ? '#ff000036' : '',
                        outlineColor: errors[index]?.name ? 'var(--error)' : '',
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell
                    width={quantityColumn}
                    className={`${styles['table-cell']} ${styles.center}`}
                  >
                    <input
                      type="number"
                      min="1"
                      {...register(`${fieldName}.${index}.quantity`)}
                      placeholder={sizeMobile ? 'Qtd.' : 'Quantidade'}
                      onKeyDown={e => handleKeyDown(e, index)}
                      onChange={e => {
                        const newValue = e.target.value;

                        setValue(`${fieldName}.${index}.quantity`, newValue);

                        validateFields();
                      }}
                      onBlur={() => validateFields()}
                      inputMode="decimal"
                      style={{
                        background: errors[index]?.quantity ? '#ff000036' : '',
                        outlineColor: errors[index]?.quantity
                          ? 'var(--error)'
                          : '',
                      }}
                    />
                  </Table.Cell>
                  {/* {!sizeMobile && ( */}
                  <Table.Cell
                    width={quantityColumn}
                    className={`${styles['table-cell']} ${styles.center}`}
                  >
                    <input
                      {...register(`${fieldName}.${index}.unitValue`)}
                      placeholder="Valor"
                      onKeyDown={e => handleKeyDown(e, index)}
                      onChange={e => {
                        const { value } = e.target;
                        setValue(
                          `${fieldName}.${index}.unitValue`,
                          currencyMask(value).replace('R$', ''),
                        );
                        validateFields();
                      }}
                      onBlur={() => validateFields()}
                      inputMode="decimal"
                      style={{
                        background: errors[index]?.unitValue ? '#ff000036' : '',
                        outlineColor: errors[index]?.unitValue
                          ? 'var(--error)'
                          : '',
                      }}
                    />
                  </Table.Cell>
                  {/* )} */}

                  {/* {type === 'product' && ( */}
                  <Table.Cell
                    width={quantityColumn}
                    className={styles['actions-cell']}
                  >
                    {totalArray[index]?.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                    }) || '0,00'}

                    {sizeMobile && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className={styles['remove-btn']}
                      >
                        <Trash weight="fill" size={20} />
                      </button>
                    )}
                  </Table.Cell>
                  {/* )} */}
                  {!sizeMobile && (
                    <Table.Cell width="24" className={styles['actions-cell']}>
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className={styles['remove-btn']}
                      >
                        <Trash weight="fill" size={20} />
                      </button>
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Root>

            <button
              type="button"
              className={styles['add-row-btn']}
              onClick={addRow}
            >
              <PlusCircle size={20} />
              Adicionar novo {type === 'product' ? 'item' : 'serviço'}
            </button>
          </>
        ) : (
          <div>
            <button
              type="button"
              className={styles['add-row-btn']}
              onClick={addRow}
            >
              <PlusCircle size={20} />
              Adicionar novo {type === 'product' ? 'item' : 'serviço'}
            </button>
          </div>
        )}

        <div className={styles.divisor} />
      </div>
    );
  },
);
