import ReactModal from 'react-modal';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
  ArrowCounterClockwise,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  X,
} from 'phosphor-react';
import styles from './image-input.module.css';

interface ImageModalProps {
  selectedImage: string | null;
  setSelectedImage: (img: string | null, index: number | null) => void;
  onlyView?: boolean;
  onRemoveImage?: () => void;
}
export const ImageModal = ({
  selectedImage,
  setSelectedImage,
  onRemoveImage,
  onlyView,
}: ImageModalProps) => {
  const handleClose = () => setSelectedImage(null, null);
  const handleRemoveImage = () => {
    onRemoveImage?.();
    handleClose();
  };

  return (
    <ReactModal
      isOpen={!!selectedImage}
      overlayClassName={styles.overlay}
      className={styles['modal-container']}
      onRequestClose={handleClose}
    >
      {/* Botão de Fechar */}
      <button
        type="button"
        className={styles['close-btn']}
        onClick={handleClose}
      >
        <X size={32} color="var(--silver)" weight="bold" />
      </button>

      {/* Container da imagem */}
      <div className={styles['image-modal-container']}>
        <TransformWrapper
          centerOnInit
          centerZoomedOut
          initialScale={1}
          wheel={{ step: 0.1 }}
          panning={{ disabled: false }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              {/* Controles de Zoom */}
              <div className={styles['tools-container']}>
                {!onlyView && onRemoveImage && (
                  <div className={styles.actions}>
                    {/* <button
                      type="button"
                      className={styles['edit-button']}
                      onClick={onRemoveImage}
                    >
                      Editar
                    </button> */}
                    <button
                      type="button"
                      className={styles['remove-button']}
                      onClick={handleRemoveImage}
                    >
                      Excluir
                    </button>
                  </div>
                )}
                <div className={styles['zoom-buttons']}>
                  <button
                    type="button"
                    onClick={() => zoomIn()}
                    className={styles['zoom-button']}
                  >
                    <MagnifyingGlassPlus size={32} />
                  </button>
                  <button
                    type="button"
                    onClick={() => zoomOut()}
                    className={styles['zoom-button']}
                  >
                    <MagnifyingGlassMinus size={32} />
                  </button>
                  <button
                    type="button"
                    onClick={() => resetTransform()}
                    className={styles['zoom-button']}
                  >
                    <ArrowCounterClockwise size={32} />
                  </button>
                </div>
              </div>

              {/* Área de Transformação */}
              <TransformComponent contentClass={styles['transform-component']}>
                <img
                  src={selectedImage ?? ''}
                  alt="Imagem Selecionada"
                  className={styles['selected-image']}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </ReactModal>
  );
};
