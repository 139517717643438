import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  Car,
  CaretLeft,
  CaretRight,
  CheckSquareOffset,
  FileText,
  User,
} from 'phosphor-react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLayout } from '../../../../shared/PageLayout';
import { Steps } from '../components/Steps';
import styles from '../CreateOrderServiceForm/create-order-service-form.module.css';
import { VehicleForm } from './steps/vehicleForm';
import { CustomerForm } from './steps/customerForm';
import { ServicesForm } from './steps/servicesForm';
import { ChecklistForm } from './steps/checklistForm';
import { OrderServiceFormDTO } from '../CreateOrderServiceForm/OrderServiceDTO';
import { Checklist } from '../../../../@types/checklist';
import { validationSchemas } from '../CreateOrderServiceForm/validationSchemas';
import { defaultOS } from '../CreateOrderServiceForm/createOSMockup';
import {
  getLatestChecklistController,
  getRepairController,
} from '../controller';
import { FeedbackModal } from '../../../../shared/FeedbackModal';
import { mapRepairToFormValues } from './parseOrderServiceForm';

export type OSstep = 'vehicle' | 'customer' | 'service' | 'checklist';

export const EditOrderServiceForm = () => {
  const [selectedStep, setSelectedStep] = useState<OSstep>('vehicle');
  const [latestChecklist, setLatestChecklist] = useState<Checklist | null>(
    null,
  );
  const originalRepairRef = useRef<OrderServiceFormDTO | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const repairId = queryParams.get('repairId');

  const steps = useMemo(
    () => [
      {
        label: 'Dados do veículo',
        icon: (
          <Car
            size={24}
            weight={selectedStep === 'vehicle' ? 'fill' : 'bold'}
          />
        ),
        value: 'vehicle' as OSstep,
        warning: false,
      },
      {
        label: 'Dados do cliente',
        icon: (
          <User
            size={24}
            weight={selectedStep === 'customer' ? 'fill' : 'bold'}
          />
        ),
        value: 'customer' as OSstep,
        warning: false,
      },
      {
        label: 'Checklist',
        icon: (
          <CheckSquareOffset
            size={24}
            weight={selectedStep === 'customer' ? 'fill' : 'bold'}
          />
        ),
        value: 'checklist' as OSstep,
        warning: false,
      },
      {
        label: 'Dados do serviço',
        icon: (
          <FileText
            size={24}
            weight={selectedStep === 'service' ? 'fill' : 'bold'}
          />
        ),
        value: 'service' as OSstep,
        warning: false,
      },
    ],
    [selectedStep],
  );

  const methods = useForm<OrderServiceFormDTO>({
    defaultValues: defaultOS,
    resolver: useMemo(
      () => yupResolver(validationSchemas[selectedStep]),
      [selectedStep],
    ),
    mode: 'onChange',
  });

  const handleChangeStep = async (direction: 'previous' | 'next') => {
    const currentIndex = steps.findIndex(step => step.value === selectedStep);

    if (!(await methods.trigger()) && direction === 'next') {
      return;
    }

    if (direction === 'next' && currentIndex < steps.length - 1) {
      setSelectedStep(steps[currentIndex + 1].value);
    } else if (direction === 'previous' && currentIndex > 0) {
      setSelectedStep(steps[currentIndex - 1].value);
    }
  };

  // faz a busca por checklist depois que a placa é informada
  const getLatestChecklist = useCallback(async (plate: string) => {
    const res = await getLatestChecklistController(plate);
    if (res) {
      setLatestChecklist(res);
    }
  }, []);

  const CurrentForm = useMemo(() => {
    if (selectedStep === 'vehicle')
      return <VehicleForm handlePlate={getLatestChecklist} />;
    if (selectedStep === 'customer') return <CustomerForm />;
    if (selectedStep === 'checklist')
      return <ChecklistForm latestChecklist={latestChecklist} />;
    return <ServicesForm originalRepairRef={originalRepairRef} />;
  }, [getLatestChecklist, latestChecklist, selectedStep]);

  const fetchRepairById = useCallback(async () => {
    setIsLoading(true);
    try {
      if (repairId) {
        const res = await getRepairController(repairId);
        if (res) {
          const parsedOS = mapRepairToFormValues(res);
          originalRepairRef.current = parsedOS;
          methods.reset(parsedOS);
          if (res?.vehicle?.license_plate) {
            await getLatestChecklist(res?.vehicle?.license_plate);
          }
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [getLatestChecklist, methods, repairId]);

  useEffect(() => {
    fetchRepairById();
  }, [fetchRepairById]);

  const navigate = useNavigate();

  return (
    <FormProvider {...methods}>
      <FeedbackModal isOpen={isLoading} variant="sending" />
      <div className={styles.container}>
        <PageLayout.Root
          title="EDITAR ORDEM DE SERVIÇO"
          handleNavigation={() => navigate(-1)}
        />
        <div className={styles.content}>
          <button
            type="button"
            className={styles['change-step-btn']}
            style={{
              opacity: selectedStep === 'vehicle' ? 0 : 1,
            }}
            onClick={() => handleChangeStep('previous')}
          >
            <CaretLeft size={24} weight="bold" color="var(--white)" />
          </button>
          <section className={styles['form-section']}>
            <Steps
              onClick={async (step: OSstep) => {
                if (await methods.trigger()) {
                  setSelectedStep(step);
                }
              }}
              selectedStep={selectedStep}
              steps={steps}
              ordered
            />

            {CurrentForm}
            <div className={styles['mobile-actions-container']}>
              <button
                type="button"
                className={styles['primary-btn']}
                onClick={() => handleChangeStep('previous')}
                style={{
                  display: selectedStep === 'vehicle' ? 'none' : 'flex',
                }}
              >
                <ArrowCircleLeft size={24} />
                Voltar
              </button>
              <button
                type="button"
                className={styles['second-btn']}
                onClick={() => handleChangeStep('next')}
                style={{
                  display: selectedStep === 'service' ? 'none' : 'flex',
                }}
              >
                Próximo
                <ArrowCircleRight size={24} />
              </button>
            </div>
          </section>
          <button
            type="button"
            className={styles['change-step-btn']}
            onClick={() => handleChangeStep('next')}
            style={{
              opacity: selectedStep === 'service' ? 0 : 1,
            }}
          >
            <CaretRight size={24} weight="bold" color="var(--white)" />
          </button>
        </div>
      </div>
    </FormProvider>
  );
};
