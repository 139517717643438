import React, { useState } from 'react';
import { theme } from '../../../../../styles/theme';

interface Props {
  month: string;
  revenue: number;
  expense?: number;
  number: number;
  services?: number;
}

const Card: React.FC<Props> = ({
  month,
  revenue,
  expense = 0,
  number,
  services = 0,
}) => {
  const [isHover, setIsHover] = useState(false);
  const handleFormattedNumber = (value: number) => {
    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };
  const date = new Date();
  const currnteMonth = number === date.getMonth() + 1;

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleColorWhite = (color1: string, color2: string) => {
    if (isHover) return color1;
    return color2;
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundColor: isHover ? theme.colors.green['1'] : 'white',
        borderRadius: '1rem',
        padding: '1.5rem',
        boxShadow: '0.1rem 0.1rem 1px 5px #eee',
        width: '96%',
        margin: '2rem',
        marginTop: 0,
        alignSelf: 'center',
        borderWidth: '0.1rem',
        borderColor: theme.colors.green['1'],
        borderStyle: currnteMonth ? 'solid' : 'none',
        cursor: 'default',
      }}
    >
      {/* title */}
      <p
        style={{
          fontSize: '1.8rem',
          fontWeight: 'bold',
          color: handleColorWhite('white', theme.colors.green['1']),
        }}
      >
        {month}
      </p>

      {/* row */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <p
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: handleColorWhite('white', 'black'),
          }}
        >
          Serviços realizados:
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            fontWeight: '500',
            color: handleColorWhite('white', 'black'),
          }}
        >
          {services}
        </p>
      </div>

      {/* row */}
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <p
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: handleColorWhite('white', 'black'),
          }}
        >
          Receitas:
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            fontWeight: '500',
            color: handleColorWhite('white', theme.colors.green['1']),
          }}
        >
          {handleFormattedNumber(revenue)}
        </p>
      </div> */}

      {/* row */}
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <p
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: handleColorWhite('white', '#1f1f1f'),
          }}
        >
          Despesas:
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            fontWeight: '500',
            color: theme.colors.red['2'],
          }}
        >
          {expense === 0 ? 'Não informadas' : handleFormattedNumber(expense)}
        </p>
      </div> */}

      <div
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          width: '100%',
          borderColor: handleColorWhite('white', theme.colors.green['1']),
          marginTop: '2rem',
        }}
      />

      {/* row */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <p
          style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: handleColorWhite('white', theme.colors.green['1']),
          }}
        >
          Total:
        </p>
        <p
          style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color:
              revenue - expense < 0
                ? theme.colors.red['2']
                : handleColorWhite('white', theme.colors.green['1']),
          }}
        >
          {handleFormattedNumber(revenue - expense)}
        </p>
      </div>
    </div>
  );
};

export default Card;
