import { Route, Routes as Switch } from 'react-router-dom';
import { QuotationNotification } from '../../pages/common/QuotationNofication';
import PrivateRoute from '../private/routesPrivate';
import { AgentManagement } from '../../pages/common/AgentManagement';
import { Register } from '../../pages/common/Register';
import { WorkshopQuotation } from '../../pages/common/Quotation/workshop';
import { AutoshopQuotation } from '../../pages/common/Quotation/autoshop';
import { AnswerQuotationProvider } from '../../contexts/answerQuotationContext';
import { QuotationProvider } from '../../contexts/quotationContext';
import { BudgetCreate } from '../../pages/common/BudgetCreate';
import { Notifications } from '../../pages/common/Notifications/Notifications';
import LoginLandingPage from '../../pages/common/LandingPage/Pages/Login/Login';
import { Budget } from '../../pages/common/Budget';
import { useAuth } from '../../hooks/useAuth';
import { Checklists } from '../../pages/common/Checklists';
import { CreateOrderServiceForm } from '../../pages/common/OrderService/CreateOrderServiceForm';
import { EditOrderServiceForm } from '../../pages/common/OrderService/EditOrderServiceForm';
import { OrderServiceList } from '../../pages/common/OrderService/OrderServiceList';
import { FinanceVerification } from '../../pages/common/Finance/pages/Verification';
import { Finance } from '../../pages/common/Finance';
import { PreventiveMaintenances } from '../../pages/workshop/PreventiveMaintenance';
import { PreventiveMaintenances as FleetPreventiveMaintenances } from '../../pages/fleet/PreventiveMaintenance';
import { Dashboard } from '../../pages/common/Dashboard';
import UserEdit from '../../pages/common/UserEdit/UserEdit';
import { History } from '../../pages/common/History';
import { Home } from '../../pages/common/Home';
import { Services } from '../../pages/workshop/Services';
import { SearchVehicle } from '../../pages/common/SearchVehicle';
import { ChecklistPrivateReport } from '../../pages/common/ChecklistReport/private';

export const CommonRoutes = () => {
  const { user } = useAuth();
  const userPermissions = user?.permissions ?? [];

  const commonRoutes = [
    { path: '/page-login', element: <LoginLandingPage /> },
    {
      path: '/home',
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
    },

    {
      path: '/notifications',
      element: (
        <PrivateRoute>
          <Notifications />
        </PrivateRoute>
      ),
    },
    {
      path: '/quotation-notification',
      element: (
        <PrivateRoute>
          <QuotationNotification />
        </PrivateRoute>
      ),
    },
    {
      path: '/register',
      element: (
        <PrivateRoute>
          <Register />
        </PrivateRoute>
      ),
    },
    {
      path: '/history',
      element: (
        <PrivateRoute>
          <History />
        </PrivateRoute>
      ),
    },
    {
      path: '/user-edit',
      element: (
        <PrivateRoute>
          <UserEdit />
        </PrivateRoute>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
    },
    {
      path: '/preventive-maintenance',
      element: (
        <PrivateRoute>
          {user?.role_name !== 'fleet' ? (
            <PreventiveMaintenances />
          ) : (
            <FleetPreventiveMaintenances />
          )}
        </PrivateRoute>
      ),
    },
  ];

  const PERMISSION_ROUTES = {
    OS: [
      { path: '/new-order-service-form', element: <CreateOrderServiceForm /> },
      { path: '/edit-order-service-form', element: <EditOrderServiceForm /> },
      { path: '/order-service-list', element: <OrderServiceList /> },
    ],
    CREATE_QUOT: [
      {
        path: '/new-quotation',
        element: (
          <QuotationProvider>
            <WorkshopQuotation />
          </QuotationProvider>
        ),
      },
    ],
    ANSWER_QUOT: [
      {
        path: '/answer-quotation',
        element: (
          <AnswerQuotationProvider>
            <AutoshopQuotation />
          </AnswerQuotationProvider>
        ),
      },
    ],
    BUDGET: [
      {
        path: '/budget',
        element: <Budget />,
      },
      {
        path: '/create-budget',
        element: <BudgetCreate />,
      },
    ],
    CHECKLIST: [
      {
        path: '/checklists',
        element: <Checklists />,
      },
      {
        path: '/checklist-draft',
        element: <ChecklistPrivateReport />,
      },
    ],
    SEARCH_VEHICLE: [{ path: '/search-vehicle', element: <SearchVehicle /> }],
    FINANCE: [
      {
        path: '/finance',
        element: (
          <PrivateRoute>
            <Finance />
          </PrivateRoute>
        ),
      },
      {
        path: '/FinanceVerification',
        element: (
          <PrivateRoute>
            <FinanceVerification />
          </PrivateRoute>
        ),
      },
    ],
    ADD_SERVICES: [
      {
        path: '/services',
        element: (
          <PrivateRoute>
            <Services />
          </PrivateRoute>
        ),
      },
    ],
    ADD_AGENTS: [{ path: '/agent-management', element: <AgentManagement /> }],
  };

  const userPermissionCodes = userPermissions.map(
    permission => permission.code,
  );

  const privateRoutes = Object.entries(PERMISSION_ROUTES)
    .filter(([permission]) => userPermissionCodes.includes(permission))
    .flatMap(([_, routes]) => routes);

  return (
    <Switch>
      {/*  Rotas de permissão */}
      {privateRoutes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={<PrivateRoute>{element}</PrivateRoute>}
        />
      ))}
      {/*  Rotas communs */}
      {commonRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Switch>
  );
};
