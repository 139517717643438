import styled from 'styled-components';
import { COLORS } from '../../../styles/colors';

interface TitleProps {
  disable?: boolean;
}
export const FinanceMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const FinanceBody = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: hidden;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.15rem;

  width: 100%;
  height: auto;
  padding: 1.5rem 1rem 1rem;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  background: ${COLORS.GREEN};
  color: ${COLORS.WHITE};
  font-size: 2.5rem;
  font-weight: 800;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 789px) {
    gap: 1rem;
  }
  @media only screen and (max-width: 550px) {
    min-height: 9rem;
  }
`;

export const Title = styled.div<TitleProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.8rem;

  @media only screen and (max-width: 425px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 550px) {
    width: 100%;
    margin-top: ${props => (props.disable ? `5rem` : `0`)};
  }
`;

export const BackBtn = styled.button<TitleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  background: ${COLORS.WHITE};
  color: ${COLORS.GREEN};
  font-weight: 800;
  font-size: 1.125rem;

  border: none;
  border-radius: 15px;
  padding: 0.5rem 1rem;

  cursor: pointer;

  z-index: 10;

  @media only screen and (max-width: 1200px) {
    font-size: 1.125rem;
    width: 242px;
    height: 37px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media only screen and (max-width: 973px) {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    & > span {
      display: none;
    }

    @media only screen and (max-width: 550px) {
      position: absolute;
      bottom: ${props => (props.disable ? `30px` : `8px`)};
    }
  }
`;

export const HeaderImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;

  & > img {
    width: 95%;
  }

  @media only screen and (min-width: 1200px) {
    width: 100%;
  }
`;
export const Overlay = styled.div`
  width: 18%;
  display: block;
  height: 120%;
  z-index: 11;

  @media only screen and (max-width: 973px) {
    display: none;
  }

  &.openSideBar {
    display: flex;
    justify-content: flex-end;
    position: absolute;

    top: 0;
    bottom: 0;
    width: 100% !important;
    background: #49505762;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;

  background: ${COLORS.WHITE};
  font-size: 1.25rem;
  font-weight: 800;

  z-index: 11;

  @media only screen and (max-width: 1200px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media only screen and (max-width: 768px) {
    width: 50%;
  }

  @media only screen and (max-width: 973px) {
    display: none;
  }

  &.openSideBar {
    display: flex !important;
  }
`;

export const SidebarOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  color: #696b69;

  height: 3rem;

  padding-left: 0.5rem;
  margin-bottom: 3rem;

  border-radius: 6px;

  cursor: pointer;

  :hover {
    background: ${COLORS.LIGHTGRAY};
  }

  @media only screen and (max-width: 425px) {
    font-size: 1rem;
  }
`;

export const MainContainer = styled.div`
  height: 100%;
  width: 82%;

  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 2.125rem;
  padding-bottom: 2.125rem;

  background-color: ${COLORS.LIGHTGRAY};

  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.GREEN};
    border-radius: 10px;
  }

  @media only screen and (max-width: 1600px) {
    padding: 1rem 1rem;
  }

  @media only screen and (max-width: 974px) {
    width: 100%;
  }
  @media only screen and (max-width: 425px) {
    padding: 2.125rem 0.4rem;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  @media only screen and (max-width: 550px) {
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 15px;
  }
`;
