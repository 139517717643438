import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { Button } from '../../../../../shared/Button';
import { ModalHeader } from '../../../Checklists/components/ModalHeader';
import { ModalContent } from '../../../Finance/components/Modal';
import styles from './change-vehicle-owner-modal.module.css';
import { OrderServiceFormDTO } from '../../CreateOrderServiceForm/OrderServiceDTO';

interface Props {
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ChangeVehicleOwnerModal = ({
  handleClose,
  handleConfirm,
}: Props) => {
  const { setValue } = useFormContext<OrderServiceFormDTO>();

  const clearCustomerData = useCallback(() => {
    setValue('customer.name', '');
    setValue('customer.phone', '');
    setValue('customer.email', '');
    setValue('customer.document', '');

    // dados de endereço
    setValue('customer.address.district', '');
    setValue('customer.address.city', '');
    setValue('customer.address.number', '');
    setValue('customer.address.street', '');
    setValue('customer.address.uf', '');
    setValue('customer.address.zip', '');
  }, [setValue]);

  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Atenção" />
      <p className={styles.message}>
        Ao confirmar, o vínculo com o proprietário do veículo será removido
        <span className={styles.advice}>
          O antigo proprietário será notificado
        </span>
      </p>
      <div className={styles.actions}>
        <Button handleClick={handleClose} className={styles['second-button']}>
          Cancelar
        </Button>
        <Button
          handleClick={() => {
            clearCustomerData();
            handleConfirm();
          }}
          className={styles['primary-button']}
        >
          Mudar proprietário
        </Button>
      </div>
    </ModalContent>
  );
};
