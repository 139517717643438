import { isEqual } from 'lodash';
import { OrderServiceFormDTO } from '../CreateOrderServiceForm/OrderServiceDTO';
import { CreateRepairInput } from '../../../../@types/order-service';

export const getChangedFields = (
  current: OrderServiceFormDTO,
  original: OrderServiceFormDTO,
): Partial<CreateRepairInput> => {
  const changed: Partial<OrderServiceFormDTO> = {};

  Object.entries(current).forEach(([key, currentValue]) => {
    const originalValue = original[key as keyof OrderServiceFormDTO];

    if (!isEqual(currentValue, originalValue)) {
      changed[key as keyof OrderServiceFormDTO] = currentValue as any;
    }
  });

  if (!changed.id_repair && current.id_repair) {
    changed.id_repair = current.id_repair;
  }

  return changed;
};
