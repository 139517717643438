import styled from 'styled-components';
import { COLORS } from '../../../../../styles/colors';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;

`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  min-width: 240px;

  @media screen and (max-width: 700px) {
    flex: 1;
    flex-direction: column;
  }
`;

export const ColumnTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  /* height: 100%; */

  @media only screen and (max-width: 758px) {
    width: 100%;
    flex-direction: column;
  }
  @media only screen and (max-width: 300px) {
    min-width: 20rem;
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const WhiteContainer = styled.div`
  width: 100%;

  padding: 1.33rem;

  border-radius: 13.676px;
  background-color: ${COLORS.WHITE};

  @media screen and (max-width: 700px) {
    text-align: center;
    > div {
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Table = styled.div`
  min-width: 26.125rem;
  /* height: 20.313rem; */
  height: calc(100vh - 50vh);

  padding: 0rem 1rem 2rem 1rem;

  border-radius: 13.676px;
  background-color: ${COLORS.WHITE};

  @media screen and (max-width: 700px) {
    /* text-align: center; */
    /* width: 100%; */
    /* > div { */
    /* alig n-items: center; */
    /* justify-content: center; */
    /* } */
  }
`;

export const RowTransactionsType = styled.div<{
  typeTransaction: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;

  margin-bottom: 0.813rem;
  /* background-color: #f0f0f0; */
  /* border-radius: 0 0 0.75rem 0.75rem; */
  margin-top: 0;
`;

export const TypeTransactionCell = styled.button<{
  index: number;
  active: boolean;
}>`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* width: 2.5rem;
  height: 2.5rem; */
  /* border-radius: 0 0 0.75rem 0.75rem; */
  border: none;
  background-color: transparent;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;

  color: ${(props: { active: boolean }) =>
    props.active ? COLORS.WHITE : '#263238'};

  background-color: ${(props: { active: boolean; index: number }) => {
    if (props.active) {
      if (props.index === 0) {
        return '#007a37';
      }
      return '#E53835';
    }
    return COLORS.LIGHTGRAY;
  }};

  border-radius: ${(props: { index: number }) =>
    props.index === 0 ? '0 0 0 12px' : '0 0 12px 0'};
`;

export const DayTitlePayable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const RowDays = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
`;

export const DayCell = styled.button<{
  active: boolean;
  typeTransaction: number;
}>`
  padding: 0.125rem 0.406rem 0.125rem 0.406rem;

  border-radius: 13.676px;
  /* min-width: 2.75rem;   */
  /* min-height: 19px; */
  color: ${(props: { active: boolean }) =>
    props.active ? COLORS.WHITE : COLORS.BLACK};

  background-color: ${(props: { active: boolean; typeTransaction: number }) => {
    if (props.active) {
      if (props.typeTransaction === 0) {
        return '#007a37';
      }
      return '#E53835';
    }
    return COLORS.WHITE;
  }};

  border: none;
`;

export const TransactionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  /* height: 100%; */

  overflow-y: scroll;
  padding-bottom: 2.5rem;
  height: calc(100vh - 61vh);
`;

export const TransactionContainer = styled.div<{ typeTransactions: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;

  &:hover {
    background-color: ${(props: { typeTransactions: number }) =>
      props.typeTransactions === 0 ? '#E8F5E9' : '#FBE4E4'};
    cursor: pointer;
  }

  justify-content: space-between;
  background-color: ${(props: { typeTransactions: number }) =>
    props.typeTransactions === 0 ? '#f8fcf8' : '#FEF6F6'};
  padding: 0.688rem 1rem;
  border-radius: 0.5rem;

  margin-bottom: ${(props: { typeTransactions: number }) =>
    props.typeTransactions === 0 ? '0.625rem' : '-0.375rem'}; ;
`;

export const TransactionLabelAccountPlan = styled.div`
  display: inline-flex;
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  position: relative;
  bottom: -0.5rem;

  font-size: 0.75rem;

  margin-left: 1.25rem;

  border-radius: 0.5rem;

  color: ${COLORS.WHITE};
  background-color: #0b5226;
`;

export const TransactionsEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  height: calc(100vh - 60vh);
`;

export const TransactionData = styled.span<{ isBold: boolean }>`
  font-weight: ${(props: { isBold: boolean }) => (props.isBold ? 600 : 500)};
`;

export const ActionBtns = styled.div`
  display: flex;

  flex-direction: column;
  gap: 1rem;

  justify-content: space-between;

  color: ${COLORS.WHITE};
  font-size: 1.25rem;
  font-weight: 700;

  @media only screen and (max-width: 1600px) {
    font-size: 1rem;
  }
`;

export const ReceiveBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  height: 100%;
  padding: 1rem;

  background-color: ${COLORS.GREEN};
  border-radius: 13.034px;

  cursor: pointer;

  @media only screen and (max-width: 425px) {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
`;

export const PayBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  height: 100%;
  /* width: 45%; */
  padding: 1rem;

  background-color: ${COLORS.RED};
  border-radius: 13.034px;

  cursor: pointer;

  @media only screen and (max-width: 425px) {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
`;

export const Title = styled.div`
  margin: 0.5rem 0;
  font-size: 1.4rem;
  font-weight: 900;
  color: #0d163a;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.342px;

  @media only screen and (max-width: 1600px) {
    font-size: 1rem;
  }
`;

export const OverviewHeader = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 1.25rem;
  width: 100%;

  color: #b4b3b8;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.239px;

  @media only screen and (max-width: 1600px) {
    font-size: 0.8rem;
  }
`;

export const OverviewHeaderItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 425px) {
    font-size: 0.6rem;
  }

  @media only screen and (min-width: 759px) {
    font-size: 0.6rem;
  }
  @media only screen and (min-width: 1236px) {
    font-size: 1rem;
  }
`;

export const OverviewHeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2px;
  background: #b4b3b8;
`;

export const OverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  width: 100%;
`;

export const OverviewItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.291px;
  cursor: pointer;
  margin: 0.5rem 0;

  @media only screen and (max-width: 425px) {
    font-size: 0.6rem;
  }

  @media only screen and (min-width: 759px) {
    font-size: 0.7rem;
  }
  @media only screen and (min-width: 1236px) {
    font-size: 0.85rem;
  }
`;

export const OverviewCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border-radius: 15px;
`;

export const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: #a1a0bd;

  @media only screen and (max-width: 1200px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 758px) {
    height: 1.75rem;
  }
`;

export const PriceCents = styled.div`
  font-size: 1.2rem;
  font-weight: 800;
  color: #a29ebc;

  @media only screen and (max-width: 1600px) {
    font-size: 0.8rem;
  }
`;

export const PriceWhole = styled.div`
  font-size: 2rem;
  font-weight: 800;
  color: ${COLORS.BLACK};

  @media only screen and (max-width: 1600px) {
    font-size: 1.3rem;
  }
`;

export const CurrentMonthColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  height: fit-content;
`;

export const Prices = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  margin-top: 1rem;
`;

export const PricesRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PricesGraph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 2rem;
  border-radius: 15px;
  gap: 0.5rem;

  @media only screen and (max-width: 1200px) {
    width: 6rem;
  }
`;

export const CurrentMonthRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 50%;

  @media screen and (max-width: 700px) {
    flex: 1;
    flex-direction: column;
  }
`;

export const OverviewCircleMenu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #ffff;
  curso: pointer;
  border: none;
  z-index: 11;

  @media only screen and (min-width: 974px) {
    display: none;
  }

  &.openSideBar {
    display: none !important;
  }
`;
