import { ComponentProps, useState } from 'react';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import styles from './table-filter-header.module.css';

type TableFilterHeaderProps = {
  value: string;
  width?: string;
  hasFilter?: boolean;
} & ComponentProps<'th'>;

export const TableFilterHeader = ({
  value,
  width = 'auto',
  hasFilter = false,
  ...props
}: TableFilterHeaderProps) => {
  const [iconIdentifier, setIconIdentifier] = useState(false);

  const changeIcon = () => {
    if (!hasFilter) {
      return;
    }

    setIconIdentifier(!iconIdentifier);
  };

  const switchIcon = () => {
    if (iconIdentifier) {
      return <ArrowDown size={14} />;
    }
    return <ArrowUp size={14} />;
  };

  return (
    <th
      {...props}
      className={`${styles['filter-table-header']} ${props.className}`}
      style={{
        width,
      }}
      onClickCapture={() => {
        changeIcon();
      }}
    >
      <span>{value}</span>
      {hasFilter && switchIcon()}
    </th>
  );
};
