import { ComponentProps, Dispatch, SetStateAction } from 'react';
import styles from './select.module.css';

interface Props extends ComponentProps<'select'> {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const Select = ({ setValue, value, children, ...props }: Props) => {
  return (
    <select
      name=""
      id=""
      className={`${styles.select}`}
      onChange={newValue => {
        setValue(newValue.target.value);
      }}
      value={value}
      {...props}
    >
      {children}
    </select>
  );
};
