import {
  ClipboardText,
  ClockClockwise,
  CarSimple,
  UserList,
  ChartLine,
  Wrench,
  ListNumbers,
  Envelope,
  EnvelopeOpen,
} from '@phosphor-icons/react';
import { CheckSquareOffset, Coins, UserCircle, Wallet } from 'phosphor-react';
import { useMemo } from 'react';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  newQuotations: boolean;
  newQuotationAnswers: boolean;
}

export const useHomeCards = ({ newQuotationAnswers, newQuotations }: Props) => {
  const { user } = useAuth();
  const userPermissions = useMemo(() => user?.permissions ?? [], [user]);
  const roleName = user?.role_name;

  // Cards públicos
  const publicCards = useMemo(
    () => [
      // {
      //   title: 'Meu Cadastro',
      //   uri: '/register',
      //   description: 'Acesse e configure seus dados de cadastro',
      //   notifications: false,
      //   icone: (
      //     <UserList
      //       size={window.innerWidth === 480 ? 38 : 48}
      //       weight="bold"
      //       color="#007A37"
      //     />
      //   ),
      // },
    ],
    [],
  );

  //  Mapeamento de permissões para cards específicos
  const permissionCards = useMemo(
    () => ({
      OS: [
        {
          title: 'Ordens de Serviço',
          description: 'Gerencie as ordens de serviço',
          uri: '/order-service-list',
          notifications: false,
          icone: (
            <ClipboardText
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      CREATE_QUOT: [
        {
          title: 'Cotações',
          description: 'Crie e gerencie suas cotações',
          uri: '/new-quotation',
          notifications: newQuotations,
          icone: (
            <Envelope
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      ANSWER_QUOT: [
        {
          title: 'Responder Cotações',
          description: 'Responda suas cotações recebidas',
          uri: '/answer-quotation',
          notifications: newQuotationAnswers,
          icone: (
            <EnvelopeOpen
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      BUDGET: [
        {
          title: 'Orçamentos',
          description: 'Crie e envie um novo orçamento',
          uri: '/budget',
          notifications: false,
          icone: (
            <ListNumbers
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      CHECKLIST: [
        {
          title: 'Checklists',
          description: 'Crie e visualize todos os checklists',
          uri: '/checklists',
          notifications: false,
          icone: (
            <CheckSquareOffset
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      PREVENTIVE: [
        {
          title: 'Manutenção Preventiva',
          description: 'Crie e programe as manutenções preventivas',
          uri: '/preventive-maintenance',
          notifications: false,
          icone: (
            <ClockClockwise
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      SEARCH_VEHICLE: [
        {
          title: 'Consulta de veículos',
          description: 'Consulte dados de um veículo',
          uri: '/search-vehicle',
          notifications: false,
          icone: (
            <CarSimple
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      ADD_AGENTS: [
        {
          title: 'Cadastro de Vendedores',
          description: 'Adicione e edite os vendedores ativos',
          uri: '/agent-management',
          notifications: false,
          icone: (
            <UserCircle
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      FINANCE: [
        {
          title: 'Financeiro',
          description: 'Acesse o financeiro e crie lançamentos',
          uri: '/FinanceVerification',
          notifications: false,
          icone: (
            <ChartLine
              size={window.innerWidth === 480 ? 38 : 48}
              weight="bold"
              color="#007A37"
            />
          ),
        },
      ],
      ADD_SERVICES:
        roleName === 'gas_station'
          ? [
              {
                title: 'Cadastro de Serviços',
                description: 'Cadastre seus serviços',
                uri: '/gas-station-services',
                notifications: false,
                icone: (
                  <Wrench
                    size={window.innerWidth === 480 ? 38 : 48}
                    weight="bold"
                    color="#007A37"
                  />
                ),
              },
            ]
          : [
              {
                title: 'Cadastro de Serviços',
                description: 'Cadastre seus serviços',
                uri: '/services',
                notifications: false,
                icone: (
                  <Wrench
                    size={window.innerWidth === 480 ? 38 : 48}
                    weight="bold"
                    color="#007A37"
                  />
                ),
              },
            ],
    }),
    [newQuotationAnswers, newQuotations, roleName],
  );

  //  Cards exclusivos para posto
  const gasStationCards = useMemo(
    () => [
      {
        title: 'Ajuste de Preços',
        description: 'Atualize seus preços dos combustíveis',
        uri: '/prices',
        notifications: false,
        icone: (
          <Coins
            size={window.innerWidth === 480 ? 38 : 48}
            weight="bold"
            color="#007A37"
          />
        ),
      },
      {
        title: 'Opções de Pagamento',
        description: 'Altere as opções de pagamento aceitas',
        uri: '/payments',
        notifications: false,
        icone: (
          <Wallet
            size={window.innerWidth === 480 ? 38 : 48}
            weight="bold"
            color="#007A37"
          />
        ),
      },
    ],
    [],
  );

  // Filtragem dos cards com base nas permissões do usuário
  const userCards = useMemo(() => {
    const userPermissionCodes = userPermissions.map(
      permission => permission.code,
    );

    return Object.entries(permissionCards)
      .flatMap(([permission, cards]) =>
        userPermissionCodes.includes(permission) ? cards : [],
      )
      .filter(Boolean); // Remove arrays vazios
  }, [userPermissions, permissionCards]);

  return {
    content: [
      ...publicCards,
      ...userCards,
      ...(roleName === 'gas_station' ? gasStationCards : []),
    ],
  };
};
