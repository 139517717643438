import {
  Control,
  Controller,
  FieldArrayWithId,
  useFieldArray,
} from 'react-hook-form';
import { useRef, useState } from 'react';
import { Truck } from 'phosphor-react';
import { ProductTable } from '../../../../../../../../shared/ProductTable';
import styles from './answer.module.css';
import { Button } from '../../../../../../../../shared/Button';
import { Textarea } from '../../../../../../../../shared/Textarea';
import { useQuotationValidation } from '../../hooks/useQuotationValidation';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import { FormValues, formItemQuotation } from '../../hooks/useAnswerQuotation';
import { rejectQuotationController } from '../../../../controller';
import { ConfirmModal } from '../ConfirmModal';
import { RejectModal } from '../RejectModal';
import { QuotationItem } from '../QuotationItem';
import { Input } from '../../../../../../../../shared/Input';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { currencyMask } from '../../../../../../../../services/helpers/mask';

interface Props {
  control: Control<FormValues, any>;
  handleAnswer: () => void;
  formQuotationItems: formItemQuotation[];
  addSubItem: (index: number) => void;
  removeSubItem: (indexItem: number, indexSubitem: number) => void;
  answer: AnswerQuotation | null;
  onClose: () => void;
}

export const Answer = ({
  control,
  handleAnswer,
  formQuotationItems,
  addSubItem,
  removeSubItem,
  answer,
  onClose,
}: Props) => {
  const { fields: quotesItems } = useFieldArray({ control, name: 'items' });
  const [selectedRowId, setSelectedRowId] = useState('');
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    confirmModalItems,
    setConfirmModalItems,
    validateQuotationAnswer,
    isQuotationItemAnswered,
  } = useQuotationValidation({ formQuotationItems });

  const handleQuotationItemClick = (
    item: FieldArrayWithId<FormValues, 'items', 'id'>,
  ) => {
    setSelectedRowId(previous => {
      if (item.item_quotation_id === previous) {
        return '';
      }
      return item.item_quotation_id;
    });
  };

  const handleConfirmRejection = async (comment: string) => {
    const res = await rejectQuotationController({
      id: answer?.id_answer_quotation ?? '',
      comment,
    });

    setIsRejectModalOpen(false);
    onClose();
  };

  const { sizeMobile } = useResponsive();

  return (
    <>
      <ConfirmModal
        container={containerRef.current || document.body}
        items={confirmModalItems}
        onClose={() => setConfirmModalItems([])}
        onConfirm={handleAnswer}
      />
      <RejectModal
        answer={answer}
        container={containerRef.current || document.body}
        isOpen={isRejectModalOpen}
        onClose={() => {
          setIsRejectModalOpen(false);
        }}
        onConfirm={(comment: string) => {
          handleConfirmRejection(comment);
        }}
      />
      <div className={styles.container} ref={containerRef}>
        <ProductTable.Root>
          <ProductTable.Body>
            {quotesItems.map((item, index) => (
              <QuotationItem
                key={item.id}
                item={item}
                control={control}
                index={index}
                isSelected={
                  sizeMobile ? true : item.item_quotation_id === selectedRowId
                }
                onClick={() => handleQuotationItemClick(item)}
                isAnswered={isQuotationItemAnswered(item)}
                addSubItem={addSubItem}
                removeSubItem={removeSubItem}
              />
            ))}
          </ProductTable.Body>

          <Controller
            control={control}
            name="tax_value"
            render={({ field: { name, onChange, value } }) => (
              <div className={styles['tax-value']}>
                <span>
                  <Truck size={32} color="var(--primary)" />
                  Taxa de entrega:
                </span>
                <Input
                  name={name}
                  handleChange={data => {
                    const maskedValue = data.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/(.{2})$/, '.$1');
                    onChange({
                      ...data,
                      target: {
                        ...data.target,
                        value: maskedValue,
                      },
                    });
                  }}
                  value={currencyMask(value.toString())} // Remove todas as vírgulas, exceto a primeira
                  placeholder="R$"
                  pattern="([0-9]+)"
                  // pattern="\d*"
                  inputMode="numeric"
                />
              </div>
            )}
          />

          <Controller
            control={control}
            name="comment"
            render={({ field: { name, onChange, value } }) => (
              <Textarea
                name={name}
                label="Comentários:"
                handleChange={onChange}
                value={value}
                style={{
                  marginBottom: '.5rem',
                  fontWeight: '500',
                  color: '#000000',
                }}
              />
            )}
          />
        </ProductTable.Root>

        <div className={styles.actions}>
          <Button
            handleClick={() => setIsRejectModalOpen(true)}
            variant="cancel"
          >
            Rejeitar
          </Button>

          <Button
            handleClick={() => validateQuotationAnswer(handleAnswer)}
            variant="register"
          >
            Enviar Proposta
          </Button>
        </div>
      </div>
    </>
  );
};
