/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dispatch, SetStateAction } from 'react';
import styles from './preventive-card.module.css';
import edit from '../../../../../assets/icons/edit-icon.svg';
import whatsapp from '../../../../../assets/icons/whatsapp-icon.svg';
import add from '../../../../../assets/icons/add-icon.svg';
import { Preventive, preventiveMaintenanceResponse } from '../../interface';
import { dateOffset } from '../../../../../utils/dateFunctions';
import { sendWhatsappMessage } from '../../../../../utils/sendWhatsappMessage';
import { User } from '../../../../../@types/interface';

import autocenter_logo from '../../../../../assets/icons/autocenter_logo.svg';
import CorreiaDentada from '../../../../../assets/icons/CorreiaDentada.svg';
import AlinhamentoBalanceamento from '../../../../../assets/icons/AlinhamentoBalanceamento.svg';
import Arrefecimento from '../../../../../assets/icons/Arrefecimento.svg';
import OleoMotor from '../../../../../assets/icons/OleoMotor.svg';
import PastilhaFreio from '../../../../../assets/icons/PastilhaFreio.svg';
import Vela from '../../../../../assets/icons/vela.svg';

interface Props {
  variant?: 'default' | 'add';
  onClick?: () => void;
  preventive?: Partial<preventiveMaintenanceResponse>;
  setSelectedPreventive?: Dispatch<
    SetStateAction<Partial<preventiveMaintenanceResponse> | null>
  >;
  customer?: User;
}

const formatDate = (date: Date) => {
  const day = date.getUTCDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day <= 9 ? `0${day}` : day}/${month <= 9 ? `0${month}` : month}/${
    year <= 9 ? `0${year}` : year
  }`;
};

const handleMaintenanceIcon = (preventive: Partial<Preventive>) => {
  switch (preventive?.type_name?.toLocaleLowerCase()) {
    case 'correia dentada':
      return CorreiaDentada;
    case 'alinhamento / balanceamento':
      return AlinhamentoBalanceamento;
    case 'liquido de arrefecimento':
      return Arrefecimento;
    case 'óleo do motor e filtros':
      return OleoMotor;
    case 'pastilhas de freio dianteira':
      return PastilhaFreio;
    case 'velas e cabos de ignição':
      return Vela;
    default:
      return autocenter_logo;
  }
};

const monthsDifference = (date: string) => {
  const currentDate = new Date();
  const triggerDate = new Date(date);

  const months =
    (triggerDate.getFullYear() - currentDate.getFullYear()) * 12 +
    (triggerDate.getMonth() - currentDate.getMonth());

  return months;
};

export const PreventiveCard = ({
  variant = 'default',
  onClick,
  preventive,
  setSelectedPreventive,
  customer,
}: Props) => {
  if (variant === 'add' && onClick) {
    return (
      <div className={`${styles.container}${styles.add}`}>
        <button
          type="button"
          onClick={onClick}
          style={{ all: 'unset', alignSelf: 'baseline' }}
        >
          <div className={`${styles.container} ${styles.add}`}>
            <div className={styles.content}>
              <h3 className={styles.title}>Adicionar Outro</h3>
              <img src={add} alt="adicionar manutenção" />
            </div>
          </div>
        </button>
      </div>
    );
  }
  if (preventive)
    return (
      <div
        className={`${styles.container} ${
          preventive.sent_before_date && `${styles.alert}`
        } ${preventive.sent_after_date && `${styles.expired}`}`}
      >
        <img
          src={handleMaintenanceIcon(preventive)}
          className={styles.icon}
          alt="ícone da manutenção"
        />
        <div
          className={styles.content}
          onClick={() => {
            if (setSelectedPreventive) {
              setSelectedPreventive(preventive);
            }
          }}
        >
          <h3 className={styles.title}>{preventive.type_name}</h3>
          <span className={styles.row}>
            Próxima troca:{' '}
            {preventive.trigger_date &&
              `${monthsDifference(
                preventive.trigger_date.replace('Z', '') || '',
              )} meses`}
            <b>
              {!preventive.trigger_date &&
                preventive.car_total_km_active &&
                `${preventive.car_total_km_active.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                })} km`}
            </b>
          </span>
          <span className={styles.row}>
            Data prevista:{' '}
            <b>
              {preventive.trigger_date &&
                formatDate(
                  new Date(preventive.trigger_date.replace('Z', '') || ''),
                )}
              {!preventive.trigger_date &&
                preventive.active_at &&
                formatDate(dateOffset(new Date(preventive.active_at)))}
            </b>
          </span>
        </div>
        <div className={styles.actions}>
          <button
            type="button"
            onClick={() => {
              if (setSelectedPreventive) {
                setSelectedPreventive(preventive);
              }
            }}
          >
            <img src={edit} alt="editar manutenção" />
          </button>
          <button
            type="button"
            disabled={!(customer && customer.phone)}
            onClick={() => {
              if (customer && customer.phone)
                sendWhatsappMessage(
                  `Olá ${customer.name} não se esqueça da manutenção preventiva: ${preventive.type_name}`,
                  `${customer.phone}`,
                );
            }}
          >
            <img src={whatsapp} alt="notificar cliente" />
          </button>
        </div>
      </div>
    );
  return null;
};
