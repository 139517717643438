import { MagnifyingGlass } from 'phosphor-react';
import { ComponentProps, Dispatch, SetStateAction } from 'react';
import styles from './searchbar.module.css';

interface Props extends ComponentProps<'div'> {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  status: SearchbarStatus;
}
export type SearchbarStatus =
  | 'searching'
  | 'founded'
  | 'notfound'
  | 'error'
  | '';

export const Searchbar = ({ value, setValue, status, ...props }: Props) => {
  return (
    <div className={styles.searchbar} {...props}>
      <input
        value={value}
        onChange={e => setValue(e.target.value)}
        type="text"
        placeholder="Pesquisar por descrição de lançamento"
      />
      <MagnifyingGlass size={18} />
    </div>
  );
};
