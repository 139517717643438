/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styles from './image-input.module.css';

interface Props {
  images: string[];
  pendingImages?: string[];
  onlyView: boolean;
  onImageClick?: (url: string, index: number | null) => void;
  style: React.CSSProperties;
  imageClassName?: string;
}

export const ImageList: React.FC<Props> = ({
  images,
  pendingImages,
  onlyView,
  onImageClick,
  style,
  imageClassName,
}) => {
  return (
    <>
      {images.map((image, index) => (
        <div
          key={`uploaded - ${index}`}
          className={styles['image-wrapper']}
          style={style}
        >
          <div
            onClick={() => onImageClick?.(image, index)}
            className={styles['image-container']}
          >
            <img
              src={image}
              alt={`Imagem ${index + 1}`}
              className={`${styles.image} ${imageClassName} ${
                onlyView ? styles['only-view'] : ''
              }`}
              loading="lazy"
            />
          </div>
        </div>
      ))}

      {pendingImages &&
        pendingImages.map((image, index) => (
          <div
            key={`pending-${index}`}
            className={`${styles['image-wrapper']} ${styles.pending}`}
            style={styles}
          >
            <div>
              <img
                src={image}
                alt={`pending Upload ${index + 1}`}
                className={`${styles.image} ${styles.pending} ${imageClassName}`}
              />
            </div>
          </div>
        ))}
    </>
  );
};
