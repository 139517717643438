/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Eye, EyeSlash, Info } from 'phosphor-react';
import { Button } from '../../../../../shared/Button';
import { FinanceBody, FinanceMain } from '../../_finance';
import styles from './verification.module.css';
import {
  postCreateInternalPasswordFinance,
  postInternalLoginFinance,
} from '../../controller';
import { ErrorMessage } from '../../../../workshop/PreventiveMaintenance/_prevMaintenance';
import { ForgetModalFinance } from './components/ForgetModalFinance';
import { HeaderComponent } from '../../../../../shared/HeaderComponent';
import { useAuth } from '../../../../../hooks/useAuth';

export const FinanceVerification = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showForgetModal, setShowForgetModal] = useState<boolean>(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { workshop } = useAuth();

  useEffect(() => {
    if (workshop?.remember_me && workshop?.has_internal_password) {
      navigate('/finance');
    }
    setHasPassword(!!workshop?.has_internal_password);
  }, [workshop?.remember_me, workshop?.has_internal_password]);

  const handleVerification = useCallback(async () => {
    if (hasPassword) {
      const res = await postInternalLoginFinance(password);
      if (!workshop?.remember_me && res instanceof Object) {
        sessionStorage.setItem('auth', 'true');
        if (res.is_first_access_after_recovery) {
          navigate('/finance?afterRecovery=true');
        } else {
          navigate('/finance');
        }
      } else if (res === '404') {
        location.reload();
      }
    } else {
      const response = await postCreateInternalPasswordFinance(password);
      if (response instanceof Object) {
        sessionStorage.setItem('auth', 'true');
        navigate('/finance');
      } else if (response === '403') {
        location.reload();
      }
    }
  }, [password, workshop?.remember_me]);

  return (
    <FinanceMain>
      {!(workshop?.remember_me && hasPassword) && (
        <>
          <ForgetModalFinance
            isOpen={showForgetModal}
            setIsOpen={setShowForgetModal}
          />
          <HeaderComponent title="Financeiro" />

          <FinanceBody>
            <div className={styles.center}>
              <div className={styles.container}>
                <strong className={styles.bigText}>Acesso Restrito!</strong>
                {!hasPassword ? (
                  <p>
                    Você ainda não tem uma senha para acessar o Financeiro. Crie
                    sua senha:
                  </p>
                ) : (
                  <p>Para acessar o Financeiro, insira sua senha:</p>
                )}

                <div className={styles['input-control']}>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder={hasPassword ? 'Senha' : 'Crie uma senha'}
                    className={styles.password}
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                  />
                  <div className={styles['input-icon']}>
                    <button
                      type="button"
                      onClick={() => {
                        setPasswordVisible(currentValue => !currentValue);
                      }}
                    >
                      {passwordVisible ? (
                        <EyeSlash size={28} color="#3E3E3E" />
                      ) : (
                        <Eye size={28} color="#3E3E3E" />
                      )}
                    </button>
                  </div>
                </div>

                {password !== '' && password.length < 6 && (
                  <ErrorMessage style={{ fontSize: '0.8rem' }}>
                    O tamanho mínimo é de 6 caracteres
                  </ErrorMessage>
                )}
                <div className={styles.forgot}>
                  <Button
                    variant="unstyled"
                    handleClick={() => {
                      setShowForgetModal(true);
                      setPassword('');
                    }}
                  >
                    {hasPassword && (
                      <span className={styles['forgot-text']}>
                        Esqueci minha senha
                      </span>
                    )}
                  </Button>
                </div>
                <button
                  onClick={() => handleVerification()}
                  type="button"
                  className={styles.accessBtn}
                >
                  {hasPassword ? 'Acessar' : 'Salvar'}
                </button>
                <button
                  type="button"
                  className={styles.changeAccessBtn}
                  onClick={() => {
                    setHasPassword(currentValue => !currentValue);
                  }}
                >
                  <Info size={32} color="#1e1e1e" />
                  {hasPassword ? (
                    <p>
                      Ainda não possui uma senha? <span>Clique aqui</span> para
                      cadastrar
                    </p>
                  ) : (
                    <p>
                      Já possui acesso? <span>Clique aqui</span> para fazer
                      login
                    </p>
                  )}
                </button>
              </div>
            </div>
          </FinanceBody>
        </>
      )}
    </FinanceMain>
  );
};
