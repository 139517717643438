import {
  CaretDown,
  CaretUp,
  Check,
  Eye,
  FileText,
  PencilSimple,
  X,
} from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { maskPhone } from '../../../../services/helpers/mask';
import { Button } from '../../../../shared/Button';
import { Table } from '../../../../shared/Table';
import { dateOffset, formatDate } from '../../../../utils/dateFunctions';
import { Repair } from '../../History/interfaces';
import styles from './order-service-list.module.css';
import { COLORS } from '../../../../styles/colors';

import history from '../../../../assets/icons/history.svg';
import useRemoveModal from './hooks/useRemoveModal';
import useFinishModal from './hooks/useFinishModal';
import useSelectedRepair from './hooks/useSelectedRepair';
import { Circle } from '../../../../shared/Circle';
import useInfiniteScroll from '../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../../shared/ListLoader';

interface Props {
  repairs: Repair[];
  onScroll: () => void;
  isLoading: boolean;
}

export const RepairTable = ({ repairs, onScroll, isLoading }: Props) => {
  const navigate = useNavigate();
  const removeModal = useRemoveModal();
  const finishModal = useFinishModal();
  const selectedRepair = useSelectedRepair();

  const tableRef = useInfiniteScroll(onScroll);

  const formatRepairData = (repair: Repair) => {
    let repairDate = dateOffset(new Date(repair.created_at));

    if (repair.finish_date && repair.finish_time) {
      repairDate = dateOffset(
        new Date(`${repair.finish_date}T${repair.finish_time}`),
      );
    }

    return {
      repairCode: repair?.OS ?? '---',
      name: (repair.name || repair?.user_workshop?.user?.name) ?? '---',
      phone: maskPhone(
        repair.phone ?? repair?.user_workshop?.user?.phone ?? '---',
      ),
      vehiclePlate: repair?.vehicle?.license_plate ?? '---',
      vehicleName: repair?.vehicle?.name ?? '---',
      formattedDate: formatDate(repairDate),
    };
  };

  const renderActionButtons = (repair: Repair, vehiclePlate: string) => (
    <div className={styles['table-actions']}>
      <div className={styles['action-buttons']}>
        <div className={styles['action-scroll']}>
          <Button
            handleClick={() =>
              navigate(
                `/order-service-report?orderServiceId=${
                  repair?.id_repair ?? ''
                }`,
              )
            }
          >
            <Eye size={20} color={COLORS.WHITE} />
            Visualizar
          </Button>

          <Button handleClick={removeModal.onOpen}>
            <X size={20} color={COLORS.WHITE} weight="bold" />
            Excluir
          </Button>

          <Button
            handleClick={() =>
              navigate(
                `/${
                  repair?.draft ? 'new' : 'edit'
                }-order-service-form?repairId=${repair?.id_repair ?? ''}`,
              )
            }
          >
            <PencilSimple size={20} color={COLORS.WHITE} />
            Editar
          </Button>

          {!repair?.finished && (
            <Button handleClick={finishModal.onOpen}>
              <Check size={20} color={COLORS.WHITE} />
              Finalizar OS
            </Button>
          )}
          {repair?.vehicle_id && (
            <Button
              handleClick={() =>
                navigate(`/history?licensePlate=${vehiclePlate}`)
              }
            >
              <img
                src={history}
                alt="histórico"
                className={styles['history-icon']}
              />
              Histórico
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles['table-container']}>
      <Table.Root className={styles.table} ref={tableRef}>
        <Table.Row className={styles['table-header']}>
          <Table.Header value="Código" className={styles.desktop} />
          <Table.Header value="Cliente" />
          <Table.Header value="Placa" />
          <Table.Header value="Veículo" className={styles.desktop} />
          <Table.Header value="Contato" className={styles.desktop} />
          <Table.Header value="Data" />
        </Table.Row>
        {repairs.map(repair => {
          const {
            repairCode,
            name,
            phone,
            vehiclePlate,
            vehicleName,
            formattedDate,
          } = formatRepairData(repair);

          const isSelected =
            selectedRepair.selectedRepair?.id_repair === repair.id_repair;
          const RowIcon = isSelected ? CaretUp : CaretDown;
          const status = repair.finished ? 'default' : 'modified';
          const isFinished = repair?.finished ?? false;
          return (
            <React.Fragment key={repair.id_repair}>
              <Table.Row
                className={`${styles['table-row']} ${
                  isSelected ? styles.selected : ''
                }`}
                onClick={() =>
                  selectedRepair.selectedRepair?.id_repair === repair.id_repair
                    ? selectedRepair.onRemove()
                    : selectedRepair.onSelect(repair)
                }
              >
                <Table.Cell className={styles.desktop}>
                  <div>
                    <div>
                      {!isFinished && (
                        <div className={`${styles['draft-tag']}`}>
                          <FileText size={20} />
                          Em Andamento
                        </div>
                      )}
                    </div>
                    <div className={styles['first-column']}>
                      <RowIcon size={20} />

                      {repairCode}
                      <Circle variant={status} />
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{vehiclePlate}</Table.Cell>
                <Table.Cell className={styles.desktop}>
                  {vehicleName}
                </Table.Cell>
                <Table.Cell className={styles.desktop}>{phone}</Table.Cell>
                <Table.Cell className={styles.date}>
                  {formattedDate}

                  <div className={styles.mobile}>
                    <Circle variant={status} />
                  </div>
                </Table.Cell>
              </Table.Row>
              {selectedRepair.selectedRepair?.id_repair === repair.id_repair &&
                renderActionButtons(repair, vehiclePlate)}
            </React.Fragment>
          );
        })}
      </Table.Root>
      {isLoading && <ListLoader />}
    </div>
  );
};
