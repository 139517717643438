import { useFormContext } from 'react-hook-form';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { InputComponent } from '../../../../../../shared/InputComponent';
import styles from '../../../CreateOrderServiceForm/create-order-service-form.module.css';
import { OrderServiceFormDTO } from '../../../CreateOrderServiceForm/OrderServiceDTO';
import {
  getAddressController,
  getUserByDocumentController,
} from '../../../controller';
import {
  maskCpfCnpj,
  maskPhone,
} from '../../../../../../services/helpers/mask';
import { ChangeVehicleOwnerModal } from '../../../components/ChangeVehicleOwnerModal';

interface Props {}

export const CustomerForm = (props: Props) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<OrderServiceFormDTO>();
  const [isPlateLoading, setIsPlateLoading] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  // const [changeVehicleOwner, setChangeVehicleOwner] = useState(false);
  const [
    isConfirmChangeVehicleOwnerModalOpen,
    setIsConfirmChangeVehicleOwnerModalOpen,
  ] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [showNonEditableAdvice, setShowNonEditableAdvice] = useState(false);
  const [previousCustomerPhone, setPreviousCustomerPhone] = useState('');

  const zip = watch('customer.address.zip');
  const noClient = watch('customer.noClient');
  const changeVehicleOwner = watch('customer.changeVehicleOwner');
  const userId = watch('customer.userId');
  const customerPhone = watch('customer.phone');

  const handleClearCustomer = useCallback(
    (keepFields: (keyof typeof customerFields)[] = []) => {
      const customerFields = {
        'customer.name': '',
        'customer.phone': '',
        'customer.email': '',
        'customer.document': '',
        'customer.userId': '',
        'customer.address.city': '',
        'customer.address.district': '',
        'customer.address.street': '',
        'customer.address.uf': '',
        'customer.noClient': false,
      };

      // Filtra os campos que NÃO devem ser resetados
      (Object.keys(customerFields) as (keyof typeof customerFields)[]).forEach(
        field => {
          if (!keepFields.includes(field)) {
            setValue(field, customerFields[field]);
          }
        },
      );
    },
    [setValue],
  );

  const fetchAddressInfo = useCallback(async () => {
    if (zip.length === 9) {
      try {
        const res = await getAddressController(zip);

        setValue('customer.address.city', res?.localidade || 'não encontrado');
        setValue('customer.address.district', res?.bairro || 'não encontrado');
        setValue(
          'customer.address.street',
          res?.logradouro || 'não encontrado',
        );
        setValue('customer.address.uf', res?.uf || 'não encontrado');
      } catch (err) {
        console.error(err);
      } finally {
        setIsPlateLoading(false);
      }
    }
  }, [setValue, zip]);

  const fetchUserByDocument = useCallback(
    async (documentValue: string) => {
      try {
        setIsDocumentLoading(true);
        setUserNotFound(false);
        const res = await getUserByDocumentController(documentValue);

        if (res) {
          setValue('customer.name', res.name ?? '');
          setValue('customer.phone', maskPhone(res.phone ?? ''));
          setValue('customer.email', res.email ?? '');
          setValue('customer.userId', res.id_user ?? '');
          setPreviousCustomerPhone(maskPhone(res.phone ?? ''));
        } else {
          maskPhone('');
          setUserNotFound(true);
        }
      } catch (err) {
        console.error('Error fetching user by document:', err);
      } finally {
        setIsDocumentLoading(false);
      }
    },
    [setValue],
  );

  const handleDocumentChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const unformattedDocument = value.replace(/\D/g, '');
      setValue('customer.document', maskCpfCnpj(value));

      if (unformattedDocument.length >= 11) {
        fetchUserByDocument(unformattedDocument);
      } else {
        handleClearCustomer(['customer.document']);
      }
    },
    [fetchUserByDocument, handleClearCustomer, setValue],
  );

  useEffect(() => {
    fetchAddressInfo();
  }, [fetchAddressInfo]);

  return (
    <form className={`${styles.form} `}>
      {isConfirmChangeVehicleOwnerModalOpen && (
        <ChangeVehicleOwnerModal
          handleClose={() => {
            setIsConfirmChangeVehicleOwnerModalOpen(false);
            setValue('customer.changeVehicleOwner', false);
          }}
          handleConfirm={() => {
            setIsConfirmChangeVehicleOwnerModalOpen(false);
            setValue('customer.changeVehicleOwner', true);
          }}
        />
      )}
      <div className={styles['form-row']}>
        <label htmlFor="one-time-customer" className={styles.checkbox}>
          <input
            type="checkbox"
            id="one-time-customer"
            {...register('customer.noClient')}
            onChange={e => {
              setValue('customer.noClient', e.target.checked);
              handleClearCustomer(['customer.noClient']);
            }}
          />
          Cliente avulso?
        </label>
      </div>

      {!noClient ? (
        <>
          <div className={styles['form-column']}>
            <InputComponent
              {...register('customer.document')}
              label="CPF/CNPJ:"
              className={styles.input}
              required
              onChange={e => {
                handleDocumentChange(e);
              }}
              isLoading={isDocumentLoading}
              hint={errors.customer?.document?.message ?? ''}
              state={errors.customer?.document?.message ? 'error' : 'default'}
            />
            {userNotFound && <span>Cliente não cadastrado</span>}
          </div>
          <div className={styles['form-column']}>
            <InputComponent
              {...register('customer.name')}
              label="Nome:"
              className={styles.input}
              required
              hint={errors.customer?.name?.message ?? ''}
              state={errors.customer?.name?.message ? 'error' : 'default'}
            />

            <label htmlFor="change-vehicle-owner" className={styles.checkbox}>
              <input
                type="checkbox"
                id="change-vehicle-owner"
                // checked={changeVehicleOwner}
                {...register('customer.changeVehicleOwner')}
                onChange={e => {
                  if (changeVehicleOwner) {
                    // setChangeVehicleOwner(false);
                    setValue('customer.changeVehicleOwner', false);
                  } else {
                    setIsConfirmChangeVehicleOwnerModalOpen(true);
                  }
                }}
              />
              Esse veículo pertence a outra pessoa?
            </label>
          </div>
          <div className={styles['form-row']}>
            <InputComponent
              {...register('customer.phone')}
              label="Telefone:"
              className={styles.input}
              // onClick={() => {
              //   if (userId) {
              //     setShowNonEditableAdvice(
              //       previousCustomerPhone !== customerPhone,
              //     );
              //   }
              // }}
              onChange={e => {
                if (userId) {
                  setShowNonEditableAdvice(
                    previousCustomerPhone !== customerPhone,
                  );
                }
                setValue('customer.phone', maskPhone(e.target.value));
              }}
              state={showNonEditableAdvice ? 'selected' : 'default'}
              hint={
                showNonEditableAdvice
                  ? 'Aviso: peça a seu cliente para editar essa informação.'
                  : ''
              }
            />
          </div>
          <div className={styles['form-row']}>
            <InputComponent
              {...register('customer.email')}
              label="Email:"
              className={styles.input}
            />
          </div>
          <div className={`${styles['form-row']} ${styles['cep-uf-city']}`}>
            <InputComponent
              label="CEP:"
              {...register('customer.address.zip')}
              className={`${styles.input} ${styles.cep}`}
              isLoading={isPlateLoading}
            />
            <InputComponent
              {...register('customer.address.uf')}
              label="UF:"
              className={`${styles.input} ${styles.uf}`}
            />
            <InputComponent
              {...register('customer.address.city')}
              label="Cidade:"
              className={`${styles.input} ${styles.cidade}`}
            />
          </div>
          <div className={`${styles['form-row']} ${styles['address-row']}`}>
            <InputComponent
              {...register('customer.address.district')}
              label="Bairro:"
              className={`${styles.input} ${styles.bairro}`}
            />
            <InputComponent
              {...register('customer.address.street')}
              label="Rua:"
              className={`${styles.input} ${styles.rua}`}
            />
            <InputComponent
              {...register('customer.address.number')}
              label="Número:"
              className={`${styles.input} ${styles.numero}`}
              placeholder="N°"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles['form-column']}>
            <InputComponent
              {...register('customer.name')}
              label="Nome:"
              className={styles.input}
              required
              hint={errors.customer?.name?.message ?? ''}
              state={errors.customer?.name?.message ? 'error' : 'default'}
            />
          </div>
          <div className={styles['form-row']}>
            <InputComponent
              {...register('customer.phone')}
              label="Telefone:"
              className={styles.input}
              onChange={e => {
                setValue('customer.phone', maskPhone(e.target.value));
              }}
            />
          </div>
        </>
      )}
    </form>
  );
};
