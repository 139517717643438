import { Repair } from '../../../../History/interfaces';
import { AlertMessage } from '../../../../../../shared/AlertMessage';

interface Props {
  repair: Repair | null;
  onClose: () => void;
  onFinish: () => void;
}

export const FinishModal = ({ repair, onClose, onFinish }: Props) => {
  return (
    <AlertMessage.Root
      isOpen
      onClose={onClose}
      title="Finalizar OS?"
      footer={
        <AlertMessage.Footer>
          <>
            <AlertMessage.ConfirmButton onClick={onClose}>
              Revisar
            </AlertMessage.ConfirmButton>
            <AlertMessage.CancelButton
              onClick={async () => {
                onFinish();
                onClose();
              }}
            >
              Finalizar
            </AlertMessage.CancelButton>
          </>
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p>
          A OS: <b>{repair?.external_id || repair?.OS || ''}</b> será finalizada
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
