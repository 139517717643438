import { TableFilterRow } from './TableFilterRow';
import { TableFilterRoot } from './TableFilterRoot';
import { TableFilterCell } from './TableFilterCell';
import { TableFilterHeader } from './TableFilterHeader';

export const TableUniqueSearch = {
  Root: TableFilterRoot,
  Row: TableFilterRow,
  Cell: TableFilterCell,
  Header: TableFilterHeader,
};
