import { Dispatch, SetStateAction } from 'react';
import styles from './aside.module.css';

interface Props {
  selectedOption: 'maintenances' | 'vehicles-list';
  setSelectedOption: Dispatch<SetStateAction<'maintenances' | 'vehicles-list'>>;
  isOpen: boolean;
}

export const Aside = ({ selectedOption, setSelectedOption, isOpen }: Props) => {
  const handleOptionClick = (selected: 'maintenances' | 'vehicles-list') => {
    setSelectedOption(selected);
  };

  return (
    <aside className={`${styles.aside} ${isOpen ? styles.opened : ''}`}>
      <ul>
        <li
          className={`${styles.option} ${
            selectedOption === 'maintenances' ? styles.selected : ''
          }`}
        >
          <button
            type="button"
            onClick={() => handleOptionClick('maintenances')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
            >
              <path d="M2.42871 3.75C2.42871 3.15326 2.66576 2.58097 3.08772 2.15901C3.50968 1.73705 4.08197 1.5 4.67871 1.5H9.17871C9.77545 1.5 10.3477 1.73705 10.7697 2.15901C11.1917 2.58097 11.4287 3.15326 11.4287 3.75V8.25C11.4287 8.84674 11.1917 9.41903 10.7697 9.84099C10.3477 10.2629 9.77545 10.5 9.17871 10.5H4.67871C4.08197 10.5 3.50968 10.2629 3.08772 9.84099C2.66576 9.41903 2.42871 8.84674 2.42871 8.25V3.75ZM14.4287 3.75C14.4287 3.15326 14.6658 2.58097 15.0877 2.15901C15.5097 1.73705 16.082 1.5 16.6787 1.5H21.1787C21.7754 1.5 22.3477 1.73705 22.7697 2.15901C23.1917 2.58097 23.4287 3.15326 23.4287 3.75V8.25C23.4287 8.84674 23.1917 9.41903 22.7697 9.84099C22.3477 10.2629 21.7754 10.5 21.1787 10.5H16.6787C16.082 10.5 15.5097 10.2629 15.0877 9.84099C14.6658 9.41903 14.4287 8.84674 14.4287 8.25V3.75ZM2.42871 15.75C2.42871 15.1533 2.66576 14.581 3.08772 14.159C3.50968 13.7371 4.08197 13.5 4.67871 13.5H9.17871C9.77545 13.5 10.3477 13.7371 10.7697 14.159C11.1917 14.581 11.4287 15.1533 11.4287 15.75V20.25C11.4287 20.8467 11.1917 21.419 10.7697 21.841C10.3477 22.2629 9.77545 22.5 9.17871 22.5H4.67871C4.08197 22.5 3.50968 22.2629 3.08772 21.841C2.66576 21.419 2.42871 20.8467 2.42871 20.25V15.75ZM14.4287 15.75C14.4287 15.1533 14.6658 14.581 15.0877 14.159C15.5097 13.7371 16.082 13.5 16.6787 13.5H21.1787C21.7754 13.5 22.3477 13.7371 22.7697 14.159C23.1917 14.581 23.4287 15.1533 23.4287 15.75V20.25C23.4287 20.8467 23.1917 21.419 22.7697 21.841C22.3477 22.2629 21.7754 22.5 21.1787 22.5H16.6787C16.082 22.5 15.5097 22.2629 15.0877 21.841C14.6658 21.419 14.4287 20.8467 14.4287 20.25V15.75Z" />
            </svg>
            Manutenções
          </button>
        </li>
        <li
          className={`${styles.option} ${
            selectedOption === 'vehicles-list' ? styles.selected : ''
          }`}
        >
          <button
            type="button"
            onClick={() => handleOptionClick('vehicles-list')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="24"
              viewBox="0 0 19 24"
            >
              <path d="M15.9287 0H3.92871C3.13306 0 2.37 0.316071 1.80739 0.87868C1.24478 1.44129 0.928711 2.20435 0.928711 3V21C0.928711 21.7956 1.24478 22.5587 1.80739 23.1213C2.37 23.6839 3.13306 24 3.92871 24H15.9287C16.7244 24 17.4874 23.6839 18.05 23.1213C18.6126 22.5587 18.9287 21.7956 18.9287 21V3C18.9287 2.20435 18.6126 1.44129 18.05 0.87868C17.4874 0.316071 16.7244 0 15.9287 0V0ZM5.42871 6H14.4287C14.6276 6 14.8184 6.07902 14.959 6.21967C15.0997 6.36032 15.1787 6.55109 15.1787 6.75C15.1787 6.94891 15.0997 7.13968 14.959 7.28033C14.8184 7.42098 14.6276 7.5 14.4287 7.5H5.42871C5.2298 7.5 5.03903 7.42098 4.89838 7.28033C4.75773 7.13968 4.67871 6.94891 4.67871 6.75C4.67871 6.55109 4.75773 6.36032 4.89838 6.21967C5.03903 6.07902 5.2298 6 5.42871 6ZM4.67871 9.75C4.67871 9.55109 4.75773 9.36032 4.89838 9.21967C5.03903 9.07902 5.2298 9 5.42871 9H14.4287C14.6276 9 14.8184 9.07902 14.959 9.21967C15.0997 9.36032 15.1787 9.55109 15.1787 9.75C15.1787 9.94891 15.0997 10.1397 14.959 10.2803C14.8184 10.421 14.6276 10.5 14.4287 10.5H5.42871C5.2298 10.5 5.03903 10.421 4.89838 10.2803C4.75773 10.1397 4.67871 9.94891 4.67871 9.75ZM5.42871 12H14.4287C14.6276 12 14.8184 12.079 14.959 12.2197C15.0997 12.3603 15.1787 12.5511 15.1787 12.75C15.1787 12.9489 15.0997 13.1397 14.959 13.2803C14.8184 13.421 14.6276 13.5 14.4287 13.5H5.42871C5.2298 13.5 5.03903 13.421 4.89838 13.2803C4.75773 13.1397 4.67871 12.9489 4.67871 12.75C4.67871 12.5511 4.75773 12.3603 4.89838 12.2197C5.03903 12.079 5.2298 12 5.42871 12ZM5.42871 15H9.92871C10.1276 15 10.3184 15.079 10.459 15.2197C10.5997 15.3603 10.6787 15.5511 10.6787 15.75C10.6787 15.9489 10.5997 16.1397 10.459 16.2803C10.3184 16.421 10.1276 16.5 9.92871 16.5H5.42871C5.2298 16.5 5.03903 16.421 4.89838 16.2803C4.75773 16.1397 4.67871 15.9489 4.67871 15.75C4.67871 15.5511 4.75773 15.3603 4.89838 15.2197C5.03903 15.079 5.2298 15 5.42871 15Z" />
            </svg>
            Lista de Veículos
          </button>
        </li>
      </ul>
    </aside>
  );
};
