import * as y from 'yup';

// name -> plano de contas
// type -> income | expense

const transformToNumber = (value: number, originalValue: string) => {
  if (typeof originalValue === 'string') {
    return parseFloat(originalValue.replace(/\./g, '').replace(',', '.'));
  }
  return value;
};
export const updateTransactionSchema = y.object({
  description: y.string().nullable(),
  discharge_date: y.string(),
  due_date: y.string(),
  value: y
    .mixed()
    .test(
      'Tipo inválido',
      'Insira um valor válido. Exemplo: 23,50',
      value => typeof value === 'string' || typeof value === 'number',
    )
    .transform(transformToNumber)
    .test('valor inválido', 'O valor não pode ser 0,00', value => value !== 0)
    .required('O valor é obrigatório'),
  transactionId: y
    .string()
    .uuid()
    .required('Não foi possível identificar a transação'),
  transaction_code_id: y
    .string()
    .uuid()
    .required('selecione um plano de contas'),
});

export type UpdateTransactionDTO = y.InferType<typeof updateTransactionSchema>;
