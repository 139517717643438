/* eslint-disable jsx-a11y/label-has-associated-control */
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Eye, EyeSlash } from 'phosphor-react';
import styles from './login.module.css';
import mainFigure from '../../../../../assets/img/LandingPage/mechanical.svg';
import backgroundPattern from '../../../../../assets/img/LandingPage/backgroundPatternLogin.svg';
import {
  authenticateAgentController,
  authenticateWorkshopController,
  schema,
} from './controller';
import { AuthenticationResponse, LoginProps } from './interface';
import { isAuthenticated } from '../../../../../services/api/auth';
import { useAuth } from '../../../../../hooks/useAuth';
import { getCurrentVersion } from '../../../../../shared/RefreshModal/model';
import { COLORS } from '../../../../../styles/colors';
import { Container } from '../../Components/Container/Container';
import { ForgetModalLandingPage } from './components/ForgetModal/index';
import { Button } from '../../../../../shared/Button';
import { sendWhatsappMessage } from '../../../../../utils/sendWhatsappMessage';
import bridgeWebView from '../../../../../utils/bridgeWebView';
import { FeedbackModal } from '../../../../../shared/FeedbackModal';
// import { visitorAuthenticate } from './model';

export type AccessType = 'workshop' | 'agent';

export const Login = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showForgetModal, setShowForgetModal] = useState<boolean>(false);
  const [accessType, setAccessType] = useState<AccessType>('workshop');
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { setWorkshop, setUser, authenticate } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get('token');

  useEffect(() => {
    if (token && token.length > 0) {
      localStorage.setItem('@AutoCenter: accessToken', `${token}`);
      navigate('/home');
      // localStorage.setItem('reload', 'true');
    }
  }, [navigate, token]);

  useEffect(() => {
    const handleAuthentication = async () => {
      setIsAuthenticating(true);
      try {
        if (isAuthenticated()) {
          await authenticate();
          navigate('/home');
        }
      } finally {
        setIsAuthenticating(false);
      }
    };

    handleAuthentication();
  }, [authenticate, navigate]);

  const onSubmit = async (data: LoginProps) => {
    const requestBody = {
      email: data.email.trim(),
      password: data.password,
    };

    let res: AuthenticationResponse | undefined;

    setIsLoading(true);

    if (accessType === 'workshop') {
      res = await authenticateWorkshopController(requestBody);
    } else {
      res = await authenticateAgentController(requestBody);
    }
    setIsLoading(false);
    if (res) {
      await handleLocalStorage(res);
      setWorkshop(res?.user?.workshop ?? null);
      setUser(res?.user ?? null);
      // chamada para webview setar o onesignal
      await bridgeWebView.loginOneSignal(res?.user.id_user);
    } else {
      setIsWrong(true);
      setTimeout(() => setIsWrong(false), 5000);
    }
  };

  const fetchVersion = async () => {
    const res = await getCurrentVersion();
    if (res) localStorage.setItem('@Autocenter: version', res.version);
  };

  const handleLocalStorage = useCallback(
    async (response: AuthenticationResponse) => {
      localStorage.setItem(
        '@AutoCenter: accessToken',
        `${response?.accessToken ?? ''}`,
      );
      localStorage.setItem(
        '@AutoCenter: refreshToken',
        `${response?.refreshToken ?? ''}`,
      );

      // localStorage.setItem('reload', 'true');

      await fetchVersion();

      navigate('/home');
    },
    [navigate],
  );

  const handleSwitchAccessType = (type: AccessType) => {
    setAccessType(type);
  };

  return (
    <Container>
      <ForgetModalLandingPage
        isOpen={showForgetModal}
        setIsOpen={setShowForgetModal}
        accessType={accessType}
        handleSwitchAccessType={handleSwitchAccessType}
      />
      <FeedbackModal isOpen={isAuthenticating} variant="sending" />
      <main className={styles.main}>
        <header className={styles.headerTop}>
          <strong>Fazer Login</strong>
          <p>Bem-vindo de volta 👋</p>
        </header>
        <div className={styles.wrapper}>
          <img src={mainFigure} alt="mecanico" />
          <div>
            <header className={styles.header}>
              <strong>Fazer Login</strong>
              <p>Bem-vindo de volta 👋</p>
            </header>
            <div className={styles['access-type-container']}>
              <span>Acessar como:</span>
              <div className={styles['access-type-selector']}>
                <button
                  type="button"
                  onClick={() => setAccessType('workshop')}
                  className={accessType === 'workshop' ? styles.selected : ''}
                >
                  Gestor
                </button>
                <button
                  type="button"
                  onClick={() => setAccessType('agent')}
                  className={accessType === 'agent' ? styles.selected : ''}
                >
                  Colaborador
                </button>
              </div>
            </div>
            <form
              className={styles.inputArea}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <>
                    <label htmlFor="name">Email, CPF ou CNPJ</label>
                    <input
                      type="text"
                      value={value}
                      onChange={onChange}
                      id="name"
                      placeholder="minhaconta@email.com"
                    />
                    {errors && errors?.email && (
                      <span
                        style={{
                          color: COLORS.RED,
                          fontSize: '0.8rem',
                        }}
                      >
                        {errors?.email.message}
                      </span>
                    )}
                  </>
                )}
              />

              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value } }) => (
                  <>
                    <label htmlFor="password">Senha</label>
                    <div className={styles['input-icon-container']}>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={value}
                        onChange={onChange}
                        id="password"
                        placeholder="senha de 6 dígitos"
                      />
                      <button
                        type="button"
                        className={styles.icon}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ display: value ? 'block' : 'none' }}
                      >
                        {showPassword ? (
                          <Eye size="1.8rem" color={COLORS.BLACK} />
                        ) : (
                          <EyeSlash size="1.8rem" color={COLORS.BLACK} />
                        )}
                      </button>
                    </div>
                    {isWrong ? (
                      <div style={{ width: '100%', color: COLORS.RED }}>
                        {' '}
                        Usuário ou Senha incorreto, tente novamente.
                      </div>
                    ) : null}
                    {errors && errors?.password && (
                      <span
                        style={{
                          color: COLORS.RED,
                          fontSize: '0.8rem',
                        }}
                      >
                        {errors?.password?.message}
                      </span>
                    )}
                  </>
                )}
              />

              <div className={styles.row}>
                <label htmlFor="keepLogged">
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    id="keepLogged"
                  />
                  <p className={styles.checkboxText}>Manter Logado</p>
                </label>
                <Button
                  handleClick={() => setShowForgetModal(true)}
                  variant="unstyled"
                >
                  <strong
                    style={{
                      color: 'var(--primary)',
                    }}
                  >
                    Esqueci minha senha
                  </strong>
                </Button>
              </div>

              <button
                type="submit"
                className={`${styles['login-btn']} ${
                  isWrong ? styles.wrong : ''
                }`}
              >
                {!isLoading ? 'LOGIN' : <div className={styles.loader} />}
              </button>
            </form>
            <div className={styles.cadastro_recuperacao}>
              <div className={styles.mobile}>
                <Button
                  handleClick={() => setShowForgetModal(true)}
                  variant="unstyled"
                >
                  <strong
                    style={{
                      color: 'var(--primary)',
                    }}
                  >
                    Esqueci minha senha
                  </strong>
                </Button>
              </div>
              <p>
                Ainda não tem um cadastro? Crie uma conta{' '}
                <button
                  type="button"
                  className={styles.cadastre_se}
                  onClick={() =>
                    sendWhatsappMessage(
                      `Olá! Gostaria de ajuda sobre como posso me inscrever no software da AUTOCENTER.`,
                      '+558791080605',
                    )
                  }
                >
                  Cadastre-se
                </button>
              </p>
            </div>
          </div>
        </div>

        <img
          className={styles.backgroundPatterLogin}
          src={backgroundPattern}
          alt="marca"
        />
      </main>
    </Container>
  );
};

export default Login;
