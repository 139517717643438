import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormSetValue,
} from 'react-hook-form';
import { ChangeEvent, useCallback, useState } from 'react';
import { ExpandableSection } from '../../../../../../../shared/ExpandableSection';
import { InputComponent } from '../../../../../../../shared/InputComponent';
import styles from './data-step.module.css';
import { FormValues } from '../..';
import { VehicleResponse } from '../../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import {
  maskLicensePlate,
  maskPhone,
} from '../../../../../../../services/helpers/mask';

interface Props {
  control: Control<FormValues>;
  vehicle: VehicleResponse | null;
  handleSearchVehicle: (licensePlate: string) => void;
  resetVehicle: () => void;
  setValue: UseFormSetValue<FormValues>;
  errors: Partial<FieldErrorsImpl<FormValues>>;
  isValid: boolean;
  isLoadingVehicle: boolean;
  isMobile: boolean;
}

export const DataStep = ({
  control,
  handleSearchVehicle,
  vehicle,
  resetVehicle,
  setValue,
  errors,
  isValid,
  isLoadingVehicle,
  isMobile,
}: Props) => {
  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const maskedValue = maskLicensePlate(value);
      setValue('plate', maskedValue);

      if (maskedValue.length === 7) {
        await handleSearchVehicle(maskedValue);
      } else {
        resetVehicle();
      }
    },
    [handleSearchVehicle, resetVehicle, setValue],
  );

  return (
    <form action="" className={styles.form}>
      <ExpandableSection title="Veículo">
        <div className={styles['form-row']}>
          <Controller
            control={control}
            name="plate"
            render={({ field }) => (
              <InputComponent
                label="Placa"
                width={isMobile ? '100%' : '34%'}
                maxLength={7}
                isLoading={isLoadingVehicle}
                hint={!isValid ? errors?.plate?.message ?? '' : ''}
                state={!isValid && errors?.plate ? 'error' : 'default'}
                {...field}
                onChange={handleChange}
                className={styles['input-component']}
              />
            )}
          />
          <InputComponent
            label="Veículo"
            width={isMobile ? '100%' : '62%'}
            value={vehicle?.model ?? ''}
            disabled
            className={styles['input-component']}
          />
        </div>
        <div className={styles['form-row']}>
          <InputComponent
            label="Ano"
            width={isMobile ? '100%' : '34%'}
            value={vehicle?.year ?? ''}
            disabled
            className={styles['input-component']}
          />
          <InputComponent
            label="Chassi"
            width={isMobile ? '100%' : '62%'}
            value={vehicle?.chassi ?? ''}
            disabled
            className={styles['input-component']}
          />
        </div>
      </ExpandableSection>
      <ExpandableSection title="Cliente">
        <div className={styles['form-row']}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <InputComponent
                label="Nome"
                width={isMobile ? '100%' : '60%'}
                hint={!isValid ? errors?.name?.message ?? '' : ''}
                state={!isValid && errors?.name ? 'error' : 'default'}
                {...field}
                className={styles['input-component']}
              />
            )}
          />

          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <InputComponent
                label="Telefone"
                width={isMobile ? '100%' : '38%'}
                hint={!isValid ? errors?.phone?.message ?? '' : ''}
                state={!isValid && errors?.phone ? 'error' : 'default'}
                {...field}
                onChange={e => {
                  setValue('phone', maskPhone(e.target.value));
                }}
                className={styles['input-component']}
              />
            )}
          />
        </div>
      </ExpandableSection>
    </form>
  );
};
