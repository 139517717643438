import { useRef, useCallback } from 'react';
import { MagnifyingGlass, PlusCircle } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import styles from './order-service-list.module.css';
import { removeObjectFromLocal } from '../../../../utils/localStorage';
import { DescriptionTable } from './components/DescriptionTable';
import { DeleteModal } from './components/DeleteModal';
// import { Repair } from '../../History/interfaces';
import { Button } from '../../../../shared/Button';
import { useRepairData } from './useRepairData';
import { FinishModal } from './components/FinishModal';
import { PageLayout } from '../../../../shared/PageLayout';
import { RepairTable } from './OrderServiceTable';
import useRemoveModal from './hooks/useRemoveModal';
import useSelectedRepair from './hooks/useSelectedRepair';
import useFinishModal from './hooks/useFinishModal';
import { FeedbackModal } from '../../../../shared/FeedbackModal';
import { InputComponent } from '../../../../shared/InputComponent';

export const OrderServiceList = () => {
  const navigate = useNavigate();
  const {
    filteredRepairs,
    loadMoreData,
    repairs,
    searchQuery,
    setSearchQuery,
    isLoading,
    isFetchingRepairs,
    deleteRepair,
    finishRepair,
    refetchFilteredSearch,
  } = useRepairData();

  const actionsRef = useRef<HTMLDivElement | null>(null);

  const removeModal = useRemoveModal();
  const finishModal = useFinishModal();
  const selectedRepair = useSelectedRepair();

  const onFinish = useCallback(async () => {
    await finishRepair(selectedRepair.selectedRepair?.id_repair ?? '');
    setSearchQuery('');
  }, [finishRepair, selectedRepair.selectedRepair?.id_repair, setSearchQuery]);

  const onDelete = useCallback(async () => {
    await deleteRepair(selectedRepair.selectedRepair?.id_repair ?? '');
    setSearchQuery('');
  }, [deleteRepair, selectedRepair.selectedRepair?.id_repair, setSearchQuery]);

  return (
    <PageLayout.Root title="ORDEM DE SERVIÇO">
      <FeedbackModal isOpen={isFetchingRepairs} variant="sending" />

      {repairs.length === 0 && (
        <FeedbackModal isOpen={isLoading} variant="sending" />
      )}

      {removeModal.isOpen && (
        <DeleteModal
          onDelete={onDelete}
          onClose={() => {
            removeModal.onClose();
          }}
          repair={selectedRepair.selectedRepair}
        />
      )}

      {finishModal.isOpen && (
        <FinishModal
          onFinish={onFinish}
          onClose={() => {
            finishModal.onClose();
          }}
          repair={selectedRepair.selectedRepair}
        />
      )}

      <section>
        <div className={styles['order-service-list']}>
          <div className={styles['order-service-list-header']}>
            <div className={styles.actions} ref={actionsRef}>
              <InputComponent
                value={searchQuery}
                onChange={e => {
                  setSearchQuery(e.target.value.toUpperCase());
                }}
                placeholder="Pesquisar por OS, Cliente, ou Placa"
                icon={
                  <button
                    type="button"
                    onClick={() => {
                      refetchFilteredSearch();
                    }}
                  >
                    <MagnifyingGlass
                      size="1.6rem"
                      color="#A5A5A5"
                      weight="bold"
                      className=""
                    />
                  </button>
                }
                className={styles.search}
              />
              <div className={styles.info}>
                <div className={styles.desktop}>
                  <DescriptionTable />
                </div>
                <div className={styles.button}>
                  <Button
                    handleClick={() => {
                      removeObjectFromLocal('@local-order-service');
                      navigate(`/new-order-service-form`);
                    }}
                    style={{
                      fontWeight: 700,
                      padding: '1rem',
                      boxShadow: '0px 2px 2px #00000044',
                      fontSize: '1.2rem',
                    }}
                  >
                    <PlusCircle size="2rem" /> Nova O.S.
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.mobile}>
              <DescriptionTable />
            </div>
          </div>
          <RepairTable
            repairs={searchQuery.trim().length > 0 ? filteredRepairs : repairs}
            onScroll={() => loadMoreData()}
            isLoading={isLoading}
          />
        </div>
      </section>
    </PageLayout.Root>
  );
};
