import { useFormContext } from 'react-hook-form';
import { ChangeEvent, useCallback, useState } from 'react';
import { InputComponent } from '../../../../../../shared/InputComponent';
import styles from '../../../CreateOrderServiceForm/create-order-service-form.module.css';
import { fetchVehicleDataByLicensePlateController } from '../../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import {
  maskCpfCnpj,
  maskLicensePlate,
  maskPhone,
} from '../../../../../../services/helpers/mask';
import { OrderServiceFormDTO } from '../../../CreateOrderServiceForm/OrderServiceDTO';
import { KmModal } from '../../../components/KmModal';
import { TextareaComponent } from '../../../../../../shared/TextareaComponent';

interface Props {
  handlePlate: (plate: string) => void;
}

export const VehicleForm = ({ handlePlate }: Props) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<OrderServiceFormDTO>();
  const [isLoading, setIsLoading] = useState(false);
  const [isKmModalOpen, setIsKmModalOpen] = useState(false);

  const noVehicle = watch('vehicle.noVehicle');

  // verifica se os campos já estão preenchidos para não fazer outra requisição
  const isVehicleDataFilled = useCallback(() => {
    const values = getValues();
    return (
      values.vehicle.name.trim() !== '' && values.vehicle.year.trim() !== ''
    );
  }, [getValues]);

  const fetchVehicleInfo = useCallback(
    async (licensePlate: string) => {
      if (licensePlate.length === 7) {
        if (!isVehicleDataFilled()) {
          try {
            setIsLoading(true);
            const res = await fetchVehicleDataByLicensePlateController(
              licensePlate,
            );

            setValue('vehicle.name', res?.name || 'Não encontrado');
            setValue('vehicle.year', res?.year || '');
            setValue('vehicle.km', `${res?.current_km || ''}`);
            setValue('vehicle.lastKm', `${res?.current_km || ''}`);
            setValue('vehicle.vehicleId', res?.id_vehicle ?? '');

            setValue('customer.userId', res?.user?.id_user ?? '');
            setValue('customer.name', res?.user?.name || '');
            setValue(
              'customer.document',
              maskCpfCnpj(res?.user?.document || ''),
            );
            setValue('customer.phone', maskPhone(res?.user?.phone || ''));
            setValue('customer.email', res?.user?.email || '');
            setValue('customer.address.zip', res?.user?.address?.zip || '');
            setValue('customer.address.city', res?.user?.address?.city || '');
            setValue(
              'customer.address.district',
              res?.user?.address?.district || '',
            );
            setValue(
              'customer.address.number',
              res?.user?.address?.number || '',
            );
            setValue('customer.address.uf', res?.user?.address?.uf || '');

            setIsKmModalOpen(true);

            handlePlate(licensePlate);
          } catch (error) {
            console.error('Erro ao buscar veículo:', error);
          } finally {
            setIsLoading(false);
          }
        }
      } else {
        setValue('vehicle.name', '');
        setValue('vehicle.year', '');
        setValue('vehicle.km', '');
        setValue('vehicle.lastKm', '');

        setValue('vehicle.vehicleId', '');

        setValue('customer.userId', '');
        setValue('customer.name', '');
        setValue('customer.document', '');
        setValue('customer.phone', '');
        setValue('customer.email', '');
        setValue('customer.address.zip', '');
        setValue('customer.address.city', '');
        setValue('customer.address.district', '');
        setValue('customer.address.number', '');
        setValue('customer.address.uf', '');

        setValue('checklist.checklist_category_details', []);
        setValue('checklist.checklist_images', []);
        setValue('checklist.id_checklist_vehicle', '');

        setValue('isDraft', true);
      }
    },
    [handlePlate, isVehicleDataFilled, setValue],
  );

  const handleNoVehicleOption = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    setValue('vehicle.noVehicle', isChecked);
    if (isChecked) {
      setValue('vehicle.name', '');
      setValue('vehicle.year', '');
      setValue('vehicle.km', '');
      setValue('vehicle.lastKm', '');
      setValue('vehicle.vehicleId', '');
      setValue('vehicle.plate', '');

      setValue('customer.userId', '');
      setValue('customer.name', '');
      setValue('customer.document', '');
      setValue('customer.phone', '');
      setValue('customer.email', '');
      setValue('customer.address.zip', '');
      setValue('customer.address.city', '');
      setValue('customer.address.district', '');
      setValue('customer.address.number', '');
      setValue('customer.address.uf', '');
    } else {
      setValue('vehicle.description', '');
    }
  };

  return (
    <form className={`${styles.form} `}>
      <div className={styles['form-row']}>
        <label htmlFor="others" className={styles.checkbox}>
          <input
            type="checkbox"
            id="others"
            {...register('vehicle.noVehicle')}
            onChange={handleNoVehicleOption}
          />
          Outros
        </label>
      </div>

      {!noVehicle ? (
        <>
          {isKmModalOpen && (
            <KmModal handleClose={() => setIsKmModalOpen(false)} />
          )}

          <div className={styles['form-row']}>
            <InputComponent
              {...register('vehicle.plate')}
              label="Placa:"
              className={styles.input}
              required
              placeholder="Ex: placa ABC1E23"
              maxLength={7}
              onChange={e => {
                const formattedPlate = maskLicensePlate(e.target.value);
                setValue('vehicle.plate', formattedPlate);
                fetchVehicleInfo(formattedPlate);
              }}
              isLoading={isLoading}
              hint={errors.vehicle?.plate?.message}
              state={errors.vehicle?.plate?.message ? 'error' : 'default'}
            />
          </div>
          <div className={styles['form-row']}>
            <InputComponent
              {...register('vehicle.name')}
              label="Veículo:"
              className={styles.input}
              disabled
            />
          </div>
          <div className={styles['form-row']}>
            <InputComponent
              {...register('vehicle.year')}
              label="Ano:"
              className={styles.input}
              disabled
            />
            <InputComponent
              {...register('vehicle.km')}
              label="Quilometragem:"
              className={styles.input}
              hint={errors.vehicle?.km?.message}
              state={errors.vehicle?.km?.message ? 'error' : 'default'}
            />
          </div>
        </>
      ) : (
        <div className={styles['form-row']}>
          <TextareaComponent
            {...register('vehicle.description')}
            label="Descrição: "
            hint="Informações adicionais"
            className={styles.textarea}
          />
        </div>
      )}
    </form>
  );
};
