import { useForm } from 'react-hook-form';

import { Trash } from 'phosphor-react';
import styles from './delete-transaction-form.module.css';
import { ButtonComponent } from '../../Button';
import { deleteExpense } from '../../../../../model';
import message from '../../../../../../../../utils/message';

interface DeleteTransactionProps {
  onClose?: () => void;
  transactionId: string;
  handleDelete: (id: string) => void;
}

export const DeleteTransactionForm = ({
  onClose,
  transactionId,
  handleDelete,
}: DeleteTransactionProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  async function onSubmit() {
    // http call post
    try {
      await deleteExpense(transactionId);

      handleDelete(transactionId);
      message('Transação Deletada com sucesso', 'success');
    } catch (error) {
      message(error, 'error');
    }
    if (onClose) onClose();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles['form-delete']}>
      <p>Essa conta será deletada permanentemente</p>
      <div className={styles.row}>
        <ButtonComponent text="Cancelar" onClick={onClose} />
        <ButtonComponent
          text="Deletar"
          variant="outline"
          variantStyle="danger"
          Icon={Trash}
          iconWeight="fill"
          iconSize={20}
          iconLeft
          type="submit"
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
};
