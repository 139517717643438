/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { Eye, EyeSlash } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';

import styles from './profile-edit.module.css';
import { maskPhone } from '../../../services/helpers/mask';
import { COLORS } from '../../../styles/colors';
import { updateUserPasswordController } from './controller';
import { PageLayout } from '../../../shared/PageLayout';
import { useAuth } from '../../../hooks/useAuth';
import { User } from '../../../@types/interface';

export interface Address {
  id_address: string;
  zip: string;
  street: string;
  uf: string;
  city: string;
  district: string;
  number: string;
}

export const ProfileEdit = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPhone(user?.phone ?? '');
  }, [user?.phone]);

  // const permissions = (user?.permissions ?? []).map(
  //   permission => permission.code,
  // );
  // const permissionType =
  //   permissions.includes('CREATE_QUOT') && permissions.includes('ANSWER_QUOT')
  //     ? 'quotation'
  //     : 'default';

  const navigate = useNavigate();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (
        newPassword.length > 0 ||
        confirmPassword.length > 0 ||
        phone.length >= 10
      ) {
        const res = await updateUserPasswordController({
          newPassword: newPassword.trim(),
          confirmPassword: confirmPassword.trim(),
          phone: phone.trim(),
        });

        if (res && user && user?.agents) {
          // user.agents[0].is_first_access = false;
          // localStorage.setItem('@AutoCenter: user', JSON.stringify(user));
          if (localStorage.getItem('hasToChangePassword')) {
            localStorage.removeItem('hasToChangePassword');
            navigate('/home');
          }
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout.Root title="Meu Perfil">
      <div className={styles.container}>
        <h1>Editar Dados</h1>
        <section className={styles.section}>
          <div className={styles['form-row']}>
            <div className={styles['input-control']}>
              <label htmlFor="phone">Nome da Empresa</label>
              <input
                placeholder="Nome da Empresa"
                name="Phone"
                type="text"
                value={user?.agents?.[0]?.company?.fantasy_name || ''}
                readOnly
                disabled
              />
            </div>
          </div>
        </section>
        <form>
          <section className={styles.section}>
            <div className={styles['form-row']}>
              <div className={styles['input-control']}>
                <label htmlFor="phone">Trocar seu telefone (opcional)</label>
                <input
                  placeholder="Telefone"
                  name="Phone"
                  type="text"
                  value={maskPhone(phone)}
                  onChange={e => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>
          </section>
          <section className={styles.section}>
            <div className={styles['form-row']}>
              <div className={styles['input-control']}>
                <label htmlFor="newPassword">Trocar sua senha</label>
                <input
                  placeholder="Nova Senha"
                  name="newPassword"
                  type={passwordVisible ? 'text' : 'password'}
                  value={newPassword}
                  onChange={e => {
                    setNewPassword(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className={styles.icon}
                  onClick={() =>
                    setPasswordVisible(currentValue => !currentValue)
                  }
                >
                  {passwordVisible ? (
                    <Eye size="2rem" color={COLORS.CHARCOAL} />
                  ) : (
                    <EyeSlash size="2rem" color={COLORS.CHARCOAL} />
                  )}
                </button>
              </div>
              <div className={styles['input-control']}>
                <input
                  placeholder="Confirme sua senha"
                  type="password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
          </section>
        </form>

        <button
          type="button"
          className={styles['save-button']}
          onClick={onSubmit}
          disabled={isLoading}
        >
          Salvar
        </button>
      </div>
    </PageLayout.Root>
  );
};
