import { ComponentPropsWithRef } from 'react';
import styles from './menu-navigation.module.css';

export type MenuNavigationProps = ComponentPropsWithRef<'aside'>;

export const MenuNavigationRoot = ({
  children,
  className,
  ...props
}: MenuNavigationProps) => {
  return (
    <aside {...props} className={`${styles['menu-navigation']} ${className}`}>
      {children}
    </aside>
  );
};
