/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { toast, ToastOptions, Zoom } from 'react-toastify';
import imagePlaceholder from '../../assets/img/image-placeholder.svg';
import styles from './image-input.module.css';
import {
  getAssignedUrl,
  sendBucketImage,
  deleteBucketImage,
} from '../../services/api/bucket';
import { ImageModal } from './ImageModal';
import { ImageList } from './ImageList';
import { ImageUploader } from './ImageUploader';

export interface GetAssignedUrlResponse {
  url?: string;
  filename?: string;
  image_url: string;
  key?: string;
}

type ImageInputProps = {
  width?: number;
  height?: number;
  size?: number;
  maxImages?: number;
  onImagesSelect?: (files: File[] | null) => void;
  bucketPath?: string;
  setImageUrls?: (urls: string[]) => void;
  imagesUrls?: string[];
  className?: string;
  imageClassName?: string;
  setIsUploadingImages?: Dispatch<SetStateAction<boolean>>;
  onlyView?: boolean;
};

const ImageInput: React.FC<ImageInputProps> = ({
  width,
  height,
  size,
  maxImages = 5,
  onImagesSelect,
  bucketPath = '',
  setImageUrls,
  imagesUrls = [],
  className,
  imageClassName,
  setIsUploadingImages,
  onlyView = false,
}) => {
  const [images, setImages] = useState<GetAssignedUrlResponse[]>(
    imagesUrls.map(image => ({
      url: undefined,
      filename: undefined,
      image_url: image,
      key: undefined,
    })),
  );
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [pendingImages, setPendingImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const toastConfig: ToastOptions = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    transition: Zoom,
  };

  const handleAddImage = () => {
    if (images.length < maxImages && inputFileRef.current) {
      inputFileRef.current.value = '';
      inputFileRef.current.click();
    } else {
      alert(`Você só pode adicionar até ${maxImages} imagens.`);
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length + images.length > maxImages) {
      toast.warning(`Você só pode adicionar até ${maxImages} imagens.`, {
        ...toastConfig,
      });
      return;
    }
    setIsSending(true);

    // Criar URLs temporários para exibir as imagens antes do upload
    const previewUrls = files.map(file => URL.createObjectURL(file));
    setPendingImages(prev => [...prev, ...previewUrls]);

    try {
      setIsUploadingImages?.(true);
      const newImages = await Promise.all(
        files.map(async file => {
          const uploadingToastId = toast.info(
            `Enviando imagem ${file.name}...`,
            {
              ...toastConfig,
            },
          );

          try {
            // Obter URL assinada
            const assignedUrlData = await getAssignedUrl(file.name, bucketPath);
            // Enviar imagem para o bucket
            await sendBucketImage(file, assignedUrlData.url);

            toast.update(uploadingToastId, {
              render: `Imagem ${file.name} enviada com sucesso! ✅`,
              ...toastConfig,
              type: 'success',
              autoClose: 3000,
            });

            return assignedUrlData;
          } catch (error) {
            toast.update(uploadingToastId, {
              render: `Erro ao enviar imagem ${file.name}: ${
                (error as any).response?.data?.message ||
                (error as Error).message
              }`,
              ...toastConfig,
              type: 'error',
              autoClose: 5000,
            });

            throw error; // Garante que a Promise falha para o `Promise.all`
          }
        }),
      );

      // Atualiza o estado com as novas imagens
      setImages(prevImages => [...prevImages, ...newImages]);

      // Remover as imagens da lista de pendentes após o upload
      setPendingImages(prev => prev.filter(url => !previewUrls.includes(url)));

      // Executa o callback, se houver
      if (onImagesSelect) {
        onImagesSelect(files);
      }
    } catch (error) {
      console.error('Erro ao enviar uma ou mais imagens:', error);
    } finally {
      setIsSending(false);
      setIsUploadingImages?.(false);
    }
  };

  const handleRemoveImage = async (index: number) => {
    const imageToRemove = images[index];
    try {
      // Chama a função para deletar a imagem do bucket usando a key (filepath)
      if (imageToRemove.key) await deleteBucketImage(imageToRemove.key);

      // Remove a imagem da lista de imagens no estado
      setImages(prevImages => prevImages.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Erro ao remover a imagem:', error);
      alert('Erro ao remover a imagem, por favor tente novamente.');
    }
  };

  // const handleImageClick = (imgUrl: string) => {
  //   setSelectedImage(imgUrl);
  // };

  // useEffect(() => {
  //   const images_urls = images.map(image => image.image_url || '');
  //   setImageUrls?.(images_urls);
  //   console.log('useEffect 1');
  // }, [images, setImageUrls]);

  // useEffect(() => {
  //   if (imagesUrls.length > 0) {
  //     setImages(imagesUrls.map(image => ({ image_url: image })));
  //   }
  //   console.log('useEffect 2');
  // }, [imagesUrls]);

  // ⚠️ Atualiza `images` apenas se `imagesUrls` mudar significativamente
  useEffect(() => {
    const currentImagesUrls = imagesUrls.map(image => ({ image_url: image }));

    setImages(previousImage => {
      const isSame =
        previousImage.length === imagesUrls.length &&
        previousImage.every(
          (img, index) => img.image_url === imagesUrls[index],
        );

      if (!isSame) {
        console.log('mudou');
        return currentImagesUrls;
      }
      return previousImage;
    });
  }, [imagesUrls]);

  // ⚠️ Atualiza `setImageUrls` somente quando `images` mudar
  useEffect(() => {
    if (!setImageUrls) return;

    const imageUrls = images.map(image => image.image_url);

    // 🔥 Comparação eficiente: Usa `every()` para verificar mudanças
    const isSame =
      imageUrls.length === imagesUrls.length &&
      imageUrls.every((url, index) => url === imagesUrls[index]);

    if (!isSame) {
      console.log('mudou 2');
      setImageUrls(imageUrls);
    }
  }, [images, setImageUrls, imagesUrls]);

  const getSizeStyle = () => {
    return {
      width: size || width || '',
      height: size || height || '',
    };
  };

  const placeholderImages: string[] = useMemo(
    () =>
      Array.from({
        length: maxImages - images.length - pendingImages.length - 1,
      }).map(_ => imagePlaceholder),
    [images.length, maxImages, pendingImages.length],
  );

  const handleImageClick = (img: string | null, index: number | null) => {
    setSelectedImage(img);
    setSelectedIndex(index);
  };

  return (
    <div className={`${styles['image-input-container']} ${className}`}>
      <ImageModal
        selectedImage={selectedImage}
        setSelectedImage={handleImageClick}
        onRemoveImage={() => {
          if (selectedIndex !== null) {
            handleRemoveImage(selectedIndex);
          }
        }}
        onlyView={onlyView}
      />

      <ImageList
        images={images.map(item => item.image_url)}
        pendingImages={pendingImages}
        onlyView={onlyView}
        onImageClick={handleImageClick}
        style={getSizeStyle()}
        imageClassName={imageClassName}
      />

      {!onlyView && (
        <ImageUploader
          isSending={isSending}
          imageClassName={imageClassName}
          size={getSizeStyle().width}
          handleAddImage={handleAddImage}
        />
      )}
      {!onlyView && (
        <ImageList
          images={placeholderImages}
          onlyView={onlyView}
          style={getSizeStyle()}
          imageClassName={imageClassName}
        />
      )}
      <input
        ref={inputFileRef}
        type="file"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageInput;
