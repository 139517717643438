import * as yup from 'yup';
import {
  createCustomer,
  createCustomerVehicle,
  createPreventiveMaintenance,
  deletePreventiveMaintenance,
  fetchPreventiveMaintenanceByLicensePlate,
  fetchPreventiveMaintenanceTypes,
  updateVehicleCurrentKm,
} from './model';
import { PreventiveMaintenanceInput } from './interface';
import message from '../../../utils/message';
import { handleError } from '../../../utils/handleError';

export const handleSearchMaintenances = async (licensePlate: string) => {
  try {
    const formattedLicensePlate = licensePlate
      .replace(/[^a-zA-Z0-9]/g, '')
      .trim();

    const schema = yup
      .string()
      .trim()
      .required('A placa é obrigatória')
      .length(7, 'A placa deve ter 7 caracteres');

    await schema.validate(formattedLicensePlate, { abortEarly: false });

    const res = await fetchPreventiveMaintenanceByLicensePlate(
      formattedLicensePlate,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const handleCreateMaintenances = async (
  preventive: PreventiveMaintenanceInput,
) => {
  try {
    const schema = yup.object({
      typeId: yup
        .string()
        .required('Não foi possível identificar o tipo da manutenção'),
      vehicleId: yup
        .string()
        .required('Não foi possível identificar o veículo da manutenção'),
      kmToActive: yup
        .number()
        .test(
          'averageKmDay',
          'Valor de km para próxima troca inválido',
          value => {
            if (value && value < 100) return false;
            if (value && value >= 200000) return false;
            return true;
          },
        ),
      // is_trigger_date: yup.boolean(),
      months: yup.number(),
      averageKmDay: yup
        .number()
        .required('A média de Quilometragem é obrigatória')
        .min(1, 'A média de Quilometragem deve ser maior que 0'),
      currentKm: yup
        .number()
        .required('A média de Quilometragem atual é obrigatória'),
      typeName: yup.string().nullable(),
    });
    await schema.validate(preventive, { abortEarly: false });
    return await createPreventiveMaintenance(preventive);
  } catch (error) {
    handleError(error);
  }
};

export const handleDeleteMaintenances = async (id_preventive: string) => {
  if (id_preventive && id_preventive.length !== 0) {
    return deletePreventiveMaintenance(id_preventive);
  }
  message('Não foi possível remover essa manutenção', 'error');
};
export const handleSearchMaintenancesTypes = async () => {
  try {
    const response = await fetchPreventiveMaintenanceTypes();
    if (!response) return null;
    return response.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    handleError(error);
  }
};
export const handleUpdateCurrentKm = async (
  vehicleId: string,
  currentKm: number,
) => {
  if (vehicleId && currentKm >= 100) {
    try {
      const response = await updateVehicleCurrentKm(vehicleId, currentKm);
      return response;
    } catch (err) {
      handleError(err);
    }
  }
};
export const handleRegisterVehicleCustomer = async (
  document: string,
  name: string,
  licensePlate: string,
) => {
  try {
    const schema = yup.object({
      document: yup.string(),
      name: yup.string(),
      licensePlate: yup.string(),
    });
    await schema.validate({
      document,
      name,
      licensePlate,
    });
    const createdCustomer = await createCustomer(document, name);
    await createCustomerVehicle(
      licensePlate,
      createdCustomer.id_users_Workshop,
    );
    message('veículo vinculado com sucesso', 'success');
    return createdCustomer;
  } catch (error) {
    handleError(error);
  }
};
