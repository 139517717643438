import { Preventive } from '../../interface';
import { Modal } from '../Modal';
import styles from './delete-modal.module.css';

interface Props {
  onClose: () => void;
  preventive: Partial<Preventive> | null;
  handleDeleteMaintenance: () => void;
  isModalOpen: boolean;
}

export const DeleteModal = ({
  onClose,
  handleDeleteMaintenance,
  preventive,
  isModalOpen,
}: Props) => {
  return (
    <Modal isOpen={isModalOpen} onClose={onClose} title="Tem certeza?">
      <div className={styles.container}>
        <h3>
          A manutenção: <b>{preventive?.type_name || ''}</b> será excluida
        </h3>

        <div className={styles.actions}>
          <button type="button" className={styles.cancel} onClick={onClose}>
            Cancelar
          </button>
          <button
            type="button"
            className={styles.exclude}
            onClick={() => {
              handleDeleteMaintenance();
              onClose();
            }}
          >
            Excluir
          </button>
        </div>
      </div>
    </Modal>
  );
};
