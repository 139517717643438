import { Dispatch, SetStateAction, useState } from 'react';
import api from '../../../../../services/api/api';
import message from '../../../../../utils/message';
import { CategoryProps, GroupedServiceProps } from '../../interfaces';
// import { visitorId } from '../../../../../routes/routes';
import { Modal } from '../../../PreventiveMaintenance/components/Modal';
import styles from './add-service-modal.module.css';

interface Props {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  workshopId: string;
  categories: CategoryProps[];
  services: GroupedServiceProps[];
  setIsVisitorModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddServiceModal = ({
  modalVisible,
  setModalVisible,
  workshopId,
  categories,
  services,
  setIsVisitorModalOpen,
}: Props) => {
  const [category, setCategory] = useState('');
  const [serviceName, setServiceName] = useState('');

  const handleCreateService = async () => {
    const categoryName = categories.filter(c => c.id_service === category)?.[0]
      ?.name;
    const categoryServices = services.filter(
      service => service.category === categoryName,
    )?.[0]?.services;
    const serviceNameAlreadyExists = categoryServices?.find(
      service =>
        service.name.toLocaleLowerCase() === serviceName.toLocaleLowerCase(),
    );
    const categoryAlreadyExists = categoryServices?.some(
      service => service.name.toLowerCase() === categoryName.toLowerCase(),
    );
    // if (userLocal && userLocal.id_user !== visitorId) {
    if (
      (serviceNameAlreadyExists && serviceName.length > 0) ||
      !categoryAlreadyExists
    ) {
      try {
        await api.post('/service/child', {
          name: serviceName || categoryName,
          price: 0,
          service_id: category,
          workshop_id: workshopId,
        });
      } catch (error) {
        message('não foi possível cadastrar o serviço', 'error');
      }
    } else {
      console.log('esse serviço já existe');
    }
    // } else {
    //   setIsVisitorModalOpen(true);
    // }
    setModalVisible(false);
    setServiceName('');
    setCategory('');
  };

  return (
    <Modal
      isOpen={modalVisible}
      onClose={() => {
        setModalVisible(false);
        setServiceName('');
      }}
      title="Adicione novo serviço"
    >
      <div className={styles.container}>
        <form>
          <select name="" id="" onChange={e => setCategory(e.target.value)}>
            <option value="" disabled selected>
              Escolha a categoria
            </option>
            {categories &&
              categories.map(categoryItem => {
                const { id_service, name } = categoryItem;
                return (
                  <option value={id_service} key={id_service}>
                    {name}
                  </option>
                );
              })}
          </select>
          <input
            type="text"
            placeholder="Nome do serviço"
            value={serviceName}
            onChange={e => {
              const { value } = e.target;
              setServiceName(value.toUpperCase());
            }}
          />
        </form>
        <button type="button" onClick={handleCreateService}>
          Adicionar
        </button>
      </div>
    </Modal>
  );
};
