import { TransactionTypes } from '../../../interfaces';

interface TransactionCodes {
  value: TransactionTypes | '';
  name: string;
}

export const transactionCodes: TransactionCodes[] = [
  {
    name: 'A pagar',
    value: 'payable',
  },
  {
    name: 'A receber',
    value: 'receivable',
  },
  {
    name: 'Pago',
    value: 'paid',
  },
  {
    name: 'Recebido',
    value: 'received',
  },
];
