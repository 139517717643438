/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { Box, Button, Image, Row, Stack, Text, Pressable } from 'native-base';
import { Plus } from 'phosphor-react';
import { useCallback, useEffect, useState } from 'react';
import { MainComponent } from '../../../shared/MainComponent/MainComponent';

import carDriving from '../../../assets/img/car-driving.svg';
import pixel from '../../../styles/size';
import { ServiceCard } from './Components/ServiceCard';
import { AddServiceModal } from './Components/AddServiceModal';
import { EditServiceModal } from './Components/EditServiceModal';
import api from '../../../services/api/api';
import { ServiceProps, GroupedServiceProps, CategoryProps } from './interfaces';
import { FeedbackModal } from '../../../shared/FeedbackModal';
import { COLORS } from '../../../styles/colors';
import { useAuth } from '../../../hooks/useAuth';
// import { VisitorModal } from '../../../shared/VisitorModal';

export const Services = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [services, setServices] = useState<GroupedServiceProps[]>([]);
  const [modalServices, setModalServices] = useState<GroupedServiceProps>({
    category: '',
    services: [],
  });
  const [categories, setCategories] = useState<CategoryProps[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isVisitorModalOpen, setIsVisitorModalOpen] = useState(false);

  const { workshop } = useAuth();

  const fetchServices = useCallback(async () => {
    const { data } = await api.get<ServiceProps[]>('/service/child/workshop', {
      params: {
        id: workshop?.id_workshop ?? '',
      },
    });
    if (data) {
      const groupByCategory: GroupedServiceProps[] = [];
      data.forEach((dataService: ServiceProps) => {
        const category = groupByCategory.find(
          c => c.category === dataService.service.name,
        );

        if (!category) {
          groupByCategory.push({
            category: dataService.service.name,
            services: [
              {
                ...dataService.service,
                name: dataService.name,
                id_child_service: dataService.id_child_service,
              },
            ],
          });
        } else {
          category.services.push({
            ...dataService.service,
            name: dataService.name,
            id_child_service: dataService.id_child_service,
          });
        }
      });
      groupByCategory.sort((a: GroupedServiceProps, b: GroupedServiceProps) => {
        if (a.category < b.category) {
          return -1;
        }
        if (a.category > b.category) {
          return 1;
        }
        return 0;
      });
      setServices(groupByCategory);
    }
  }, [workshop?.id_workshop]);

  const fetchCategories = async () => {
    await api
      .get<CategoryProps[]>('/service')
      .then(res => setCategories(res.data))
      .catch(error => console.log(error))
      .finally(() => {
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    // atualiza se um dos modais forem fechados
    if (workshop || (!isEditModalVisible && !isEditModalVisible))
      fetchServices();
  }, [fetchServices, isAddModalVisible, isEditModalVisible, workshop]);

  return (
    <Row justifyContent="center" alignItems="center">
      <MainComponent title="Cadastro de Serviços">
        <AddServiceModal
          services={services}
          modalVisible={isAddModalVisible}
          setModalVisible={setIsAddModalVisible}
          setIsVisitorModalOpen={setIsVisitorModalOpen}
          workshopId={workshop?.id_workshop ?? ''}
          categories={categories}
        />
        <EditServiceModal
          modalVisible={isEditModalVisible}
          setModalVisible={setIsEditModalVisible}
          modalServices={modalServices}
          setModalServices={setModalServices}
          setIsVisitorModalOpen={setIsVisitorModalOpen}
          workshopId={workshop?.id_workshop ?? ''}
        />
        {/* <VisitorModal
          isOpen={isVisitorModalOpen}
          onClose={() => setIsVisitorModalOpen(false)}
        /> */}
        {isLoadingData ? (
          <FeedbackModal isOpen={isLoadingData} />
        ) : services.length === 0 ? (
          <>
            <Box alignItems="center" mb="2rem" mt="-4rem" minH="60vh">
              <Image
                src={carDriving}
                size={{ base: '200', md: '500', lg: pixel(620, 1920) }}
                resizeMode="contain"
                mb="-2rem"
                alt="imagem de um carro em movimento "
              />
              <Text
                fontWeight="600"
                fontSize={{ md: '1.4rem' }}
                textAlign="center"
              >
                No momento você não possui serviços cadastrados!
              </Text>
              <Text fontSize={{ md: '1.2rem' }} textAlign="center">
                clique no botão ao lado para cadastrar novos serviços.
              </Text>
            </Box>
            <Button
              position="fixed"
              right="2rem"
              bottom="5rem"
              borderRadius="50%"
              bg={COLORS.GREEN}
              onPress={() => setIsAddModalVisible(true)}
            >
              <Plus size="4rem" weight="bold" color={COLORS.WHITE} />
            </Button>
          </>
        ) : (
          <>
            <Stack maxW="80%" w="100%" maxH="60vh" overflowY="scroll" mt="2rem">
              {services.map((service, index: number) => {
                const { category } = service;
                return (
                  <Pressable
                    onPress={() => {
                      setIsEditModalVisible(true);
                      setModalServices(service);
                    }}
                    key={`${category}-${index}`}
                  >
                    <ServiceCard name={category} />
                  </Pressable>
                );
              })}
            </Stack>
            <Button
              position="fixed"
              bottom="2rem"
              bg={COLORS.GREEN}
              _text={{ fontSize: '1.2rem' }}
              px="2rem"
              py="1rem"
              onPress={() => setIsAddModalVisible(true)}
            >
              Adicionar Serviço
            </Button>
          </>
        )}
      </MainComponent>
    </Row>
  );
};
