import {
  CreateRepairInput,
  TransformRepairDraftParams,
} from '../../../@types/order-service';
import { handleErrorMessage } from '../../../utils/handleErrorMessage';
import { ToastNotification } from '../../../utils/ToastNotification';
import { CreateCustomerInput, TransferVehicleUpdateInput } from './interfaces';
import {
  createCustomer,
  createRepairDraft,
  findCustomersByWorkshop,
  getAddress,
  getLatestChecklist,
  getRepair,
  getUserByDocument,
  transferVehicle,
  transformRepairDraft,
  updateRepair,
} from './model';

export const getAddressController = async (zip: string) => {
  try {
    const res = await getAddress(zip);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getUserByDocumentController = async (document: string) => {
  try {
    const res = await getUserByDocument(document);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const transferVehicleController = async (
  props: TransferVehicleUpdateInput,
) => {
  try {
    const res = await transferVehicle(props);
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const findCustomersByWorkshopController = async () => {
  try {
    const res = await findCustomersByWorkshop();
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const createCustomerController = async (props: CreateCustomerInput) => {
  try {
    const res = await createCustomer(props);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const createRepairDraftController = async (props: CreateRepairInput) => {
  try {
    const res = await createRepairDraft(props);
    return res;
  } catch (err) {
    console.log(err);
    ToastNotification.show(handleErrorMessage(err), 'error');
    throw err;
  }
};
/**
 * Controlador responsável por transformar um rascunho de reparo em uma ordem de serviço.
 * - Chama a função `transformRepairDraft` para processar a requisição.
 * - Captura erros e exibe uma notificação caso ocorra uma falha.
 *
 * @param {TransformRepairDraftParams} params - Parâmetros necessários para a transformação do rascunho.
 * @returns {Promise<CreateRepairResponse | undefined>} Retorna os dados da ordem de serviço criada ou `undefined` em caso de erro.
 *
 * @throws {AppError} Se a API retornar um erro tratado, exibe uma notificação na interface.
 *
 * @example
 * try {
 *   const response = await transformRepairDraftController({
 *     identification: "ABC123",
 *     statusId: "1",
 *     changeVehicleOwner: false
 *   });
 *   console.log(response);
 * } catch (error) {
 *   console.error("Erro ao transformar rascunho:", error);
 * }
 */
export const transformRepairDraftController = async (
  params: TransformRepairDraftParams,
) => {
  try {
    const res = await transformRepairDraft(params);
    return res;
  } catch (err) {
    console.log(err);
    ToastNotification.show(handleErrorMessage(err), 'error');
    throw err;
  }
};

export const getLatestChecklistController = async (plate: string) => {
  try {
    const res = await getLatestChecklist(plate);
    return res;
  } catch (err) {
    console.log('erro: ', err);
    // ToastNotification.show(handleErrorMessage(err), 'error');
  }
};

export const updateRepairController = async (
  orderServiceData: Partial<CreateRepairInput>,
) => {
  try {
    const res = await updateRepair(orderServiceData);
    return res;
  } catch (err) {
    console.log('erro: ', err);
    ToastNotification.show(handleErrorMessage(err), 'error');
    throw err;
  }
};

export const getRepairController = async (repairId: string) => {
  try {
    const res = await getRepair(repairId);
    return res;
  } catch (err) {
    console.log('erro: ', err);
    ToastNotification.show(handleErrorMessage(err), 'error');
  }
};
