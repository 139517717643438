import {
  formatDate,
  formatDateString,
} from '../../../../../utils/dateFunctions';
import { TransactionResponse } from '../../interfaces';
import styles from './transaction.module.css';

export const Transaction = ({
  name,
  created_at,
  due_date,
  discharge_date,
  id_transaction,
  value,
  type,
  finished,
  transaction_code,
}: TransactionResponse) => {
  const isIncome = type.toLowerCase() === 'income';
  let status = 'Pendente';
  if (isIncome) {
    if (finished) status = 'Recebida';
  } else if (finished) status = 'Paga';
  return (
    <li
      className={`${styles['transaction-item']} ${!isIncome && styles.expense}`}
      key={id_transaction}
    >
      <span className={styles.left}>
        {name || `${transaction_code.code}-${transaction_code.name}`}
      </span>
      <span>{formatDate(new Date(created_at))}</span>
      <span>{status}</span>
      <span>{formatDateString(due_date)}</span>
      <span>{discharge_date ? formatDateString(discharge_date) : '---'}</span>
      <span>
        {isIncome ? '+' : '-'}R$ {value}{' '}
      </span>
    </li>
  );
};
