import { useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import {
  deleteOrderServiceController,
  finishOrderServiceController,
  getOrderServiceController,
} from './controller';
import { Repair } from '../../History/interfaces';

const LIMIT_REPAIRS = 80;
const LIMIT_FILTERED_REPAIRS = 999;

export const useRepairData = () => {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const cache = useRef<{ [key: string]: Repair[] }>({});

  // Query para listagem principal com paginação infinita
  const {
    data: paginatedRepairs,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingRepairs,
    isLoading: isLoadingRepairs,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['repairs'],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getOrderServiceController({
        limit: LIMIT_REPAIRS,
        page: pageParam,
      });
      return res?.repairs || [];
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < LIMIT_REPAIRS) return undefined;
      return allPages.length + 1;
    },
    enabled: debouncedSearchQuery.trim().length === 0,
    staleTime: 1000 * 60,
  });

  const repairs = paginatedRepairs?.pages.flat() ?? [];

  // Query para busca por placa
  const {
    data: filteredRepairs = [],
    isFetching: isFetchingFiltered,
    refetch: refetchFilteredSearch,
  } = useQuery({
    queryKey: ['filteredRepairs', debouncedSearchQuery],
    queryFn: async () => {
      if (cache.current[debouncedSearchQuery]) {
        return cache.current[debouncedSearchQuery];
      }

      const res = await getOrderServiceController({
        query: debouncedSearchQuery,
        page: 1,
        limit: LIMIT_FILTERED_REPAIRS,
      });

      const data = res?.repairs || [];
      cache.current[debouncedSearchQuery] = data;
      return data;
    },
    enabled: debouncedSearchQuery.trim().length > 0,
    staleTime: 1000 * 60,
  });

  // Scroll infinito
  const loadMoreData = () => {
    if (hasNextPage && searchQuery.trim().length === 0) {
      fetchNextPage();
    }
  };

  const deleteRepair = async (id_repair: string) => {
    try {
      await deleteOrderServiceController(id_repair);

      // Atualiza cache da lista principal
      queryClient.setQueryData<{ pages: Repair[][]; pageParams: unknown[] }>(
        ['repairs'],
        (oldData = { pages: [], pageParams: [] }) => {
          const updatedPages = oldData.pages.map(page =>
            page.filter(repair => repair.id_repair !== id_repair),
          );
          return { ...oldData, pages: updatedPages };
        },
      );

      // Atualiza cache da lista filtrada
      queryClient.setQueryData<Repair[]>(
        ['filteredRepairs', debouncedSearchQuery],
        oldData => {
          if (!oldData) return [];
          return oldData.filter(r => r.id_repair !== id_repair);
        },
      );
    } catch (error) {
      console.error('Erro ao deletar OS:', error);
      throw error;
    }
  };

  const finishRepair = async (id_repair: string) => {
    try {
      const updatedRepair = await finishOrderServiceController(id_repair);

      if (!updatedRepair) return;

      // Atualiza e reordena lista principal
      queryClient.setQueryData<{ pages: Repair[][]; pageParams: unknown[] }>(
        ['repairs'],
        (oldData = { pages: [], pageParams: [] }) => {
          const flatRepairs = oldData.pages.flat();

          // Substitui a OS finalizada
          const updatedList = flatRepairs.map(repair =>
            repair.id_repair === updatedRepair.id_repair
              ? updatedRepair
              : repair,
          );

          // não finalizadas no topo
          const sorted = updatedList.sort((a, b) => {
            if (a.finished && !b.finished) return 1;
            if (!a.finished && b.finished) return -1;
            return 0;
          });

          return {
            pages: [sorted], // reseta para 1 página, já ordenada
            pageParams: [1] as unknown[],
          };
        },
      );

      // Atualiza lista filtrada
      queryClient.setQueryData<Repair[]>(
        ['filteredRepairs', debouncedSearchQuery],
        oldData => {
          if (!oldData) return [];

          const updated = oldData.map(repair =>
            repair.id_repair === updatedRepair.id_repair
              ? updatedRepair
              : repair,
          );

          return updated.sort((a, b) => {
            if (a.finished && !b.finished) return 1;
            if (!a.finished && b.finished) return -1;
            return 0;
          });
        },
      );

      return updatedRepair;
    } catch (error) {
      console.error('Erro ao finalizar OS:', error);
      throw error;
    }
  };

  return {
    repairs,
    filteredRepairs,
    isLoading: isLoadingRepairs || isFetchingFiltered,
    searchQuery,
    setSearchQuery,
    loadMoreData,
    refetch,
    isFetchingRepairs,
    deleteRepair,
    finishRepair,
    refetchFilteredSearch,
  };
};
