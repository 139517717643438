import { getOrderServiceData, getRepairPdfURL } from './model';
import { ToastNotification } from '../../ToastNotification';
import { handleErrorMessage } from '../../handleErrorMessage';

export const getOrderServiceDataController = async (orderServiceId: string) => {
  try {
    const res = await getOrderServiceData(orderServiceId);
    return res;
  } catch (error) {
    ToastNotification.show(handleErrorMessage(error), 'error');
  }
};

export const getRepairPdfURLController = async (orderServiceId: string) => {
  try {
    const res = await getRepairPdfURL(orderServiceId);
    return res;
  } catch (error) {
    ToastNotification.show(handleErrorMessage(error), 'error');
  }
};
