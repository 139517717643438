import { Route, Routes as Switch } from 'react-router-dom';

import { OrderServiceReport } from '../../utils/pdf/OrderService';
import { Workshop } from '../../@types/interface';

interface Props {
  workshopData: Workshop | null;
}

export const ReportRoutes = ({ workshopData }: Props) => {
  return (
    <Switch>
      <Route
        path="/order-service-report"
        element={<OrderServiceReport workshopData={workshopData} />}
      />
      <Route
        path="/transaction-report"
        element={<OrderServiceReport workshopData={workshopData} />}
      />
    </Switch>
  );
};
