import { ComponentPropsWithoutRef, ElementType } from 'react';

import styles from './menu-navigation-item.module.css';

type MenuNavigationItemProps = {
  value: string;
  tittle: string;
  isActive: boolean;
  icon: ElementType;
} & ComponentPropsWithoutRef<'button'>;

export const MenuNavigationItem = ({
  tittle,
  value,
  isActive,
  className,
  icon: Icon,
  ...props
}: MenuNavigationItemProps) => {
  return (
    <button
      type="button"
      {...props}
      className={`${styles['navigation-link']} ${className} ${
        isActive ? styles.active : ''
      }`}
      title={tittle}
    >
      <Icon size={24} weight="fill" />
      <span>{tittle}</span>
    </button>
  );
};
