import {
  ArrowCounterClockwise,
  Coins,
  FunnelSimple,
  MinusCircle,
  PlusCircle,
} from 'phosphor-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Searchbar, SearchbarStatus } from '../../../../../shared/Searchbar';
import { formatDateString } from '../../../../../utils/dateFunctions';
import { getYearsOptions } from '../../../../../utils/getYearsOptions';
import { CardInfo } from '../../components/CardInfo';
import {
  getTransactionCodesController,
  getTransactionsController,
} from '../../controller';
import {
  TransactionCodeResponse,
  TransactionResponse,
  TransactionTypes,
} from '../../interfaces';
import { TransactionsTable } from './components/TransactionsTable';
import styles from './transactions.module.css';
import { transactionCodes as transactionTypes } from './utils/transactionCodes';
import { Loading } from '../../../../../shared/Loading';
import { useResponsive } from '../../../../../hooks/useResponsive';
import { CreateTransactionModal } from '../../components/CreateTransactionModal';
import { FilterModal } from '../../components/FilterModal';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../utils/monthBoundaries';

interface Props {
  selectedMonth: string;
  selectedYear: string;
  filter?: TransactionTypes | null;
}

export const Transactions = ({
  selectedMonth,
  selectedYear,
  filter = null,
}: Props) => {
  const [month, setMonth] = useState(selectedMonth);
  const [year, setYear] = useState(
    selectedYear || `${new Date().getFullYear()}`,
  );
  const [search, setSearch] = useState('');
  const [filterCode, setFilterCode] = useState<string>(filter || '');
  const [searchStatus, setSearchStatus] = useState<SearchbarStatus>('');
  const [yearsOptions, setYearsOptions] = useState(['2023']);
  const [pendingPayment, setPendingPayment] = useState(0);
  const [pendingReceive, setPendingReceive] = useState(0);
  const [filteredTransactions, setFilteredTransactions] = useState<
    TransactionResponse[]
  >([]);

  const [transactionsCodes, setTransactionsCodes] = useState<
    TransactionCodeResponse[]
  >([]);

  const [selectedTransactionCode, setSelectedTransactionCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [debouncedSearch] = useDebounce(search, 500);

  const [initialDate, setInitialDate] = useState<string>(
    getFirstDayOfMonth(new Date()).toISOString().split('T')[0],
  );
  const [finalDate, setFinalDate] = useState<string>(
    getLastDayOfMonth(new Date()).toISOString().split('T')[0],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setisFilterModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'expense' | 'income'>('income');

  const [countFiltersApplied, setCountFiltersApplied] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<Set<string>>(new Set());

  const fetchTransactionCodes = async () => {
    const res = await getTransactionCodesController();
    if (res) {
      setTransactionsCodes(res);
    }
  };

  useEffect(() => {
    fetchTransactionCodes();
  }, []);

  const handleTransactions = (res: TransactionResponse[]) => {
    setFilteredTransactions(res);
  };

  const fetchTransactions = useCallback(
    async (
      query: string,
      monthParam: string,
      yearParam: string,
      filterParam: string,
      transactionCode: string,
      startDate: string,
      endDate: string,
    ) => {
      setSearchStatus('searching');

      setIsLoading(true);
      console.log('parâmetros: ', {
        month: monthParam,
        year: yearParam,
        query,
        filter: filterParam,
        transactionCode,
      });

      const res = await getTransactionsController({
        query,
        transaction_code_id: transactionCode,
        filter: filterParam,
        start_date: startDate,
        final_date: endDate,
      });

      if (res) {
        setFilteredTransactions(res);
        setPendingPayment(
          res.reduce((accumulator, current) => {
            return !current.discharge_date && current.type === 'expense'
              ? accumulator + current.value
              : accumulator;
          }, 0),
        );
        setPendingReceive(
          res.reduce((accumulator, current) => {
            return !current.discharge_date && current.type === 'income'
              ? accumulator + current.value
              : accumulator;
          }, 0),
        );
      }
      setIsLoading(false);

      setAppliedFilters(prevFilters => {
        const newFilters = new Set(prevFilters);

        if (filterCode && !newFilters.has('type')) {
          newFilters.add('type');
        }

        if (!filterCode && newFilters.has('type')) {
          newFilters.delete('type');
        }

        if (selectedTransactionCode && !newFilters.has('accountPlan')) {
          newFilters.add('accountPlan');
        }

        if (!selectedTransactionCode && newFilters.has('accountPlan')) {
          newFilters.delete('accountPlan');
        }

        setCountFiltersApplied(newFilters.size);

        return newFilters;
      });
    },
    [filterCode, selectedTransactionCode],
  );

  useEffect(() => {
    if (debouncedSearch.trim().length > 0 || search.trim().length === 0) {
      fetchTransactions(
        debouncedSearch.trim(),
        month,
        year,
        filterCode,
        selectedTransactionCode,
        initialDate,
        finalDate,
      );
    }
    setYearsOptions(getYearsOptions());
  }, [
    debouncedSearch,
    month,
    year,
    filterCode,
    selectedTransactionCode,
    search,
    fetchTransactions,
    initialDate,
    finalDate,
  ]);

  function handleUpdated(transactionUpdated: TransactionResponse) {
    setFilteredTransactions(state => {
      const updatedTransactions = state.map(transaction =>
        transaction.id_transaction === transactionUpdated.id_transaction
          ? transactionUpdated
          : transaction,
      );

      // Recalcular os valores totais imediatamente após a atualização
      const newPendingPayment = updatedTransactions.reduce((acc, cur) => {
        return !cur.discharge_date && cur.type === 'expense'
          ? acc + cur.value
          : acc;
      }, 0);

      const newPendingReceive = updatedTransactions.reduce((acc, cur) => {
        return !cur.discharge_date && cur.type === 'income'
          ? acc + cur.value
          : acc;
      }, 0);

      setPendingPayment(newPendingPayment);
      setPendingReceive(newPendingReceive);

      return updatedTransactions;
    });
  }

  function handleDelete(id: string) {
    setFilteredTransactions(state =>
      state.filter(transaction => transaction.id_transaction !== id),
    );
  }

  function handleResetFilters() {
    setFilterCode('');
    setSearch('');
    setSelectedTransactionCode('');
  }

  const totalReceive = useMemo(
    () =>
      filteredTransactions.reduce((acc, cur) => {
        // discharge date => apenas valores recebidos
        return cur.type.toLowerCase() === 'income' && cur.discharge_date
          ? acc + cur.value
          : acc;
      }, 0),
    [filteredTransactions],
  );

  const totalPayment = useMemo(
    () =>
      filteredTransactions.reduce((acc, cur) => {
        return cur.type.toLowerCase() === 'expense' && cur.discharge_date
          ? acc + cur.value
          : acc;
      }, 0),
    [filteredTransactions],
  );

  const handleSetFilter = (
    type?: string,
    accountPlan?: string,
    currentInitialDate?: string,
    currentFinalDate?: string,
  ) => {
    setFilterCode(type || '');
    setSelectedTransactionCode(accountPlan || '');
    setInitialDate(
      currentInitialDate ||
        getFirstDayOfMonth(new Date()).toISOString().split('T')[0],
    );
    setFinalDate(
      currentFinalDate ||
        getLastDayOfMonth(new Date()).toISOString().split('T')[0],
    );
  };

  const monthTotal = useMemo(() => {
    return totalReceive + pendingReceive - (totalPayment + pendingPayment);
  }, [pendingPayment, pendingReceive, totalPayment, totalReceive]);

  const { sizeMobile } = useResponsive();

  const handleOpenCreateTransactionModal = (type: 'income' | 'expense') => {
    setIsModalOpen(true);
    setModalType(type);
  };

  return (
    <div className={styles.container}>
      <CreateTransactionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={async () => {
          await fetchTransactions(
            debouncedSearch.trim(),
            month,
            year,
            filterCode,
            selectedTransactionCode,
            initialDate,
            finalDate,
          );
        }}
        type={modalType}
      />

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setisFilterModalOpen(false)}
        transactionsCodes={transactionsCodes}
        handleSetFilter={handleSetFilter}
        initialDate={initialDate}
        finalDate={finalDate}
      />
      <div className={styles['header-wrapper']}>
        <h1 className={styles.title}>LANÇAMENTOS</h1>
        <div className={styles['action-buttons']}>
          <button
            className={styles['receive-button']}
            type="button"
            onClick={() => handleOpenCreateTransactionModal('income')}
          >
            <PlusCircle size="1.25rem" weight="fill" />
            Conta a Receber
          </button>
          <button
            className={styles['payment-button']}
            type="button"
            onClick={() => handleOpenCreateTransactionModal('expense')}
          >
            <MinusCircle size="1.25rem" weight="fill" />
            Conta a Pagar
          </button>
        </div>
      </div>
      <div className={styles['transactions-page-header']}>
        <div className={styles.cards}>
          <CardInfo
            value={totalReceive}
            title="Total recebido"
            variant="receiveDay"
          />
          <CardInfo
            value={pendingReceive}
            title="Total a receber"
            variant="pendingReceive"
          />
          <CardInfo
            value={pendingPayment}
            title="Total a Pagar"
            variant="pendingPayment"
          />
          <CardInfo
            value={totalPayment}
            title="Total pago"
            variant="paymentDay"
          />
          <CardInfo
            value={monthTotal}
            variant="monthTotal"
            title="Total do mês"
            Icon={<Coins />}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['list-header']}>
          <div className={styles.filters}>
            <div className={styles['filters-container']}>
              <div className={styles['button-wrapper']}>
                <button
                  className={`${styles['filter-button']} ${
                    countFiltersApplied > 0 ? styles.active : ''
                  }`}
                  type="button"
                  onClick={() => setisFilterModalOpen(true)}
                >
                  <FunnelSimple size="0.875rem" weight="fill" />
                  Filtros
                </button>
                {countFiltersApplied > 0 && (
                  <div className={styles['filters-counter']}>
                    {countFiltersApplied}
                  </div>
                )}
              </div>

              <div className={styles['filter-applied']}>
                <span>{`De ${formatDateString(initialDate)}`}</span>
                <span>{`Até ${formatDateString(finalDate)}`}</span>
              </div>

              {filterCode && (
                <div className={styles['filter-applied']}>
                  {
                    transactionTypes.find(type => type.value === filterCode)
                      ?.name
                  }
                </div>
              )}

              {selectedTransactionCode && (
                <div className={styles['filter-applied']}>
                  {
                    transactionsCodes.find(
                      item =>
                        item.id_transaction_code === selectedTransactionCode,
                    )?.name
                  }
                </div>
              )}
            </div>

            {/* <div className={styles['search-filter']}> */}
            <Searchbar
              value={search}
              setValue={setSearch}
              status={searchStatus}
            />
            {/* </div> */}
          </div>
          <ArrowCounterClockwise
            className={styles.reset}
            onClick={handleResetFilters}
          />
        </div>
        {isLoading ? (
          <div className={styles['loading-wrapper']}>
            <Loading />
            <Loading />
            <Loading />
          </div>
        ) : (
          <TransactionsTable
            transactions={filteredTransactions}
            handleUpdated={handleUpdated}
            handleDelete={handleDelete}
            selectedMonth={month}
            selectedYear={year}
            handleTransactions={handleTransactions}
          />
        )}
      </div>
    </div>
  );
};
