/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { numberWithCommas } from '../../../services/helpers/mask';
import { FeedbackModal } from '../../../shared/FeedbackModal';
import { OrderServicesDataFetcher } from '../../../services/dataFetchers/OrderServicesDataFetcher/OrderServicesDataFetcher';
import { Repair } from './interfaces';
import { ScrollTopButton } from './components/ScrollTopButton';
import { fetchVehicleDataByLicensePlateController } from '../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import { VehicleResponse } from '../../../services/dataFetchers/VehicleDataFetcher/model';
import styles from './history.module.css';
import { InfoRow } from './components/InfoRow';
import { HistoryCard } from './components/HistoryCard';
import { SectionSeparator } from './components/SectionSeparator';
import { PageLayout } from '../../../shared/PageLayout';

export const History = () => {
  const [dataHistory, SetDataHistory] = useState<Repair[]>([]);
  const [vehicle, setVehicle] = useState<VehicleResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const licensePlate = queryParams.get('licensePlate');

  const fetchApiRepairHistory = useCallback(async () => {
    const orderServicesDataFetcher = new OrderServicesDataFetcher();
    const vehicleResponse = await fetchVehicleDataByLicensePlateController(
      licensePlate || '',
    );
    if (vehicleResponse) setVehicle(vehicleResponse);
    setIsLoading(true);
    const response =
      await orderServicesDataFetcher.fetchOrderServicesByLicensePlate(
        licensePlate || '',
      );
    SetDataHistory(response || []);
    setIsLoading(false);
  }, [licensePlate]);

  useEffect(() => {
    fetchApiRepairHistory();
    return () => {
      setVehicle(null);
      setIsLoading(true);
    };
  }, [fetchApiRepairHistory]);

  return (
    <PageLayout.Root title="Histórico de Serviço">
      <FeedbackModal isOpen={isLoading} />

      <ul className={styles['repairs-list']}>
        {dataHistory.length > 0 ? (
          <div className={styles.column}>
            <div className={styles.column}>
              <SectionSeparator title="Veículo" />
              <div className={styles.row}>
                <InfoRow label="Placa:" value={licensePlate} />
                <InfoRow
                  label="Km:"
                  value={
                    vehicle && vehicle.current_km
                      ? numberWithCommas(`${vehicle.current_km}`)
                      : null
                  }
                />
              </div>
              <div className={styles.row}>
                <InfoRow
                  label="Veículo:"
                  value={vehicle && vehicle.name ? vehicle.name : null}
                />
              </div>
            </div>
            {dataHistory &&
              dataHistory.map(repair => (
                <HistoryCard
                  repair={repair}
                  key={repair?.external_id || repair.OS}
                />
              ))}
          </div>
        ) : (
          <b>Nenhum resultado.</b>
        )}
        <ScrollTopButton />
      </ul>
    </PageLayout.Root>
  );
};
