import { useFormContext } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../shared/Button';
import { InputComponent } from '../../../../../shared/InputComponent';
import { ModalHeader } from '../../../Checklists/components/ModalHeader';
import { ModalContent } from '../../../Finance/components/Modal';
import styles from './km-modal.module.css';
import { OrderServiceFormDTO } from '../../CreateOrderServiceForm/OrderServiceDTO';

interface Props {
  handleClose: () => void;
}

export const KmModal = ({ handleClose }: Props) => {
  const { setValue, watch } = useFormContext<OrderServiceFormDTO>();
  const currentKm = watch('vehicle.km');

  const [newKM, setNewKM] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = inputRef.current;

    setTimeout(() => {
      input?.focus();
    }, 100);
  }, []);

  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Atenção" />
      Atualize a Km
      <InputComponent
        value={newKM}
        onChange={e => setNewKM(e.target.value)}
        placeholder={`${currentKm}`}
        ref={inputRef}
      />
      <Button
        handleClick={() => {
          if (newKM.length > 0) setValue('vehicle.km', newKM);
          handleClose();
        }}
      >
        Atualizar
      </Button>
    </ModalContent>
  );
};
