import { useMemo } from 'react';
import {
  dateOffset,
  formatDate,
  formatDateString,
} from '../../../../../../../utils/dateFunctions';
import { TransactionResponse } from '../../../../interfaces';
import { FormatPrice } from '../../utils';
import { ButtonComponent } from '../Button';

import styles from './modal-view.module.css';
import { ViewItem } from './ViewItem';

interface ModalViewProps {
  transaction: TransactionResponse;
  viewClassName?: string;
  onClose: () => void;
}

export const ModalView = ({
  transaction,
  viewClassName,
  onClose,
}: ModalViewProps) => {
  const valueFormat = useMemo(
    () => FormatPrice(transaction.value),
    [transaction.value],
  );

  const typeStyle = useMemo(
    () => (transaction.type === 'income' ? styles.income : styles.expense),
    [transaction.type],
  );

  // Dates
  const created_date = useMemo(
    () => formatDate(dateOffset(new Date(transaction.created_at))),
    [transaction.created_at],
  );

  const due_date = useMemo(() => {
    return formatDateString(transaction.due_date);
  }, [transaction.due_date]);

  const discharge_date = useMemo(() => {
    if (!transaction.discharge_date) return '---';

    return formatDateString(transaction.discharge_date);
  }, [transaction.discharge_date]);

  const showTransactionCode = useMemo(
    () => transaction.type === 'expense',
    [transaction.type],
  );
  const transactionCodeEmpty = useMemo(
    () => transaction.transaction_code.code === '000',
    [transaction.transaction_code.code],
  );
  const transactionCodeSubtitle = useMemo(() => {
    if (transactionCodeEmpty) {
      return 'Não informado';
    }
    return `${transaction.transaction_code.code} ${transaction.transaction_code.name}`;
  }, [
    transaction.transaction_code.code,
    transaction.transaction_code.name,
    transactionCodeEmpty,
  ]);

  const subtitle = useMemo(
    () => transaction.description || 'Não informado',
    [transaction.description],
  );

  return (
    <div className={`${styles['modal-view']} ${viewClassName}`}>
      <ViewItem
        title="Descrição"
        subtitle={subtitle}
        subtitleSize="lg"
        itemClassname={`${!transaction.description && styles.empty}`}
      />
      <div className={styles.row}>
        <ViewItem
          title="Valor"
          subtitle={valueFormat}
          subtitleSize="lg"
          itemClassname={`${styles.value} ${typeStyle}`}
        />
        <ViewItem title="Validade" subtitle={due_date} />
      </div>
      <div className={styles.row}>
        <ViewItem title="Lançamento" subtitle={created_date} />
        <ViewItem title="Baixa" subtitle={discharge_date} />
      </div>
      {showTransactionCode && (
        <ViewItem
          title="Plano de contas"
          subtitle={transactionCodeSubtitle}
          itemClassname={`${transactionCodeEmpty && styles.empty}`}
        />
      )}

      <ButtonComponent
        text="voltar"
        style={{ margin: 'auto', width: 212 }}
        onClick={onClose}
      />
    </div>
  );
};
