import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { InputComponent } from '../../input';

import styles from './form-create-receive.module.css';
import { ButtonComponent } from '../../Button';
import { updateExpense } from '../../../../../model';
import {
  TransactionCodeResponse,
  TransactionResponse,
} from '../../../../../interfaces';
import { formatNumber } from '../../../utils/FormatPrice';
import { getTransactionCodesController } from '../../../../../controller';
import {
  UpdateTransactionDTO,
  updateTransactionSchema,
} from '../../../utils/DTOs/updateTransactionDTO';
import message from '../../../../../../../../utils/message';

interface EditTransactionProps {
  onClose?: () => void;
  transaction: TransactionResponse | null;
  handleUpdate: (response: TransactionResponse) => void;
}

export const EditTransactionForm = ({
  onClose,
  transaction,
  handleUpdate,
}: EditTransactionProps) => {
  const [transactionCodes, setTransactionsCode] = useState<
    TransactionCodeResponse[]
  >([]);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateTransactionDTO>({
    resolver: yupResolver(updateTransactionSchema),
    defaultValues: {
      description: transaction?.description ?? '',
      value: formatNumber(transaction?.value || 0),
      discharge_date: transaction?.discharge_date || '',
      due_date: transaction?.due_date ?? '',
      transactionId: transaction?.id_transaction ?? '',
      transaction_code_id:
        transaction?.transaction_code?.id_transaction_code ?? '',
    },
  });

  async function onSubmit(data: UpdateTransactionDTO) {
    // http call put
    try {
      const responseUpdated = await updateExpense(data);
      handleUpdate(responseUpdated);
    } catch (error) {
      message(error, 'error');
    }
    if (onClose) onClose();
  }

  const fetchTransactionCodes = useCallback(async () => {
    const response = await getTransactionCodesController();
    if (response) {
      setTransactionsCode(response);
      setValue(
        'transaction_code_id',
        transaction?.transaction_code.name === 'Outros'
          ? ''
          : transaction?.transaction_code?.id_transaction_code ?? '',
      );
    }
  }, [
    setValue,
    transaction?.transaction_code?.id_transaction_code,
    transaction?.transaction_code?.name,
  ]);

  useEffect(() => {
    if (transaction?.type === 'expense') fetchTransactionCodes();
  }, [fetchTransactionCodes, transaction?.type]);

  watch('transaction_code_id');

  console.log('modal atualizar');

  return (
    <form
      onSubmit={handleSubmit(data => onSubmit(data))}
      className={styles.form}
    >
      <InputComponent
        label="Descrição"
        {...register('description')}
        variant={errors?.description?.message ? 'danger' : 'default'}
        hint={errors?.description?.message}
      />
      <div className={styles.row}>
        <InputComponent
          label="Vencimento"
          type="date"
          {...register('due_date')}
          hint={errors.due_date?.message}
        />
        <InputComponent
          label="Baixa"
          type="date"
          {...register('discharge_date')}
          hint={errors.discharge_date?.message}
        />
      </div>
      <InputComponent
        label="Valor"
        type="text"
        pattern="^\d{1,3}(?:\.\d{3})*,\d{2}$"
        {...register('value')}
        onChange={e => {
          let newValue = e.target.value;
          console.log(newValue);
          newValue = newValue.replaceAll(/[^0-9,.]/g, '');
          newValue = newValue.replaceAll('.', '');
          newValue = newValue.replaceAll(',', '.');

          e.target.selectionStart = e.target.value.length;
          e.target.selectionEnd = e.target.value.length;
          const numericValue = parseFloat(newValue);

          if (getValues('value').length > newValue.length) {
            if (!isNaN(numericValue) && numericValue > 0) {
              newValue = (numericValue / 10).toString();
            } else {
              newValue = '0.00';
            }
            newValue = parseFloat(newValue).toLocaleString('pt-bt', {
              minimumFractionDigits: 2,
            });
          } else if (!isNaN(numericValue)) {
            const [integerValue, decimalValue] = newValue.split('.');
            if (parseFloat(integerValue) === 0) {
              newValue = (parseFloat(newValue) * 10).toFixed(2);
            } else if (decimalValue.length > 2) {
              newValue = (parseFloat(newValue) * 10).toFixed(2);
            } else {
              newValue = numericValue.toFixed(2);
            }
            newValue = parseFloat(newValue).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            });
          } else {
            newValue = '0.00';
            newValue = parseFloat(newValue).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            });
          }

          if (
            isNaN(numericValue) ||
            newValue.length === 0 ||
            typeof newValue === 'undefined'
          ) {
            newValue = '0.00';
            newValue = parseFloat(newValue).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            });
          }

          setValue('value', newValue);
        }}
        hint={errors.value?.message?.toString()}
        variant={errors.value ? 'danger' : 'default'}
      />
      {transaction?.type === 'expense' && (
        <select
          {...register('transaction_code_id')}
          defaultChecked
          defaultValue=""
          className={`${styles.select} ${
            getValues('transaction_code_id') === '' ? styles.default : ''
          }`}
        >
          <option value="">Selecione um plano</option>
          {transactionCodes.length > 0 &&
            transactionCodes.map(transactionCode => {
              const { code, name, id_transaction_code } = transactionCode;
              return (
                <option value={id_transaction_code} key={id_transaction_code}>
                  {code}-{name}
                </option>
              );
            })}
        </select>
      )}
      <div className={styles.row}>
        <ButtonComponent text="Cancelar" variant="outline" onClick={onClose} />
        <ButtonComponent
          type="submit"
          text="Atualizar Conta"
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
};
