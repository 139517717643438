import { useEffect, useState } from 'react';
import { File } from '@phosphor-icons/react';
import styles from './report.module.css';
import { TransactionResponse } from '../../interfaces';
import { Transaction } from '../../components/TransactionReport';
import { COLORS } from '../../../../../styles/colors';
import { handleHtmlToPdf } from '../../../../../utils/pdf/handleHtmlToPdf';
import { getTransactionsBalanceController } from '../../controller';
import { InputDate } from '../../../../../shared/InputDate';
import { isWebView } from '../../../../../utils/detectUserAgent';
import { transactionPdf } from '../../../../../utils/pdf/Trasaction/transactionPdf';
import { useAuth } from '../../../../../hooks/useAuth';

export const Report = () => {
  const [transactions, setTransactions] = useState<TransactionResponse[]>([]);
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  const { workshop } = useAuth();

  useEffect(() => {
    const getReportTransactions = async () => {
      const res = await getTransactionsBalanceController({
        initialDate: initialDate ? initialDate.toISOString().split('T')[0] : '',
        finalDate: finalDate ? finalDate.toISOString().split('T')[0] : '',
      });
      if (res) {
        setTransactions(res);
      } else {
        setTransactions([]);
      }
    };
    if (initialDate) {
      getReportTransactions();
    }
  }, [initialDate, finalDate]);

  const handlePdf = () => {
    const html = transactionPdf({
      data: transactions,
      year,
      initialDate,
      finalDate,
      workshopData: workshop,
    });

    if (isWebView === '1') {
      const data = {
        name: 'pdf',
        html,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      handleHtmlToPdf(html, 'relatório de transações');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles['release-list']}>
        <div className={styles['release-list--header']}>
          <div className={styles.row}>
            <div className={(styles['filter-wrapper'], styles.wrap)}>
              <h1 className={styles['reports-label']}>Relatórios</h1>
            </div>
            {/* <span>...</span> */}
          </div>
          <div
            className={(styles.row, styles.wrap)}
            style={{ marginBottom: '1rem' }}
          >
            <div className={(styles.filters, styles.wrap)}>
              <h1 className={styles['filter-label']}>Selecionar Período:</h1>
              <div className={styles['date-filter']}>
                <InputDate
                  value={
                    initialDate instanceof Date
                      ? initialDate.toISOString().split('T')[0]
                      : ''
                  }
                  setValue={setInitialDate}
                />
                <span className={styles.wrapSpan}>Até</span>
                <InputDate
                  value={
                    finalDate instanceof Date
                      ? finalDate.toISOString().split('T')[0]
                      : ''
                  }
                  setValue={setFinalDate}
                />
              </div>
            </div>
            <button
              type="button"
              className={styles['green-button']}
              onClick={handlePdf}
            >
              <File color={COLORS.WHITE} size={18} /> Baixar Relatório
            </button>
          </div>
          <hr className={styles.marginTop} />
        </div>
        <ul className={styles['release-list--content']}>
          <li className={styles['report-list--header']}>
            <span className={styles.left}>Título</span>

            <span>Data de Lançamento</span>
            <span>Natureza</span>
            <span>Data de Vencimento</span>
            <span>Data de Baixa</span>
            <span>Valor</span>
          </li>
          {transactions.length > 0 &&
            transactions.map(transaction => (
              <Transaction {...transaction} key={transaction.id_transaction} />
            ))}
          {transactions.length === 0 && 'sem lançamentos no período informado'}
        </ul>
      </div>
    </div>
  );
};
