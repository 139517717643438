import { Repair } from '../../../@types/order-service';
import api from '../../../services/api/api';

export const getOrderServiceData = async (orderServiceId: string) => {
  const { data } = await api.get<Repair>(`/repair/${orderServiceId}`);
  return data;
};

export const getRepairPdfURL = async (orderServiceId: string) => {
  const { data } = await api.get<{
    fileUrl: string;
  }>(`/pdf/repair/${orderServiceId}`);
  return data;
};
