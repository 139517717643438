import {
  CarSimple,
  UserList,
  ListNumbers,
  Envelope,
  EnvelopeOpen,
} from '@phosphor-icons/react';
import { CheckSquareOffset } from 'phosphor-react';
import { useMemo } from 'react';

interface Props {
  newQuotations: boolean;
  newQuotationAnswers: boolean;
}

export const useHomeCards = ({ newQuotationAnswers, newQuotations }: Props) => {
  const content = useMemo(() => {
    return [
      {
        title: 'Cotações',
        description: 'Crie e gerencie suas cotações',
        uri: '/agent-new-quotation',
        notifications: newQuotations,
        icone: (
          <Envelope
            size={window.innerWidth === 480 ? 38 : 48}
            weight="bold"
            color="#007A37"
          />
        ),
      },
      {
        title: 'Responder Cotações',
        description: 'Responda suas cotações recebidas',
        uri: '/agent-answer-quotation',
        notifications: newQuotationAnswers,
        icone: (
          <EnvelopeOpen
            size={window.innerWidth === 480 ? 38 : 48}
            weight="bold"
            color="#007A37"
          />
        ),
      },
      {
        title: 'Orçamentos',
        description: 'Crie e envie um novo orçamento',
        uri: '/budget',
        notifications: false,
        icone: (
          <ListNumbers
            size={window.innerWidth === 480 ? 38 : 48}
            weight="bold"
            color="#007A37"
          />
        ),
      },
      {
        title: 'Consulta de veículos',
        description: 'Consulte dados de um veículo',
        uri: '/search-vehicle',
        notifications: false,
        icone: (
          <CarSimple
            size={window.innerWidth === 480 ? 38 : 48}
            weight="bold"
            color="#007A37"
          />
        ),
      },
      // {
      //   title: 'Checklists',
      //   description: 'Crie e visualize todos os checklists',
      //   uri: '/checklists',
      //   notifications: false,
      //   icone: (
      //     <CheckSquareOffset
      //       size={window.innerWidth === 480 ? 38 : 48}
      //       weight="bold"
      //       color="#007A37"
      //     />
      //   ),
      // },
      // {
      //   title: 'Meu Cadastro',
      //   description: 'Acesse e configure seus dados de cadastro',
      //   uri: '/profile-edit',
      //   notifications: false,
      //   icone: (
      //     <UserList
      //       size={window.innerWidth === 480 ? 38 : 48}
      //       weight="bold"
      //       color="#007A37"
      //     />
      //   ),
      // },
    ];
  }, [newQuotationAnswers, newQuotations]);

  return {
    content,
  };
};
