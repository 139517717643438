import { ReactNode } from 'react';
import { WarningCircle } from 'phosphor-react';
import styles from './steps.module.css';
import { OSstep } from '../../CreateOrderServiceForm';

interface Step {
  label: string;
  icon: ReactNode;
  value: OSstep;
  warning: boolean;
}

interface Props {
  steps: Step[];
  onClick: (step: OSstep) => void;
  selectedStep: OSstep;
  ordered?: boolean;
}

export const Steps = ({
  steps,
  onClick,
  selectedStep,
  ordered = false,
}: Props) => {
  return (
    <div className={styles.container}>
      {steps.map((step, index) => {
        const selectedIndex = steps.findIndex(s => s.value === selectedStep);
        const isDisabled = ordered && index > selectedIndex + 1;

        return (
          <button
            className={`${styles.step} ${
              selectedStep === step.value ? styles.selected : ''
            } ${isDisabled ? styles.disabled : ''}`}
            key={step.value}
            onClick={() => !isDisabled && onClick(step.value)}
            type="button"
            tabIndex={isDisabled ? -1 : 0}
            disabled={isDisabled}
          >
            {step.icon}
            <span>{step.label}</span>
            {step.warning && (
              <WarningCircle
                size={24}
                weight="fill"
                color="#FEE631"
                className={styles.warning}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};
