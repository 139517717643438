import { useState, useRef, useEffect } from 'react';
import { CaretDown, CaretUp } from 'phosphor-react';
import styles from './expandable-section.module.css';

interface ExpandableSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  height?: number;
}

export const ExpandableSection = ({
  title,
  children,
  className,
  height,
}: ExpandableSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded
        ? `${height || contentRef.current.scrollHeight + 50}px`
        : '0px';
    }
  }, [height, isExpanded]);

  return (
    <div className={`${styles.container} ${className}`}>
      <header
        className={styles.header}
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsExpanded(!isExpanded);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className={styles['header-content']}>
          <strong>{title}</strong>
          <span className={styles.icon}>
            {isExpanded ? (
              <CaretUp size={24} weight="bold" />
            ) : (
              <CaretDown size={24} weight="bold" />
            )}
          </span>
        </div>
        <div className={styles.rectangle} />
      </header>

      <div
        ref={contentRef}
        className={`${styles.content} ${
          isExpanded ? styles.expanded : styles.collapsed
        }`}
      >
        {children}
      </div>
    </div>
  );
};
