import { XCircle } from 'phosphor-react';
import { Table } from '../../../../../shared/Table';
import styles from './checklist-table.module.css';
import { useDynamicHeight } from '../../../../../hooks/useDynamicHeight';
import { ChecklistTableRow } from './checklistTableRow';
import { Checklist } from '../../../../../@types/checklist';
import { useResponsive } from '../../../../../hooks/useResponsive';

interface Props {
  checklists: Checklist[];
  handleRowClick: (checklist: Checklist | null) => void;
  selectedChecklist: Checklist | null;
}

export const ChecklistTable = ({
  checklists,
  handleRowClick,
  selectedChecklist,
}: Props) => {
  const { height, ref } = useDynamicHeight();
  const { sizeMobile } = useResponsive();

  return (
    <div ref={ref} className={styles['table-container']}>
      <Table.Root
        height={height}
        className={styles['table-root']}
        tableScrollClassName={styles['table-scroll']}
      >
        <Table.Row className={styles['table-header']}>
          <Table.Header value="Número" width="16%" />
          {!sizeMobile && (
            <Table.Header value="Cliente" width="32%" align="left" />
          )}
          <Table.Header value="Placa" width="32%" align="left" />
          <Table.Header value="Criação" />
        </Table.Row>

        {checklists && checklists.length ? (
          checklists.map(checklist => (
            <ChecklistTableRow
              key={checklist.id_checklist_vehicle}
              checklist={checklist}
              handleRowClick={handleRowClick}
              selectedRowId={selectedChecklist?.id_checklist_vehicle ?? ''}
              isMobile={sizeMobile}
            />
          ))
        ) : (
          <div className={styles['no-checklists']}>
            <XCircle className={styles.icon} />
            <span>Nenhum checklist Encontrado</span>
          </div>
        )}
      </Table.Root>
    </div>
  );
};
