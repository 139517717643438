import { MagnifyingGlass } from 'phosphor-react';
// import { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import styles from './search-bar.module.css';
import { UnifiedQuoteSearch } from '../Quotation/workshop/components/UnifiedQuoteSearch';
import { UnifiedReceivedQuoteSearch } from '../Quotation/autoshop/components/UnifiedReceivedQuoteSearch';

interface Props {
  query: string;
  isAgent?: boolean;
  isReceivedQuotation?: boolean;
  handleQuery: (value: string) => void;
}

export const SearchBar = ({
  query,
  isAgent = false,
  isReceivedQuotation = false,
  handleQuery,
}: Props) => {
  const [isOpenFilterDropdown, setIsOpenFilterDropdown] = useState(false);

  const handleDropdownClose = () => {
    setIsOpenFilterDropdown(false);
  };

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounce(
    query,
    300,
  );

  useEffect(() => {
    if (debouncedSearchQuery.trim().length > 0) {
      setIsOpenFilterDropdown(true);
    } else {
      setIsOpenFilterDropdown(false);
    }
  }, [debouncedSearchQuery]);

  return (
    <div className={styles['search-bar-container']}>
      <div className={styles['input-control']}>
        <input
          type="text"
          placeholder={
            isReceivedQuotation
              ? 'Pesquisar por Cotação, Placa, ou Origem'
              : 'Pesquisar por Cotação, Placa, ou Fornecedor'
          }
          value={query}
          onChange={e => {
            handleQuery(e.target.value);
          }}
        />
        <MagnifyingGlass size="1.6rem" color="#A5A5A5" weight="bold" />
      </div>
      {/* <div className={styles['filter-select']}>
        <Faders size="2rem" color="#A5A5A5" weight="bold" />
      </div> */}

      {!isReceivedQuotation && debouncedSearchQuery && isOpenFilterDropdown && (
        <UnifiedQuoteSearch
          isAgent={isAgent}
          searchValue={debouncedSearchQuery}
          handleDropdownClose={handleDropdownClose}
          // handleClearSearch={handleClearSearch}
        />
      )}

      {isReceivedQuotation && debouncedSearchQuery && isOpenFilterDropdown && (
        <UnifiedReceivedQuoteSearch
          isAgent={isAgent}
          searchValue={debouncedSearchQuery}
          handleDropdownClose={handleDropdownClose}
          // handleClearSearch={handleClearSearch}
        />
      )}
    </div>
  );
};
