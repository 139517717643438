import { ArrowUp } from 'phosphor-react';
import { useEffect, useState } from 'react';
import styles from './scrollTopButton.module.css';

export const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    setIsVisible(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      type="button"
      onClick={handleScrollTop}
      className={`${styles.button} ${isVisible ? styles.visible : ''}`}
    >
      <ArrowUp size={24} />
    </button>
  );
};
