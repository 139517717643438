import { handleError } from '../../../utils/handleError';
import { getActiveAnnouncements, updateReadAnnouncement } from './model';

export const getActiveAnnouncementsController = async () => {
  try {
    const res = await getActiveAnnouncements();
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const updateReadAnnouncementController = async (
  id_announcement_history: string,
) => {
  try {
    const res = await updateReadAnnouncement(id_announcement_history);
    return res;
  } catch (error) {
    handleError(error);
  }
};
