import api from '../../../../../../services/api/api';
import { QuotationFilterOptions, Quotations } from './interfaces';

interface GetQuotationsProps {
  limit?: number;
  page?: number;
  option?: 'not_answered' | 'answered' | 'finished';
  filter?: string;
  query?: string;
}

export const getQuotations = async ({
  limit,
  option,
  page,
  // filter,
  query,
}: GetQuotationsProps) => {
  const params: Partial<GetQuotationsProps> = {};
  if (limit) params.limit = limit;
  if (page) params.page = page;
  if (option) params.option = option;
  // if (filter) params.filter = filter;
  if (query) params.query = query;

  const { data } = await api.get<Quotations>('/quotation/v2', {
    params,
  });

  return data;
};
