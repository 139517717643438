import { create } from 'zustand';
import { Repair } from '../../../History/interfaces';

interface SelectedRepairStore {
  selectedRepair: Repair | null;
  onSelect: (repair: Repair) => void;
  onRemove: () => void;
}

const useSelectedRepair = create<SelectedRepairStore>(set => ({
  selectedRepair: null,
  onSelect: (repair: Repair) => set({ selectedRepair: repair }),
  onRemove: () => set({ selectedRepair: null }),
}));

export default useSelectedRepair;
