import { useEffect, useRef, useState } from 'react';

import styles from './unified-quote-search.module.css';
import { Quotations } from './interfaces';
import { fetchQuotationsByFilter } from './controller';
import { TableUniqueSearch } from './components/Table';
import { dateOffset, formatDate } from '../../../../../../utils/dateFunctions';
import { useQuotation } from '../../../../../../contexts/quotationContext';
import useSelectedQuotation from '../../hooks/useSelectedQuotation';
import { useFinishedQuotations } from '../../../autoshop_agent/pages/FinishedQuotations/hooks/useFinishedQuotations';
import useSelectedAnswer from '../../../autoshop_agent/hooks/useSelectedAnswerAgent';
import useViewModalAgent from '../../../workshop_agent/hooks/useViewModalAgent';
import useViewModal from '../../hooks/useViewModal';
import useViewRepliesModalAgent from '../../../workshop_agent/hooks/useViewRepliesModalAgent';
import useViewRepliesModal from '../../hooks/useViewRepliesModal';

const CREATED_QUOTATION_TAB = 'created';
const ANSWERED_QUOTATION_TAB = 'replies';
const FINISHED_QUOTATION_TAB = 'completed';

interface UnifiedQuoteSearchProps {
  isAgent?: boolean;
  searchValue: string;
  // searchType: string;
  handleClearSearch?: () => void;
  handleDropdownClose?: () => void;
}

export const UnifiedQuoteSearch = ({
  isAgent = false,
  searchValue,
  // searchType,
  handleClearSearch,
  handleDropdownClose,
}: UnifiedQuoteSearchProps) => {
  const [filteredResults, setFilteredResults] = useState<Quotations>();
  const [isLoading, setIsLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const { setSelectedPage } = useQuotation();
  const selectedQuotation = useSelectedQuotation();

  const viewModalAgent = useViewModalAgent();
  const viewModal = useViewModal();

  const viewRepliesModalAgent = useViewRepliesModalAgent();
  const viewRepliesModal = useViewRepliesModal();

  const handleSearch = (response: Quotations | undefined): void => {
    setFilteredResults({
      not_answered: [],
      answered: [],
      finished: [],
    });

    if (response) {
      setFilteredResults(response);
    }
  };

  const handleSelectResult = (): void => {
    // handleClearSearch();

    setFilteredResults({
      not_answered: [],
      answered: [],
      finished: [],
    });
  };

  const handleFormatDate = (date: Date) => {
    if (!date) return '--/--/--';
    const currentDate = dateOffset(new Date(date));

    return `${formatDate(currentDate)}`;
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const result = await fetchQuotationsByFilter(searchValue);
      handleSearch(result);

      setIsLoading(false);
    };
    fetchData();
  }, [searchValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        if (handleDropdownClose) {
          handleDropdownClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleDropdownClose]);

  return (
    <div className={styles['search-results-container']} ref={containerRef}>
      {isLoading && (
        <div className="search-results">
          <div className={styles['search-result-wrapper']}>
            <div className={styles['search-results-header']}>
              <h3>Carregando...</h3>
              <hr className={styles['search-header-divisor']} />
            </div>
          </div>
        </div>
      )}
      {!isLoading &&
        filteredResults &&
        (filteredResults.not_answered.length <= 0 &&
        filteredResults.answered.length <= 0 &&
        filteredResults.finished.length <= 0 ? (
          <div className="search-results">
            <div className={styles['search-result-wrapper']}>
              <div className={styles['search-results-header']}>
                <h3>Nenhum resultado encontrado</h3>
                <hr className={styles['search-header-divisor']} />
              </div>
            </div>
          </div>
        ) : (
          (filteredResults.not_answered.length > 0 ||
            filteredResults.answered.length > 0 ||
            filteredResults.finished.length > 0) && (
            <div className="search-results">
              {filteredResults.not_answered.length > 0 && (
                <div className={styles['search-result-wrapper']}>
                  <div className={styles['search-results-header']}>
                    <h3>Cotações Criadas</h3>
                    <hr className={styles['search-header-divisor']} />
                  </div>

                  <TableUniqueSearch.Root>
                    <TableUniqueSearch.Row>
                      <TableUniqueSearch.Header
                        value="Nº Cotação"
                        align="left"
                      />
                      <TableUniqueSearch.Header value="Placa" align="left" />
                      <TableUniqueSearch.Header
                        value="Data de Criação"
                        align="left"
                      />
                    </TableUniqueSearch.Row>
                    {filteredResults.not_answered.map(result => (
                      <TableUniqueSearch.Row
                        key={result.id_quotation}
                        onClick={() => {
                          setSelectedPage(CREATED_QUOTATION_TAB);

                          selectedQuotation.onSelect(result);

                          if (isAgent) {
                            viewModalAgent.onOpen();
                          } else {
                            viewModal.onOpen();
                          }

                          handleSelectResult();
                        }}
                        className=""
                      >
                        <TableUniqueSearch.Cell
                          value={result.QUOTATION.toString()}
                        />
                        <TableUniqueSearch.Cell
                          value={result.buffer_plate || 'Não Informada'}
                        />
                        <TableUniqueSearch.Cell
                          value={handleFormatDate(result.created_at)}
                        />
                      </TableUniqueSearch.Row>
                    ))}
                  </TableUniqueSearch.Root>
                </div>
              )}

              {filteredResults.answered.length > 0 && (
                <div className={styles['search-result-wrapper']}>
                  <div className={styles['search-results-header']}>
                    <h3>Cotações Respondidas</h3>
                    <hr className={styles['search-header-divisor']} />
                  </div>

                  <TableUniqueSearch.Root>
                    <TableUniqueSearch.Row>
                      <TableUniqueSearch.Header value="Nº Cotação" />
                      <TableUniqueSearch.Header value="Placa" />
                      <TableUniqueSearch.Header value="Data de Criação" />
                    </TableUniqueSearch.Row>
                    {filteredResults.answered.map(result => (
                      <TableUniqueSearch.Row
                        key={result.id_quotation}
                        onClick={() => {
                          setSelectedPage(ANSWERED_QUOTATION_TAB);

                          selectedQuotation.onSelect(result);

                          if (isAgent) {
                            viewRepliesModalAgent.onOpen();
                          } else {
                            viewRepliesModal.onOpen();
                          }

                          handleSelectResult();
                        }}
                        className=""
                      >
                        <TableUniqueSearch.Cell
                          value={result.QUOTATION.toString()}
                        />
                        <TableUniqueSearch.Cell
                          value={result.buffer_plate || 'Não Informada'}
                        />
                        <TableUniqueSearch.Cell
                          value={handleFormatDate(result.created_at)}
                        />
                      </TableUniqueSearch.Row>
                    ))}
                  </TableUniqueSearch.Root>
                </div>
              )}

              {filteredResults.finished.length > 0 && (
                <div className={styles['search-result-wrapper']}>
                  <div className={styles['search-results-header']}>
                    <h3>Cotações Finalizadas</h3>
                    <hr className={styles['search-header-divisor']} />
                  </div>

                  <TableUniqueSearch.Root>
                    <TableUniqueSearch.Row>
                      <TableUniqueSearch.Header value="Nº Cotação" />
                      <TableUniqueSearch.Header value="Placa" />
                      <TableUniqueSearch.Header value="Fornecedor" />
                    </TableUniqueSearch.Row>
                    {filteredResults.finished.map(result => (
                      <TableUniqueSearch.Row
                        key={result.answers_quotation?.[0]?.id_answer_quotation}
                        onClick={() => {
                          setSelectedPage(FINISHED_QUOTATION_TAB);

                          selectedQuotation.onSetAnswers(
                            result.answers_quotation || [],
                          );

                          selectedQuotation.onSelect(result);

                          if (isAgent) {
                            viewModalAgent.onOpen();
                          } else {
                            viewModal.onOpen();
                          }

                          handleSelectResult();
                        }}
                        className=""
                      >
                        <TableUniqueSearch.Cell
                          value={result.QUOTATION.toString()}
                        />
                        <TableUniqueSearch.Cell
                          value={result.buffer_plate || 'Não Informada'}
                        />
                        <TableUniqueSearch.Cell
                          value={
                            result.answers_quotation?.[0]?.workshop
                              .fantasy_name || 'Não Informado'
                          }
                        />
                      </TableUniqueSearch.Row>
                    ))}
                  </TableUniqueSearch.Root>
                </div>
              )}
            </div>
          )
        ))}
    </div>
  );
};
