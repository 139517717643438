import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { ModalHeader } from '../ModalHeader';
import useDeleteChecklistModal from '../../hooks/useDeleteChecklistModal';
import styles from './delete-checklist-modal.module.css';
import { ModalContent } from '../../../Finance/components/Modal';
import { Button } from '../../../../../shared/Button';
import { deleteChecklistController } from '../../controller';
import { Checklist } from '../../../../../@types/checklist';
import { FeedbackModal } from '../../../../../shared/FeedbackModal';
import { handleErrorMessage } from '../../../../../utils/handleErrorMessage';

interface DeleteChecklistModalProps {
  onClose?: () => void;
  refetch: () => void;
  checklist: Checklist | null;
}

export const DeleteChecklistModal = ({
  onClose,
  refetch,
  checklist,
}: DeleteChecklistModalProps) => {
  const modal = useDeleteChecklistModal();

  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    onClose?.();
    modal.onClose();
  };
  const handleRemove = () => {
    mutate(checklist?.id_checklist_vehicle ?? '');
  };

  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: async (id: string) => {
      await deleteChecklistController(id);
      queryClient.invalidateQueries(['checklists']);
      handleClose();
      refetch();
    },
    onSuccess: (_, id) => {
      // ⚡️ Atualiza cache removendo o checklist excluído da lista
      queryClient.setQueryData(
        ['checklists'],
        (oldData: Checklist[] | undefined) => {
          if (!oldData) return []; // Retorna um array vazio caso `oldData` seja undefined
          return oldData.filter(item => item.id_checklist_vehicle !== id);
        },
      );

      modal.onClose();
      onClose?.();
    },
    onError: err => {
      console.error('Erro ao criar checklist:', err);
      setError(handleErrorMessage(err));
    },
  });

  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Excluir Checklist" />
      <FeedbackModal isOpen={isLoading} variant="sending" />
      <div className={styles.container}>
        {error ? (
          <span> Esse checklist já foi excluído </span>
        ) : (
          <>
            <span>
              Deseja Excluir o Checklist <b>{checklist?.CHECKLIST ?? ''}</b>?
            </span>
            <span className={styles.advice}>
              Essa ação removerá o checklist apenas para você, o cliente ainda
              terá acesso.
            </span>
          </>
        )}
        {error ? (
          <footer className={styles.actions}>
            <Button
              className={styles['second-button']}
              handleClick={() => {
                handleClose();
                refetch();
              }}
              style={{ margin: 'auto' }}
            >
              Fechar
            </Button>
          </footer>
        ) : (
          <footer className={styles.actions}>
            <Button
              className={styles['second-button']}
              handleClick={handleClose}
            >
              Voltar
            </Button>
            <Button
              className={styles['primary-button']}
              handleClick={handleRemove}
            >
              Confirmar
            </Button>
          </footer>
        )}
      </div>
    </ModalContent>
  );
};
