import { useEffect, useRef, useState } from 'react';
import styles from './unified-quote-search.module.css';
import { ReceivedQuotations } from './interfaces';
import { TableUniqueSearch } from './components/Table';
import { fetchReceivedQuotationsByFilter } from './controller';
import { dateOffset, formatDate } from '../../../../../../utils/dateFunctions';
import useViewModal from '../../hooks/useViewModal';
import { useAnswerQuotation } from '../../../../../../contexts/answerQuotationContext';
import useSelectedAnswer from '../../hooks/useSelectedAnswer';
import useAnswerModal from '../../hooks/useAnswerModal';
import useConfirmModal from '../../hooks/useConfirmModal';
import useViewModalAgent from '../../../autoshop_agent/hooks/useViewModalAgent';
import useSelectedAnswerAgent from '../../../autoshop_agent/hooks/useSelectedAnswerAgent';
import useConfirmModalAgent from '../../../autoshop_agent/hooks/useConfirmModalAgent';
import useAnswerModalAgent from '../../../autoshop_agent/hooks/useAnswerModalAgent';

const RECEIVED_QUOTATION_TAB = 'notAnswered';
const CONFIRMED_QUOTATION_TAB = 'approved';
const FINISHED_QUOTATION_TAB = 'finished';

interface UnifiedReceivedQuoteSearchProps {
  isAgent?: boolean;
  searchValue: string;
  // searchType: string;
  handleClearSearch?: () => void;
  handleDropdownClose?: () => void;
}

export const UnifiedReceivedQuoteSearch = ({
  isAgent,
  searchValue,
  // searchType,
  handleClearSearch,
  handleDropdownClose,
}: UnifiedReceivedQuoteSearchProps) => {
  const [filteredResults, setFilteredResults] = useState<ReceivedQuotations>();
  const [isLoading, setIsLoading] = useState(false);

  const { setSelectedPage } = useAnswerQuotation();
  const selectedAnswerQuotation = useSelectedAnswer();
  const selectedAnswerAgentQuotation = useSelectedAnswerAgent();

  const confirmModal = useConfirmModal();
  const confirmModalAgent = useConfirmModalAgent();

  const viewModal = useViewModal();
  const viewModalAgent = useViewModalAgent();

  const answerModal = useAnswerModal();
  const answerModalAgent = useAnswerModalAgent();

  const containerRef = useRef<HTMLDivElement>(null);

  const handleSearch = (response: ReceivedQuotations | undefined): void => {
    setFilteredResults({
      recieved: [],
      not_confirmed: [],
      finished: [],
    });

    if (response) {
      setFilteredResults(response);
    }
  };

  const handleSelectResult = (): void => {
    // handleClearSearch();

    setFilteredResults({
      recieved: [],
      not_confirmed: [],
      finished: [],
    });
  };

  const handleFormatDate = (date: Date) => {
    if (!date) return '--/--/--';
    const currentDate = dateOffset(new Date(date));

    return `${formatDate(currentDate)}`;
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const result = await fetchReceivedQuotationsByFilter(searchValue);

      handleSearch(result);

      setIsLoading(false);
    };
    fetchData();
  }, [searchValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        if (handleDropdownClose) {
          handleDropdownClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleDropdownClose]);

  return (
    <div className={styles['search-results-container']} ref={containerRef}>
      {isLoading && (
        <div className="search-results">
          <div className={styles['search-result-wrapper']}>
            <div className={styles['search-results-header']}>
              <h3>Carregando...</h3>
              <hr className={styles['search-header-divisor']} />
            </div>
          </div>
        </div>
      )}
      {!isLoading &&
        filteredResults &&
        (filteredResults.recieved.length <= 0 &&
        filteredResults.not_confirmed.length <= 0 &&
        filteredResults.finished.length <= 0 ? (
          <div className="search-results">
            <div className={styles['search-result-wrapper']}>
              <div className={styles['search-results-header']}>
                <h3>Nenhum resultado encontrado</h3>
                <hr className={styles['search-header-divisor']} />
              </div>
            </div>
          </div>
        ) : (
          (filteredResults.recieved.length > 0 ||
            filteredResults.not_confirmed.length > 0 ||
            filteredResults.finished.length > 0) && (
            <div className="search-results">
              {filteredResults.recieved.length > 0 && (
                <div className={styles['search-result-wrapper']}>
                  <div className={styles['search-results-header']}>
                    <h3>Cotações Recebidas</h3>
                    <hr className={styles['search-header-divisor']} />
                  </div>

                  <TableUniqueSearch.Root>
                    <TableUniqueSearch.Row>
                      <TableUniqueSearch.Header
                        value="Nº Cotação"
                        align="left"
                      />
                      <TableUniqueSearch.Header value="Placa" align="left" />
                      <TableUniqueSearch.Header
                        value="Data de Criação"
                        align="left"
                      />
                    </TableUniqueSearch.Row>
                    {filteredResults.recieved.map(result => (
                      <TableUniqueSearch.Row
                        key={result.quotation_id}
                        onClick={() => {
                          setSelectedPage(RECEIVED_QUOTATION_TAB);

                          if (isAgent) {
                            selectedAnswerAgentQuotation.onSelect(result);
                          } else {
                            selectedAnswerQuotation.onSelect(result);
                          }

                          if (!result.answered && isAgent) {
                            answerModalAgent.onOpen();
                          } else if (!result.answered) {
                            answerModal.onOpen();
                          } else if (isAgent) {
                            viewModalAgent.onOpen();
                          } else {
                            viewModal.onOpen();
                          }

                          handleSelectResult();
                        }}
                        className=""
                      >
                        <TableUniqueSearch.Cell
                          value={result.quotation?.QUOTATION.toString() || ''}
                        />
                        <TableUniqueSearch.Cell
                          value={
                            result.quotation?.buffer_plate || 'Não Informada'
                          }
                        />
                        <TableUniqueSearch.Cell
                          value={handleFormatDate(result.created_at)}
                        />
                      </TableUniqueSearch.Row>
                    ))}
                  </TableUniqueSearch.Root>
                </div>
              )}

              {filteredResults.not_confirmed.length > 0 && (
                <div className={styles['search-result-wrapper']}>
                  <div className={styles['search-results-header']}>
                    <h3>Cotações Aguardando Confirmação</h3>
                    <hr className={styles['search-header-divisor']} />
                  </div>

                  <TableUniqueSearch.Root>
                    <TableUniqueSearch.Row>
                      <TableUniqueSearch.Header value="Nº Cotação" />
                      <TableUniqueSearch.Header value="Placa" />
                      <TableUniqueSearch.Header value="Data de Criação" />
                    </TableUniqueSearch.Row>
                    {filteredResults.not_confirmed.map(result => (
                      <TableUniqueSearch.Row
                        key={result.quotation_id}
                        onClick={() => {
                          setSelectedPage(CONFIRMED_QUOTATION_TAB);

                          if (isAgent) {
                            selectedAnswerAgentQuotation.onSelect(result);
                            confirmModalAgent.onOpen();
                          } else {
                            selectedAnswerQuotation.onSelect(result);
                            confirmModal.onOpen();
                          }

                          handleSelectResult();
                        }}
                        className=""
                      >
                        <TableUniqueSearch.Cell
                          value={result.quotation?.QUOTATION.toString() || ''}
                        />
                        <TableUniqueSearch.Cell
                          value={
                            result.quotation?.buffer_plate || 'Não Informada'
                          }
                        />
                        <TableUniqueSearch.Cell
                          value={handleFormatDate(result.created_at)}
                        />
                      </TableUniqueSearch.Row>
                    ))}
                  </TableUniqueSearch.Root>
                </div>
              )}

              {filteredResults.finished.length > 0 && (
                <div className={styles['search-result-wrapper']}>
                  <div className={styles['search-results-header']}>
                    <h3>Cotações Finalizadas</h3>
                    <hr className={styles['search-header-divisor']} />
                  </div>

                  <TableUniqueSearch.Root>
                    <TableUniqueSearch.Row>
                      <TableUniqueSearch.Header value="Nº Cotação" />
                      <TableUniqueSearch.Header value="Placa" />
                      <TableUniqueSearch.Header value="Data de Criação" />
                    </TableUniqueSearch.Row>
                    {filteredResults.finished.map(result => (
                      <TableUniqueSearch.Row
                        key={result.quotation_id}
                        onClick={() => {
                          setSelectedPage(FINISHED_QUOTATION_TAB);

                          if (isAgent) {
                            selectedAnswerAgentQuotation.onSelect(result);
                            viewModalAgent.onOpen();
                          } else {
                            selectedAnswerQuotation.onSelect(result);
                            viewModal.onOpen();
                          }

                          handleSelectResult();
                        }}
                        className=""
                      >
                        <TableUniqueSearch.Cell
                          value={result.quotation?.QUOTATION.toString() || ''}
                        />
                        <TableUniqueSearch.Cell
                          value={
                            result.quotation?.buffer_plate || 'Não Informada'
                          }
                        />
                        <TableUniqueSearch.Cell
                          value={handleFormatDate(result.created_at)}
                        />
                      </TableUniqueSearch.Row>
                    ))}
                  </TableUniqueSearch.Root>
                </div>
              )}
            </div>
          )
        ))}
    </div>
  );
};
