/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CaretDown, CaretUp, FileText, X } from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { Button } from '../../../../../../../../../shared/Button';
import { Table } from '../../../../../../../Finance/components/Table';
import styles from './desktop-list.module.css';
import { Quotation } from '../../../../../../../../../@types/interface';
import {
  dateOffset,
  formatDate,
  formatTime,
} from '../../../../../../../../../utils/dateFunctions';
import useSelectedQuotation from '../../../../../../workshop/hooks/useSelectedQuotation';
import useFinishModal from '../../../../../hooks/useFinishModal';
import useInfiniteScroll from '../../../../../../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../../../../../../../shared/ListLoader';
import useViewRepliesModalAgent from '../../../../../hooks/useViewRepliesModalAgent';
import { Circle } from '../../../../../../../../../shared/Circle';

interface Props {
  quotationList: Quotation[];
  handleRowClick: (quotation: Quotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const DesktopList = ({
  quotationList,
  handleRowClick,
  onScroll,
  isLoading,
  isEmpty = false,
  notFound = false,
}: Props) => {
  const selectedQuotation = useSelectedQuotation();
  const finishModal = useFinishModal();
  const viewRepliesModal = useViewRepliesModalAgent();

  const listRef = useInfiniteScroll(onScroll);

  const message = useMemo(() => {
    if (isEmpty) return 'Sem Novas Cotações';
    if (notFound) return 'Nenhuma Cotação Encontrada';
    return null;
  }, [isEmpty, notFound]);

  return (
    <Table.Root>
      <Table.Row className={styles['table-header']}>
        <Table.Header value="N° Cotação" align="left" />
        {/* <Table.Header value="Situação" align="left" /> */}
        <Table.Header value="Data de Criação" />
        <Table.Header value="Respostas" />
        <Table.Header value="Placa" />
      </Table.Row>

      <div ref={listRef} className={styles['table-content']}>
        {message && <p>{message}</p>}
        {!message &&
          quotationList.map(quotationItem => (
            <ListItem
              key={quotationItem.id_quotation}
              quotation={quotationItem}
              isSelected={
                !!selectedQuotation.quotation &&
                selectedQuotation.quotation.id_quotation ===
                  quotationItem.id_quotation
              }
              onFinish={() => finishModal.onOpen()}
              onRowClick={() => handleRowClick(quotationItem)}
              onViewReplies={() => viewRepliesModal.onOpen()}
            />
          ))}
        {!message && isLoading && <ListLoader />}
      </div>
    </Table.Root>
  );
};

interface ListItemProps {
  quotation: Quotation;
  onViewReplies: () => void;
  onFinish: () => void;
  onRowClick: (quotation: Quotation) => void;
  isSelected: boolean;
}

const ListItem = ({
  quotation,
  isSelected,
  onFinish,
  onRowClick,
  onViewReplies,
}: ListItemProps) => {
  // const situation = useMemo(() => {
  //   if (!quotation.answers_quotation) {
  //     return { color: '#89939E', label: 'Aguardando Aprovação' };
  //   }

  //   const hasOfferNotVisualized = quotation.answers_quotation.some(
  //     answer => !answer.visualized && !answer.confirmed && answer.answered,
  //   );

  //   if (quotation?.finished) {
  //     return { color: '#263238', label: 'Cotação Finalizada' };
  //   }
  //   if (hasOfferNotVisualized) {
  //     return { color: '#007A37', label: 'Nova Oferta' };
  //   }

  //   return { color: '#89939E', label: 'Aguardando Aprovação' };
  // }, [quotation.answers_quotation, quotation?.finished]);

  const createdAt = useMemo(() => {
    if (!quotation?.created_at) return '--/--/--';
    const date = dateOffset(new Date(quotation.created_at));

    return `${formatDate(date)} às ${formatTime(date)}`;
  }, [quotation.created_at]);

  const numberOfAnswers = useMemo(() => {
    if (
      !quotation?.answers_quotation ||
      !quotation?.number_answer ||
      !quotation?.answers_quotation?.length
    )
      return '---';
    return `${quotation.number_answer} de ${quotation.answers_quotation.length}`;
  }, [quotation.answers_quotation, quotation.number_answer]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onRowClick(quotation);
      e.stopPropagation();
    },
    [onRowClick, quotation],
  );

  const visualizedStatus = useMemo(() => {
    if (quotation.visualized) return 'default';
    return 'notification';
  }, [quotation.visualized]);

  const RowIcon = useMemo(
    () => (isSelected ? CaretUp : CaretDown),
    [isSelected],
  );

  const licensePlate = useMemo(() => {
    if (!quotation.vehicle) return '---';
    return quotation.vehicle.license_plate;
  }, [quotation.vehicle]);

  return (
    <div>
      <Table.Row
        className={`${styles['table-row']} ${
          isSelected ? styles.selected : ''
        }`}
        onClick={handleClick}
      >
        <Table.Cell alignStart>
          <div className={styles['first-line']}>
            <RowIcon size={20} />
            {quotation?.QUOTATION ?? ''}
            <Circle variant={visualizedStatus} />
          </div>
        </Table.Cell>
        {/* <Table.Cell alignStart style={{ color: situation.color }}>
          {situation.label}
        </Table.Cell> */}
        <Table.Cell>{createdAt}</Table.Cell>
        <Table.Cell>{numberOfAnswers}</Table.Cell>
        <Table.Cell>{licensePlate}</Table.Cell>
      </Table.Row>
      {isSelected && (
        <Table.Row className={styles['row-actions']}>
          <Button handleClick={onViewReplies}>
            <FileText size={20} weight="bold" />
            {quotation.new_offers ? 'Avaliar Propostas' : 'Visualizar'}
          </Button>
          <Button handleClick={onFinish} variant="cancel">
            <X size={20} weight="bold" />
            Finalizar
          </Button>
        </Table.Row>
      )}
    </div>
  );
};
