/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from '../../create-order-service-form.module.css';
import { categories } from '../../../../Checklists/components/CreateChecklistModal';
import {
  Checklist,
  CreateChecklistInput,
} from '../../../../../../@types/checklist';
import {
  createChecklistController,
  deleteChecklistController,
} from '../../../../Checklists/controller';
import { ChecklistDTO, OrderServiceFormDTO } from '../../OrderServiceDTO';
import { Button } from '../../../../../../shared/Button';
import { AddExistingChecklistConfirmationModal } from '../../../components/AddExistingChecklistConfirmationModal';
import { ToastNotification } from '../../../../../../utils/ToastNotification';
import { ChecklistItems } from './ChecklistItems';

interface Props {
  latestChecklist: Checklist | null;
}

export const ChecklistForm = ({ latestChecklist }: Props) => {
  const { watch, setValue, getValues } = useFormContext<OrderServiceFormDTO>();
  const [showForm, setShowForm] = useState(false);
  const [isCreatingChecklist, setIsCreatingChecklist] = useState(false);
  const previousFormValuesRef = useRef<ChecklistDTO>({
    checklist_category_details: [],
    checklist_images: [],
    id_checklist_vehicle: '',
  });

  const [confirmChecklistModal, setConfirmChecklistModal] = useState(false);
  const [usingLatestChecklist, setUsingLatestChecklist] = useState(false);
  const creatingChecklistRef = useRef(false);

  const isDraft = watch('isChecklistDraft');
  const checklistId = watch('checklist.id_checklist_vehicle') ?? '';
  const noVehicle = watch('vehicle.noVehicle') ?? '';
  const checklistImages = getValues('checklist.checklist_images') || [];
  const checklistCategories = getValues('checklist.checklist_category_details');

  // Cria um novo checklist e adiciona à lista de opções
  const handleCreateNewChecklist = useCallback(async () => {
    setIsCreatingChecklist(true);
    try {
      if (creatingChecklistRef.current) return; // Impede chamadas duplicadas
      creatingChecklistRef.current = true;

      const { customer, vehicle } = getValues();
      const res = await createChecklistController({
        name: customer.name,
        plate: vehicle.plate,
        phone: customer.phone,
        checklist_category_details: categories.map(category => ({
          category: category.name,
          observations: '',
        })),
        checklist_images: [],
      });

      if (res) {
        previousFormValuesRef.current = {
          id_checklist_vehicle: res?.id_checklist_vehicle ?? '',
          checklist_category_details:
            res?.checklist_category_details?.map(detail => ({
              category: detail?.category ?? '',
              observations: detail?.observations ?? '',
            })) ?? [],
          checklist_images: res?.checklist_vehicle_imgs ?? [],
        };

        setValue('isChecklistDraft', true);
        setValue('checklist', {
          checklist_category_details:
            res?.checklist_category_details?.map(detail => ({
              category: detail?.category ?? '',
              observations: detail?.observations ?? '',
            })) ?? [],
          checklist_images: res?.checklist_vehicle_imgs ?? [],
          id_checklist_vehicle: res?.id_checklist_vehicle ?? '',
        });

        setShowForm(true);
      }
    } catch (err) {
      console.error(err);
    } finally {
      creatingChecklistRef.current = false;
      setIsCreatingChecklist(false);
    }
  }, [getValues, setValue]);

  const isFormEmpty = useCallback((formData: ChecklistDTO): boolean => {
    return (
      formData.checklist_images.length === 0 &&
      formData.checklist_category_details.every(
        item => item.observations?.trim().length === 0,
      )
    );
  }, []);

  const updateChecklist = useCallback(async () => {
    const { checklist, customer, vehicle } = getValues();

    const checklistFormValues = {
      checklist_category_details: checklist.checklist_category_details,
      checklist_images: checklist.checklist_images,
      id_checklist_vehicle: checklist.id_checklist_vehicle,
    };
    const currentFormValues: CreateChecklistInput = {
      name: customer.name,
      phone: customer.phone,
      plate: vehicle.plate,
      ...checklistFormValues,
    };
    if (!isFormEmpty(checklistFormValues)) {
      try {
        const res = await createChecklistController(currentFormValues);

        if (res) {
          setValue('checklist', {
            checklist_images: res?.checklist_vehicle_imgs?.map(img => ({
              category: img?.category,
              img_url: img?.img_url,
            })),
            checklist_category_details: res?.checklist_category_details?.map(
              detail => {
                return {
                  category: detail?.category ?? '',
                  observations: detail?.observations ?? '',
                };
              },
            ),
            id_checklist_vehicle: res.id_checklist_vehicle,
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [getValues, isFormEmpty, setValue]);

  const handleFormChange = useCallback(() => {
    updateChecklist();
  }, [updateChecklist]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentFormValues = getValues('checklist');

      if (
        JSON.stringify(currentFormValues) !==
          JSON.stringify(previousFormValuesRef.current) &&
        showForm
      ) {
        handleFormChange();
        previousFormValuesRef.current = { ...currentFormValues };
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [getValues, handleFormChange, showForm]);

  const handleUseLatestChecklist = async () => {
    await handleDeleteChecklist();
    if (latestChecklist) {
      setValue('isChecklistDraft', latestChecklist?.draft ?? false);

      setValue('checklist', {
        checklist_category_details:
          latestChecklist?.checklist_category_details?.map(detail => {
            return {
              category: detail?.category ?? '',
              observations: detail?.observations ?? '',
            };
          }),
        checklist_images: latestChecklist?.checklist_vehicle_imgs?.map(img => ({
          category: img?.category,
          img_url: img?.img_url,
        })),
        id_checklist_vehicle: latestChecklist.id_checklist_vehicle,
      });

      setConfirmChecklistModal(false);
    }
  };

  const handleDeleteChecklist = async () => {
    const { id_checklist_vehicle } = getValues('checklist');
    try {
      await deleteChecklistController(id_checklist_vehicle);

      setValue('checklist', {
        checklist_category_details: [],
        checklist_images: [],
        id_checklist_vehicle: '',
      });
    } catch (err) {
      ToastNotification.show(
        'Não foi possível apagar esse checklist.',
        'warning',
      );
    }
  };

  useEffect(() => {
    setShowForm(!!checklistId && checklistId.length > 0);
  }, [checklistId]);

  useEffect(() => {
    setUsingLatestChecklist(
      !!checklistId &&
        !!latestChecklist &&
        checklistId === latestChecklist.id_checklist_vehicle,
    );
  }, [checklistId, latestChecklist]);

  if (!showForm) {
    return (
      <div className={styles.form}>
        {noVehicle ? (
          <p>Adicione um Veículo a OS para usar um checklist</p>
        ) : (
          <Button
            handleClick={async () => {
              await handleCreateNewChecklist();
            }}
            isLoading={isCreatingChecklist}
            disabled={isCreatingChecklist}
          >
            Adicionar Checklist
          </Button>
        )}
      </div>
    );
  }

  return (
    <form action="" className={styles.form}>
      {confirmChecklistModal && latestChecklist && !usingLatestChecklist && (
        <AddExistingChecklistConfirmationModal
          handleClose={() => {
            setUsingLatestChecklist(false);
            setConfirmChecklistModal(false);
          }}
          handleConfirm={handleUseLatestChecklist}
        />
      )}
      <div className={styles['form-row']}>
        {latestChecklist && (
          <div className={styles['form-row']}>
            <label
              htmlFor="last-checklist"
              className={styles.checkbox}
              onClick={async () => {
                if (!usingLatestChecklist) setConfirmChecklistModal(true);
                else {
                  setConfirmChecklistModal(false);
                  setValue('checklist.id_checklist_vehicle', '');
                  await handleCreateNewChecklist();
                }
              }}
            >
              <input
                type="checkbox"
                name="last-checklist"
                id="last-checklist"
                checked={usingLatestChecklist}
              />
              Usar último checklist
            </label>
            {/* <button
              type="button"
              onClick={() => {
                setValue('checklist', {
                  checklist_category_details: [],
                  checklist_images: [],
                  id_checklist_vehicle: '',
                });
              }}
            >
              excluir
            </button> */}
          </div>
        )}
      </div>
      <ChecklistItems
        isDraft={isDraft}
        checklistCategories={checklistCategories}
        checklistImages={checklistImages}
      />
    </form>
  );
};
