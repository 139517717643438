import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import styles from './images-step.module.css';
import { ExpandableSection } from '../../../../../../../shared/ExpandableSection';
import ImageInput from '../../../../../../../shared/ImageInput';
import { categories, FormValues } from '../..';
import { ChecklistCategoryDetail } from '../../../../../../../@types/checklist';
import { TextareaComponent } from '../../../../../../../shared/TextareaComponent';

interface Props {
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  setIsUploadingImages: Dispatch<SetStateAction<boolean>>;
  setCategoryDetails: Dispatch<SetStateAction<ChecklistCategoryDetail[]>>;
  categoryDetails: ChecklistCategoryDetail[];
}

export const ImagesStep = ({
  setValue,
  getValues,
  setIsUploadingImages,
  categoryDetails,
  setCategoryDetails,
}: Props) => {
  const handleImageChange = (category: string, images: string[]) => {
    const currentImages = getValues('checklist_images') || [];
    const updatedImages = [
      ...currentImages.filter(img => img.category !== category),
      ...images.map(img => ({ img_url: img, category })),
    ];

    setValue('checklist_images', updatedImages);
  };

  const checklistImages = getValues('checklist_images') || [];

  const getCategoryImages = (category: string) =>
    checklistImages
      .filter(img => img.category === category)
      .map(img => img.img_url);

  return (
    <form action="" className={styles.form}>
      {categories.map((category, index) => (
        <ExpandableSection
          title={categories[index].label}
          key={categories[index].name}
        >
          <ImageInput
            bucketPath="checklist"
            setImageUrls={images => handleImageChange(category.name, images)}
            imagesUrls={getCategoryImages(category.name)}
            maxImages={10}
            imageClassName={styles['image-input']}
            setIsUploadingImages={setIsUploadingImages}
            size={68}
          />

          <TextareaComponent
            className={styles.textarea}
            value={
              categoryDetails.find(
                currentCategory => currentCategory.category === category.name,
              )?.observations ?? ''
            }
            onChange={e => {
              setCategoryDetails(previous =>
                previous.map(currentCategory => {
                  if (currentCategory.category === category.name) {
                    return {
                      ...currentCategory,
                      observations: e.target.value,
                    };
                  }
                  return currentCategory;
                }),
              );
            }}
            placeholder={`Digite observações sobre ${category.label}...`}
          />
        </ExpandableSection>
      ))}
    </form>
  );
};
