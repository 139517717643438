import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../shared/Button';
import { ModalHeader } from '../../../Checklists/components/ModalHeader';
import { ModalContent } from '../../../Finance/components/Modal';
import styles from './order-service-success-modal.module.css';

interface Props {
  isEditing?: boolean;
}

export const OrderServiceSuccessModal = ({ isEditing }: Props) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/order-service-list');
  };

  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader
        handleClose={handleRedirect}
        title={`OS ${isEditing ? 'editada' : 'criada'} com Sucesso`}
      />
      <p className={styles.message}>
        Você será redirecionado para a página de Ordens de Serviço
      </p>
      <div className={styles.actions}>
        <Button
          handleClick={handleRedirect}
          className={styles['primary-button']}
        >
          OK
        </Button>
      </div>
    </ModalContent>
  );
};
