import { ArrowCircleLeft, CheckSquareOffset } from 'phosphor-react';
import { useMemo } from 'react';
import { ModalHeader } from '../ModalHeader';
import styles from './confirm-redo-checklist-modal.module.css';
import { ModalContent } from '../../../Finance/components/Modal';
import { Button } from '../../../../../shared/Button';
import { Checklist } from '../../../../../@types/checklist';
import useSendChecklistModal from '../../hooks/useSendChecklistModal';
import { useResponsive } from '../../../../../hooks/useResponsive';

interface ConfirmRedoChecklistModalProps {
  onClose?: () => void;
  onCreate: () => void;
  checklist: Checklist | null;
}

export const ConfirmRedoChecklistModal = ({
  onClose,
  checklist,
  onCreate,
}: ConfirmRedoChecklistModalProps) => {
  const { sizeMobile } = useResponsive();

  const modal = useSendChecklistModal();
  const iconSize = useMemo(() => (sizeMobile ? 32 : 26), [sizeMobile]);
  const handleClose = () => {
    onClose?.();
    modal.onClose();
  };

  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Atenção" />
      <div className={styles.container}>
        <span>
          O checklist <b>{checklist?.CHECKLIST ?? ''}</b> não pode ser editado,
          mas você pode criar um novo com base no atual.
        </span>

        <footer className={styles.actions}>
          <Button className={styles['second-button']} handleClick={handleClose}>
            <ArrowCircleLeft size={iconSize} weight="fill" />
            Voltar
          </Button>
          <Button className={styles['primary-button']} handleClick={onCreate}>
            <CheckSquareOffset size={iconSize} weight="fill" />
            Criar novo checklist
          </Button>
        </footer>
      </div>
    </ModalContent>
  );
};
