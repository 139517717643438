/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import styled from 'styled-components';
import { useState } from 'react';

import { IService } from '../../../../../@types/interface';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    color: '#1E1E1E',
    border: 0,
    fontFamily: 'montserrat',
  },
  '@media screen and (max-width:900px)': {
    [`&.${tableCellClasses.head}, &.${tableCellClasses.body}`]: {
      padding: '0.25rem',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#1E1E1E',
    border: 0,
    fontFamily: 'montserrat',
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}:first-child`]: {
    borderTopLeftRadius: '11px',
  },
  [`&.${tableCellClasses.body}:nth-child(5)`]: {
    borderTopRightRadius: '11px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '@media screen and (max-width:900px)': {
    height: '3rem',
  },
  '&': {
    border: 0,
    height: '5rem',
  },
}));

interface IServices {
  services?: IService[];
  setServices?: (services: IService[]) => void;
  full?: boolean;
}
interface IRowTable extends IService {
  index?: number;
  color: boolean;
}

export const ReviewServiceTable = (props: IServices) => {
  const { services, setServices, full } = props;

  // eslint-disable-next-line react/no-unstable-nested-components
  const RowTable = ({
    name,
    quantity,
    total,
    index,
    cod_prod,
    color,
  }: IRowTable) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
      <StyledTableRow
        onClick={() => setOpen(!open)}
        sx={{ background: color ? '#f5faf5' : '#fff' }}
      >
        <StyledTableCell align="center" style={{ fontSize: '10px' }}>
          {index}
        </StyledTableCell>
        <StyledTableCell
          align="center"
          style={{ width: '8rem', fontSize: '10px' }}
        >
          {name}
        </StyledTableCell>
        <StyledTableCell align="center" style={{ fontSize: '10px' }}>
          {quantity}
        </StyledTableCell>
        <StyledTableCell align="center" style={{ fontSize: '10px' }}>
          {(total / quantity).toLocaleString('pt-BR')}
        </StyledTableCell>
        <StyledTableCell align="center" style={{ fontSize: '10px' }}>
          {total.toLocaleString('pt-BR', {
            minimumIntegerDigits: 2,
            minimumFractionDigits: 2,
            useGrouping: true,
          })}
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <TableContainer sx={{ width: full ? 'auto' : '100%' }}>
      <Table sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Item</StyledTableCell>
            <StyledTableCell>Produto/Serviço</StyledTableCell>
            <StyledTableCell align="center">Qtd.</StyledTableCell>
            <StyledTableCell align="center">Unit.</StyledTableCell>
            <StyledTableCell align="center">Subtotal</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!services &&
            services?.map((service: IService, index: number) => (
              <RowTable
                name={service.name}
                cod_prod={service.cod_prod}
                index={index + 1}
                description={service.description ? service.description : ''}
                total={service.total}
                quantity={service.quantity}
                key={index}
                color={index % 2 === 0}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
