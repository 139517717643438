import { CaretDown, Eye, PencilSimple, X } from '@phosphor-icons/react';
import styles from './maintenance-item.module.css';
import { COLORS } from '../../../../../styles/colors';
import Tooltip from '../../../../../shared/ToolTip';
import { preventiveMaintenanceResponse } from '../../interface';
import { dateOffset, formatDate } from '../../../../../utils/dateFunctions';

interface Props {
  selected: boolean;
  handleClick: () => void;
  maintenance: preventiveMaintenanceResponse;
}

export const MaintenanceItem = ({
  selected,
  handleClick,
  maintenance,
}: Props) => {
  const handleText = (text: string) => {
    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {text.length > 15 ? `${text.slice(0, 15)}...` : text}
      </span>
    );
  };

  return (
    <button type="button" className={styles.container} onClick={handleClick}>
      <div className={`${styles.row} ${selected ? styles.selected : ''}`}>
        <div className={`${styles.desktop} ${styles.dropdown}`}>
          <div className={styles.icon}>{/* <CaretDown size="1.5rem" /> */}</div>
          <span className={styles['text-left']}>
            {maintenance.vehicle.name}
          </span>
        </div>
        <div className={styles['first-mobile']}>
          <div className={styles.icon}>{/* <CaretDown size="1.5rem" /> */}</div>
          <Tooltip text={maintenance.vehicle.license_plate}>
            {handleText(maintenance.vehicle.license_plate)}
          </Tooltip>
        </div>
        <div>{maintenance.vehicle.model}</div>
        <div>
          <Tooltip
            text={formatDate(dateOffset(new Date(maintenance.active_at)))}
          >
            {handleText(
              formatDate(dateOffset(new Date(maintenance.active_at))),
            )}
          </Tooltip>
        </div>
      </div>
      {/* Definir funcionalidade */}
      {/* {selected && (
        <div className={styles.subrow}>
          <button type="button">
            <Eye size={20} color={COLORS.WHITE} />
            Visualizar
          </button>
          <button type="button">
            <X size={20} color={COLORS.WHITE} weight="bold" />
            Excluir
          </button>
          <button type="button">
            <PencilSimple size={20} color={COLORS.WHITE} />
            Editar
          </button>
        </div>
      )} */}
    </button>
  );
};
