import { ComponentPropsWithoutRef } from 'react';
import ReactModal from 'react-modal';

import { X } from 'phosphor-react';
import styles from './modal.module.css';
import logo from '../../../../../assets/logo_icone.svg';

/** Modal Context */

type ModalContentProps = ComponentPropsWithoutRef<'div'> & {
  open: boolean;
  onClose?: () => void;
  borderTop?: boolean;
  hasLogo?: boolean;
  ContentClassName?: string;
  full?: boolean;
  noCloseButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
};

ReactModal.setAppElement('#root');

export const ModalContent = ({
  children,
  open,
  onClose,
  borderTop = false,
  hasLogo = false,
  full = false,
  noCloseButton = false,
  ContentClassName,
  shouldCloseOnOverlayClick = false,
  ...props
}: ModalContentProps) => {
  return (
    <ReactModal
      isOpen={open}
      overlayClassName={styles.overlay}
      className={`${styles.container} ${borderTop && styles.border} ${
        full && styles.full
      }`}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {hasLogo && <img src={logo} alt="Logo" className={styles.logo} />}
      {!noCloseButton && (
        <button type="button" onClick={onClose} className={styles.close}>
          <X size={16} />
        </button>
      )}
      <div {...props} className={`${styles.content} ${ContentClassName}`}>
        {children}
      </div>
    </ReactModal>
  );
};
