import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PlusSquare, Trash } from '@phosphor-icons/react';
import { useForm, Controller } from 'react-hook-form';
import { Preventive, preventiveMaintenanceResponse } from '../../interface';
import styles from './view-modal.module.css';
import { dateOffset, formatDateUTC } from '../../../../../utils/dateFunctions';
import { Modal } from '../../../../../shared/Modal';
import { Input } from '../../../../../shared/Input';
import { Button } from '../../../../../shared/Button';

interface Props {
  onClose: () => void;
  preventive: Partial<preventiveMaintenanceResponse> | null;
  handleCreatePreventive: (
    preventive: Preventive,
    option: 'km' | 'date',
    km?: number,
    trigger_date?: string,
  ) => Promise<preventiveMaintenanceResponse | undefined>;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
}

interface valuesForm {
  option: 'km' | 'date';
  km?: string;
  trigger_date?: string;
  date: string;
}

export const ViewModal = ({
  onClose,
  preventive,
  handleCreatePreventive,
  setIsDeleteModalOpen,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<valuesForm>({
    defaultValues: {
      option: 'km',
      km: '',
      trigger_date: '',
      date: '',
    },
  });

  const selectedOption = watch('option');

  const handleInputChange = (data: React.ChangeEvent<HTMLInputElement>) => {
    const value = data.target.value.replace(/\D/g, '');
    setValue('km', value);
  };

  const handleSave = async (data: valuesForm) => {
    if (preventive && selectedOption) {
      const res = await handleCreatePreventive(
        preventive as Preventive,
        data.option,
        parseFloat(data.km || '0'),
        data.date,
      );
      if (res) {
        setValue(
          'km',
          res.car_km_to_active ? res.car_km_to_active.toString() : '',
        );
        if (res.trigger_date) {
          setValue(
            'trigger_date',
            formatDateUTC(
              dateOffset(
                new Date(preventive?.trigger_date?.replace('Z', '') || ''),
              ),
            ),
          );
        } else {
          setValue(
            'trigger_date',
            formatDateUTC(dateOffset(new Date(res?.active_at || ''))),
          );
        }
      }
    }
    onClose();
  };

  useEffect(() => {
    if (preventive !== null) {
      setIsModalOpen(true);
      const value = preventive.trigger_date ? 'date' : 'km';
      setValue('option', value);
      setValue(
        'km',
        preventive.car_km_to_active
          ? preventive.car_km_to_active.toString()
          : '',
      );
      if (preventive.trigger_date) {
        setValue(
          'trigger_date',
          formatDateUTC(new Date(preventive?.trigger_date || '')),
        );
      } else {
        const date = preventive?.active_at
          ? formatDateUTC(new Date(preventive?.active_at))
          : '';
        setValue('trigger_date', date);
      }
    } else {
      setIsModalOpen(false);
    }
  }, [preventive]);

  useEffect(() => {
    if (!isModalOpen) {
      reset();
    }
  }, [isModalOpen, reset]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        onClose();
      }}
      title="Itens Preventivos"
    >
      <div className={styles.container}>
        <Controller
          name="option"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="select"
              options={[
                {
                  name: 'KM',
                  value: 'km',
                },
                {
                  name: 'Data',
                  value: 'date',
                },
              ]}
              label="Tipo de Agendamento"
            />
          )}
        />
        {selectedOption === 'km' ? (
          <Controller
            name="km"
            control={control}
            rules={{
              required: 'Informe a KM',
              validate: value =>
                (value && value.toString().length >= 3) || 'km mínimo: 100',
            }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  label="KM"
                  onChange={handleInputChange}
                  style={{ borderColor: errors.km ? 'red' : '' }}
                />
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {errors.km && errors.km.message}
                </span>
              </>
            )}
          />
        ) : (
          <Controller
            name="date"
            control={control}
            rules={{ required: 'Informe a data' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="date"
                  label="Data"
                  style={{ borderColor: errors.date ? 'red' : '' }}
                />
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {errors.date && errors.date.message}
                </span>
              </>
            )}
          />
        )}

        <Controller
          name="trigger_date"
          control={control}
          render={({ field }) => (
            <Input {...field} label="Data Prevista" type="text" disabled />
          )}
        />

        <div className={styles.actions}>
          {preventive &&
            (preventive.car_km_to_active || preventive.trigger_date) && (
              <Button
                handleClick={() => {
                  setIsDeleteModalOpen(true);
                  setIsModalOpen(false);
                }}
                icon={<Trash size={24} color="#fff" />}
                variant="register"
                theme="delete"
                style={{
                  width: '100%',
                  fontWeight: 400,
                  fontSize: '1.5rem',
                }}
              >
                Excluir
              </Button>
            )}
          <Button
            handleClick={handleSubmit(handleSave)}
            variant="register"
            icon={<PlusSquare size={24} />}
            style={{
              width: '100%',
              fontWeight: 400,
              fontSize: '1.5rem',
            }}
          >
            Salvar
          </Button>
        </div>
      </div>
    </Modal>
  );
};
