import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { CaretLeft, CaretRight, XSquare } from '@phosphor-icons/react';
import Markdown from 'react-markdown';
import Logo from '../../assets/icon_autocenter.svg';
import styles from './modal.module.css';

import { AnnouncementResponse } from '../../pages/workshop/Home/interfaces';
import { updateReadAnnouncementController } from '../../pages/workshop/Home/controller';

const fileExtensionVideo = [
  'mp4',
  'avi',
  'mov',
  'wmv',
  'flv',
  'webm',
  'mkv',
  'ogg',
];

const fileExtensionImage = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tiff',
  'heic',
  'avif',
];

type ModalSizes = 'default' | 'large' | 'full';
interface Props {
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  initialStepNavBarAnnouncement?: number;
  size?: ModalSizes;
  isLoading?: boolean;
  style?: CSSProperties;
  disableScroll?: boolean;
  container?: Element | DocumentFragment;
  bodyStyle?: string;
  className?: string;
  announcements?: AnnouncementResponse[];
}

export const ModalAnnouncement = ({
  isOpen,
  onClose,
  title,
  initialStepNavBarAnnouncement = 0,
  size = 'default',
  isLoading = false,
  style,
  disableScroll = true,
  container = document.body,
  bodyStyle = '',
  className,
  announcements,
}: Props) => {
  const [isFocus, setIsFocus] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const [isLoadingImage, setIsLoadingImage] = useState(true);

  const [announcement, setAnnouncement] = useState<AnnouncementResponse | null>(
    announcements?.[0] || null,
  );

  const images = useMemo(
    () => announcement?.announcement.img_urls || [],
    [announcement],
  );

  const [navBarAnnouncementImagesStep, setNavBarAnnouncementImagesStep] =
    useState(0);

  const [navBarAnnouncementStep, setNavBarAnnouncementStep] = useState(
    initialStepNavBarAnnouncement,
  );
  const [imageUrl, setImageUrl] = useState(images[0]?.img_url || '');

  const renderMediaContent = (url: string) => {
    const fileExtension = url.split('.').pop();

    if (!fileExtension)
      return (
        <a
          href={
            announcement?.announcement.img_urls[navBarAnnouncementImagesStep]
              .redirect_link || '#'
          }
        >
          <img className={styles.image} src={Logo} alt="Imagem do anúncio" />
        </a>
      );

    if (
      !fileExtensionImage.includes(fileExtension) &&
      !fileExtensionVideo.includes(fileExtension)
    )
      return (
        <a
          href={
            announcement?.announcement.img_urls[navBarAnnouncementImagesStep]
              .redirect_link || '#'
          }
        >
          <img className={styles.image} src={Logo} alt="Imagem do anúncio" />
        </a>
      );

    if (fileExtensionVideo.includes(fileExtension)) {
      return (
        <video
          className={styles.image}
          autoPlay
          loop
          muted={false}
          controls
          playsInline
        >
          <source src={url} type="video/mp4" />
          <track kind="captions" />
        </video>
      );
    }

    if (fileExtensionImage.includes(fileExtension))
      return (
        <a
          href={
            announcement?.announcement.img_urls[navBarAnnouncementImagesStep]
              .redirect_link || '#'
          }
        >
          <img className={styles.image} src={url} alt="Imagem do Anúncio" />
        </a>
      );
  };

  const handlePressNavBarImages = (direction: 'left' | 'right') => {
    if (navBarAnnouncementImagesStep === 0 && direction === 'left') {
      return;
    }

    if (
      navBarAnnouncementImagesStep === images.length - 1 &&
      direction === 'right'
    ) {
      return;
    }

    setIsLoadingImage(true);

    setNavBarAnnouncementImagesStep(prevStep => {
      if (direction === 'left' && prevStep !== 0) {
        return prevStep - 1;
      }

      if (direction === 'right' && images && prevStep < images.length - 1) {
        return prevStep + 1;
      }

      return prevStep;
    });
  };

  const handlePressNavBarAnnouncements = async (
    direction: 'left' | 'right',
  ) => {
    setNavBarAnnouncementStep(prevStep => {
      if (direction === 'left' && prevStep !== 0) {
        return prevStep - 1;
      }

      if (
        direction === 'right' &&
        announcements &&
        prevStep < announcements.length - 1
      ) {
        return prevStep + 1;
      }

      return prevStep;
    });

    setNavBarAnnouncementImagesStep(0);

    await handleUpdateReadAnnouncement();
  };

  const handleUpdateReadAnnouncement = async () => {
    await updateReadAnnouncementController(
      announcement?.id_announcement_history || '',
    );
  };

  useEffect(() => {
    if (announcements) {
      setAnnouncement(announcements[navBarAnnouncementStep]);
    }
  }, [navBarAnnouncementStep, announcements]);

  useEffect(() => {
    if (images) {
      setImageUrl(images[navBarAnnouncementImagesStep]?.img_url || '');
    }

    setIsLoadingImage(false);
  }, [navBarAnnouncementImagesStep, images]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      if (disableScroll) {
        overlayRef.current?.focus();
        document.body.style.overflow = 'hidden';
      }

      // Focus on available title
      if (titleRef.current) {
        titleRef.current.focus();
      } else {
        // if cannot focus on title focus on first element in modal
        const firstFocusableElement = modalRef.current.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        ) as HTMLElement | null;
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      }
    }

    return () => {
      if (isOpen) document.body.style.overflow = 'auto';
    };
  }, [disableScroll, isOpen, isFocus]);

  return createPortal(
    isOpen ? (
      <div
        className={styles.overlay}
        ref={overlayRef}
        // make overlay focusable on Safari
        tabIndex={-1}
      >
        <div
          className={`${styles.content} ${styles[size]} ${className}`}
          ref={modalRef}
          style={style}
        >
          <div className={styles.header}>
            <hr />
            <div className={styles.content}>
              <h2 ref={titleRef} tabIndex={-1}>
                {title}
              </h2>
            </div>
            {/* Adiciona tabIndex para permitir foco */}
            {onClose && (
              <button
                type="button"
                onClick={() => {
                  handleUpdateReadAnnouncement();
                  onClose();
                }}
              >
                <XSquare size={30} color="var(--black)" />
              </button>
            )}
          </div>
          <div className={`${styles.body} ${bodyStyle}`}>
            {isLoadingImage && (
              <div className={styles['loading-overlay']}>
                <span className={styles.loader} />
              </div>
            )}

            {!isLoadingImage && (
              <div className={styles.imageContainer}>
                {images.length > 1 && (
                  <CaretLeft
                    size={32}
                    onClick={() => handlePressNavBarImages('left')}
                    className={styles.icon}
                  />
                )}

                {renderMediaContent(imageUrl)}

                {images.length > 1 && (
                  <CaretRight
                    size={32}
                    onClick={() => handlePressNavBarImages('right')}
                    className={styles.icon}
                  />
                )}
              </div>
            )}

            <div className={styles.navBarImage}>
              <div className={styles.navBarStepsContainer}>
                {images &&
                  images.length > 1 &&
                  images.map((image, index) => (
                    <div
                      key={image.id_announcement_img}
                      className={`${styles.navBarImageSteps} ${
                        navBarAnnouncementImagesStep === index
                          ? styles.selected
                          : ''
                      }`}
                    />
                  ))}
              </div>
            </div>
            <div className={styles.textContainer}>
              <h1>{announcement?.announcement.title}</h1>
              <Markdown className={styles.message}>
                {announcement?.announcement.message || ''}
              </Markdown>
            </div>
            <div className={styles.navBarFooter}>
              {(announcements?.length ?? 0) > 1 && (
                <CaretLeft
                  size={32}
                  onClick={() => handlePressNavBarAnnouncements('left')}
                  className={styles.icon}
                />
              )}
              <div className={styles.navBarStepsContainer}>
                {announcements &&
                  announcements.length > 1 &&
                  announcements.map((currentAnnouncement, index) => (
                    <div
                      key={currentAnnouncement.announcement.id_announcement}
                      className={`${styles.navBarSteps} ${
                        navBarAnnouncementStep === index ? styles.selected : ''
                      }`}
                    >
                      {(index + 1).toString().padStart(2, '0')}
                    </div>
                  ))}
              </div>
              {(announcements?.length ?? 0) > 1 && (
                <CaretRight
                  size={32}
                  onClick={() => handlePressNavBarAnnouncements('right')}
                  className={styles.icon}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    ) : null,
    container,
  );
};
