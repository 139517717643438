import { ModalHeader } from '../ModalHeader';
import styles from './error-modal.module.css';
import { ModalContent } from '../../../Finance/components/Modal';
import { Button } from '../../../../../shared/Button';

interface ErrorModalProps {
  onClose: () => void;
  isOpen: boolean;
  message: string;
}

export const ErrorModal = ({ onClose, isOpen, message }: ErrorModalProps) => {
  const handleClose = () => {
    onClose();
  };

  if (!isOpen) return null;
  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Atenção" />
      <div className={styles.container}>
        <span>{message}</span>
        <footer className={styles.actions}>
          <Button className={styles['second-button']} handleClick={handleClose}>
            Voltar
          </Button>
        </footer>
      </div>
    </ModalContent>
  );
};
