import { ComponentProps, useState } from 'react';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import styles from './table-header.module.css';

type TableHeaderProps = {
  value: string;
  width?: string;
  hasFilter?: boolean;
} & ComponentProps<'th'>;

export const TableHeader = ({
  value,
  width = 'auto',
  hasFilter = false,
  ...props
}: TableHeaderProps) => {
  const [iconIdentifier, setIconIdentifier] = useState(false);

  const switchLabel = (field: string) => {
    switch (field) {
      case 'Tipo':
        return 'type';
      case 'Descrição':
        return 'description';
      case 'Lançamento':
        return 'created_at';
      case 'Vencimento':
        return 'due_date';
      case 'Baixa':
        return 'discharge_date';
      case 'Valor':
        return 'value';
      default:
        return '';
    }
  };

  const orderChoose = () => {
    if (iconIdentifier) {
      return 'DESC';
    }
    return 'ASC';
  };

  const sortTransactions = async () => {
    console.log('sort');
  };

  const changeIcon = () => {
    if (!hasFilter) {
      return;
    }

    setIconIdentifier(!iconIdentifier);
    sortTransactions();
  };

  const switchIcon = () => {
    if (iconIdentifier) {
      return <ArrowDown size={14} />;
    }
    return <ArrowUp size={14} />;
  };

  return (
    <th
      {...props}
      className={`${styles['table-header']} ${props.className}`}
      style={{
        width,
      }}
      onClickCapture={() => {
        changeIcon();
      }}
    >
      <span>{value}</span>
      {hasFilter && switchIcon()}
    </th>
  );
};
