import {
  CaretDown,
  CaretUp,
  Eye,
  FileText,
  PaperPlaneRight,
  PencilSimple,
  Trash,
} from 'phosphor-react';
import { formatDate } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import styles from './checklist-table.module.css';
import { Table } from '../../../../../shared/Table';
import { Button } from '../../../../../shared/Button';
import { dateOffset } from '../../../../../utils/dateFunctions';
import { Checklist } from '../../../../../@types/checklist';
import useEditChecklistModal from '../../hooks/useEditChecklistModal';
import useDeleteChecklistModal from '../../hooks/useDeleteChecklistModal';
import useSendChecklistModal from '../../hooks/useSendChecklistModal';
import useCreateChecklistModal from '../../hooks/useCreateChecklistModal';

interface ChecklistTableRowProps {
  checklist: Checklist;
  selectedRowId: string;
  handleRowClick: (checklist: Checklist | null) => void;
  isMobile: boolean;
}

export const ChecklistTableRow = ({
  checklist,
  handleRowClick,
  selectedRowId,
  isMobile,
}: ChecklistTableRowProps) => {
  const createModal = useCreateChecklistModal();
  const editModal = useEditChecklistModal();
  const deleteModal = useDeleteChecklistModal();
  const sendModal = useSendChecklistModal();

  const isSelected = selectedRowId === checklist.id_checklist_vehicle;
  const rowActiveStyle =
    selectedRowId === checklist.id_checklist_vehicle ? styles.collapse : '';

  const navigate = useNavigate();

  const RowIcon = useMemo(
    () => (isSelected ? CaretUp : CaretDown),
    [isSelected],
  );

  const iconSize = useMemo(() => (!isMobile ? 24 : 18), [isMobile]);

  const isDraft = useMemo(() => checklist?.draft, [checklist]);

  const handleViewReport = useCallback(() => {
    if (checklist?.draft) {
      navigate(
        `/checklist-draft?checklistId=${checklist.id_checklist_vehicle}`,
      );
    } else {
      navigate(
        `/checklist-view-report?checklistId=${checklist.id_checklist_vehicle}`,
      );
    }
  }, [checklist?.draft, checklist?.id_checklist_vehicle, navigate]);

  return (
    <>
      <Table.Row
        onClick={() => handleRowClick(checklist)}
        className={`${styles['table-row']} ${
          selectedRowId === checklist.id_checklist_vehicle
            ? styles.selected
            : ''
        }`}
      >
        <Table.Cell className={styles['first-cell']}>
          {!isMobile && <RowIcon size={20} />}
          {String(checklist?.CHECKLIST ?? 0).padStart(3, '0')}
          {isDraft && (
            <div className={`${styles['draft-tag']}`}>
              <FileText size={20} />
              Rascunho
            </div>
          )}
        </Table.Cell>
        {!isMobile && (
          <Table.Cell alignStart>
            {checklist?.name ?? 'não informado'}
          </Table.Cell>
        )}
        <Table.Cell alignStart>
          {checklist?.plate ?? 'não informado'}
        </Table.Cell>
        <Table.Cell>
          {formatDate(dateOffset(new Date(checklist.created_at)), 'dd/MM/yyyy')}
        </Table.Cell>
        {/* <Table.Cell>{checklist.situacao}</Table.Cell> */}
      </Table.Row>
      {isSelected && (
        <Table.Row className={`${styles['row-actions']} ${rowActiveStyle}`}>
          <Table.Cell colSpan={4} className={styles.actions}>
            <div>
              <Button
                variant="icon"
                icon={<Trash size={iconSize} color="#E53835" weight="fill" />}
                className={styles['button-delete']}
                handleClick={() => deleteModal.onOpen()}
              />

              <Button
                className={styles.button}
                handleClick={() => sendModal.onOpen()}
              >
                <PaperPlaneRight size={iconSize} weight="fill" />
                Enviar
              </Button>

              <Button className={styles.button} handleClick={handleViewReport}>
                <Eye size={iconSize} weight="bold" /> Visualizar
              </Button>
              <Button
                className={styles.button}
                handleClick={() => {
                  if (checklist?.draft) createModal.onOpen();
                  else editModal.onOpen();
                }}
              >
                <PencilSimple size={iconSize} weight="fill" /> Editar
              </Button>
            </div>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};
