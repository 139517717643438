import { Trash } from 'phosphor-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import api from '../../../../../services/api/api';
import message from '../../../../../utils/message';
import { GroupedServiceProps } from '../../interfaces';
import { AddServiceNameModal } from '../AddServiceNameModal';
// import { visitorId } from '../../../../../routes/routes';
import { Modal } from '../../../PreventiveMaintenance/components/Modal';
import styles from './edit-service-modal.module.css';

interface EditServiceModalProps {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setModalServices: Dispatch<SetStateAction<GroupedServiceProps>>;
  modalServices: GroupedServiceProps;
  workshopId: string;
  setIsVisitorModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditServiceModal = ({
  modalVisible,
  setModalVisible,
  modalServices,
  setModalServices,
  workshopId,
  setIsVisitorModalOpen,
}: EditServiceModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newServiceId, setNewServiceId] = useState<string>('');
  const [newServiceName, setNewServiceName] = useState<string>('');

  const handleDelete = async (id: string) => {
    // if (userLocal && userLocal.id_user !== visitorId) {
    await api
      .delete(`/service/child/${id}`)
      .then(() => {
        setModalVisible(false);
      })
      .catch(err => message(err, 'error'));
    // } else {
    //   setIsVisitorModalOpen(true);
    // }
  };

  useEffect(() => {
    if (isModalOpen === false && newServiceId.length !== 0) {
      const date = new Date();
      setModalServices({
        ...modalServices,
        services: [
          ...modalServices.services,
          {
            created_at: date,
            id_child_service: newServiceId,
            id_service: '',
            name:
              newServiceName.length > 0
                ? newServiceName
                : modalServices.category,
            updated_at: date,
            value: newServiceName,
          },
        ],
      });
    }
    setNewServiceId('');
    setNewServiceName('');
  }, [isModalOpen]);
  return (
    <>
      <AddServiceNameModal
        modalVisible={isModalOpen}
        setModalVisible={setIsModalOpen}
        workshopId={workshopId}
        modalServices={modalServices}
        setNewServiceId={setNewServiceId}
        newServiceName={newServiceName}
        setNewServiceName={setNewServiceName}
        setIsVisitorModalOpen={setIsVisitorModalOpen}
      />
      {!isModalOpen && (
        <Modal
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          title={`Categoria: ${modalServices.category}`}
        >
          <div className={styles.container}>
            <ul className={styles['services-list']}>
              {modalServices &&
                modalServices.services.map(service => {
                  const { name, id_child_service } = service;
                  return (
                    <li className={styles.item}>
                      <span>{name}</span>
                      <button
                        className={styles.trash}
                        type="button"
                        onClick={() => handleDelete(id_child_service)}
                      >
                        <Trash size="2rem" color="#B4B4B4" weight="fill" />
                      </button>
                    </li>
                  );
                })}
            </ul>
            <button
              type="button"
              className={styles['add-btn']}
              onClick={() => setIsModalOpen(true)}
            >
              Adicionar
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};
