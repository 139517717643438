import { useEffect, useState } from 'react';
import { AnnouncementResponse } from '../../interfaces';
import Logo from '../../../../../assets/icon_autocenter.svg';
import styles from './announcement.banner.module.css';

interface AnnouncementProps {
  announcements: AnnouncementResponse[];

  handleOpenModalTotalAnnouncements: (initialStep: number) => void;
}

const FILE_EXTENSIONS_VIDEO = [
  'mp4',
  'avi',
  'mov',
  'wmv',
  'flv',
  'webm',
  'mkv',
  'ogg',
];

const DEFAULT_IMAGE = Logo;

export const AnnouncementBanner = ({
  announcements,
  handleOpenModalTotalAnnouncements,
}: AnnouncementProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const renderMediaContent = (url: string) => {
    if (!url) {
      return DEFAULT_IMAGE;
    }

    const fileExtension = url.split('.').pop()?.toLowerCase();
    const isVideo =
      fileExtension && FILE_EXTENSIONS_VIDEO.includes(fileExtension);

    return isVideo ? DEFAULT_IMAGE : url;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % announcements.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [announcements.length]);

  const currentAnnouncement = announcements[currentIndex];

  return (
    <button
      type="button"
      className={styles['announcement-banner']}
      onClick={() => handleOpenModalTotalAnnouncements(currentIndex)}
    >
      <img
        src={
          currentAnnouncement.announcement.img_urls.length > 0
            ? renderMediaContent(
                currentAnnouncement.announcement.img_urls[0].img_url,
              )
            : DEFAULT_IMAGE
        }
        alt="Imagem do Anúncio"
      />
      <div className={styles['announcement-info']}>
        <p className={styles.title}>{currentAnnouncement.announcement.title}</p>
        <p className={styles.description}>
          {currentAnnouncement.announcement.subtitle === 'false'
            ? 'A autocenter adicionou uma nova funcionalidade ...'
            : currentAnnouncement.announcement.subtitle}
        </p>
      </div>

      <button type="button" className={styles['announcement-button']}>
        Clique para ver mais
      </button>
    </button>
  );
};
