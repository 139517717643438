import { WarningCircle } from 'phosphor-react';
import { ComponentProps } from 'react';
import styles from './modal-view.module.css';

interface ViewItemProps extends ComponentProps<'div'> {
  title: string;
  subtitle?: string;
  subtitleSize?: 'sm' | 'lg';
  itemClassname?: string;
}

export const ViewItem = ({
  title,
  subtitle,
  subtitleSize = 'sm',
  itemClassname = '',
  ...props
}: ViewItemProps) => {
  return (
    <div className={`${styles['modal-view-item']} ${itemClassname}`} {...props}>
      <span>{title}</span>
      <strong className={styles[subtitleSize]}>{subtitle}</strong>
      {!subtitle && (
        <WarningCircle
          size={22}
          style={{
            color: '#FBC02D',
          }}
        />
      )}
    </div>
  );
};
