import { Vehicle } from '../../../@types/interface';
import api from '../../../services/api/api';
import message from '../../../utils/message';
import {
  PreventiveMaintenanceInput,
  PreventiveMaintenanceType,
  PreventiveMaintenanceVehicle,
  preventiveMaintenanceResponse,
} from './interface';

export const fetchPreventiveMaintenanceByLicensePlate = async (
  licensePlate: string,
) => {
  const { data } = await api.get<PreventiveMaintenanceVehicle>(
    `/preventive/${licensePlate}`,
  );
  return data;
};

export const fetchPreventiveMaintenanceTypes = async () => {
  const { data } = await api.get<PreventiveMaintenanceType[]>(
    '/preventivetype',
  );

  return data;
};

export const createPreventiveMaintenance = async (
  preventiveMaintenance: PreventiveMaintenanceInput,
) => {
  const { data } = await api.post<preventiveMaintenanceResponse>(
    '/preventive',
    {
      ...preventiveMaintenance,
    },
  );
  return data;
};

export const deletePreventiveMaintenance = async (preventive_id: string) => {
  try {
    api.delete(`/preventive/${preventive_id}`);
    return true;
  } catch (error) {
    message(error, 'error');
    return false;
  }
};

export const updateVehicleCurrentKm = async (
  vehicleId: string,
  currentKm: number,
) => {
  const { data } = await api.put<PreventiveMaintenanceVehicle>('/vehicle/km', {
    vehicleId,
    currentKm,
  });
  return data;
};

export const LinkFleetVehicle = async (vehicleId: string) => {
  const { data } = await api.post<Vehicle>(`/vehicle/fleet/${vehicleId}`);
  return data;
};

export const fetchFleetPreventiveMaintenance = async (vehicleId: string) => {
  const { data } = await api.get<PreventiveMaintenanceVehicle>(
    `/preventive/fleet/${vehicleId}`,
  );

  return data;
};

export const findFleetVehicles = async () => {
  const { data } = await api.get<Vehicle[]>('/vehicle/fleet');

  return data;
};

export const fetchFleetMaintenances = async (limit?: number, page?: number) => {
  let url = '/preventive/fleet';
  if (limit && limit) {
    url += `?page=${page}&limit=${limit}`;
  }

  const { data } = await api.get<preventiveMaintenanceResponse[]>(url);

  return data;
};
