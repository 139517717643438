import { CreateChecklistInput } from '../../../@types/checklist';
import {
  createChecklist,
  deleteChecklist,
  editChecklist,
  getChecklistById,
  getChecklistDraftById,
  getChecklists,
  transformDraftChecklist,
} from './model';

export const getChecklistsController = async (query = '') => {
  const data = await getChecklists(query);
  return data;
};

export const getChecklistByIdController = async (id: string) => {
  const data = await getChecklistById(id);
  return data;
};
export const getChecklistDraftByIdController = async (id: string) => {
  const data = await getChecklistDraftById(id);
  return data;
};

export const createChecklistController = async (
  props: CreateChecklistInput,
) => {
  const data = await createChecklist({ ...props });
  return data;
};
export const transformDraftChecklistController = async (id: string) => {
  const data = await transformDraftChecklist(id);
  return data;
};

export const editChecklistController = async (props: CreateChecklistInput) => {
  const data = await editChecklist({ ...props });
  return data;
};

export const deleteChecklistController = async (id: string) => {
  const data = await deleteChecklist(id);
  return data;
};
