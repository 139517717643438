import { useEffect, useState } from 'react';
import { PlusSquare } from '@phosphor-icons/react';
import { TransactionCodeResponse } from '../../../../interfaces';
import { TransactionCodeItem } from '../TransactionCodeItem';
import { Button } from '../../../../../../../shared/Button';
import { Input } from '../../../../../../../shared/Input';

import styles from './transaction-code-list.module.css';

import {
  getTransactionCodesController,
  updateTransactionCodeController,
  deleteTransactionCodeController,
  createTransactionCodeController,
} from '../../../../controller';

export const TransactionCodeList = () => {
  const [transactionCodes, setTransactionCodes] = useState<
    TransactionCodeResponse[]
  >([]);
  const [isCreatingTransactionCode, setIsCreatingTransactionCode] =
    useState(false);
  const [newTransactionCodeName, setNewTransactionCodeName] = useState('');

  const fetchTransactionCodes = async () => {
    const res = await getTransactionCodesController();

    if (res) {
      setTransactionCodes(res);
    }
  };

  useEffect(() => {
    fetchTransactionCodes();
  }, []);

  const handleCreateTransactionCode = async (newName: string) => {
    const res = await createTransactionCodeController({
      name: newName,
    });
    if (res) {
      // updating list after successful request
      setTransactionCodes(previous => [...previous, res]);
    }
  };

  const handleEditTransactionCode = async (
    transactionCodeId: string,
    newName: string,
  ) => {
    const res = await updateTransactionCodeController({
      name: newName,
      transaction_code_id: transactionCodeId,
    });
    if (res) {
      // updating list after successful request
      setTransactionCodes(previous =>
        previous.map(transactionCode => {
          if (transactionCode.id_transaction_code === res.id_transaction_code) {
            return res;
          }
          return transactionCode;
        }),
      );
    }
  };
  const handleRemoveTransactionCode = async (transactionCodeId: string) => {
    const res = await deleteTransactionCodeController(transactionCodeId);
    if (res) {
      // updating list after successful request
      setTransactionCodes(previous =>
        previous.filter(
          transactionCode =>
            transactionCode.id_transaction_code !== transactionCodeId,
        ),
      );
    }
  };

  return (
    <div className={styles.container}>
      <h1>Meus Planos de Contas</h1>
      <ul className={styles['transaction-code-list']}>
        {transactionCodes.map(transactionCode => (
          <TransactionCodeItem
            key={transactionCode.id_transaction_code}
            transactionCode={transactionCode}
            onEdit={(newName: string) =>
              handleEditTransactionCode(
                transactionCode.id_transaction_code,
                newName,
              )
            }
            onRemove={() =>
              handleRemoveTransactionCode(transactionCode.id_transaction_code)
            }
          />
        ))}
      </ul>
      {isCreatingTransactionCode && (
        <div className={styles['new-transaction-code-wrapper']}>
          <Input
            name="newTransactionCodeName"
            label="Novo Plano de Contas"
            value={newTransactionCodeName}
            onChange={e => setNewTransactionCodeName(e.target.value)}
          />
          <Button
            style={{
              marginLeft: 'auto',
            }}
            handleClick={() => {
              setIsCreatingTransactionCode(false);
              setNewTransactionCodeName('');
            }}
            variant="cancel"
          >
            cancelar
          </Button>
        </div>
      )}
      <Button
        variant="register"
        handleClick={() => {
          if (isCreatingTransactionCode) {
            handleCreateTransactionCode(newTransactionCodeName);
            setIsCreatingTransactionCode(false);
            setNewTransactionCodeName('');
          } else {
            setIsCreatingTransactionCode(true);
          }
        }}
      >
        {isCreatingTransactionCode ? (
          <>
            <PlusSquare size={32} />
            Salvar
          </>
        ) : (
          <>
            <PlusSquare size={32} />
            Novo Plano
          </>
        )}
      </Button>
    </div>
  );
};
