export default function formatPrice(value: number): string {
  const formatValue = value.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });

  return formatValue;
}

export const formatNumber = (number: number) => {
  if (typeof number !== 'number') {
    throw new Error('Input must be a number');
  }

  const formatted = number.toFixed(2);

  const [integerPart, decimalPart] = formatted.split('.');

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    '.',
  );

  return `${formattedIntegerPart},${decimalPart}`;
};
