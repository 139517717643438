import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import styles from './input-component.module.css';
import { LoadingSpinner } from '../LoadingSpinner';

type InputStates = 'default' | 'error' | 'success' | 'selected' | 'disabled';

interface InputComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  hint?: string;
  icon?: ReactNode;
  hasError?: boolean;
  className?: string;
  width?: string;
  isLoading?: boolean;
  state?: InputStates;
  required?: boolean;
}

export const InputComponent = forwardRef<HTMLInputElement, InputComponentProps>(
  (
    {
      hint,
      label,
      placeholder,
      icon,
      className,
      width = '',
      hasError = false,
      autoComplete = 'off',
      isLoading = false,
      state = 'default',
      required = false,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={`${styles['input-container']} ${
          hasError ? styles['has-error'] : ''
        } ${className} ${styles[state]}`}
        style={{ width }}
      >
        {label && (
          <label htmlFor="input" className={styles['input-label']}>
            <span>
              {label}
              {required && <b>*</b>}
            </span>
            {isLoading && <LoadingSpinner size={16} />}
          </label>
        )}
        <div className={styles['input-wrapper']}>
          <input
            ref={ref}
            id={props.id}
            type="text"
            placeholder={placeholder}
            className={styles.input}
            autoComplete={autoComplete}
            {...props}
          />
          {icon && <span className={styles['input-icon']}>{icon}</span>}
        </div>
        {hint && <p className={styles['input-hint']}>{hint}</p>}
      </div>
    );
  },
);
