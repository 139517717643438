import { useState, useEffect, useRef } from 'react';

export const useDynamicHeight = (offset = 20) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>('60vh');

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const availableHeight = window.innerHeight - containerTop - offset;
        setHeight(`${availableHeight}px`);
      }
    };

    updateHeight(); // Ajusta na montagem inicial

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [offset]);

  return { ref: containerRef, height };
};
