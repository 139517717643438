/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';
import { Eye, EyeSlash } from 'phosphor-react';
import { Controller, useForm } from 'react-hook-form';
import { Store } from 'react-notifications-component';
import styles from './settings.module.css';
import { putSaveRememberFinance, putSaveNewPassword } from '../../controller';

import { TransactionCodeList } from './components/TransactionCodeList';
import { useAuth } from '../../../../../hooks/useAuth';
import { getWorkshopData } from '../../../../../utils/workshopData';

interface FormValues {
  currentPassword: string;
  newPassword: string;
}

export const Settings = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isCheckboxConnected, setIsCheckboxConnected] = useState(false);
  const [loadingOption, setLoadingOption] = useState<string | null>(null);
  const { setWorkshop } = useAuth();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
  });

  // Busca dados da oficina
  useEffect(() => {
    const fetchWorkshopData = async () => {
      try {
        const data = await getWorkshopData();
        setIsCheckboxConnected(!!data?.remember_me);
      } catch {
        setIsCheckboxConnected(false);
      }
    };
    fetchWorkshopData();
  }, []);

  // Função de submissão da nova senha
  const handleSubmitPassword = useCallback(async (data: FormValues) => {
    await putSaveNewPassword(data.currentPassword, data.newPassword);
  }, []);

  // Função unificada para o checkbox
  const handleCheckboxChange = useCallback(
    async (rememberMe: boolean, option: 'remember' | 'dont_remember') => {
      setLoadingOption(option);
      showLoadingNotification();
      try {
        const res = await putSaveRememberFinance(rememberMe);
        if (res) {
          setWorkshop(prev =>
            prev ? { ...prev, remember_me: !!res.data.remember_me } : null,
          );
          setIsCheckboxConnected(rememberMe);
        }
      } catch (error) {
        console.error('erro ao salvar opção: ', error);
      } finally {
        setLoadingOption(null);
        removeLoadingNotification();
      }
    },
    [setWorkshop],
  );

  return (
    <div className={styles.container}>
      <div className={styles['filter-wrapper']}>
        <div className={styles['release-list--header']}>
          <h3>Mudança de senha</h3>

          <Controller
            control={control}
            name="currentPassword"
            render={({ field }) => (
              <div className={styles['input-control']}>
                <label htmlFor={field.name}>Senha atual: </label>
                <div className={styles['input-icon-control']}>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    // placeholder="Senha atual"
                    className={styles.date}
                    {...field}
                  />
                  <button
                    type="button"
                    className={styles.icon}
                    onClick={() =>
                      setPasswordVisible(currentValue => !currentValue)
                    }
                  >
                    {passwordVisible ? (
                      <EyeSlash size={28} color="#3E3E3E" />
                    ) : (
                      <Eye size={28} color="#3E3E3E" />
                    )}
                  </button>
                </div>
              </div>
            )}
          />

          <Controller
            control={control}
            name="newPassword"
            render={({ field }) => (
              <div className={styles['input-control']}>
                <label htmlFor={field.name}>Nova senha: </label>

                <input
                  type={passwordVisible ? 'text' : 'password'}
                  // placeholder="Nova senha"
                  className={styles.date}
                  {...field}
                />
              </div>
            )}
          />

          <button
            type="submit"
            className={styles['green-button']}
            onClick={handleSubmit(handleSubmitPassword)}
          >
            Salvar
          </button>
        </div>

        <div className={styles['release-list--header']}>
          <h3>Acesso</h3>
          <div>
            <label className={styles['container-input']}>
              Matenha-me conectado
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(true, 'remember')}
              />
              <span
                className={
                  isCheckboxConnected
                    ? `${styles.checkmark} ${styles.checked}`
                    : styles.checked
                }
                style={{
                  backgroundColor: isCheckboxConnected ? '#6bb56a' : '#cccc',
                }}
              />
            </label>
          </div>
          <div className={styles.wrap}>
            <label className={styles['container-input']}>
              Sempre solicitar a senha de acesso
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(false, 'dont_remember')}
              />
              <span
                className={
                  !isCheckboxConnected
                    ? `${styles.checkmark} ${styles.checked}`
                    : styles.checked
                }
                style={{
                  backgroundColor: !isCheckboxConnected ? '#6bb56a' : '#cccc',
                }}
              />
            </label>
          </div>
        </div>
      </div>
      <TransactionCodeList />
    </div>
  );
};

const showLoadingNotification = () => {
  Store.addNotification({
    title: 'Carregando...',
    message: 'Aguarde enquanto processamos sua solicitação.',
    type: 'success',
    container: 'bottom-left',
    insert: 'bottom',
    animationIn: ['animate__animated', 'animate__slideInLeft'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 0, // A notificação fica fixa até ser removida manualmente
      onScreen: true,
      pauseOnHover: true,
    },
    id: 'loading-notification', // ID para referência, útil para remoção
  });
};

const removeLoadingNotification = () => {
  Store.removeNotification('loading-notification');
};
