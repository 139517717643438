import { PlusSquare } from '@phosphor-icons/react';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import styles from './new-preventive-modal.module.css';
import { Modal } from '../../../../../shared/Modal';
import { Button } from '../../../../../shared/Button';
import { Input } from '../../../../../shared/Input';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleCreatePreventive: (
    name: string,
    option: 'km' | 'date',
    kmToActive?: number,
    trigger_date?: string,
  ) => void;
}

interface valuesForm {
  name: string;
  selectedOption: 'km' | 'date';
  kmToActive?: string;
  date?: string;
}

export const NewPreventiveModal = ({
  isOpen,
  onClose,
  handleCreatePreventive,
}: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<valuesForm>({
    defaultValues: {
      name: '',
      selectedOption: 'km',
      kmToActive: '',
      date: '',
    },
  });

  const selectedOption = watch('selectedOption');

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (data: React.ChangeEvent<HTMLInputElement>) => {
    const value = data.target.value.replace(/\D/g, '');
    setValue('kmToActive', value);
  };

  const onSubmit = async (data: valuesForm) => {
    setIsLoading(true);
    await handleCreatePreventive(
      data.name,
      data.selectedOption,
      parseFloat(data.kmToActive ?? '0'),
      data.date,
    );
    setIsLoading(false);
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      title="Adicionar Manutenção"
    >
      <div className={styles.container}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <Input {...field} label="Nome:" />}
        />

        <Controller
          name="selectedOption"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="select"
              options={[
                {
                  name: 'KM',
                  value: 'km',
                },
                {
                  name: 'Data',
                  value: 'date',
                },
              ]}
              label="Tipo de Agendamento"
            />
          )}
        />
        {selectedOption === 'km' ? (
          <Controller
            name="kmToActive"
            control={control}
            rules={{
              required: 'Informe a quilometragem',
              validate: value =>
                (value && value.length >= 3) || 'km mínimo: 100',
            }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  label="Quilometragem"
                  onChange={handleInputChange}
                  style={{ borderColor: errors.kmToActive ? 'red' : '' }}
                />
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {errors.kmToActive && errors.kmToActive.message}
                </span>
              </>
            )}
          />
        ) : (
          <Controller
            name="date"
            control={control}
            rules={{ required: 'Informe a data' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="date"
                  label="Data"
                  style={{ borderColor: errors.date ? 'red' : '' }}
                />
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {errors.date && errors.date.message}
                </span>
              </>
            )}
          />
        )}

        <Button
          handleClick={handleSubmit(onSubmit)}
          variant="register"
          icon={<PlusSquare size={24} />}
          style={{
            fontWeight: 400,
            fontSize: '1.5rem',
          }}
          disabled={isLoading}
        >
          Salvar
        </Button>
      </div>
    </Modal>
  );
};
