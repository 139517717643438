import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  Car,
  CaretLeft,
  CaretRight,
  CheckSquareOffset,
  FileText,
  User,
} from 'phosphor-react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLayout } from '../../../../shared/PageLayout';
import { Steps } from '../components/Steps';
import styles from './create-order-service-form.module.css';
import { VehicleForm } from './steps/vehicleForm';
import { CustomerForm } from './steps/customerForm';
import { ServicesForm } from './steps/servicesForm';
import { ChecklistForm } from './steps/checklistForm';
import { OrderServiceFormDTO, ServiceDTO } from './OrderServiceDTO';
import { Checklist } from '../../../../@types/checklist';
import { validationSchemas } from './validationSchemas';
import { defaultOS } from './createOSMockup';
import { parseOSForm } from './parseOrderServiceForm';
import {
  createRepairDraftController,
  getLatestChecklistController,
  getRepairController,
} from '../controller';
import { maskCpfCnpj, maskPhone } from '../../../../services/helpers/mask';
import { FeedbackModal } from '../../../../shared/FeedbackModal';

export type OSstep = 'vehicle' | 'customer' | 'service' | 'checklist';

export const CreateOrderServiceForm = () => {
  const [selectedStep, setSelectedStep] = useState<OSstep>('vehicle');
  const [latestChecklist, setLatestChecklist] = useState<Checklist | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const steps = useMemo(
    () => [
      {
        label: 'Dados do veículo',
        icon: (
          <Car
            size={24}
            weight={selectedStep === 'vehicle' ? 'fill' : 'bold'}
          />
        ),
        value: 'vehicle' as OSstep,
        warning: false,
      },
      {
        label: 'Dados do cliente',
        icon: (
          <User
            size={24}
            weight={selectedStep === 'customer' ? 'fill' : 'bold'}
          />
        ),
        value: 'customer' as OSstep,
        warning: false,
      },
      {
        label: 'Checklist',
        icon: (
          <CheckSquareOffset
            size={24}
            weight={selectedStep === 'customer' ? 'fill' : 'bold'}
          />
        ),
        value: 'checklist' as OSstep,
        warning: false,
      },
      {
        label: 'Dados do serviço',
        icon: (
          <FileText
            size={24}
            weight={selectedStep === 'service' ? 'fill' : 'bold'}
          />
        ),
        value: 'service' as OSstep,
        warning: false,
      },
    ],
    [selectedStep],
  );

  const methods = useForm<OrderServiceFormDTO>({
    defaultValues: defaultOS,
    resolver: useMemo(
      () => yupResolver(validationSchemas[selectedStep]),
      [selectedStep],
    ),
    mode: 'onChange',
  });

  const handleChangeStep = async (direction: 'previous' | 'next') => {
    const currentIndex = steps.findIndex(step => step.value === selectedStep);

    if (!(await methods.trigger()) && direction === 'next') {
      return;
    }

    if (direction === 'next' && currentIndex < steps.length - 1) {
      setSelectedStep(steps[currentIndex + 1].value);
    } else if (direction === 'previous' && currentIndex > 0) {
      setSelectedStep(steps[currentIndex - 1].value);
    }
  };

  // monitoramento dos dados do formulário
  const formValues = useWatch({
    control: methods.control,
    defaultValue: defaultOS,
  });

  //  Armazena os valores anteriores sem renderizar mais de uma vez
  const previousFormValuesRef = useRef<OrderServiceFormDTO>(defaultOS);

  // Atualiza o formulário somente quando houver mudanças
  const updateForm = useCallback(async () => {
    const isDraft = methods.getValues('isDraft');
    if (!isEqual(formValues, previousFormValuesRef.current) && isDraft) {
      previousFormValuesRef.current = {
        ...(formValues as OrderServiceFormDTO),
      };

      // salva apenas se for da página de cliente a diante
      if (selectedStep !== 'vehicle') {
        try {
          const parsedForm = parseOSForm(formValues as OrderServiceFormDTO);
          const res = await createRepairDraftController(parsedForm);

          if (res) {
            methods.setValue('id_repair', res.repair?.id_repair ?? '');
          }
        } catch (err) {
          console.error('Erro ao atualizar formulário:', err);
        }
        // console.log(formValues);
      }
    }
  }, [formValues, methods, selectedStep]);

  // faz a busca por checklist depois que a placa é informada
  const getLatestChecklist = useCallback(async (plate: string) => {
    const res = await getLatestChecklistController(plate);
    if (res) {
      setLatestChecklist(res);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateForm();
    }, 5000);

    return () => clearInterval(interval);
  }, [updateForm]);

  const CurrentForm = useMemo(() => {
    if (selectedStep === 'vehicle')
      return <VehicleForm handlePlate={getLatestChecklist} />;
    if (selectedStep === 'customer') return <CustomerForm />;
    if (selectedStep === 'checklist')
      return <ChecklistForm latestChecklist={latestChecklist} />;
    return <ServicesForm />;
  }, [getLatestChecklist, latestChecklist, selectedStep]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const repairId = queryParams.get('repairId');

  const fetchRepairById = useCallback(async () => {
    setIsLoading(true);
    try {
      if (repairId) {
        const res = await getRepairController(repairId);
        if (res) {
          const vehicle = res?.vehicle;
          const user = res?.user_workshop;
          const address = res?.address;
          methods.setValue('vehicle', {
            description: res?.description ?? '',
            km: `${vehicle?.current_km ?? 0}`,
            lastKm: `${vehicle?.current_km ?? 0}`,
            name: vehicle?.name ?? '',
            noVehicle: res?.no_vehicle ?? false,
            plate: vehicle?.license_plate ?? '',
            vehicleId: res?.vehicle_id ?? '',
            year: vehicle?.year ?? '',
          });

          methods.setValue('customer', {
            userId: user?.id_user ?? '',
            document: maskCpfCnpj(res?.document ?? ''),
            changeVehicleOwner: false,
            email: res?.email ?? '',
            name: res?.name ?? '',
            noClient: res?.no_client ?? false,
            phone: maskPhone(res?.phone ?? ''),
            address: {
              city: address?.city ?? '',
              district: address?.district ?? '',
              number: address?.number ?? '',
              street: address?.street ?? '',
              uf: address?.uf ?? '',
              zip: address?.zip ?? '',
              additional: '',
            },
          });

          const serviceTable: ServiceDTO[] = [];
          const productTable: ServiceDTO[] = [];

          (res?.child_services ?? []).forEach(childService => {
            if (childService.type === 'Produto' || childService.type === null) {
              productTable.push({
                name: childService?.name ?? '',
                quantity: `${childService?.quantity ?? 0}`,
                unitValue: `${
                  (childService?.total ?? 0) / (childService?.quantity ?? 0)
                }`,
              });
            } else if (childService.type === 'Serviço') {
              serviceTable.push({
                name: childService?.name ?? '',
                quantity: `${childService?.quantity ?? 0}`,
                unitValue: `${
                  (childService?.total ?? 0) / (childService?.quantity ?? 0)
                }`,
              });
            }
          });

          methods.setValue('services', {
            comment: res?.comment ?? '',
            discount: `${res?.discount ?? 0}`,
            images: [],
            productTable,
            serviceTable,
          });

          methods.setValue(
            'checklist.id_checklist_vehicle',
            res?.checklist_id ?? '',
          );
          methods.setValue('id_repair', res?.id_repair ?? '');

          if (vehicle?.license_plate) {
            await getLatestChecklist(vehicle.license_plate);
          }
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [getLatestChecklist, methods, repairId]);

  useEffect(() => {
    fetchRepairById();
  }, [fetchRepairById]);

  const navigate = useNavigate();

  return (
    <FormProvider {...methods}>
      <FeedbackModal isOpen={isLoading} variant="sending" />

      <div className={styles.container}>
        <PageLayout.Root
          title="NOVA ORDEM DE SERVIÇO"
          handleNavigation={() => navigate(-1)}
        />
        <div className={styles.content}>
          <button
            type="button"
            className={styles['change-step-btn']}
            style={{
              opacity: selectedStep === 'vehicle' ? 0 : 1,
            }}
            onClick={() => handleChangeStep('previous')}
          >
            <CaretLeft size={24} weight="bold" color="var(--white)" />
          </button>
          <section className={styles['form-section']}>
            <Steps
              onClick={async (step: OSstep) => {
                if (await methods.trigger()) {
                  setSelectedStep(step);
                }
              }}
              selectedStep={selectedStep}
              steps={steps}
              ordered
            />

            {CurrentForm}
            <div className={styles['mobile-actions-container']}>
              <button
                type="button"
                className={styles['primary-btn']}
                onClick={() => handleChangeStep('previous')}
                style={{
                  display: selectedStep === 'vehicle' ? 'none' : 'flex',
                }}
              >
                <ArrowCircleLeft size={24} />
                Voltar
              </button>
              <button
                type="button"
                className={styles['second-btn']}
                onClick={() => handleChangeStep('next')}
                style={{
                  display: selectedStep === 'service' ? 'none' : 'flex',
                }}
              >
                Próximo
                <ArrowCircleRight size={24} />
              </button>
            </div>
          </section>
          <button
            type="button"
            className={styles['change-step-btn']}
            onClick={() => handleChangeStep('next')}
            style={{
              opacity: selectedStep === 'service' ? 0 : 1,
            }}
          >
            <CaretRight size={24} weight="bold" color="var(--white)" />
          </button>
        </div>
      </div>
    </FormProvider>
  );
};
