import { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import { BudgetProps } from '../../../../../features/budget/interfaces';
import { sendWhatsappMessage } from '../../../../../utils/sendWhatsappMessage';
import { numberOnly } from '../../../../../services/helpers/mask';
import { setBudget } from '../../../../../features/budget/budget-slice';
import { useAuth } from '../../../../../hooks/useAuth';
import { OrderServiceFormDTO } from '../../../OrderService/CreateOrderServiceForm/OrderServiceDTO';
import { defaultOS } from '../../../OrderService/CreateOrderServiceForm/createOSMockup';
import { parseOSForm } from '../../../OrderService/CreateOrderServiceForm/parseOrderServiceForm';
import { createRepairDraftController } from '../../../OrderService/controller';

interface Props {
  dispatch: Dispatch<AnyAction>;
  navigate: NavigateFunction;
}

export const useBudgetActions = ({ dispatch, navigate }: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [budgetId, setBudgetId] = useState('');

  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const { workshop } = useAuth();

  const handleRowPress = (index: number) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleWhatsappMessage = (budget: BudgetProps) => {
    // if (userLocal && userLocal.id_user !== visitorId) {
    const budgetUserName = budget.user ? budget.user.name : budget.name;

    const linkText = `Olá, ${encodeURIComponent(
      budgetUserName,
    )}! Segue o orçamento da *${encodeURIComponent(
      workshop?.fantasy_name ?? '',
    )}*%0A%0Ahttps://oficinas.autocenterapp.com/budget-report?budgetId=${
      budget.id_budget
    }%0A%0A🛞Veículo: ${
      encodeURIComponent(budget.vehicle ? budget.vehicle : 'não informado') ||
      'não informado'
    } %0A🚘Placa: ${encodeURIComponent(
      budget.plate,
    )}%0A%0A%0ABaixe nosso app Autocenter!%0A%0Ahttps://onelink.to/629pmh
    %0A%0A%0ACaso o link não esteja clicável, responda essa mensagem, ou, copie o link e insira em um navegador de sua preferência para acessar o conteúdo.
    `;

    sendWhatsappMessage(
      linkText,
      budget.user ? budget.user.phone : `+55${numberOnly(budget.phone)}`,
    );
    // }
  };

  const handleDeleteBudget = (budget: BudgetProps) => {
    // if (userLocal && userLocal.id_user !== visitorId) {
    if (budget.id_budget) setBudgetId(budget.id_budget);
    setIsDeleteModalOpen(true);
    // }
  };
  const handleEdit = (budget: BudgetProps) => {
    dispatch(
      setBudget({
        ...budget,
        name: budget.user ? budget.user.name : budget.name,
        document: budget.user ? budget.user.document : budget.document,
        phone: budget.user ? budget.user.phone : budget.phone,
        status: /^[0-9]+$/.test(budget.status) ? budget.status : '',
        user: {
          document: budget.user ? budget.user.document : budget.document,
          name: budget.user ? budget.user.name : budget.name,
          phone: budget.user ? budget.user.phone : budget.phone,
        },
        checklist_id: budget.checklist_id || '',
        chassi: budget.chassi || '',
        checklist: budget.checklist || null,
      }),
    );
    navigate('/create-budget');
  };

  const handleCreateOS = async (budget: BudgetProps) => {
    const newOSObject: OrderServiceFormDTO = {
      ...defaultOS,
      customer: {
        ...defaultOS.customer,
        document: budget?.document ?? '',
        name: budget?.name ?? '',
        phone: budget?.phone ?? '',
      },
      services: {
        ...defaultOS.services,
        comment: budget?.comment ?? '',
        discount: `${budget?.discount ?? 0}`,
        productTable: budget.child_services.map(item => ({
          name: item.name,
          quantity: `${item.quantity}`,
          unitValue: `${item.total / item.quantity}`,
        })),
        images:
          budget?.checklist?.banners?.map(banner => ({
            category: '',
            img_url: banner.img_url ?? '',
          })) ?? [],
      },
      vehicle: {
        ...defaultOS.vehicle,
        plate: budget.plate,
      },
    };

    const parsedForm = parseOSForm(newOSObject);
    const res = await createRepairDraftController(parsedForm);

    if (res) {
      navigate(`/new-order-service-form?repairId=${res.repair.id_repair}`);
    }
  };

  return {
    handleCreateOS,
    handleEdit,
    handleDeleteBudget,
    handleWhatsappMessage,
    handleRowPress,
    budgetId,
    setBudgetId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    selectedRow,
  };
};
