import { useState } from 'react';
import { ModalContent, ModalTitle } from '../Modal';
import styles from '../../pages/Transactions/transactions.module.css';
import { CreateReceiveForm } from '../../pages/Transactions/components/Forms';
import { CreateTransactionMessageModal } from '../CreateTransactionMessageModal';
import { TransactionResponse } from '../../interfaces';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (transaction: TransactionResponse) => void;
  type: 'expense' | 'income';
}

export const CreateTransactionModal = ({
  isOpen,
  onClose,
  type,
  onCreate,
}: Props) => {
  const typeStyle = type === 'income' ? styles.income : styles.expense;
  const title =
    type === 'expense' ? 'Criar conta a pagar' : 'Criar conta a receber';

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleClose = () => {
    setShowSuccessModal(false);
    onClose();
  };

  return (
    <ModalContent
      open={isOpen}
      onClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      {showSuccessModal ? (
        <CreateTransactionMessageModal
          message="Sucesso!"
          onClose={() => {
            setShowSuccessModal(false);
          }}
        />
      ) : (
        <>
          <ModalTitle titleClassName={`${styles['modal-title']} ${typeStyle}`}>
            {title}
          </ModalTitle>
          <CreateReceiveForm
            onClose={() => setShowSuccessModal(true)}
            onCancel={onClose}
            type={type}
            onCreate={onCreate}
          />
        </>
      )}
    </ModalContent>
  );
};
