import { Dispatch, SetStateAction } from 'react';
import styles from './vehicle-form.module.css';

import Mileage from '../../../../../assets/icons/Mileage.svg';
import { numberWithCommas } from '../../../../../services/helpers/mask';

interface Props {
  handleUpdateKm: () => void;
  currentKm: number;
  setCurrentKm: Dispatch<SetStateAction<number>>;
  averageKm: number;
  setAverageKm: Dispatch<SetStateAction<number>>;
  isDisabled: boolean;
}

export const VehicleForm = ({
  handleUpdateKm,
  currentKm,
  setCurrentKm,
  averageKm,
  setAverageKm,
  isDisabled,
}: Props) => {
  return (
    <div className={styles['vehicle-form']}>
      <img src={Mileage} alt="KM" />
      <h2>DADOS DE KM</h2>
      <form>
        <div className={styles['input-control']}>
          <span>Km Atual </span>
          <input
            type="text"
            value={numberWithCommas(`${currentKm || ''}`)}
            onChange={e =>
              setCurrentKm(
                parseInt(e.target.value.replace(/[^\d]/g, '') || '0', 10),
              )
            }
            onBlur={e => {
              setCurrentKm(
                parseInt(e.target.value.replace(/[^\d]/g, '') || '0', 10),
              );
              handleUpdateKm();
            }}
          />
        </div>
        <div className={styles['input-control']}>
          <span>Km/dia </span>
          <input
            type="text"
            value={numberWithCommas(`${averageKm || ''}`)}
            onChange={e =>
              setAverageKm(
                parseInt(e.target.value.replace(/[^\d]/g, '') || '0', 10),
              )
            }
            disabled={isDisabled}
          />
        </div>
      </form>
    </div>
  );
};
