import { useState } from 'react';
import styles from './preventive-maintenances.module.css';

import { HeaderComponent } from '../../../shared/HeaderComponent';

import { Aside } from './components/Aside';
import { PreventiveMaintenance } from './components/PreventiveMaintenance';
import { MaintenancesList } from './components/MaintenaceList';

export const PreventiveMaintenances = () => {
  const [openSideBar, setOpenSideBar] = useState(false);

  const [selectedOption, setSelectedOption] = useState<
    'maintenances' | 'vehicles-list'
  >('maintenances');

  return (
    <div className={styles.container}>
      <HeaderComponent
        handleMenuClick={() => setOpenSideBar(prev => !prev)}
        title="Manutenção preventiva"
      />
      <div className={styles.content}>
        <Aside
          isOpen={openSideBar}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        {selectedOption === 'maintenances' ? (
          <PreventiveMaintenance />
        ) : (
          <MaintenancesList />
        )}
      </div>
    </div>
  );
};
