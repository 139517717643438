import { ClockClockwise, Eye, PencilSimple, Trash } from 'phosphor-react';
import { Fragment, useMemo } from 'react';
import { Table } from '../../../../components/Table';
import { FormatPrice, setSituation } from '../../utils';

import styles from './transactions-table.module.css';
import { TransactionResponse } from '../../../../interfaces';
import {
  dateOffset,
  formatDate,
  formatDateString,
} from '../../../../../../../utils/dateFunctions';
import { ModalContent } from '../../../../components/Modal/ModalRoot';
import { TypeIcon } from '../TypeIcon';
import { DeleteTransactionForm, ReverseValueForm } from '../Forms';
import { ModalTitle } from '../../../../components/Modal';
import { useModalItem } from '../../hooks/useModalItem';
import { EditTransactionForm } from '../Forms';
import { ModalView } from '../ModalView';

interface TransactionItemProps {
  transaction: TransactionResponse;
  rowActive: string;
  onActive: () => void;
  handleUpdated: (data: TransactionResponse) => void;
  handleDelete: (id: string) => void;
}

export const TransactionItem = ({
  transaction,
  rowActive,
  onActive,
  handleUpdated,
  handleDelete,
}: TransactionItemProps) => {
  const { openModal, handleCloseModal, handleOpenModal } = useModalItem();

  // ------- FORMATS
  const valueFormat = useMemo(
    () => FormatPrice(transaction.value),
    [transaction.value],
  );
  const situation = setSituation(transaction);
  const typeStyle = useMemo(
    () => (transaction.type === 'income' ? styles.income : styles.expense),
    [transaction.type],
  );
  const rowActiveStyle = useMemo(
    () => (rowActive === transaction.id_transaction ? styles.collapse : ''),
    [rowActive, transaction.id_transaction],
  );
  // ------------------ END FORMATS

  const showTransactionCode = useMemo(
    () => transaction.type === 'expense',
    [transaction.type],
  );

  const transactionCodeEmpty = useMemo(
    () => transaction.transaction_code.code === '000',
    [transaction.transaction_code.code],
  );

  const transactionCode = useMemo(() => {
    if (transactionCodeEmpty) {
      return 'Não informado';
    }
    return `${transaction.transaction_code.code}`;
  }, [transaction.transaction_code.code, transactionCodeEmpty]);

  const transactionCodeName = useMemo(() => {
    const codeName = transaction?.transaction_code?.name;
    if (!codeName) return '';
    return codeName.length > 15 ? `${codeName.slice(0, 15)}...` : codeName;
  }, [transaction?.transaction_code?.name]);

  return (
    <Fragment key={transaction.id_transaction}>
      <Table.Row
        className={`${styles['table-row-item']} ${typeStyle} ${rowActiveStyle}`}
        onClick={() => onActive()}
      >
        <Table.Cell width={40}>
          <TypeIcon type={transaction.type} />
        </Table.Cell>

        <Table.Cell alignStart>
          {transaction.description ||
            transaction.name ||
            transactionCodeName ||
            'Sem Descrição'}
        </Table.Cell>
        <Table.Cell width={140} datatype="Lançamento">
          {formatDate(dateOffset(new Date(transaction.created_at)))}
        </Table.Cell>
        <Table.Cell width={140} datatype="Vencimento">
          {formatDateString(transaction.due_date)}
        </Table.Cell>
        <Table.Cell width={140} datatype="Baixa">
          {transaction.discharge_date
            ? formatDateString(transaction.discharge_date)
            : '---'}
        </Table.Cell>
        <Table.Cell
          width={120}
          datatype="situação"
          className={styles['column-situation']}
          style={{ color: situation.color }}
        >
          {situation.value}
        </Table.Cell>
        <Table.Cell
          width={180}
          className={`${styles['column-price']} ${typeStyle} `}
        >
          {showTransactionCode ? (
            <div className={styles.info}>
              {valueFormat}
              <span
                className={`${styles['transaction-code']} ${styles.mobile}`}
              >
                {transactionCode} - {transactionCodeName}
              </span>
            </div>
          ) : (
            `${valueFormat}`
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row
        className={`${styles['row-actions']} ${rowActiveStyle} ${typeStyle}`}
      >
        <Table.Cell colSpan={7} className={styles.actions}>
          <div>
            <button
              type="button"
              className={`${styles.button} ${styles.status}`}
              onClick={() => handleOpenModal('reverse')}
            >
              <ClockClockwise size={24} weight="bold" />
              <span>{transaction.finished ? 'Estornar' : 'Dar baixa'}</span>
            </button>
            <button
              type="button"
              className={styles.button}
              onClick={() => handleOpenModal('edit')}
            >
              <PencilSimple size={24} weight="bold" />
              <span>Editar</span>
            </button>
            <button
              type="button"
              className={styles.button}
              onClick={() => handleOpenModal('view')}
            >
              <Eye size={24} weight="bold" />
              <span>Visualizar</span>
            </button>
            <button
              type="button"
              className={styles.button}
              style={{
                background: 'transparent',
                border: '1px solid #E53835',
                color: '#E53835',
              }}
              onClick={() => handleOpenModal('delete')}
            >
              <Trash size={24} weight="fill" />
              <span>Excluir</span>
            </button>
          </div>
        </Table.Cell>
      </Table.Row>

      {/* MODAL PARA ESTONAR OU DAR BAIXA EM UMA CONTA */}
      <ModalContent
        open={openModal.reverse}
        onClose={() => handleCloseModal('reverse')}
      >
        <ModalTitle titleClassName={`${styles['modal-title']} ${typeStyle}`}>
          {transaction.finished ? ' Estornar Valor' : 'dar baixa'}
        </ModalTitle>
        {transaction.finished && <p>Deseja estonar essa transação?</p>}
        <ReverseValueForm
          onClose={() => handleCloseModal('reverse')}
          transaction={transaction}
          handleUpdate={handleUpdated}
        />
      </ModalContent>

      {/* MODAL PARA EDITAR UMA TRANSAÇÃO */}
      <ModalContent
        open={openModal.edit}
        onClose={() => handleCloseModal('edit')}
      >
        <ModalTitle titleClassName={`${styles['modal-title']} ${typeStyle}`}>
          {transaction.type === 'expense'
            ? 'Editar conta a pagar'
            : 'Editar conta a receber'}
        </ModalTitle>
        <EditTransactionForm
          onClose={() => handleCloseModal('edit')}
          transaction={transaction}
          handleUpdate={handleUpdated}
        />
      </ModalContent>
      {/* MODAL VISUALIZAÇÃO */}
      <ModalContent
        open={openModal.view}
        onClose={() => handleCloseModal('view')}
      >
        <ModalTitle titleClassName={`${styles['modal-title']} ${typeStyle}`}>
          Conta {situation.value}
        </ModalTitle>
        <ModalView
          transaction={transaction}
          onClose={() => handleCloseModal('view')}
        />
      </ModalContent>
      {/* MODAL DELETE */}
      <ModalContent
        open={openModal.delete}
        onClose={() => handleCloseModal('delete')}
      >
        <ModalTitle titleClassName={`${styles['modal-title']}`}>
          Deletar conta?
        </ModalTitle>
        <DeleteTransactionForm
          transactionId={transaction.id_transaction}
          onClose={() => handleCloseModal('delete')}
          handleDelete={handleDelete}
        />
      </ModalContent>
    </Fragment>
  );
};
