import {
  ChildService,
  CreateRepairInput,
  GetRepairResponse,
  UpdateRepairInput,
} from '../../../../@types/order-service';
import { maskCpfCnpj, maskPhone } from '../../../../services/helpers/mask';
import { defaultOS } from '../CreateOrderServiceForm/createOSMockup';
import {
  OrderServiceFormDTO,
  ServiceDTO,
} from '../CreateOrderServiceForm/OrderServiceDTO';

/**
 * Converte apenas os campos alterados do formulário para o formato aceito na atualização da OS.
 * @param changedValues - Campos modificados do formulário.
 * @param status - Status da OS (finalizado ou em andamento).
 * @returns Objeto parcial com apenas os campos que devem ser enviados.
 */
export const parseOSFormChanges = (
  changedValues: Partial<OrderServiceFormDTO>,
  status: 'finished' | 'in_progress',
): Partial<CreateRepairInput> => {
  let result: Partial<CreateRepairInput> = {};

  // ID sempre vai junto
  if (changedValues.id_repair) {
    result.id_repair = changedValues.id_repair;
  }

  // customer
  if (changedValues.customer) {
    const { customer } = changedValues;
    if (customer.address) {
      Object.assign(result, customer.address);
    }
    result = {
      ...result,
      document: customer.document,
      email: customer.email,
      name: customer.name,
      phone: customer.phone,
    };
    // if (customer.document) result.document = customer.document;
    // if (customer.email) result.email = customer.email;
    // if (customer.name) result.name = customer.name;
    // if (customer.phone) result.phone = customer.phone;
    if (customer.noClient !== undefined) result.no_client = customer.noClient;
  }

  // services
  if (changedValues.services) {
    const { services } = changedValues;
    if (services.comment !== undefined) result.comment = services.comment;
    if (services.discount !== undefined) {
      result.discount = parseNumber(services.discount, true);
    }

    if (services.images) {
      result.image_urls = services.images.map(img => img.img_url);
    }

    const child_services: ChildService[] = [];

    if (services.productTable) {
      child_services.push(
        ...mapChildServices(services.productTable, 'Produto').filter(
          isValidService,
        ),
      );
    }

    if (services.serviceTable) {
      child_services.push(
        ...mapChildServices(services.serviceTable, 'Serviço').filter(
          isValidService,
        ),
      );
    }

    if (child_services.length > 0) {
      result.child_services = child_services;
    }
  }

  // checklist
  if (changedValues.checklist?.id_checklist_vehicle) {
    result.checklist_vehicle_id = changedValues.checklist.id_checklist_vehicle;
  }

  // vehicle
  if (changedValues.vehicle) {
    const { vehicle } = changedValues;
    if (vehicle.km) result.km = parseNumber(vehicle.km);
    if (vehicle.plate) result.plate = vehicle.plate;
    if (vehicle.description !== undefined)
      result.description = vehicle.description;
    if (vehicle.noVehicle !== undefined) result.no_vehicle = vehicle.noVehicle;
  }

  // status sempre vai
  result.status = status === 'finished' ? 'finalizado' : 'em andamento';

  return result;
};

/**
 * Converte os valores do formulário para o formato aceito no envio da ordem de serviço.
 * @param formValues - Dados do formulário da OS.
 * @returns Objeto formatado para a criação da OS.
 */
export const parseOSForm = (
  formValues: OrderServiceFormDTO,
  status: 'finished' | 'in_progress',
): CreateRepairInput => {
  const { checklist, customer, id_repair, services, vehicle } = formValues;

  const productTable = mapChildServices(
    services.productTable,
    'Produto',
  ).filter(service => isValidService(service));
  const serviceTable = mapChildServices(
    services.serviceTable,
    'Serviço',
  ).filter(service => isValidService(service));

  const child_services: ChildService[] = [...productTable, ...serviceTable];

  const result: UpdateRepairInput = {
    id_repair,
    // cliente
    ...customer.address,
    comment: services.comment,
    discount: parseNumber(services.discount, true),
    document: customer.document,
    email: customer.email,
    name: customer.name,
    phone: customer.phone,
    no_client: customer.noClient,
    // serviços
    child_services,
    image_urls: services.images.map(img => img.img_url),
    // checklist
    checklist_vehicle_id: checklist.id_checklist_vehicle,
    // veículo
    km: parseNumber(vehicle.km),
    plate: vehicle.plate,
    no_vehicle: vehicle.noVehicle,
    description: vehicle.description,
    status: status === 'finished' ? 'finalizado' : 'em andamento',
  };
  return result;
};

/**
 * Converte a lista de serviços do formulário para o formato aceito na OS.
 * @param productTable - Lista de produtos/serviços do formulário.
 * @param type - define se os itens serão mapeados.
 * @returns Lista formatada de serviços para a OS.
 */
const mapChildServices = (
  productTable: ServiceDTO[],
  type: 'Serviço' | 'Produto',
): ChildService[] => {
  return productTable.map(service => {
    // Converte os valores para número, garantindo que não sejam NaN
    const serviceQuantity = parseNumber(service.quantity);
    const serviceTotal = parseNumber(service.unitValue, true) * serviceQuantity;

    return {
      quantity: serviceQuantity,
      total: serviceTotal,
      name: service.name,
      type,
    };
  });
};

/**
 * Verifica se um serviço é válido com base em três critérios:
 * - `quantity` deve ser maior que 0.
 * - `name` deve ter pelo menos 1 caractere.
 * - `total` deve ser maior que 0.
 *
 * @param {Object} service - O objeto do serviço a ser validado.
 * @param {number} [service.quantity] - A quantidade do serviço.
 * @param {string} [service.name] - O nome do serviço.
 * @param {number} [service.total] - O valor total do serviço.
 * @returns {boolean} Retorna `true` se o serviço for válido, caso contrário, retorna `false`.
 */
const isValidService = (service: ChildService) => {
  const quantity = service?.quantity ?? 0;
  const name = (service?.name || '').trim();
  const total = service?.total ?? 0;
  return quantity > 0 && name.length > 0 && total > 0;
};

/**
 * Converte uma string numérica em um número de ponto flutuante.
 *
 * - Caso a string contenha um valor numérico válido, ele será convertido e retornado.
 * - Se a string não for um número válido, a função retorna `0` por padrão.
 *
 * @param {string} strNumber - A string que representa um número.
 * @param {boolean} isBrazilianCurrency - A indica se é um valor monetário no padrão brasileiro.
 * @returns {number} O valor numérico convertido ou `0` caso a entrada não seja válida.
 *
 * @example
 * console.log(parseNumber("10.5")); // 10.5
 * console.log(parseNumber("10,5", true)); // 10.5
 * console.log(parseNumber("abc"));  // 0
 * console.log(parseNumber(""));     // 0
 */
export const parseNumber = (strNumber: string, isBrazilianCurrency = false) => {
  if (isBrazilianCurrency) {
    strNumber = strNumber.replaceAll('.', '').replace(',', '.');
  }
  return !isNaN(parseFloat(strNumber)) ? parseFloat(strNumber) : 0;
};

export const mapRepairToFormValues = (
  res: GetRepairResponse,
): OrderServiceFormDTO => {
  const vehicle = res?.vehicle;
  const user = res?.user_workshop;
  const address = res?.address;
  const checklistVehicle = res?.checklist_vehicle;

  const serviceTable: ServiceDTO[] = [];
  const productTable: ServiceDTO[] = [];

  (res?.child_services ?? []).forEach(childService => {
    const item: ServiceDTO = {
      name: childService?.name ?? '',
      quantity: `${childService?.quantity ?? 0}`,
      unitValue: `${
        (childService?.total ?? 0) / (childService?.quantity ?? (0 || 1))
      }`,
    };

    if (childService.type === 'Produto' || childService.type === null) {
      productTable.push(item);
    } else if (childService.type === 'Serviço') {
      serviceTable.push(item);
    }
  });

  const parsed: OrderServiceFormDTO = {
    id_repair: res?.id_repair ?? '',
    vehicle: {
      description: res?.description ?? '',
      km: `${vehicle?.current_km ?? 0}`,
      lastKm: `${vehicle?.current_km ?? 0}`,
      name: vehicle?.name ?? '',
      noVehicle: res?.no_vehicle ?? false,
      plate: vehicle?.license_plate ?? '',
      vehicleId: res?.vehicle_id ?? '',
      year: vehicle?.year ?? '',
    },
    customer: {
      userId: user?.id_user ?? '',
      document: maskCpfCnpj(res?.document ?? ''),
      changeVehicleOwner: false,
      email: res?.email ?? '',
      name: res?.name ?? '',
      noClient: res?.no_client ?? false,
      phone: maskPhone(res?.phone ?? ''),
      address: {
        city: address?.city ?? '',
        district: address?.district ?? '',
        number: address?.number ?? '',
        street: address?.street ?? '',
        uf: address?.uf ?? '',
        zip: address?.zip ?? '',
        additional: '',
      },
    },
    services: {
      comment: res?.comment ?? '',
      discount: `${res?.discount ?? 0}`,
      images: [],
      productTable,
      serviceTable,
    },
    checklist: {
      id_checklist_vehicle: checklistVehicle?.id_checklist_vehicle ?? '',
      checklist_category_details:
        checklistVehicle?.checklist_category_details ??
        defaultOS.checklist.checklist_category_details,
      checklist_images:
        checklistVehicle?.checklist_vehicle_imgs ??
        defaultOS.checklist.checklist_images,
    },
    isChecklistDraft: !checklistVehicle,
    isDraft: false,
  };

  return parsed;
};
