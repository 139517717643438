import { OrderServiceFormDTO } from './OrderServiceDTO';

export const defaultOS: OrderServiceFormDTO = {
  id_repair: '',
  isDraft: true,
  isChecklistDraft: true,
  vehicle: {
    plate: '',
    year: '',
    km: '',
    lastKm: '',
    name: '',
    noVehicle: false,
    vehicleId: '',
    description: '',
  },
  customer: {
    name: '',
    document: '',
    phone: '',
    email: '',
    address: {
      zip: '',
      uf: '',
      city: '',
      district: '',
      street: '',
      number: '',
      additional: '',
    },
    noClient: false,
    changeVehicleOwner: false,
    userId: '',
  },
  services: {
    discount: '',
    images: [],
    productTable: [{ name: '', quantity: '1', unitValue: '0,00' }],
    serviceTable: [{ name: '', quantity: '1', unitValue: '0,00' }],
    comment: '',
  },
  checklist: {
    id_checklist_vehicle: '',
    checklist_images: [],
    checklist_category_details: [],
  },
};
