import { CSSProperties, ReactNode } from 'react';
import styles from './body.module.css';
import useInfiniteScroll from '../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../ListLoader';

interface Props {
  children: ReactNode;
  onScroll?: () => void;
  style?: CSSProperties;
  isLoading?: boolean;
}

export const Body = ({
  children,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onScroll = () => {},
  style,
  isLoading = false,
}: Props) => {
  const listRef = useInfiniteScroll(onScroll);

  return (
    <div className={styles.wrapper} style={style}>
      <div className={styles.content} ref={listRef}>
        {children}
        {isLoading && <ListLoader />}
      </div>
    </div>
  );
};
