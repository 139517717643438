import { QuotationFilterOptions } from './interfaces';
import { getReceivedQuotations } from './model';

export const fetchReceivedQuotationsByFilter = async (
  filterValue: string,
  // filterType: string,
) => {
  if (filterValue.trim().length > 0) {
    const res = await getReceivedQuotations({
      // filter: filterType,
      query: filterValue,
    });

    return res;
  }
};
