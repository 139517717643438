import * as yup from 'yup';

export const customerValidationSchema = yup.object().shape({
  customer: yup.object().shape({
    document: yup.string().when('noClient', {
      is: true,
      then: schema => schema.notRequired(),
      otherwise: schema => schema.required('O CPF/CNPJ é obrigatório'),
    }),
    name: yup.string().required('O Nome é obrigatório'),
    noClient: yup.boolean().default(false),
  }),
});
