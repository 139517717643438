import { Checklist } from '../../../@types/checklist';
import { User } from '../../../@types/interface';
import {
  CreateRepairInput,
  CreateRepairResponse,
  GetRepairResponse,
  TransformRepairDraftParams,
} from '../../../@types/order-service';
import api from '../../../services/api/api';
import { AddressResponse } from '../Register/interface';
import {
  CreateCustomerInput,
  CreateCustomerResponse,
  CustomersResponse,
  TransferVehicleUpdateInput,
} from './interfaces';

export const getAddress = async (zip: string) => {
  const { data } = await api.get<AddressResponse>(
    `https://viacep.com.br/ws/${zip}/json/`,
  );

  return data;
};
export const getUserByDocument = async (document: string) => {
  const { data } = await api.get<User>('/users', {
    params: {
      document,
    },
  });

  return data;
};

export const transferVehicle = async (
  transferInput: TransferVehicleUpdateInput,
) => {
  const { data } = await api.put('/vehicle/transfer', transferInput);
  return data;
};

export const findCustomersByWorkshop = async () => {
  const { data } = await api.get<CustomersResponse[]>('/workshop/customer', {
    params: { limit: 1000 },
  });
  return data;
};

export const createCustomer = async (customerData: CreateCustomerInput) => {
  const { data } = await api.post<CreateCustomerResponse>(
    '/workshop/customer',
    {
      vehicles: [],
      ...customerData,
    },
  );
  return data;
};

export const createRepairDraft = async (
  orderServiceData: CreateRepairInput,
) => {
  const { data } = await api.post<CreateRepairResponse>('/repair/draft', {
    ...orderServiceData,
  });
  return data;
};

/**
 * Transforma um rascunho de reparo em uma ordem de serviço finalizada.
 *
 * @param {TransformRepairDraftParams} params - Parâmetros necessários para a transformação.
 * @returns {Promise<CreateRepairResponse>} Retorna a resposta da API com os dados da OS criada.
 *
 * @example
 * const response = await transformRepairDraft({
 *   identification: "ABC123",
 *   statusId: "1",
 *   changeVehicleOwner: false
 * });
 * console.log(response);
 */
export const transformRepairDraft = async (
  params: TransformRepairDraftParams,
) => {
  const { data } = await api.put<CreateRepairResponse>(
    `/repair/draft/transform?identification=${params.identification}&status=${params.status}&changeVehicleOwner=${params.changeVehicleOwner}`,
  );

  return data;
};

export const getLatestChecklist = async (plate: string) => {
  const { data } = await api.get<Checklist>(
    `/checklist_vehicle/latest/${plate}`,
  );

  return data;
};

export const updateRepair = async (
  orderServiceData: Partial<CreateRepairInput>,
) => {
  const { data } = await api.put<CreateRepairResponse>('/repair/v2', {
    ...orderServiceData,
  });
  return data;
};

export const getRepair = async (repairId: string) => {
  const { data } = await api.get<GetRepairResponse>(`/repair/${repairId}`);
  return data;
};
