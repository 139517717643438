import {
  ChildService,
  CreateRepairInput,
} from '../../../../@types/order-service';
import { OrderServiceFormDTO, ServiceDTO } from './OrderServiceDTO';

/**
 * Converte os valores do formulário para o formato aceito no envio da ordem de serviço.
 * @param formValues - Dados do formulário da OS.
 * @returns Objeto formatado para a criação da OS.
 */
export const parseOSForm = (
  formValues: OrderServiceFormDTO,
): CreateRepairInput => {
  const { checklist, customer, id_repair, services, vehicle } = formValues;

  const productTable = mapChildServices(
    services.productTable,
    'Produto',
  ).filter(service => isValidService(service));
  const serviceTable = mapChildServices(
    services.serviceTable,
    'Serviço',
  ).filter(service => isValidService(service));

  const child_services: ChildService[] = [...productTable, ...serviceTable];

  const result: CreateRepairInput = {
    id_repair,
    // cliente
    ...customer.address,
    comment: services.comment,
    discount: parseNumber(services.discount, true),
    document: customer.document,
    email: customer.email,
    name: customer.name,
    phone: customer.phone,
    no_client: customer.noClient,
    // serviços
    child_services,
    image_urls: services.images.map(img => img.img_url),
    // checklist
    checklist_vehicle_id: checklist.id_checklist_vehicle,
    // veículo
    km: parseNumber(vehicle.km),
    plate: vehicle.plate,
    no_vehicle: vehicle.noVehicle,
    description: vehicle.description,
  };

  // if (
  //   Object.values(customer.address).every(
  //     value => value !== '' && value !== null,
  //   )
  // ) {
  //   const { additional, city, district, number, street, uf, zip } =
  //     customer.address;
  //   result.additional = additional;
  //   result.city = city;
  //   result.district = district;
  //   result.number = number;
  //   result.street = street;
  //   result.uf = uf;
  //   result.zip = zip;
  // }

  return result;
};

/**
 * Converte a lista de serviços do formulário para o formato aceito na OS.
 * @param productTable - Lista de produtos/serviços do formulário.
 * @param type - define se os itens serão mapeados.
 * @returns Lista formatada de serviços para a OS.
 */
const mapChildServices = (
  productTable: ServiceDTO[],
  type: 'Serviço' | 'Produto',
): ChildService[] => {
  return productTable.map(service => {
    // Converte os valores para número, garantindo que não sejam NaN
    const serviceQuantity = parseNumber(service.quantity);
    const serviceTotal = parseNumber(service.unitValue, true) * serviceQuantity;

    return {
      quantity: serviceQuantity,
      total: serviceTotal,
      name: service.name,
      type,
    };
  });
};

/**
 * Verifica se um serviço é válido com base em três critérios:
 * - `quantity` deve ser maior que 0.
 * - `name` deve ter pelo menos 1 caractere.
 * - `total` deve ser maior que 0.
 *
 * @param {Object} service - O objeto do serviço a ser validado.
 * @param {number} [service.quantity] - A quantidade do serviço.
 * @param {string} [service.name] - O nome do serviço.
 * @param {number} [service.total] - O valor total do serviço.
 * @returns {boolean} Retorna `true` se o serviço for válido, caso contrário, retorna `false`.
 */
const isValidService = (service: ChildService) => {
  const quantity = service?.quantity ?? 0;
  const name = (service?.name || '').trim();
  const total = service?.total ?? 0;
  return quantity > 0 && name.length > 0 && total > 0;
};

/**
 * Converte uma string numérica em um número de ponto flutuante.
 *
 * - Caso a string contenha um valor numérico válido, ele será convertido e retornado.
 * - Se a string não for um número válido, a função retorna `0` por padrão.
 *
 * @param {string} strNumber - A string que representa um número.
 * @param {boolean} isBrazilianCurrency - A indica se é um valor monetário no padrão brasileiro.
 * @returns {number} O valor numérico convertido ou `0` caso a entrada não seja válida.
 *
 * @example
 * console.log(parseNumber("10.5")); // 10.5
 * console.log(parseNumber("10,5", true)); // 10.5
 * console.log(parseNumber("abc"));  // 0
 * console.log(parseNumber(""));     // 0
 */
export const parseNumber = (strNumber: string, isBrazilianCurrency = false) => {
  if (isBrazilianCurrency) {
    strNumber = strNumber.replaceAll('.', '').replace(',', '.');
  }
  return !isNaN(parseFloat(strNumber)) ? parseFloat(strNumber) : 0;
};
