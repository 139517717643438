import { ReactNode } from 'react';
import { ArrowLeft, FileArrowDown } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { MainComponent } from '../../../../../shared/MainComponent/MainComponent';
import logo from '../../../../../assets/icons/ReportLogo.svg';
import styles from './container.module.css';
import { COLORS } from '../../../../../styles/colors';

interface Props {
  children: ReactNode;
  handleDownloadPdf?: () => void;
  workshopName: string;
  documentName: string;
  className?: string;
  isDownloadingPdf?: boolean;
}

export const Container = ({
  children,
  handleDownloadPdf,
  documentName,
  workshopName,
  className,
  isDownloadingPdf = false,
}: Props) => {
  const accessToken = localStorage.getItem('@AutoCenter: accessToken');
  const navigate = useNavigate();
  return (
    <MainComponent className={className}>
      <div className={styles.container} id="container">
        <div className={styles.header}>
          {accessToken && (
            <button
              type="button"
              className={styles.backBtn}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeft size="3rem" color={COLORS.GREEN} weight="bold" />
            </button>
          )}
          <img src={logo} alt="logo" />
        </div>
        <div className={styles.content}>
          <h1>{workshopName}</h1>
          <h2>{documentName}</h2>
          {children}
        </div>
      </div>
      {handleDownloadPdf && (
        <button
          type="button"
          className={styles.downloadBtn}
          onClick={handleDownloadPdf}
          disabled={isDownloadingPdf}
        >
          <FileArrowDown size="1.8rem" />
          gerar pdf
        </button>
      )}
    </MainComponent>
  );
};
