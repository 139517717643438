export const handleErrorMessage = (err: any) => {
  let msg = 'Erro não identificado.';

  if (typeof err === 'string') {
    msg = err.toUpperCase();
  } else if (err.response && err.response.data && err.response.data.message) {
    msg = err.response.data.message;
  } else if (err instanceof Error) {
    msg = err.message;
  } else if (err.message) {
    msg = err.message;
  }
  return msg;
};
