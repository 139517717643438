import { ReactElement } from 'react';
import styles from './card-info.module.css';

type Variants =
  | 'receiveDay'
  | 'paymentDay'
  | 'pendingPayment'
  | 'pendingReceive'
  | 'monthTotal'
  | 'auto';

interface Props {
  variant?: Variants;
  dateInfo?: string;
  value: number;
  title: string;
  Icon?: ReactElement<SVGAElement>;
  emphasisValue?: boolean;
}

export const CardInfo = ({
  title,
  value,
  variant = 'auto',
  dateInfo,
  Icon,
  emphasisValue = false,
}: Props) => {
  const [integerValue, decimalValue] = value
    .toLocaleString('pt-br', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .split(',');

  function setVariantStyle() {
    if (variant !== 'auto') {
      if (variant === 'paymentDay') return styles.red;
      if (variant === 'pendingPayment') return styles.lightRed;
      if (variant === 'pendingReceive') return styles.lightGreen;
      if (variant === 'receiveDay') return styles.green;
      if (variant === 'monthTotal') return styles.gray;
      return styles.green;
    }

    if (value === 0) return '';

    return value < 0 ? styles.red : styles.green;
  }

  function setValueSize() {
    const rem = 16;
    const absoluteValue = Math.abs(value);
    if (absoluteValue < 100) return 2 * rem;
    if (absoluteValue < 1000) return 1.75 * rem;
    if (absoluteValue < 10000) return 1.5 * rem;
    return 1.25 * rem;
  }

  const variantStyle = setVariantStyle();
  const valueSize = setValueSize();

  return (
    <div className={`${styles.container} ${styles[variant]} ${variantStyle}`}>
      {dateInfo && <div className={styles.date}>{dateInfo}</div>}
      <h2 className={`${styles.title}`}>
        {title} {Icon && Icon}
      </h2>
      <h1 className={`${styles.value}`}>
        <div
          className={`${styles.integer} ${
            emphasisValue ? `${styles.emphasis}` : ''
          }`}
          style={{
            fontSize: valueSize,
          }}
        >
          R$ {integerValue}
        </div>
        <div
          className={styles.decimal}
          style={{
            fontSize: valueSize,
          }}
        >
          ,{decimalValue}
        </div>
      </h1>
    </div>
  );
};
