/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowClockwise, CaretDown, CaretUp, Eye } from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { Button } from '../../../../../../../../../shared/Button';
import { Table } from '../../../../../../../Finance/components/Table';
import styles from './desktop-list.module.css';
import { Quotation } from '../../../../../../../../../@types/interface';
import {
  dateOffset,
  formatDate,
} from '../../../../../../../../../utils/dateFunctions';
import useViewModalAgent from '../../../../../hooks/useViewModalAgent';
import useSelectedQuotation from '../../../../../../workshop/hooks/useSelectedQuotation';
import useInfiniteScroll from '../../../../../../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../../../../../../../shared/ListLoader';

interface Props {
  quotationList: Quotation[];
  handleRowClick: (quotation: Quotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
  onOpenRedoModal: () => void;
}

export const DesktopList = ({
  quotationList,
  handleRowClick,
  onScroll,
  isLoading,
  isEmpty = false,
  notFound = false,
  onOpenRedoModal,
}: Props) => {
  const viewModal = useViewModalAgent();
  const selectedQuotation = useSelectedQuotation();

  const listRef = useInfiniteScroll(onScroll);

  const message = useMemo(() => {
    if (isEmpty) return 'Sem Novas Cotações';
    if (notFound) return 'Nenhuma Cotação Encontrada';
    return null;
  }, [isEmpty, notFound]);

  const handleRowSelected = useCallback(
    (quotationItem: Quotation) => {
      if (
        !selectedQuotation.quotation ||
        !selectedQuotation.quotation.answers_quotation ||
        selectedQuotation.quotation.answers_quotation.length === 0 ||
        !quotationItem.answers_quotation
      )
        return false;

      return (
        selectedQuotation.quotation.answers_quotation[0].id_answer_quotation ===
        quotationItem.answers_quotation[0].id_answer_quotation
      );
    },
    [selectedQuotation.quotation],
  );

  return (
    <Table.Root>
      <Table.Row className={styles['table-header']}>
        <Table.Header value="N° Cotação" align="left" />
        <Table.Header value="Situação" align="left" />
        <Table.Header value="Fornecedor" align="left" />
        <Table.Header value="Data da Criação" align="center" />
        <Table.Header value="Data da Finalização" align="center" />
      </Table.Row>
      <div ref={listRef} className={styles['table-content']}>
        {message && <p>{message}</p>}
        {!message &&
          quotationList.map(quotationItem => (
            <ListItem
              key={`${quotationItem.id_quotation} ${
                quotationItem?.answers_quotation
                  ? quotationItem?.answers_quotation[0]?.id_answer_quotation
                  : ''
              }`}
              quotation={quotationItem}
              isSelected={handleRowSelected(quotationItem)}
              onRedo={() => onOpenRedoModal()}
              onRowClick={() => handleRowClick(quotationItem)}
              onView={() => {
                document.body.style.overflow = 'hidden';
                viewModal.onOpen();
              }}
            />
          ))}
        {!message && isLoading && <ListLoader />}
      </div>
    </Table.Root>
  );
};

interface ListItemProps {
  quotation: Quotation;
  onView: () => void;
  onRedo: () => void;
  onRowClick: (quotation: Quotation) => void;
  isSelected: boolean;
}

const ListItem = ({
  quotation,
  isSelected,
  onRedo,
  onRowClick,
  onView,
}: ListItemProps) => {
  const createdAt = useMemo(() => {
    if (!quotation?.created_at) return '--/--/--';
    return formatDate(dateOffset(new Date(quotation.created_at)));
  }, [quotation.created_at]);
  const finishedAt = useMemo(() => {
    if (!quotation?.updated_at) return '--/--/--';
    return formatDate(dateOffset(new Date(quotation.updated_at)));
  }, [quotation.updated_at]);

  const situation: { label: string; color: string } = useMemo(() => {
    if (!quotation.answers_quotation) return { label: '---', color: '#89939E' };
    if (quotation.answers_quotation[0].rejected)
      return { label: 'proposta rejeitada pelo comprador', color: '#E53835' };
    if (quotation?.answers_quotation[0]?.rejected_by_reciever)
      return { label: 'rejeitada pelo fornecedor', color: '#E53835' };
    return { label: 'finalizada', color: '#007A37' };
  }, [quotation?.answers_quotation]);

  const autoshop = useMemo(() => {
    if (!quotation?.answers_quotation) return '---';
    return quotation?.answers_quotation[0]?.workshop?.fantasy_name ?? '';
  }, [quotation?.answers_quotation]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onRowClick(quotation);
      e.stopPropagation();
    },
    [onRowClick, quotation],
  );

  // const visualizedStatus = useMemo(() => {
  //   if (quotation.visualized) return 'default';
  //   return 'notification';
  // }, [quotation.visualized]);

  const RowIcon = useMemo(
    () => (isSelected ? CaretUp : CaretDown),
    [isSelected],
  );

  return (
    <div>
      <Table.Row
        className={`${styles['table-row']} ${
          isSelected ? styles.selected : ''
        }`}
        onClick={handleClick}
      >
        <Table.Cell alignStart>
          <div className={styles['first-line']}>
            <RowIcon size={20} />
            {quotation?.QUOTATION ?? ''}
            {/* <Circle variant={visualizedStatus} /> */}
          </div>
        </Table.Cell>
        <Table.Cell
          alignStart
          style={{ color: situation.color, maxWidth: 'inherit' }}
        >
          {situation.label}
        </Table.Cell>
        <Table.Cell alignStart>{autoshop}</Table.Cell>
        <Table.Cell>{createdAt}</Table.Cell>
        <Table.Cell>{finishedAt}</Table.Cell>
      </Table.Row>
      {isSelected && (
        <Table.Row className={styles['row-actions']}>
          <Button handleClick={onView}>
            <Eye size={20} weight="bold" />
            Visualizar
          </Button>
          <Button handleClick={onRedo} style={{ background: 'var(--b_grey)' }}>
            <ArrowClockwise
              size={20}
              style={{
                transform: 'rotateY(180deg)',
              }}
            />
            Refazer
          </Button>
        </Table.Row>
      )}
    </div>
  );
};
