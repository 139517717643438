/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { COLORS } from '../../../styles/colors';

interface InputProps {
  backgroundColor?: string;
  outlineStyle?: 'borders' | 'none' | 'shadow';
  fontSize?: number;
  fontWeight?: 'bold' | 'bolder' | 'normal' | '500';
  color?: string;
  height?: number;
  width?: number;
  borderColor?: string;
}

interface InputContainerProps {
  color?: string;
  fontWeight?: 'bold' | 'bolder' | 'normal' | '500';
  fontSize?: number;
  justifyContent?:
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end';
  alignItems?:
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end';
  width?: responsiveSizeOptions | string;
  flexDirection?: string | responsiveDirectionOptions;
}
interface responsiveSizeOptions {
  base?: string;
  lg?: string;
}

interface responsiveDirectionOptions {
  base?: string;
  lg?: string;
}

interface PrevCardItemActionProps {
  hidden?: boolean;
  backgroundColor?: string;
  variant?: string;
}
interface PrevCardProps {
  isEditing: boolean;
}
export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  height: 100%;
  gap: 2rem;
  /* margin-bottom: 2rem; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  gap: 1rem;
`;

export const InfoColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 1051px) {
    justify-content: center;
    padding: 0 0.5rem;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;

  @media (max-width: 670px) {
    width: 80%;
    justify-content: center;
  }
`;

export const Fill = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* height: 85%; */
  background: #599358;
  font-size: 1.35rem;
  color: #fff;
  padding: 1.5rem;
  gap: 1rem;
  width: 100%;
  border-radius: 8px;

  position: relative;

  & > img {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media only screen and (max-width: 1450px) {
    font-size: 0.8rem !important;
  }
  @media only screen and (min-width: 1925px) {
    font-size: 2rem !important;
  }
`;

export const FillBsSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  right: -7.9%;
  height: 35px;
  width: 70px;

  background: ${COLORS.GREEN};
  color: white;

  border-radius: 10px;
  border: 1px solid #4b4b4b;

  font-size: 1.35rem;

  @media only screen and (max-width: 425px) {
    right: -11%;
  }

  @media only screen and (max-width: 1450px) {
    font-size: 0.8rem !important;
  }
  @media only screen and (min-width: 1925px) {
    font-size: 2rem !important;
  }
`;
export const PrevColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  width: 100%;
  padding: 0 2.5rem;

  @media screen and (max-width: 480px) {
    padding: 0 0.5rem;
  }
`;

export const ClearFieldsBtn = styled.text`
  display: flex;

  gap: 0.6rem;
  text-decoration: underline;
  font-weight: 300;
  color: ${COLORS.GREEN};

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const PrevCard = styled.li<PrevCardProps>`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-around;

  position: relative;

  background: #6bb56a;

  border-radius: 8px;

  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);

  min-width: 14rem;
  max-height: 80px;

  padding: 2.5rem 0.8rem 1.25rem;

  border: ${props => (props.isEditing ? 'solid 1px #f50 !important' : 'none')};

  span.status-indicator {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: ${COLORS.WHITE};
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    opacity: 0;
    transition: all 0.25s ease-out;

    @media screen and (max-width: 480px) {
      & {
        transform: translate(-50%, -80%);
      }
    }
  }
  span.status-indicator.onSave {
    animation: fade-out 1.5s ease-in-out;
    opacity: 1;
    background: ${COLORS.GREEN};
  }

  span.status-indicator.onEdit {
    opacity: 1;
    background: #f50;
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media screen and (max-width: 480px) {
    box-shadow: 0 0 1px 2px #d9d9d9;
    padding: 0.5rem;
  }
`;

export const PrevCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 2px;

  margin-bottom: 1rem;

  font-weight: 600;

  & > span {
    display: flex;
    flex-wrap: nowrap;
    font-size: 1rem;
    text-align: center;
  }
  & > img {
    width: 3.2rem;
    height: 3.2rem;
  }
  @media screen and (max-width: 480px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    & > img {
      width: 2rem;
      height: 2rem;
      margin-right: 0.8rem;
    }
    & > span {
      font-size: 1.3rem;
    }
  }
`;
export const PrevCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  & > div {
    margin-bottom: 0.25rem;
  }
  /* @media screen and (max-width: 480px) {
    flex-direction: row;
    align-items: space;
    justify-items: center;
  } */
`;

export const PrevCardItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 1rem;

  font-size: 1.25rem;
  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 0rem 0.25rem;
  }
`;

export const Input = styled.input<InputProps>`
  all: unset;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : COLORS.WHITE};
  color: ${props =>
    props.color
      ? props.color
      : props.backgroundColor
      ? COLORS.WHITE
      : COLORS.BLACK};
  border-radius: 8px;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  /* height: ${props => (props.height ? `${props.height}px` : '2.5rem')}; */
  text-transform: uppercase;
  outline: none;
  border: ${props =>
    props.outlineStyle && props.outlineStyle === 'none'
      ? 'none'
      : '0.5px solid #d4d4d4'};
  border-color: ${props => (props.borderColor ? props.borderColor : 'inherit')};
  box-shadow: ${props =>
    props.outlineStyle === 'shadow'
      ? '1px 1px 3px 1px rgba(0, 0, 0, 0.5)'
      : 'none'};
  padding: 0.5rem;
  font-size: ${props => (props.fontSize ? props.fontSize : '18')}px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};

  &::placeholder {
    font-size: 1rem;
    text-transform: capitalize;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const InputContainerLicence = styled.div<InputContainerProps>`
  display: flex;
  gap: 0.3rem;
  align-items: start;
  align-items: center;
  justify-content: space-between;
  & label {
    color: ${props => (props.color ? props.color : COLORS.BLACK)};
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '1rem')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'regular')};
    white-space: nowrap;
  }
  width: ${props =>
    typeof props.width === 'string'
      ? props.width
      : typeof props.width === 'object'
      ? props.width?.lg
      : 'auto'};

  & > .search-btn {
    background-color: ${COLORS.GREEN};
    color: #fff;
    border-radius: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
  }
`;
export const DataSection = styled.section`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  align-items: end;
  width: 480px;

  @media (max-width: 670px) {
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-width: 480px) {
    align-items: center;
    justify-content: center;
  }
`;

export const ErrorMessage = styled.span`
  color: ${COLORS.RED};
  font-weight: 500;
  font-size: 1rem;
  padding-left: 0.5rem;
`;
export const PrevCardItemAction = styled.div<PrevCardItemActionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  color: ${props => (props.variant === 'delete' ? COLORS.RED : COLORS.WHITE)};
  background: ${props =>
    props.backgroundColor ? `${props.backgroundColor}` : COLORS.WHITE};
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: ${props => (props.hidden ? 'pointer' : 'default')};
  visibility: ${props => (props.hidden ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out;
  width: 8rem;
  margin: 0 auto;
  border: ${props =>
    props.variant === 'delete' ? `1px solid ${COLORS.RED}` : 'inherit'};
  margin-bottom: 0.25rem;

  &:hover {
    color: ${COLORS.WHITE};
    transition: all 0.5s ease-out;
    background-color: ${props =>
      props.variant === 'delete' ? COLORS.RED : COLORS.GREEN};
  }

  & > span {
    margin-left: 0.25rem;
  }
  @media screen and (max-width: 480px) {
    margin: 0 0.5rem;
  }
`;
export const PrevCardFooter = styled.div`
  & {
    width: 100%;

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 480px) {
      & {
        margin-top: 1rem;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
`;

export const Overlay = styled.div`
  display: none;

  &.isOpen {
    position: fixed;
    max-height: 100vh;
    height: 100%;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: auto;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }
`;

export const Modal = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const ContainerPreventive = styled.div`
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  border: 0px solid black;
  box-sizing: border-box;
  border-radius: 15px;

  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;

  max-height: 548px;
  max-width: 380px;
  width: 75%;

  position: relative;
  background-color: white;
  overflow: hidden;
`;

export const BtnClose = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  border-radius: 50%;
  height: 1.9rem;
  width: 1.9rem;
  background-color: #6bb56a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 3px;
  top: 4px;
  cursor: pointer;
`;
