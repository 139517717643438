import {
  ArrowCircleLeft,
  ArrowCircleRight,
  CheckSquareOffset,
} from 'phosphor-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../../../shared/Button';
import useCreateChecklistModal from '../../hooks/useCreateChecklistModal';
import styles from './create-checklist-modal.module.css';
import { ModalContent } from '../../../Finance/components/Modal';
import { DataStep } from './steps/DataStep';
import { ImagesStep } from './steps/ImagesStep';
import { ModalHeader } from '../ModalHeader';
import { StepController } from './StepController';
import { fetchVehicleDataByLicensePlateController } from '../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import { VehicleResponse } from '../../../../../services/dataFetchers/VehicleDataFetcher/model';
import { maskPhone, numberOnly } from '../../../../../services/helpers/mask';
import {
  createChecklistController,
  getChecklistByIdController,
  getChecklistDraftByIdController,
  transformDraftChecklistController,
} from '../../controller';
import {
  Checklist,
  ChecklistCategoryDetail,
  ChecklistImage,
} from '../../../../../@types/checklist';
import { FeedbackModal } from '../../../../../shared/FeedbackModal';
import { checklistSchema } from './steps/schema';
import { SaveChecklistDraftModal } from '../SaveChecklistDraftModal';
import { useResponsive } from '../../../../../hooks/useResponsive';
import { handleErrorMessage } from '../../../../../utils/handleErrorMessage';
import { ErrorModal } from '../ErrorModal';

export type Step = 'data' | 'images';

interface CreateChecklistModalProps {
  onClose?: () => void;
  refetch: () => void;
  checklist: Checklist | null;
}

export interface FormValues {
  id_checklist_vehicle: string;
  plate: string;
  name: string;
  phone: string;
  checklist_images: ChecklistImage[];
  checklist_category_details: ChecklistCategoryDetail[];
}

export const categories = [
  { name: 'panel', label: 'Painel' },
  { name: 'bodywork', label: 'Lataria' },
  { name: 'tire', label: 'Pneus' },
  { name: 'accessory', label: 'Acessórios' },
  { name: 'others', label: 'Outros' },
];

export const CreateChecklistModal = ({
  onClose,
  refetch,
  checklist,
}: CreateChecklistModalProps) => {
  const modal = useCreateChecklistModal();
  const { sizeMobile } = useResponsive();

  const [step, setStep] = useState<Step>('data');
  const [vehicle, setVehicle] = useState<VehicleResponse | null>(null);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [isLoadingVehicle, setIsLoadingVehicle] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const [categoryDetails, setCategoryDetails] = useState<
    ChecklistCategoryDetail[]
  >(() =>
    categories.map(category => ({
      category: category.name,
      observations: '',
    })),
  );

  const {
    control,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      id_checklist_vehicle: '',
      checklist_images: [],
      checklist_category_details: [],
      name: '',
      phone: '',
      plate: '',
    },
    resolver: yupResolver(checklistSchema),
    mode: 'onChange',
  });

  const [previousValues, setPreviousValues] = useState(() => getValues());
  const [currentChecklist, setCurrentChecklist] = useState<Checklist | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const isFormEmpty = useCallback(
    (formData: FormValues): boolean => {
      return (
        Object.entries({
          name: formData.name.trim(),
          phone: formData.phone.trim(),
          id_checklist_vehicle: formData.id_checklist_vehicle.trim(),
          plate: formData.plate.trim(),
        }).every(([_, value]) => value === '') &&
        formData.checklist_images.length === 0 &&
        categoryDetails.every(item => item.observations?.trim().length === 0)
      );
    },
    [categoryDetails],
  );

  const updateChecklist = useCallback(
    async (formData: FormValues) => {
      const currentFormValues = {
        ...formData,
        checklist_category_details: categoryDetails,
      };
      if (!isFormEmpty(currentFormValues)) {
        try {
          const res = await createChecklistController(currentFormValues);

          if (res) {
            setCurrentChecklist(res);
            const formValues = getValues();
            reset({
              ...formValues,
              phone: maskPhone(formValues.phone ?? ''),
              id_checklist_vehicle: res?.id_checklist_vehicle,
              checklist_category_details:
                formValues.checklist_category_details.map(detail => ({
                  ...detail,
                  id_checklist_category_details:
                    res?.checklist_category_details.find(
                      item => item.category === detail.category,
                    )?.id_checklist_category_details ?? '',
                })),
            });
          }
        } catch (err) {
          setError(handleErrorMessage(error));
        }
      }
    },
    [categoryDetails, error, getValues, isFormEmpty, reset],
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: async (formData: FormValues) => {
      await updateChecklist({
        ...formData,
        phone: numberOnly(formData.phone ?? ''),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['checklists']);
      handleClose();
      refetch();
    },
    onError: err => {
      if (err) {
        setError(handleErrorMessage(err));
      }
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    if (step === 'images') {
      await mutate(data);
    }
  };

  const handleSearchVehicle = useCallback(
    async (licensePlate: string) => {
      try {
        setIsLoadingVehicle(true);
        const res = await fetchVehicleDataByLicensePlateController(
          licensePlate,
        );
        if (res) {
          setVehicle(res);

          setValue('name', res?.user?.name ?? '');
          setValue('phone', maskPhone(res?.user?.phone ?? ''));
        }
      } catch (err) {
        setError(handleErrorMessage(err));
      } finally {
        setIsLoadingVehicle(false);
      }
      await trigger();
    },
    [setValue, trigger],
  );
  const resetVehicle = useCallback(() => {
    setVehicle(null);
    setValue('name', '');
    setValue('phone', '');
  }, [setValue]);

  const handleClose = useCallback(() => {
    modal.onClose();
    resetVehicle();
    reset({
      checklist_images: [],
      name: '',
      phone: '',
      plate: '',
      checklist_category_details: [],
      id_checklist_vehicle: '',
    });
    onClose?.();
  }, [modal, onClose, reset, resetVehicle]);

  const handleNextClick = async () => {
    const isFormValid = await trigger();
    if (isFormValid && step === 'data') {
      setStep('images');
    }
  };

  const handleStep = async (newStep: Step) => {
    const isFormValid = await trigger();
    if (isFormValid) setStep(newStep);
  };
  const handleBackClick = () => {
    if (step === 'images') {
      setStep('data');
    }
  };

  const handleFormChange = useCallback(() => {
    const { phone, ...rest } = getValues();
    updateChecklist({
      ...rest,
      phone: numberOnly(phone ?? ''),
    });
  }, [getValues, updateChecklist]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentValues: FormValues = {
        ...getValues(),
        checklist_category_details: categoryDetails,
      }; // Pega os valores atuais do formulário

      if (JSON.stringify(currentValues) !== JSON.stringify(previousValues)) {
        handleFormChange();
        setPreviousValues(currentValues); // Atualiza os valores anteriores
      }
    }, 5000); // Verifica a cada 5 segundos
    return () => clearInterval(interval); // Limpa o intervalo quando o componente desmontar
  }, [previousValues, getValues, categoryDetails, handleFormChange]);

  const fetchChecklist = useCallback(async () => {
    setIsFetching(true);
    try {
      const res = checklist?.draft
        ? await getChecklistDraftByIdController(
            checklist?.id_checklist_vehicle ?? '',
          )
        : await getChecklistByIdController(
            checklist?.id_checklist_vehicle ?? '',
          );
      if (res) {
        setCurrentChecklist(res);
        reset({
          name: res?.name ?? '',
          phone: res?.phone ?? '',
          checklist_images:
            res.checklist_vehicle_imgs.map(img => ({
              category: img.category,
              img_url: img.img_url,
            })) ?? [],
          checklist_category_details: res.checklist_category_details ?? [],
          id_checklist_vehicle: res.id_checklist_vehicle ?? '',
          plate: checklist?.plate ?? '',
        });

        const details =
          res.checklist_category_details.map(detail => ({
            category: detail.category ?? '',
            observations: detail.observations ?? '',
            id_checklist_category_details:
              detail.id_checklist_category_details ?? '',
          })) ?? [];

        setCategoryDetails(previous =>
          details.length > 0 ? details : previous,
        );
        await handleSearchVehicle(checklist?.plate ?? '');
      }
    } catch (err) {
      console.error('Erro ao buscar checklist:', err);
    } finally {
      setIsFetching(false);
    }
  }, [checklist, handleSearchVehicle, reset]);

  useEffect(() => {
    if (checklist) {
      fetchChecklist();
    }
  }, [checklist, fetchChecklist]);

  const handleModalClose = useCallback(() => {
    const formData = {
      ...getValues(),
      checklist_category_details: categoryDetails,
    };
    if (isFormEmpty(formData)) {
      handleClose();
    } else {
      setShowSaveModal(true);
    }
  }, [categoryDetails, getValues, handleClose, isFormEmpty]);

  const iconSize = useMemo(() => (sizeMobile ? 32 : 26), [sizeMobile]);

  if (!modal.isOpen) return null;

  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <SaveChecklistDraftModal
        checklist={currentChecklist}
        onDelete={handleClose}
        onSave={async () => {
          await updateChecklist({
            ...getValues(),
            checklist_category_details: categoryDetails,
          });
          refetch();
          handleClose();
        }}
        isOpen={showSaveModal}
        onClose={() => setShowSaveModal(false)}
      />
      {error && (
        <ErrorModal isOpen message={error} onClose={() => setError(null)} />
      )}
      <FeedbackModal isOpen={isLoading || isFetching} variant="sending" />
      <ModalHeader handleClose={handleModalClose} title="Criar Checklist" />
      <StepController
        step={step}
        setStep={handleStep}
        className={styles['step-controller']}
        itemClassName={styles['step-controller-item']}
      />
      {step === 'data' ? (
        <DataStep
          control={control}
          handleSearchVehicle={handleSearchVehicle}
          vehicle={vehicle}
          resetVehicle={resetVehicle}
          setValue={setValue}
          errors={errors}
          isValid={isValid}
          isLoadingVehicle={isLoadingVehicle}
          isMobile={sizeMobile}
        />
      ) : (
        <ImagesStep
          categoryDetails={categoryDetails}
          setCategoryDetails={setCategoryDetails}
          setValue={setValue}
          getValues={getValues}
          setIsUploadingImages={setIsUploadingImages}
        />
      )}
      <footer className={styles.actions}>
        {step === 'data' ? (
          <Button
            className={styles['primary-button']}
            handleClick={handleNextClick}
            disabled={!isValid}
          >
            Próximo <ArrowCircleRight size={iconSize} weight="fill" />
          </Button>
        ) : (
          <Button
            className={styles['second-button']}
            handleClick={handleBackClick}
          >
            <ArrowCircleLeft size={iconSize} weight="fill" />
            Anterior
          </Button>
        )}
        {step === 'images' && (
          <Button
            className={styles['primary-button']}
            handleClick={() =>
              onSubmit({
                ...getValues(),
                checklist_category_details: categoryDetails,
              })
            }
            disabled={isUploadingImages}
          >
            <CheckSquareOffset size={iconSize} weight="fill" />
            Criar {!sizeMobile ? 'Checklist' : ''}
          </Button>
        )}
      </footer>
    </ModalContent>
  );
};
