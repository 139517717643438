import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { InputComponent } from '../../input';

import styles from './form-create-receive.module.css';
import { ButtonComponent } from '../../Button';
import {
  CreateIncomeDTO,
  createIncomeSchema,
} from '../../../utils/DTOs/createIncomeDTO';
import { createExpense } from '../../../../../model';
import { getTransactionCodesController } from '../../../../../controller';
import {
  TransactionCodeResponse,
  TransactionResponse,
} from '../../../../../interfaces';

interface CreateReceiveProps {
  onClose?: () => void;
  type: 'income' | 'expense';
  onCreate?: (transaction: TransactionResponse) => void;
  onCancel?: () => void;
}

export const CreateReceiveForm = ({
  onClose,
  type,
  onCreate,
  onCancel,
}: CreateReceiveProps) => {
  const [transactionCodes, setTransactionsCode] = useState<
    TransactionCodeResponse[]
  >([]);

  const today = new Date().toLocaleDateString().split('/').reverse().join('-');
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors, isLoading, isSubmitted },
  } = useForm<CreateIncomeDTO>({
    resolver: yupResolver(createIncomeSchema),
    defaultValues: {
      type,
      value: '0,00',
      discharge_date: today,
      due_date: today,
    },
  });

  async function onSubmit(data: CreateIncomeDTO) {
    // http call post
    const res = await createExpense(data);
    if (res) {
      onCreate?.(res);
    }
    if (onClose) onClose();
  }

  const fetchTransactionCodes = useCallback(async () => {
    const response = await getTransactionCodesController();
    if (response) {
      setTransactionsCode(response);
    }
  }, []);

  useEffect(() => {
    if (type === 'expense') fetchTransactionCodes();
  }, [fetchTransactionCodes, type]);

  const handleDueDateChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const now = new Date();
      const currentDate = new Date(e.target.value);

      if (now.getTime() !== currentDate.getTime())
        setValue('discharge_date', '');
    },
    [setValue],
  );

  watch('transaction_code_id');

  return (
    <form
      onSubmit={handleSubmit(data => onSubmit(data))}
      className={styles.form}
    >
      <InputComponent
        label="Descrição"
        {...register('description')}
        variant={errors?.description?.message ? 'danger' : 'default'}
        hint={errors?.description?.message}
      />
      <div className={styles.row}>
        <InputComponent
          label="Vencimento"
          type="date"
          {...register('due_date')}
          onChange={handleDueDateChange}
          hint={errors.due_date?.message}
        />
        <InputComponent
          label="Baixa"
          type="date"
          {...register('discharge_date')}
          hint={errors.discharge_date?.message}
        />
      </div>
      <InputComponent
        label="Valor"
        type="text"
        pattern="^\d{1,3}(?:\.\d{3})*,\d{2}$"
        {...register('value')}
        onChange={e => {
          let newValue = e.target.value;

          newValue = newValue.replaceAll(/[^0-9,.]/g, '');
          newValue = newValue.replaceAll('.', '');
          newValue = newValue.replaceAll(',', '.');

          e.target.selectionStart = e.target.value.length;
          e.target.selectionEnd = e.target.value.length;
          const numericValue = parseFloat(newValue);

          if (getValues('value').length > newValue.length) {
            if (!isNaN(numericValue) && numericValue > 0) {
              newValue = (numericValue / 10).toString();
            } else {
              newValue = '0.00';
            }
            newValue = parseFloat(newValue).toLocaleString('pt-bt', {
              minimumFractionDigits: 2,
            });
          } else if (!isNaN(numericValue)) {
            const [integerValue, decimalValue] = newValue.split('.');
            if (parseFloat(integerValue) === 0) {
              newValue = (parseFloat(newValue) * 10).toFixed(2);
            } else if (decimalValue.length > 2) {
              newValue = (parseFloat(newValue) * 10).toFixed(2);
            } else {
              newValue = numericValue.toFixed(2);
            }
            newValue = parseFloat(newValue).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            });
          } else {
            newValue = '0.00';
            newValue = parseFloat(newValue).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            });
          }

          if (
            isNaN(numericValue) ||
            newValue.length === 0 ||
            typeof newValue === 'undefined'
          ) {
            newValue = '0.00';
            newValue = parseFloat(newValue).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            });
          }

          setValue('value', newValue);
        }}
        hint={errors.value?.message?.toString()}
        variant={errors.value ? 'danger' : 'default'}
      />
      {type === 'expense' && (
        <select
          {...register('transaction_code_id')}
          defaultChecked
          defaultValue=""
          className={`${styles.select} ${
            getValues('transaction_code_id') === '' ? styles.default : ''
          }`}
        >
          <option value="">Informar plano de conta</option>
          {transactionCodes.map(transactionCode => {
            const { code, name, id_transaction_code } = transactionCode;
            return (
              <option value={id_transaction_code} key={id_transaction_code}>
                {code}-{name}
              </option>
            );
          })}
        </select>
      )}
      <div className={styles.row}>
        <ButtonComponent text="Cancelar" variant="outline" onClick={onCancel} />
        <ButtonComponent text="Criar Conta" disabled={isSubmitted} />
      </div>
    </form>
  );
};
