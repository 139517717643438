import { QuotationFilterOptions } from '../../interface';
import { getQuotations } from './model';

export const fetchQuotationsByFilter = async (
  filterValue: string,
  // filterType: string,
) => {
  if (filterValue.trim().length > 0) {
    const res = await getQuotations({
      // filter: filterType,
      query: filterValue,
    });

    return res;
  }
};
