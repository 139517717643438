import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styles from './table-filter-row.module.css';

type TableFilterRowProps = ComponentPropsWithoutRef<'tr'>;

export const TableFilterRow = forwardRef<
  HTMLTableRowElement,
  TableFilterRowProps
>(({ children, ...props }, ref) => (
  <tr
    ref={ref}
    {...props}
    className={`${styles['filter-row-item']} ${props.className}`}
  >
    {children}
  </tr>
));
