import { useMutation, useQueryClient } from 'react-query';
import { ModalHeader } from '../ModalHeader';
import styles from './save-checklist-draft-modal.module.css';
import { ModalContent } from '../../../Finance/components/Modal';
import { Button } from '../../../../../shared/Button';
import { deleteChecklistController } from '../../controller';
import { Checklist } from '../../../../../@types/checklist';
import { FeedbackModal } from '../../../../../shared/FeedbackModal';

interface SaveChecklistDraftModalProps {
  onClose: () => void;
  onDelete: () => void;
  onSave: () => void;
  checklist: Checklist | null;
  isOpen: boolean;
}

export const SaveChecklistDraftModal = ({
  onClose,
  onSave,
  onDelete,
  checklist,
  isOpen,
}: SaveChecklistDraftModalProps) => {
  const handleClose = () => {
    onClose();
  };
  const handleRemove = async () => {
    await mutate(checklist?.id_checklist_vehicle ?? '');
  };

  const handleSave = async () => {
    await onSave();
    onClose();
  };

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (id: string) => {
      await deleteChecklistController(id);
      queryClient.invalidateQueries(['checklists']);
      handleClose();
    },
    onSuccess: (_, id) => {
      queryClient.setQueryData(
        ['checklists'],
        (oldData: Checklist[] | undefined) => {
          return oldData
            ? oldData.filter(item => item.id_checklist_vehicle !== id)
            : [];
        },
      );

      onDelete();
      onClose(); // Fecha o modal corretamente
    },
    onError: error => {
      console.error('Erro ao criar checklist:', error);
    },
  });
  if (!isOpen) return null;
  return (
    <ModalContent
      open
      borderTop
      full
      ContentClassName={styles.modal}
      noCloseButton
    >
      <ModalHeader handleClose={handleClose} title="Salvar Rascunho" />
      <FeedbackModal isOpen={isLoading} variant="sending" />
      <div className={styles.container}>
        <span>
          Deseja salvar o rascunho do Checklist{' '}
          <b>{checklist?.CHECKLIST ?? ''}</b>?
        </span>
        <footer className={styles.actions}>
          <Button
            className={styles['second-button']}
            handleClick={handleRemove}
          >
            Excluir
          </Button>
          <Button className={styles['primary-button']} handleClick={handleSave}>
            Salvar
          </Button>
        </footer>
      </div>
    </ModalContent>
  );
};
