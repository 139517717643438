import { toast, ToastOptions, TypeOptions, Zoom } from 'react-toastify';

const toastConfig: ToastOptions = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark',
  transition: Zoom,
};

/**
 * Classe responsável por exibir notificações no sistema usando react-toastify.
 */
export class ToastNotification {
  /**
   * Exibe uma notificação com base no tipo especificado.
   *
   * @param {string} message - A mensagem a ser exibida na notificação.
   * @param {TypeOptions} type - O tipo da notificação, podendo ser:
   *   - `"success"`: Notificação de sucesso.
   *   - `"error"`: Notificação de erro.
   *   - `"info"`: Notificação informativa.
   *   - `"warning"`: Notificação de aviso.
   *
   * @example
   * ToastNotification.show("Operação realizada com sucesso!", "success");
   *
   * @example
   * ToastNotification.show("Erro ao processar a solicitação!", "error");
   */
  static show(message: string, type: TypeOptions) {
    switch (type) {
      case 'success':
        toast.success(message, toastConfig);
        break;
      case 'error':
        toast.error(message, toastConfig);
        break;
      case 'info':
        toast.info(message, toastConfig);
        break;
      case 'warning':
        toast.warning(message, toastConfig);
        break;
      default:
        toast(message, toastConfig);
    }
  }
}
