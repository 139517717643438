import { Step } from '.';
import { StepsController } from '../../../../../shared/StepsController';

interface Props {
  step: Step;
  setStep: (step: Step) => void;
  className?: string;
  itemClassName?: string;
}

export const StepController = ({
  step,
  setStep,
  className,
  itemClassName,
}: Props) => (
  <StepsController.Root className={className}>
    <StepsController.Item
      label="1. Dados"
      selected={step === 'data'}
      onClick={() => setStep('data')}
      className={itemClassName}
    />
    <StepsController.Item
      label="2. Imagens"
      selected={step === 'images'}
      onClick={() => setStep('images')}
      className={itemClassName}
    />
  </StepsController.Root>
);
