import * as y from 'yup';

// name -> plano de contas
// type -> income | expense

const transformToNumber = (value: number, originalValue: string) => {
  if (typeof originalValue === 'string') {
    return parseFloat(originalValue.replace(/\./g, '').replace(',', '.'));
  }
  return value;
};
export const createIncomeSchema = y.object({
  description: y.string().nullable(),
  discharge_date: y.string(),
  due_date: y.string(),
  value: y
    .mixed()
    .test(
      'Tipo inválido',
      'O valor deve ser uma string ou um número',
      value => typeof value === 'string' || typeof value === 'number',
    )
    .transform(transformToNumber)
    .test('valor inválido', 'O valor não pode ser 0,00', value => value !== 0)
    .required('O valor é obrigatório'),
  type: y
    .mixed()
    .oneOf(['expense', 'income'])
    .required('tipo não identificado'),
  transaction_code_id: y.string().uuid().nullable(),
});

export type CreateIncomeDTO = y.InferType<typeof createIncomeSchema>;
