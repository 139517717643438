import styles from './info-row.module.css';

interface Props {
  label: string;
  value?: string | null;
  breakLine?: boolean;
}

export const InfoRow = ({
  label,
  value = '-----------',
  breakLine = false,
}: Props) => {
  return (
    <div className={`${styles.row} ${breakLine ? styles.column : ''}`}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};
