import { ModalContent, ModalTitle } from '../Modal';
import styles from '../../pages/Transactions/transactions.module.css';
import { TransactionResponse } from '../../interfaces';
import { EditTransactionForm } from '../../pages/Transactions/components/Forms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEdit: (transaction: TransactionResponse) => void;
  selectedTransaction: TransactionResponse | null;
}

export const EditDashboardTransactionModal = ({
  isOpen,
  onClose,
  onEdit,
  selectedTransaction,
}: Props) => {
  const typeStyle =
    selectedTransaction?.type === 'income' ? styles.income : styles.expense;
  const title =
    selectedTransaction?.type === 'expense'
      ? 'Editar conta a pagar'
      : 'Editar conta a receber';
  return (
    <ModalContent
      open={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
    >
      <ModalTitle titleClassName={`${styles['modal-title']} ${typeStyle}`}>
        {title}
      </ModalTitle>
      <EditTransactionForm
        onClose={() => onClose()}
        transaction={selectedTransaction}
        handleUpdate={onEdit}
      />
    </ModalContent>
  );
};
