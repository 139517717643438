import { ComponentProps } from 'react';
import styles from './table-filter-root.module.css';
import responsive from './table-filter-root-responsive.module.css';

type TableFilterRootProps = ComponentProps<'table'> & {
  AutoResponsive?: boolean;
  height?: string;
  tableScrollClassName?: string;
};

export const TableFilterRoot = ({
  children,
  AutoResponsive = false,
  height = '100%',
  tableScrollClassName,
  ...props
}: TableFilterRootProps) => {
  return (
    <div
      className={`${styles['filter-scroll']} ${tableScrollClassName}`}
      style={{ height }}
    >
      <table
        {...props}
        className={`${styles['filter-table-container']} ${props.className} ${
          AutoResponsive && responsive.auto
        }`}
      >
        {children}
      </table>
    </div>
  );
};
