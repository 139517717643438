import React, { useEffect, useState } from 'react';
import { MainComponent } from '../../../shared/MainComponent/MainComponent';
import Card from './components/Card/Card';
import api from '../../../services/api/api';
import { useAuth } from '../../../hooks/useAuth';

interface TotalByMonth {
  total: number;
  services: number;
  discount: number;
}

interface TotalByYear {
  [year: string]: {
    total: number;
    services: number;
    discount: number;
    totalbymonths: {
      [month: string]: TotalByMonth;
    };
  };
}

interface DataObject {
  totalEarned: number;
  totalServices: number;
  totalDiscount: number;
  rating: number;
  totalByYear: TotalByYear;
}

interface Months {
  name: string;
  total: number;
  numberMonth: number;
  totalServices: number;
  discount: number;
}

interface Props {
  total: number;
  months: Months[];
}

const Dashboard: React.FC = () => {
  const [dashboard, setDashboard] = useState<Props>({
    total: 0,
    months: [],
  });
  const { workshop } = useAuth();

  const fetchDashboardWorkshop = async () => {
    function getDate(): string {
      const currentYear = new Date().getFullYear();
      return `${currentYear}-01-01`;
    }
    try {
      const { data } = await api.get<DataObject>('/dashboard/workshop', {
        params: {
          id: workshop?.id_workshop ?? '',
          initialDate: getDate(),
        },
      });
      const currentYear = new Date().getFullYear();
      if (data.totalByYear[currentYear].total !== 0) {
        const months = data.totalByYear[currentYear].totalbymonths;
        const monthNames = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ];

        const temp: Props = {
          total: data.totalEarned,
          months: Object.keys(months)
            .map(monthKey => ({
              name: monthNames[Number(monthKey) - 1],
              total: months[monthKey].total,
              numberMonth: Number(monthKey),
              discount: months[monthKey].discount,
              totalServices: months[monthKey].services,
            }))
            .filter(month => month.total !== 0)
            .reverse(),
        };
        setDashboard(temp);
      }
    } catch (err: any) {
      // console.log(err);
    }
  };

  useEffect(() => {
    fetchDashboardWorkshop();
  }, []);

  return (
    <MainComponent
      styleContainer={{
        alignSelf: 'center',
        backgroundColor: '#fefefe',
        margin: 0,
        padding: 0,
        maxWidth: '100%',
        overflow: 'auto',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      }}
      title="OS Finalizadas"
    >
      {dashboard.months.map(month => {
        return (
          <Card
            month={month.name}
            revenue={month.total}
            number={month.numberMonth}
            services={month.totalServices}
          />
        );
      })}
    </MainComponent>
  );
};

export { Dashboard };
