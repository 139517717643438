import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import {
  ChecklistCategoryDetail,
  OrderServiceFormDTO,
} from '../../OrderServiceDTO';
import { categories } from '../../../../Checklists/components/CreateChecklistModal';
import { ExpandableSection } from '../../../../../../shared/ExpandableSection';
import ImageInput from '../../../../../../shared/ImageInput';
import { TextareaComponent } from '../../../../../../shared/TextareaComponent';
import styles from '../../create-order-service-form.module.css';
import { ChecklistImage } from '../../../../../../@types/checklist';

interface Props {
  isDraft: boolean;
  checklistImages: ChecklistImage[];
  checklistCategories: ChecklistCategoryDetail[];
}

export const ChecklistItems = ({
  isDraft,
  checklistCategories,
  checklistImages,
}: Props) => {
  const { setValue, getValues } = useFormContext<OrderServiceFormDTO>();
  const handleImageChange = useCallback(
    (categoryName: string, images: string[]) => {
      const currentImages = getValues('checklist.checklist_images') || [];
      const updatedImages = [
        ...currentImages.filter(img => img.category !== categoryName),
        ...images.map(img => ({ img_url: img, category: categoryName })),
      ];

      setValue('checklist.checklist_images', updatedImages, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [getValues, setValue],
  );
  const handleObservationChange = useCallback(
    (categoryName: string, newObservation: string) => {
      setValue(
        'checklist.checklist_category_details',
        checklistCategories.map(detail =>
          detail.category === categoryName
            ? { ...detail, observations: newObservation }
            : detail,
        ),
        { shouldDirty: true, shouldValidate: true },
      );
    },
    [checklistCategories, setValue],
  );
  return (
    <>
      {categories.map(category => {
        const categoryObservations =
          checklistCategories.find(detail => detail.category === category.name)
            ?.observations || '';

        const categoryImages =
          checklistImages
            .filter(img => img.category === category.name)
            .map(img => img.img_url) || [];

        return (
          <ExpandableSection
            title={category.label}
            key={category.name}
            className={styles['expandable-section']}
          >
            <div className={styles['image-input-wrapper']}>
              <ImageInput
                className={styles['image-input']}
                imageClassName={styles.image}
                maxImages={10}
                imagesUrls={categoryImages}
                onlyView={!isDraft}
                bucketPath="checklist"
                setImageUrls={images =>
                  handleImageChange(category.name, images)
                }
              />
            </div>

            <TextareaComponent
              className={styles.textarea}
              value={categoryObservations}
              onChange={e => {
                handleObservationChange(category.name, e.target.value);
              }}
              placeholder={`Digite observações sobre ${category.label}...`}
              disabled={!isDraft}
            />
          </ExpandableSection>
        );
      })}
    </>
  );
};
