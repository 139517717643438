import { CopySimple, FileText, GridFour } from '@phosphor-icons/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageLayout } from '../../../../shared/PageLayout';
// import { AnsweredQuotations } from './pages/AnsweredQuotations';
// import { FinishedQuotations } from './pages/FinishedQuotations';
// import { CreatedQuotations } from './pages/CreatedQuotations';
// import { MobileWorkshopQuotationHeader } from './components/MobileWorkshopQuotationHeader';
import { Circle } from '../../../../shared/Circle';
import { useResponsive } from '../../../../hooks/useResponsive';
import {
  QuotationPages,
  useQuotation,
} from '../../../../contexts/quotationContext';
// import useViewModal from './hooks/useViewModal';
// import useViewRepliesModal from './hooks/useViewRepliesModal';
// import useSelectedQuotation from '../workshop/hooks/useSelectedQuotation';
// import useFinishModal from './hooks/useFinishModal';
// import useRedoModal from './hooks/useRedoModal';
// import useRejectModal from './hooks/useRejectModal';
// import useResumeModal from '../workshop/hooks/useResumeModal';
// import { useAgentQuotation } from '../../../../contexts/agentQuotationContext';
import { CreatedQuotations } from './pages/CreatedQuotations';
import { AnsweredQuotations } from './pages/AnsweredQuotations';
import { FinishedQuotations } from './pages/FinishedQuotations';
import { MobileWorkshopQuotationHeader } from '../workshop/components/MobileWorkshopQuotationHeader';

export const AgentWorkshopQuotation = () => {
  const { selectedPage, setSelectedPage, quotationState } = useQuotation();
  // const viewModal = useViewModal();
  // const viewReplies = useViewRepliesModal();
  // const selectedQuotation = useSelectedQuotation();
  // const finishModal = useFinishModal();
  // const redoModal = useRedoModal();
  // const rejectModal = useRejectModal();
  // const resumeModal = useResumeModal();

  const [searchParams] = useSearchParams();

  const { sizeMobile } = useResponsive();
  const navigate = useNavigate();

  const renderSelectedPage = useMemo(() => {
    switch (selectedPage) {
      case 'created':
        return <CreatedQuotations />;
      case 'replies':
        return <AnsweredQuotations />;
      case 'completed':
        return <FinishedQuotations />;
      default:
        return <CreatedQuotations />;
    }
  }, [selectedPage]);

  const handleSelectedTitle = useMemo(() => {
    switch (selectedPage) {
      case 'created':
        return 'Cotações';
      case 'replies':
        return 'Cotações Respondidas';
      case 'completed':
        return 'Cotações Finalizadas';
      default:
        return 'Cotações';
    }
  }, [selectedPage]);

  const newOffersCounter = useMemo(
    () =>
      quotationState.answeredQuotationList.filter(answer => !answer.visualized)
        .length,
    [quotationState.answeredQuotationList],
  );

  const handlePageNavigate = useCallback(() => {
    const pageToNavigate = searchParams.get('page');

    if (pageToNavigate) {
      setSelectedPage(pageToNavigate as QuotationPages);
    }
  }, [searchParams, setSelectedPage]);

  useEffect(() => {
    handlePageNavigate();
  }, [handlePageNavigate, searchParams]);

  // useEffect(() => {
  //   return () => {
  //     viewModal.onClose();
  //     viewReplies.onClose();
  //     selectedQuotation.onClear();
  //     finishModal.onClose();
  //     redoModal.onClose();
  //     rejectModal.onClose();
  //     resumeModal.onClose();
  //   };
  // }, []);

  const renderTopMenuButtons = () => {
    if (!sizeMobile) {
      return (
        <>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'created'}
            onClick={() => setSelectedPage('created')}
          >
            <GridFour size={32} weight="fill" />
            Criadas
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'replies'}
            onClick={() => {
              setSelectedPage('replies');
            }}
          >
            <FileText size={32} weight="fill" />
            {newOffersCounter > 0 && `(${newOffersCounter})`} Respondidas
            {newOffersCounter > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>

          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'completed'}
            onClick={() => setSelectedPage('completed')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </>
      );
    }
    return (
      <MobileWorkshopQuotationHeader
        newOffers={newOffersCounter}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    );
  };

  const navigateBetweenPages = () => {
    if (selectedPage === 'created') return navigate('/home');
    if (selectedPage === 'replies') return setSelectedPage('created');
    if (selectedPage === 'completed') return setSelectedPage('replies');
  };

  return (
    <PageLayout.Root
      title={handleSelectedTitle}
      topMenu={renderTopMenuButtons()}
      handleNavigation={() => {
        if (sizeMobile) {
          navigateBetweenPages();
        } else {
          navigate('/home');
        }
      }}
    >
      {renderSelectedPage}
    </PageLayout.Root>
  );
};
