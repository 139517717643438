import * as yup from 'yup';

export const vehicleValidationSchema = yup.object().shape({
  vehicle: yup.object().shape({
    plate: yup.string().when('noVehicle', {
      is: true,
      then: schema => schema.notRequired(),
      otherwise: schema =>
        schema
          .required('A placa é obrigatória')
          .matches(/^[A-Z]{3}[0-9][0-9A-Z][0-9]{2}$/, 'Placa inválida'),
    }),
    noVehicle: yup.boolean().default(false),
    km: yup.string().test('is-km-valid', function (value) {
      const { lastKm } = this.parent;
      if (!value || !lastKm) return true;

      const kmNumber = parseFloat(value.replace(',', '.'));
      const lastKmNumber = parseFloat(lastKm.replace(',', '.'));

      if (kmNumber < lastKmNumber) {
        return this.createError({
          path: 'vehicle.km',
          message: `O KM deve ser maior ou igual ao último KM (${lastKm}).`,
        });
      }

      return true;
    }),
    lastKm: yup.string(),
  }),
});
