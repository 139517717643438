import { TransactionResponse } from '../../../../pages/common/Finance/interfaces';

interface Props {
  transactions: TransactionResponse[];
  year?: string;
  period?: string;
}

export const transactionTable = ({
  transactions,
  year = '',
  period = '',
}: Props) => {
  let totalIncome = 0;
  let totalExpense = 0;
  return `

  <style>
      /*  */
      .info-row {
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .info-row p {
        width: 100%;
        padding: 1rem 1rem;
        font-size: 1.2rem;

        margin-right: 2rem;
        background-color: #e9e9e9;
        color: #1e1e1e;
        border-radius: 8px;
      }
      .info-row span {
        font-weight: 700;
        margin-right: 0.5rem;
        font-size: 1.35rem;
        color: #1e1e1e;
      }
      .income p {
        background-color: #d2e9d1;
      }
      .expense p {
        background-color: #fbc1c3;
      }
      .expense p,
      .income p {
        display: flex;
        width: calc(100% - 4rem);
        justify-content: space-between;
        font-weight: 600;
        margin: 0.4rem 0;
      }
      .expense p span,
      .income p span {
        font-weight: 600;
      }

      /*  */

      .table-container {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .table-container table {
        margin: 0 1rem;
        border-collapse: collapse;
      }
      .table-container table thead tr th {
        background-color: #e9e9e9;
        padding: 0.8rem 1rem;
      }
      .table-container table tbody tr th {
        padding: 1.5rem 1rem;
        font-weight: 400;
        color: #000;
      }
      .table-container table tbody tr:last-child {
        border-bottom: 1.5px solid #6bb56a;
      }
      .title {
        width: 25%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        text-align: left;
      }
      .value {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
  </style>
  <div class="info-row">
    <p style="width: 20%"><span>Ano: </span>${year}</p>
    <p><span>Período: </span>${period}</p>
  </div>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th class="title">Título</th>
          <th class="date">Lançamento</th>
          <th class="status">Status</th>
          <th class="date">Baixa</th>
          <th class="value">Valor</th>
        </tr>
      </thead>
      <tbody>
      ${
        transactions &&
        transactions
          .map(transaction => {
            if (transaction.finished && transaction.type === 'income') {
              totalIncome += transaction.value;
            }
            if (transaction.finished && transaction.type === 'expense') {
              totalExpense += transaction.value;
            }
            return `
        <tr>
          <th class="title">${
            transaction.name
              ? transaction.name
              : `${transaction.transaction_code.code}-${transaction.transaction_code.name}`
          }</th>
          <th class="date">${transaction.due_date}</th>
          <th class="status">${
            transaction.finished ? 'Finalizado' : 'Pendente'
          }</th>
          <th class="date">
          ${transaction.discharge_date ? transaction.discharge_date : '-'}</th>
          <th class="value">R$ ${transaction.value.toLocaleString('pt-br')}</th>
        </tr>
        `;
          })
          .join('')
      }
      </tbody>
    </table>
    <section>
      <div class="info-row income">
        <p><span>Total Recebido: </span>R$ ${totalIncome}</p>
      </div>
    </section>
    <div class="info-row expense">
      <p><span>Total Pago: </span>R$ ${totalExpense}</p>
    </div>

  `;
};
