import { IRepairs } from '../../../../@types/interface';
import api from '../../../../services/api/api';
import { Repair } from '../../History/interfaces';

export interface GetOrderServiceInput {
  limit?: number;
  page?: number;
  query?: string;
}

export const getOrderService = async (params: GetOrderServiceInput) => {
  const { data } = await api.get<IRepairs>('/repair', {
    params,
  });
  return data;
};

export const getOrderServiceByLicensePlate = async (licensePlate: string) => {
  const { data } = await api.get<Repair[]>('/users/repair/licensePlate', {
    params: {
      licensePlate,
    },
  });
  return data;
};

export const deleteOrderService = async (orderServiceId: string) => {
  await api.delete(`/repair/${orderServiceId}`);
  return true;
};

export const finishOrderService = async (orderServiceId: string) => {
  const { data } = await api.put<Repair>(`/repair/finish/${orderServiceId}`);
  return data;
};
