import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../services/api/api';
import message from '../../../../../utils/message';
import { GroupedServiceProps } from '../../interfaces';
// import { visitorId } from '../../../../../routes/routes';
import { Modal } from '../../../PreventiveMaintenance/components/Modal';
import styles from './add-service-name-modal.module.css';

interface AddServiceNameModalProps {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  workshopId: string;
  setNewServiceId: Dispatch<SetStateAction<string>>;
  newServiceName: string;
  setNewServiceName: Dispatch<SetStateAction<string>>;
  modalServices: GroupedServiceProps;
  setIsVisitorModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddServiceNameModal = ({
  modalVisible,
  setModalVisible,
  workshopId,
  modalServices,
  setNewServiceId,
  newServiceName,
  setNewServiceName,
  setIsVisitorModalOpen,
}: AddServiceNameModalProps) => {
  const handleCreateService = async () => {
    const categoryName = modalServices.category;
    const serviceNameAlreadyExists = modalServices.services.find(
      service => service.name.toLowerCase() === newServiceName.toLowerCase(),
    );
    const categoryAlreadyExists = modalServices.services.some(
      service => service.name.toLowerCase() === categoryName.toLowerCase(),
    );
    if (
      (!serviceNameAlreadyExists && newServiceName.length > 0) ||
      !categoryAlreadyExists
    ) {
      try {
        const res = await api.post('/service/child', {
          name: newServiceName || categoryName,
          price: 0,
          service_id: modalServices.services[0].id_service,
          workshop_id: workshopId,
        });
        const { data } = res;
        setNewServiceId(data.id_child_service);
        setNewServiceName(data.name);
        setModalVisible(false);
      } catch (err) {
        message('não foi possível adicionar o serviço', 'error');
      }
    }
    // else {
    //   setNewServiceName('');
    //   setNewServiceId('');
    //   setModalVisible(false);
    //   setIsVisitorModalOpen(true);
    // }
  };

  return (
    <Modal
      isOpen={modalVisible}
      onClose={() => {
        setModalVisible(false);
        setNewServiceName('');
      }}
      title={`Categoria: ${modalServices?.category || ''} - Novo Serviço`}
    >
      <div className={styles.container}>
        <form>
          <input
            type="text"
            placeholder="Nome do serviço"
            value={newServiceName}
            onChange={e => setNewServiceName(e.target.value.toUpperCase())}
          />
        </form>
        <button type="button" onClick={handleCreateService}>
          Adicionar
        </button>
      </div>
    </Modal>
  );
};
