import { CaretRight } from 'phosphor-react';
import styles from './service-card.module.css';

interface ServiceCardProps {
  name: string;
}

export const ServiceCard = ({ name }: ServiceCardProps) => {
  return (
    <div className={styles.container}>
      <span>{name}</span>
      <CaretRight size="2rem" weight="bold" color="#6BB56A" />
    </div>
  );
};
