import { ArrowDown, ArrowUp } from 'phosphor-react';
import styles from './type-icon.module.css';

interface TypeIconProps {
  type: 'income' | 'expense';
}

export const TypeIcon = ({ type }: TypeIconProps) => {
  return (
    <div
      className={`${styles.container} ${
        type === 'income' ? styles.income : styles.expense
      }`}
    >
      {type === 'income' ? <ArrowUp /> : <ArrowDown />}
    </div>
  );
};
