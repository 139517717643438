import { useCallback, useState } from 'react';

type ModalType = 'create' | 'reverse' | 'edit' | 'view' | 'delete' | 'error';

interface ModalProps {
  create: boolean;
  reverse: boolean;
  edit: boolean;
  view: boolean;
  delete: boolean;
  error: boolean;
}

export function useModalItem() {
  const [openModal, SetOpenModal] = useState<ModalProps>({
    create: false,
    reverse: false,
    edit: false,
    view: false,
    delete: false,
    error: false,
  });

  const handleCloseModal = useCallback((modal: ModalType) => {
    SetOpenModal(prev => ({
      ...prev,
      [modal]: false,
    }));
  }, []);

  const handleOpenModal = useCallback((modal: ModalType) => {
    SetOpenModal(prev => ({
      ...prev,
      [modal]: true,
    }));
  }, []);

  return {
    openModal,
    handleCloseModal,
    handleOpenModal,
  };
}
