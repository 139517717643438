import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { WarningCircle } from 'phosphor-react';
import { ListItems } from './components/ListItems';
import styles from './preventive-maintenances.module.css';
import {
  Preventive,
  PreventiveMaintenanceInput,
  PreventiveMaintenanceType,
  PreventiveMaintenanceVehicle,
} from './interface';
import Mileage from '../../../assets/icons/Mileage.svg';
import message from '../../../utils/message';
import {
  handleCreateMaintenances,
  handleDeleteMaintenances,
  handleRegisterVehicleCustomer,
  handleSearchMaintenances,
  handleSearchMaintenancesTypes,
  handleUpdateCurrentKm,
} from './controller';
import { dateOffset } from '../../../utils/dateFunctions';
// import { visitorId } from '../../../routes/routes';
import {
  maskCpfCnpj,
  maskLicensePlate,
  numberWithCommas,
} from '../../../services/helpers/mask';
import { ViewModal } from './components/ViewModal';
import { DeleteModal } from './components/DeleteModal';
import { NewPreventiveModal } from './components/NewPreventiveModal';
import { FeedbackModal } from '../../../shared/FeedbackModal/index';
import Link from '../../../assets/icons/link.svg';
import { PageLayout } from '../../../shared/PageLayout';

export const PreventiveMaintenances = () => {
  const [selectedPreventive, setSelectedPreventive] =
    useState<Partial<Preventive> | null>(null);
  const [vehicle, setVehicle] = useState<PreventiveMaintenanceVehicle | null>(
    null,
  );
  const [currentKm, setCurrentKm] = useState(0);
  const [averageKm, setAverageKm] = useState(0);
  const [preventiveTypes, setPreventiveTypes] = useState<
    PreventiveMaintenanceType[]
  >([]);
  const [customerName, setCustomerName] = useState('');
  const [customerDocument, setCustomerDocument] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isNewPreventiveModalOpen, setIsNewPreventiveModalOpen] =
    useState(false);

  const fetchTypes = async () => {
    setIsLoadingTypes(true);
    const response = await handleSearchMaintenancesTypes();
    if (response) setPreventiveTypes(response);
    setIsLoadingTypes(false);
  };

  useEffect(() => {
    // if (userLocal && userLocal.id_user === visitorId) {
    //   setLicensePlate('AMC9671');
    //   handleSearch('AMC9671');
    // }
    fetchTypes();
  }, []);

  const handleSearch = async (value: string) => {
    setIsLoadingTypes(true);
    const result = await handleSearchMaintenances(value);
    setIsLoadingTypes(false);
    if (result) {
      setVehicle(result);
      setCurrentKm(result.current_km);
      setAverageKm(result.average_km_day);
    }
  };

  const handleUpdateKm = async () => {
    if (vehicle) {
      const response = await handleUpdateCurrentKm(
        vehicle?.id_vehicle || '',
        currentKm,
      );
      if (response) {
        setVehicle({ ...vehicle, ...response });
        setAverageKm(response.average_km_day || 0);
      }
    }
  };

  const handleAddPreventive = async (
    name: string,
    option: 'km' | 'date',
    kmToActive?: number,
    trigger_date?: string,
  ) => {
    const OTHERS_ID = preventiveTypes.find(
      preventive => preventive.name.toLowerCase() === 'outros',
    )?.id_preventive_type;
    if (vehicle && vehicle.id_vehicle && OTHERS_ID) {
      const createInput: PreventiveMaintenanceInput = {
        averageKmDay: averageKm === 0 ? 30 : averageKm,
        currentKm,
        typeId: OTHERS_ID,
        vehicleId: vehicle.id_vehicle,
        typeName: name,
      };

      if (option === 'date') {
        createInput.trigger_date = trigger_date;
        // createInput.is_trigger_date = true;
      } else {
        createInput.kmToActive = kmToActive;
      }
      const response = await handleCreateMaintenances(createInput);

      if (response) {
        setVehicle(prevVehicle => {
          if (!prevVehicle) {
            return null;
          }
          const newPreventive = {
            ...response,
            active_at: dateOffset(new Date(response.active_at)),
          };
          const updatedVehicle = {
            ...prevVehicle,
            preventives: [...prevVehicle.preventives, newPreventive],
          };
          return updatedVehicle;
        });
      }
    } else {
      message('Nenhum veículo informado', 'warning');
    }
  };

  const handleLinkCustomer = async () => {
    setAverageKm(30);
    setIsLoadingTypes(true);
    const newCustomer = await handleRegisterVehicleCustomer(
      customerDocument,
      customerName,
      licensePlate,
    );
    if (newCustomer && vehicle)
      setVehicle({
        ...vehicle,
        user: { ...newCustomer, name: customerName },
        user_id: newCustomer.user_id,
      });
    setIsLoadingTypes(false);
  };

  const preventives: Partial<Preventive>[] = preventiveTypes.reduce(
    (accumulator: Partial<Preventive>[], type) => {
      const isOtherType = type.name.toLowerCase() === 'outros';
      const vehiclePreventive =
        vehicle &&
        vehicle.preventives.filter(p => p.type_id === type.id_preventive_type);

      if (vehiclePreventive && vehiclePreventive.length > 0) {
        if (isOtherType) {
          accumulator.push(...vehiclePreventive);
        } else {
          accumulator.push(vehiclePreventive[0]);
        }
      } else if (!isOtherType) {
        accumulator.push({
          type_id: type.id_preventive_type,
          type_name: type.name,
          car_total_km_active: undefined,
          active_at: undefined,
        });
      }

      return accumulator;
    },
    [],
  );

  const handleOtherMaintenance = async (
    maintenance: PreventiveMaintenanceInput,
  ) => {
    const response = await handleCreateMaintenances({
      ...maintenance,
    });

    if (response && vehicle) {
      const existingMaintenanceIndex = vehicle.preventives.findIndex(
        preventive => preventive.type_name === response.type_name,
      );

      let updatedVehicle: Preventive[] = [];
      if (existingMaintenanceIndex !== -1) {
        updatedVehicle = vehicle.preventives.map((preventive, index) =>
          index === existingMaintenanceIndex ? response : preventive,
        );
      } else {
        updatedVehicle = [...vehicle.preventives, response];
      }

      setVehicle(previousVehicle => {
        if (previousVehicle)
          return {
            ...previousVehicle,
            preventives: updatedVehicle,
          };
        return null;
      });
    }

    return response;
  };

  const handleStandardMaintenance = async (
    maintenance: PreventiveMaintenanceInput,
  ) => {
    const response = await handleCreateMaintenances({
      ...maintenance,
      // averageKmDay: maintenance.averageKmDay ?? 30,
    });

    if (response && vehicle) {
      setVehicle(previousVehicle => {
        if (!previousVehicle) return null;

        const existingMaintenanceIndex = previousVehicle.preventives.findIndex(
          preventive => preventive.type_id === response.type_id,
        );

        const updatedMaintenances =
          existingMaintenanceIndex !== -1
            ? previousVehicle.preventives.map((preventive, index) =>
                index === existingMaintenanceIndex ? response : preventive,
              )
            : [...previousVehicle.preventives, response];

        return {
          ...previousVehicle,
          preventives: updatedMaintenances,
        };
      });
    }

    return response;
  };

  const handleMaintenance = async (
    preventive: Preventive,
    option: 'km' | 'date',
    km?: number,
    trigger_date?: string,
  ) => {
    const OTHERS_ID = preventiveTypes.find(
      prev => prev.name.toLowerCase() === 'outros',
    )?.id_preventive_type;

    const maintenanceInput: PreventiveMaintenanceInput = {
      averageKmDay: averageKm,
      currentKm,
      typeId: preventive.type_id || '',
      vehicleId: vehicle?.id_vehicle || '',
    };

    let response = null;

    if (option === 'date') {
      // maintenanceInput.is_trigger_date = true;
      maintenanceInput.trigger_date = trigger_date;
    } else {
      maintenanceInput.kmToActive = km || 0;
    }

    if (OTHERS_ID === preventive.type_id) {
      response = await handleOtherMaintenance({
        ...maintenanceInput,
        typeName: preventive.type_name,
      });
    } else {
      response = await handleStandardMaintenance(maintenanceInput);
    }

    return response;
  };

  const handleDeleteMaintenance = async () => {
    if (vehicle && selectedPreventive && selectedPreventive.id_preventive) {
      const response = await handleDeleteMaintenances(
        selectedPreventive.id_preventive,
      );
      if (response) {
        const filteredVehiclePreventives =
          vehicle.preventives.filter(
            prev => prev.id_preventive !== selectedPreventive.id_preventive,
          ) || [];
        setVehicle({ ...vehicle, preventives: filteredVehiclePreventives });
      }
    } else {
      message('Erro ao deletar manutenção', 'error');
    }
    setIsDeleteModalOpen(false);
    setSelectedPreventive(null);
  };

  return (
    <PageLayout.Root title="Manutenção preventiva">
      <FeedbackModal isOpen={isLoadingTypes} />
      <div className={styles.viewport}>
        <div className={styles['search-container']}>
          <div className={styles['search-vehicle']}>
            <div className={styles['search-bar']}>
              <div
                className={`${styles['search-input-control']} ${
                  licensePlate.length < 7 &&
                  licensePlate.length > 0 &&
                  styles['invalid-entry']
                }`}
              >
                <input
                  type="text"
                  placeholder="Informe uma placa"
                  value={licensePlate}
                  // disabled={userLocal && userLocal.id_user === visitorId}
                  onChange={e => {
                    const formattedValue = maskLicensePlate(e.target.value);
                    setLicensePlate(formattedValue);
                    if (e.target.value.length === 7) {
                      handleSearch(formattedValue);
                    }
                  }}
                />
                <button type="button">
                  <BsSearch size="1.6rem" />
                </button>
              </div>

              {vehicle && !vehicle.user_id && (
                <div className={styles['customer-warning']}>
                  <WarningCircle size={24} color="#ff1100" weight="fill" />{' '}
                  Veículo &apos;{vehicle && vehicle.model && vehicle.model}
                  &apos; não vinculado a um cliente
                </div>
              )}
            </div>
            {vehicle && !vehicle.user_id ? (
              <form>
                <div className={styles['input-control']}>
                  <span>Informe um CPF/CNPJ para vincular: </span>
                  <input
                    type="text"
                    value={customerDocument}
                    onChange={e => {
                      setCustomerDocument(maskCpfCnpj(e.target.value));
                    }}
                  />
                </div>
                <div className={styles['input-control']}>
                  <span>Nome do Cliente: </span>
                  <div className={styles['button-container']}>
                    <input
                      type="text"
                      value={customerName}
                      onChange={e => {
                        setCustomerName(e.target.value);
                      }}
                    />
                    {vehicle && !vehicle.user_id && (
                      <button type="button" onClick={handleLinkCustomer}>
                        <img src={Link} alt="vincular cliente" />
                      </button>
                    )}
                  </div>
                </div>
              </form>
            ) : (
              <form>
                <div className={styles['input-control']}>
                  <span>Nome do Cliente: </span>
                  <div className={styles['button-container']}>
                    <input
                      type="text"
                      value={(vehicle && vehicle.user?.name) || ''}
                      disabled
                    />
                  </div>
                </div>
                <div className={styles['vehicle-info']}>
                  <div className={styles['input-control']}>
                    <span>Veículo: </span>
                    <input
                      type="text"
                      value={vehicle && vehicle.model ? vehicle.model : ''}
                      disabled
                    />
                  </div>
                  <div className={styles['input-control']}>
                    <span>Ano: </span>
                    <input
                      type="text"
                      value={
                        vehicle && vehicle.year && vehicle.yearModel
                          ? `${vehicle.year}/${vehicle.yearModel}`
                          : ''
                      }
                      disabled
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className={styles['vehicle-form']}>
            <img src={Mileage} alt="Quilometragem" />
            <h2>DADOS DE KM</h2>
            <form>
              <div className={styles['input-control']}>
                <span>Km Atual </span>
                <input
                  type="text"
                  value={numberWithCommas(`${currentKm || ''}`)}
                  onChange={e =>
                    setCurrentKm(
                      parseInt(e.target.value.replace(/[^\d]/g, '') || '0', 10),
                    )
                  }
                  onBlur={e => {
                    setCurrentKm(
                      parseInt(e.target.value.replace(/[^\d]/g, '') || '0', 10),
                    );
                    handleUpdateKm();
                  }}
                />
              </div>
              <div className={styles['input-control']}>
                <span>Km/dia </span>
                <input
                  type="text"
                  value={numberWithCommas(`${averageKm || ''}`)}
                  onChange={e =>
                    setAverageKm(
                      parseInt(e.target.value.replace(/[^\d]/g, '') || '0', 10),
                    )
                  }
                  disabled={!vehicle?.km_day_editable}
                />
              </div>
            </form>
          </div>
        </div>
        <ListItems
          setSelectedPreventive={setSelectedPreventive}
          vehicle={vehicle}
          items={preventives}
          handleAddPreventive={() => setIsNewPreventiveModalOpen(true)}
        />
        <ViewModal
          onClose={() => setSelectedPreventive(null)}
          preventive={selectedPreventive}
          handleCreatePreventive={handleMaintenance}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
        <DeleteModal
          preventive={selectedPreventive}
          isModalOpen={isDeleteModalOpen}
          handleDeleteMaintenance={handleDeleteMaintenance}
          onClose={() => {
            setSelectedPreventive(null);
            setIsDeleteModalOpen(false);
          }}
        />
        <NewPreventiveModal
          isOpen={isNewPreventiveModalOpen}
          onClose={() => {
            setIsNewPreventiveModalOpen(false);
            setIsDeleteModalOpen(false);
          }}
          handleCreatePreventive={handleAddPreventive}
        />
      </div>
    </PageLayout.Root>
    // </div>
  );
};
