import { useState } from 'react';
import { X, XSquare } from 'phosphor-react';
import api from '../../../../../../../services/api/api';
import logoIcon from '../../../../../../../assets/img/logo/logoAutocenterImagemBlack.svg';
import overlay from '../../../../../../../assets/img/overlayforget.svg';
import {
  maskCpfCnpj,
  numberOnly,
} from '../../../../../../../services/helpers/mask';
import message from '../../../../../../../utils/message';
import styles from './ForgetModal.module.css';
import { AccessType } from '../../Login';

interface Props {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  accessType: AccessType;
  handleSwitchAccessType: (data: AccessType) => void;
}

export const ForgetModalLandingPage = ({
  isOpen,
  setIsOpen,
  accessType,
  handleSwitchAccessType,
}: Props) => {
  const [document, setDocument] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleRecoveryPassword = async () => {
    setIsDisabled(true);

    try {
      await api
        .post('/recover', {
          role: accessType,
          phone: `${numberOnly(document)?.trim()}`,
          selected_method: 'phone',
        })
        .then(response => {
          setIsOpen(false);
          message('Um SMS e uma mensagem no whatsapp foram enviados para o número telefone do usuário.', 'success');
        });
    } catch (error) {
      setIsOpen(false);
      message(error, 'error');
    }
    setDocument('');
    setIsDisabled(false);
  };

  return (
    <div
      className={styles.overlay}
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <div className={styles.content}>
        <button type="button" onClick={() => setIsOpen(false)}>
          <XSquare size={30} color="var(--error)" />
        </button>
        <img className={styles.overlayImage} src={overlay} alt="overlayImage" />

        <div className={styles.header}>
          <div className={styles.title}>
            <img className={styles.logoImage} src={logoIcon} alt="imageLogo" />
            <div>
              <h1>recuperar senha</h1>
            </div>
          </div>

          <div className={styles['access-type-selector']}>
            <button
              type="button"
              onClick={() => handleSwitchAccessType('workshop')}
              className={accessType === 'workshop' ? styles.selected : ''}
            >
              Gestor
            </button>
            <button
              type="button"
              onClick={() => handleSwitchAccessType('agent')}
              className={accessType === 'agent' ? styles.selected : ''}
            >
              Colaborador
            </button>
          </div>

          <h2>
            Informe o documento do acesso{' '}
            <span className={styles['access-type-text']}>
              {accessType === 'workshop' ? 'Gestor:' : 'Colaborador:'}
            </span>
          </h2>
        </div>

        <div className={styles.form}>
          <h2>CNPJ/CPF:</h2>
          <input
            type="text"
            className={styles.formInput}
            placeholder="28.080.222/0001-00"
            value={document}
            onChange={(e: any) => {
              setDocument(maskCpfCnpj(e.target.value));
            }}
          />
        </div>

        <div className={styles.groupButton}>
          <button
            type="button"
            onClick={() => handleRecoveryPassword()}
            disabled={isDisabled}
          >
            Recuperar
          </button>
        </div>
      </div>
    </div>
  );
};
