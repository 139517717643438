import { Bell } from 'phosphor-react';
import { useEffect, useMemo } from 'react';
import styles from './home.module.css';
import { HeaderCard } from './components/HeaderCard';
import pattern1 from '../../../assets/img/pattern-1.svg';
import pattern2 from '../../../assets/img/pattern-2.svg';
import pattern3 from '../../../assets/img/pattern-3.svg';
import { ActionCard } from '../../gasStation/Home/components/ActionCard';
import { useHomeData } from './useHomeData';
import { useHomeCards } from './useHomeCards';
import { removeObjectFromLocal } from '../../../utils/localStorage';
import { AnswerIdDetectionModal } from './components/AnswerIdDetectionModal';
import { getLogo } from '../../../utils/getLogo';
import { AccountControlHeader } from '../../common/AccountControlHeader';
import { useAuth } from '../../../hooks/useAuth';

export const Home = () => {
  const {
    handleSingOut,
    loadingData,
    navigate,
    isAnswerQuotationModalOpen,
    handleAnswerQuotation,
    handleCancelAnswerQuotation,
    hasUnseenAnswerQuotation,
    hasUnseenQuotation,
    // isOpenAnnouncement,
    // handleCloseModal,
    // totalAnnouncements,
    // announcements,
    amountNotifications,
    newQuotationsHome,
  } = useHomeData();

  // const [isOpenModalTotalAnnouncements, setIsOpenModalTotalAnnouncements] =
  //   useState(false);

  // const [initialStepNavBarAnnouncement, setInitialStepNavBarAnnouncement] =
  //   useState(0);

  const { user: data } = useAuth();

  const { content } = useHomeCards({
    newQuotationAnswers: hasUnseenAnswerQuotation,
    newQuotations: hasUnseenQuotation,
  });

  useEffect(() => {
    removeObjectFromLocal('@local-order-service');
  }, []);

  const logo = useMemo(() => getLogo(), []);

  // const handleOpenModalTotalAnnouncements = (initialStep: number) => {
  //   setIsOpenModalTotalAnnouncements(true);
  //   setInitialStepNavBarAnnouncement(initialStep);
  // };

  // const handleCloseModalTotalAnnouncements = () => {
  //   setIsOpenModalTotalAnnouncements(false);
  // };

  return (
    <>
      {/* {announcements && announcements?.length > 0 && (
        <ModalAnnouncement
          // key={announcement.announcement.id_announcement}
          title="Novas Atualizações"
          isOpen={isOpenAnnouncement}
          onClose={() => handleCloseModal()}
          announcements={announcements}
          size="large"
        />
      )}
      {isOpenModalTotalAnnouncements &&
        totalAnnouncements &&
        totalAnnouncements.length > 0 && (
          <ModalAnnouncement
            title="Novas Atualizações"
            isOpen={isOpenModalTotalAnnouncements}
            onClose={() => handleCloseModalTotalAnnouncements()}
            announcements={totalAnnouncements}
            initialStepNavBarAnnouncement={initialStepNavBarAnnouncement}
            size="large"
          />
        )} */}
      <AnswerIdDetectionModal
        isOpen={isAnswerQuotationModalOpen}
        onClose={handleCancelAnswerQuotation}
        onConfirm={handleAnswerQuotation}
      />
      <main className={styles.main}>
        <header className={styles.header}>
          <div className={styles['logo-wrapper']}>
            <img src={logo} className={styles.logo} alt="autocenter logo" />
          </div>
          {/* {totalAnnouncements && totalAnnouncements.length > 0 && (
                <AnnouncementBanner
                  announcements={totalAnnouncements}
                  handleOpenModalTotalAnnouncements={
                    handleOpenModalTotalAnnouncements
                  }
                />
              )} */}
          <div className={styles.statistics}>
            <div className={styles['statistics-wrapper']}>
              <div className={styles['statistics-cards']}>
                <HeaderCard
                  icon={
                    <Bell
                      size={window.innerWidth > 480 ? 18 : 14}
                      weight="bold"
                    />
                  }
                  label="Atualizações de Cotação"
                  value={newQuotationsHome}
                  onClick={() => navigate('/quotation-notification')}
                  loading={loadingData}
                />
                <HeaderCard
                  icon={
                    <Bell
                      size={window.innerWidth > 480 ? 18 : 14}
                      weight="bold"
                    />
                  }
                  label="Notificações"
                  value={amountNotifications}
                  emphasis={!!(amountNotifications > 0)}
                  onClick={() => navigate('/notifications')}
                  loading={loadingData}
                />
              </div>
            </div>
            <img src={pattern1} className={styles.pattern} alt="" />
            <AccountControlHeader
              route="/profile-edit"
              username={data?.name}
              handleSingOut={handleSingOut}
            />
          </div>
        </header>
        <section className={styles.actions}>
          {content &&
            content.map(item => (
              <ActionCard
                description={item.description}
                icon={item.icone}
                title={item.title}
                path={item.uri}
                notifications={item.notifications}
                key={item.title}
              />
            ))}
          <img src={pattern2} className={styles.pattern} alt="" />
          <img
            src={pattern3}
            className={`${styles.pattern} ${styles.mobile}`}
            alt=""
          />
        </section>
      </main>
    </>
  );
};
