import {
  ArrowDown,
  ArrowUp,
  CheckCircle,
  MinusCircle,
  PlusCircle,
  TrendDown,
  TrendUp,
} from 'phosphor-react';
import { getDay } from 'date-fns';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ClockCountdown } from '@phosphor-icons/react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  ActionBtns,
  Column,
  ColumnTable,
  CurrentMonthColumn,
  CurrentMonthRow,
  DayCell,
  MainContainer,
  OverviewCircle,
  OverviewHeader,
  OverviewHeaderBar,
  OverviewHeaderItem,
  OverviewItem,
  OverviewRow,
  PayBtn,
  PriceCents,
  PriceWhole,
  Prices,
  PricesGraph,
  PricesRow,
  ReceiveBtn,
  Row,
  RowDays,
  RowTransactionsType,
  SubTitle,
  Table,
  Title,
  TransactionContainer,
  TransactionData,
  TransactionLabelAccountPlan,
  TransactionsColumn,
  TransactionsEmptyContainer,
  TypeTransactionCell,
  WhiteContainer,
} from './_dashboard';
import { COLORS } from '../../../../../styles/colors';
import styles from '../Transactions/transactions.module.css';
import {
  getDashboardController,
  getTransactionsByDayController,
} from '../../controller';
import {
  DashboardResponse,
  Total,
  TransactionResponse,
  TransactionTypes,
} from '../../interfaces';
import message from '../../../../../utils/message';
import { Select } from '../../../../../shared/Select';
import { formatCurrency } from '../../../../../utils/currency';
import { getYearsOptions } from '../../../../../utils/getYearsOptions';
import { dayOfWeekMap, transactionTypeMap } from '../../utils/conversionMaps';
import { useModalItem } from '../Transactions/hooks/useModalItem';
import { CreateTransactionModal } from '../../components/CreateTransactionModal';
import { EditDashboardTransactionModal } from '../../components/EditDashboardTransactionModal';

interface Props {
  setCurrentPage: Dispatch<SetStateAction<string>>;
  setSelectedMonth: Dispatch<SetStateAction<string>>;
  setSelectedYear: Dispatch<SetStateAction<string>>;
  setFilter: Dispatch<SetStateAction<TransactionTypes | null>>;
}

export const FinanceDashboard = ({
  setCurrentPage,
  setSelectedMonth,
  setSelectedYear,
  setFilter,
}: Props) => {
  const today = new Date();

  const { openModal, handleCloseModal, handleOpenModal } = useModalItem();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'expense' | 'income'>('income');
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [dashboard, setDashboard] = useState<DashboardResponse | null>(null);
  const [disableZoom, setDisableZoom] = useState(true);
  const [yearsOptions, setYearsOptions] = useState(['2023']);
  const [currentMonth, setCurrentMonth] = useState<Total | null>(null);
  const [isVisitorModalOpen, setIsVisitorModalOpen] = useState(false);

  const [selectedDay, setSelectedDays] = useState<number>(getDay(today));
  const [selectedTypeTransaction, setSelectedTypeTransaction] =
    useState<number>(0);
  const [weekTransactions, setWeekTransactions] = useState<
    TransactionResponse[]
  >([]);
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionResponse | null>(null);

  const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const typesTransaction = ['A receber', 'A pagar'];

  const handleDayClick = (index: number) => {
    setSelectedDays(index);
  };

  const handleTypeClick = (index: number) => {
    if (selectedTypeTransaction === index) {
      setSelectedTypeTransaction(0);
    } else {
      setSelectedTypeTransaction(index);
      setSelectedDays(getDay(today));
    }
  };

  const handleInitZoom = (e: any) => {
    const widthWindow = window.innerWidth;
    if (widthWindow <= 768 && e.detail === 2) {
      setDisableZoom(false);
    }
  };

  const handleStopZoom = (e: any) => {
    const { scale, positionY, positionX } = e.state;
    if (scale === 1 && positionY === 0 && positionX === 0) {
      e.resetTransform(1);
      return setDisableZoom(true);
    }
  };

  const handleSetTransaction = (transaction: TransactionResponse) => {
    setSelectedTransaction(transaction);

    handleOpenModal('edit');
  };

  const handleOpenCreateTransactionModal = (type: 'income' | 'expense') => {
    setIsModalOpen(true);
    setModalType(type);
  };
  const handleCreateTransaction = (newTransaction: TransactionResponse) => {
    if (dashboard) {
      const { historic, total } = dashboard;
      const updatedTotal = { ...total };
      const updatedHistoric = [...historic];

      if (newTransaction.type === 'expense') {
        if (newTransaction.discharge_date) {
          const month = `${parseInt(
            newTransaction.discharge_date.split('-')[1],
            10,
          )}`;

          if (month === `${new Date().getMonth() + 1}` && currentMonth) {
            setCurrentMonth({
              ...currentMonth,
              spent: currentMonth.spent + newTransaction.value,
            });
          }

          updatedHistoric.forEach((item, index) => {
            if (item.month && item.month === month) {
              updatedHistoric[index] = {
                ...item,
                spent: item.spent + newTransaction.value,
              };
            }
          });

          updatedTotal.spent += newTransaction.value;
          updatedTotal.balance -= newTransaction.value;
        } else {
          updatedTotal.to_spend += newTransaction.value;

          const month = `${parseInt(
            newTransaction.due_date.split('-')[1],
            10,
          )}`;

          if (month === `${new Date().getMonth() + 1}` && currentMonth) {
            setCurrentMonth({
              ...currentMonth,
              to_spend: currentMonth.to_spend + newTransaction.value,
            });
          }

          updatedHistoric.forEach((item, index) => {
            if (item.month && item.month === month) {
              updatedHistoric[index] = {
                ...item,
                to_spend: item.to_spend + newTransaction.value,
              };
            }
          });
        }
      } else if (newTransaction.type === 'income') {
        if (newTransaction.discharge_date) {
          updatedTotal.received += newTransaction.value;
          const month = `${parseInt(
            newTransaction.discharge_date.split('-')[1],
            10,
          )}`;

          if (month === `${new Date().getMonth() + 1}` && currentMonth) {
            setCurrentMonth({
              ...currentMonth,
              received: currentMonth.received + newTransaction.value,
            });
          }

          updatedHistoric.forEach((item, index) => {
            if (item.month && item.month === month) {
              updatedHistoric[index] = {
                ...item,
                received: item.received + newTransaction.value,
              };
            }
          });

          updatedTotal.balance += newTransaction.value;
        } else {
          updatedTotal.to_receive += newTransaction.value;
          const month = `${parseInt(
            newTransaction.due_date.split('-')[1],
            10,
          )}`;

          if (month === `${new Date().getMonth() + 1}` && currentMonth) {
            setCurrentMonth({
              ...currentMonth,
              to_receive: currentMonth.to_receive + newTransaction.value,
            });
          }

          updatedHistoric.forEach((item, index) => {
            if (item.month && item.month === month) {
              updatedHistoric[index] = {
                ...item,
                to_receive: item.to_receive + newTransaction.value,
              };
            }
          });
        }
      }
      setDashboard({
        ...dashboard,
        total: updatedTotal,
        historic: updatedHistoric,
      });
    }
  };

  const fetchDaysTransactions = useCallback(async () => {
    try {
      const type = getTransactionType(selectedTypeTransaction);

      const selectedDaysQuery = convertDaysToQuery(selectedDay);

      const response = await getTransactionsByDayController({
        days: selectedDaysQuery,
        type,
      });

      if (response) {
        setWeekTransactions(response);
      }
    } catch (error) {
      message(error, 'error');
    }
  }, [selectedDay, selectedTypeTransaction]);

  useEffect(() => {
    // if (selectedDays.length === 0) {
    //   return;
    // }
    fetchDaysTransactions();
  }, [fetchDaysTransactions, selectedDay, selectedTypeTransaction]);

  const getTransactionType = (transactionType: number): string =>
    transactionTypeMap[transactionType] ?? 'receivable';

  const convertDaysToQuery = (currentDay: number): string =>
    `&days=${dayOfWeekMap[currentDay] || 'sunday'}`;
  // currentDays.map(day => `&days=${dayOfWeekMap[day] || 'sunday'}`).join('');

  useEffect(() => {
    fetchDashBoard();
    setYearsOptions(getYearsOptions());
  }, [year]);

  const fetchDashBoard = async () => {
    try {
      const response = await getDashboardController(year);
      if (response) {
        setDashboard(response);
        const currentMonthNumber = `${new Date().getMonth() + 1}`;
        setCurrentMonth(
          response.historic.filter(
            item => item.month === currentMonthNumber,
          )[0],
        );
      }
    } catch (error) {
      message(error, 'error');
    }
  };

  const renderTotal = (total: number) => {
    if (total >= 0) {
      return (
        <OverviewItem style={{ color: COLORS.GREEN, width: '20%' }}>
          + {formatCurrency(total)}
        </OverviewItem>
      );
    }
    return (
      <OverviewItem style={{ color: COLORS.RED, width: '20%' }}>
        - {formatCurrency(total * -1)}
      </OverviewItem>
    );
  };

  const renderArrow = (total: number, month: number) => {
    if (month > Number(dashboard?.total?.month)) {
      return (
        <OverviewCircle
          style={{ background: 'rgba(124, 124, 124, 1)', color: COLORS.WHITE }}
        >
          <ClockCountdown />
        </OverviewCircle>
      );
    }

    if (total >= 0) {
      return (
        <OverviewCircle style={{ background: '#dcf5e8', color: COLORS.GREEN }}>
          <ArrowUp />
        </OverviewCircle>
      );
    }
    return (
      <OverviewCircle
        style={{ background: 'rgba(245, 220, 220, 1)', color: COLORS.RED }}
      >
        <ArrowDown />
      </OverviewCircle>
    );
  };

  const renderTrends = (
    current_month: number | null,
    last_month: number | null,
  ) => {
    if (current_month && last_month) {
      let ratio = 0;
      if (last_month > 0) ratio = current_month / last_month;
      let trend = 0.0;
      if (ratio < 1 && ratio > 0) {
        trend = (1 - ratio) * 100;

        return (
          <PricesGraph style={{ background: '#F5DCDC', color: COLORS.RED }}>
            <TrendDown />-{Math.floor(trend)}%
          </PricesGraph>
        );
      }

      if (ratio > 1) {
        trend = (ratio - 1) * 100;
        return (
          <PricesGraph style={{ background: '#dcf5e8', color: COLORS.GREEN }}>
            <TrendUp />+{Math.floor(trend)}%
          </PricesGraph>
        );
      }
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  const renderCents = (number: number | null) => {
    if (number) {
      const cents = Math.floor((number % 1) * 100);
      if (cents > 0) return cents;
    }

    return '00';
  };

  const renderMonth = () => {
    if (dashboard) {
      return dashboard.historic.map(item => {
        const total = renderTotal(item.received - item.spent);
        const arrow = renderArrow(
          item.received - item.spent,
          Number(item.month),
        );
        return (
          <OverviewRow key={item.month}>
            <OverviewItem
              style={{
                width: '13%',
                justifyContent: 'flex-start',
                gap: '0.5rem',
              }}
              onClick={() => {
                setCurrentPage('Transactions');
                setFilter(null);
                if (item && item.month) {
                  setSelectedMonth(item.month);
                  setSelectedYear(item.year);
                }
              }}
            >
              {arrow}
              {item.month_name ? item.month_name.slice(0, 3) : ''}
            </OverviewItem>
            <OverviewItem style={{ color: COLORS.RED, width: '21%' }}>
              -{formatCurrency(item.to_spend)}
            </OverviewItem>
            <OverviewItem style={{ color: COLORS.RED, width: '21%' }}>
              - {formatCurrency(item.spent)}
            </OverviewItem>
            <OverviewItem style={{ color: '#6ED69E', width: '21%' }}>
              +{formatCurrency(item.to_receive)}
            </OverviewItem>
            <OverviewItem style={{ color: '#6ED69E', width: '21%' }}>
              +{formatCurrency(item.received)}
            </OverviewItem>
            {total}
          </OverviewRow>
        );
      });
    }
    return <div />;
  };

  const renderDayTransaction = () => {
    return (
      <Table>
        <RowTransactionsType typeTransaction={selectedTypeTransaction}>
          {typesTransaction.map((typeTransaction, index) => (
            <TypeTransactionCell
              key={typeTransaction}
              active={selectedTypeTransaction === index}
              index={index}
              onClick={() => handleTypeClick(index)}
            >
              {typeTransaction}
            </TypeTransactionCell>
          ))}
        </RowTransactionsType>

        <RowDays>
          {days.map(dayName => (
            <DayCell
              key={dayName}
              active={selectedDay === days.indexOf(dayName)}
              typeTransaction={selectedTypeTransaction}
              onClick={() => handleDayClick(days.indexOf(dayName))}
            >
              {dayName}
            </DayCell>
          ))}
        </RowDays>

        {/* {selectedTypeTransaction === 1 && (
          <DayTitlePayable>Hoje</DayTitlePayable>
        )} */}

        <TransactionsColumn>
          {selectedTypeTransaction === 0 &&
            weekTransactions.length !== 0 &&
            weekTransactions.map(weekTransaction => (
              <TransactionContainer
                typeTransactions={selectedTypeTransaction}
                onClick={() => handleSetTransaction(weekTransaction)}
                key={weekTransaction.id_transaction}
              >
                <TransactionData isBold={false}>
                  {weekTransaction.description ||
                    weekTransaction.name ||
                    weekTransaction.transaction_code.name ||
                    'Sem Descrição'}
                </TransactionData>
                <TransactionData isBold>
                  {formatCurrency(weekTransaction.value)}
                </TransactionData>
              </TransactionContainer>
            ))}

          {selectedTypeTransaction === 1 &&
            weekTransactions.length !== 0 &&
            weekTransactions.map(weekTransaction => (
              <div key={weekTransaction.id_transaction}>
                <TransactionLabelAccountPlan>
                  {weekTransaction.transaction_code.name ||
                    'Sem Plano de Contas'}
                </TransactionLabelAccountPlan>
                <TransactionContainer
                  typeTransactions={selectedTypeTransaction}
                  onClick={() => handleSetTransaction(weekTransaction)}
                >
                  <TransactionData isBold={false}>
                    {weekTransaction.description ||
                      weekTransaction.name ||
                      'Sem Descrição'}
                  </TransactionData>
                  <TransactionData isBold>
                    {formatCurrency(weekTransaction.value)}
                  </TransactionData>
                </TransactionContainer>
              </div>
            ))}

          {weekTransactions.length === 0 && (
            <TransactionsEmptyContainer>
              <CheckCircle size={50} color="#0B5226" />
              {selectedTypeTransaction === 0 && (
                <strong>Sem contas a receber</strong>
              )}
              {selectedTypeTransaction !== 0 && (
                <strong>Sem contas a pagar</strong>
              )}
            </TransactionsEmptyContainer>
          )}
        </TransactionsColumn>
      </Table>
    );
  };

  return (
    <MainContainer>
      {/* <VisitorModal
        isOpen={isVisitorModalOpen}
        onClose={() => setIsVisitorModalOpen(false)}
      /> */}
      <CreateTransactionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={async transaction => {
          handleCreateTransaction(transaction);
          await fetchDaysTransactions();
        }}
        type={modalType}
      />

      <EditDashboardTransactionModal
        isOpen={openModal.edit}
        onClose={() => handleCloseModal('edit')}
        onEdit={async transaction => {
          handleCreateTransaction(transaction);
          await fetchDaysTransactions();
        }}
        selectedTransaction={selectedTransaction}
      />

      <Column>
        <ActionBtns>
          <ReceiveBtn
            onClick={() => handleOpenCreateTransactionModal('income')}
          >
            <PlusCircle size={40} weight="fill" />
            Conta a Receber
          </ReceiveBtn>

          <PayBtn onClick={() => handleOpenCreateTransactionModal('expense')}>
            <MinusCircle size={40} weight="fill" />
            Conta a Pagar
          </PayBtn>
        </ActionBtns>
        <CurrentMonthColumn>
          <Title>Mês Atual</Title>
          <CurrentMonthRow>
            <WhiteContainer
              onClick={() => {
                setCurrentPage('Transactions');
                setSelectedMonth(`${new Date().getMonth() + 1}`);
                setSelectedYear(year);
                setFilter('payable');
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <SubTitle>Contas a Pagar</SubTitle>
              <Prices>
                <PriceWhole>
                  R$
                  {currentMonth ? Math.floor(currentMonth.to_spend) : 0}
                </PriceWhole>
                <PriceCents>
                  ,{renderCents(currentMonth ? currentMonth.to_spend : null)}
                </PriceCents>
              </Prices>
            </WhiteContainer>
            <WhiteContainer
              onClick={() => {
                setCurrentPage('Transactions');
                setSelectedMonth(`${new Date().getMonth() + 1}`);
                setSelectedYear(year);
                setFilter('paid');
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <PricesRow>
                <SubTitle>Contas Pagas</SubTitle>
              </PricesRow>
              <Prices>
                <PriceWhole>
                  R$
                  {currentMonth ? Math.floor(currentMonth.spent) : 0}
                </PriceWhole>
                <PriceCents>
                  ,{renderCents(currentMonth ? currentMonth.spent : null)}
                </PriceCents>
              </Prices>
            </WhiteContainer>
            {/* </CurrentMonthRow>
          <CurrentMonthRow> */}
            <WhiteContainer
              onClick={() => {
                setCurrentPage('Transactions');
                setSelectedMonth(`${new Date().getMonth() + 1}`);
                setSelectedYear(year);
                setFilter('receivable');
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <SubTitle>Contas a Receber</SubTitle>
              <Prices>
                <PriceWhole>
                  R$
                  {currentMonth ? Math.floor(currentMonth.to_receive) : 0}
                </PriceWhole>
                <PriceCents>
                  ,{renderCents(currentMonth ? currentMonth.to_receive : null)}
                </PriceCents>
              </Prices>
            </WhiteContainer>
            <WhiteContainer
              onClick={() => {
                setCurrentPage('Transactions');
                setSelectedMonth(`${new Date().getMonth() + 1}`);
                setSelectedYear(year);
                setFilter('received');
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <PricesRow>
                <SubTitle>Contas Recebidas</SubTitle>
              </PricesRow>
              <Prices>
                <PriceWhole>
                  R$
                  {currentMonth ? Math.floor(currentMonth.received) : 0}
                </PriceWhole>
                <PriceCents>
                  ,{renderCents(currentMonth ? currentMonth.received : null)}
                </PriceCents>
              </Prices>
            </WhiteContainer>
          </CurrentMonthRow>
        </CurrentMonthColumn>
      </Column>
      <ColumnTable>
        <WhiteContainer onClick={handleInitZoom}>
          <Row>
            <Title>Visão Geral</Title>
            <div className={styles.filters}>
              <Select value={year} setValue={setYear}>
                <option value="" disabled>
                  Ano
                </option>
                {yearsOptions &&
                  yearsOptions.map(yearOption => (
                    <option value={yearOption} key={yearOption}>
                      {yearOption}
                    </option>
                  ))}
              </Select>
            </div>
          </Row>
          <TransformWrapper
            disabled={disableZoom}
            onTransformed={e => handleStopZoom(e)}
          >
            <TransformComponent>
              <OverviewHeader>
                <OverviewHeaderItem style={{ width: '11%' }}>
                  Mês
                </OverviewHeaderItem>
                <OverviewHeaderItem style={{ width: '22%' }}>
                  A Pagar
                </OverviewHeaderItem>
                <OverviewHeaderItem style={{ width: '16%' }}>
                  Pago
                </OverviewHeaderItem>
                <OverviewHeaderItem style={{ width: '22%' }}>
                  A Receber
                </OverviewHeaderItem>
                <OverviewHeaderItem style={{ width: '21%' }}>
                  Recebido
                </OverviewHeaderItem>

                <OverviewHeaderItem style={{ width: '21%' }}>
                  Total
                </OverviewHeaderItem>
              </OverviewHeader>
              <OverviewHeaderBar />
              {renderMonth()}
            </TransformComponent>
          </TransformWrapper>
        </WhiteContainer>
        {renderDayTransaction()}
      </ColumnTable>
    </MainContainer>
  );
};
