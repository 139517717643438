import { TransactionResponse } from '../../../pages/common/Finance/interfaces';
import { dateOffset, formatDate } from '../../dateFunctions';
import { Container } from '../components/html/container';
import { transactionTable } from '../components/html/transaction-table';
import { Workshop } from '../../../@types/interface';

interface Props {
  data: TransactionResponse[] | null;
  year: string;
  initialDate: Date | null;
  finalDate: Date | null;
  workshopData: Workshop | null;
}

export const transactionPdf = ({
  data,
  year,
  initialDate,
  finalDate,
  workshopData,
}: Props) => {
  const formatDateOrNull = (date: Date | null) =>
    date ? formatDate(dateOffset(new Date(date))) : '';

  const period =
    initialDate && finalDate
      ? `${formatDateOrNull(initialDate)} - ${formatDateOrNull(finalDate)}`
      : formatDateOrNull(initialDate);

  return Container({
    workshopName: workshopData?.fantasy_name ?? 'Oficina Não encontrada',
    documentTitle: 'Relatório',
    content: `
    ${transactionTable({ transactions: data || [], period, year })}
    `,
  });
};
