import { XSquare } from 'phosphor-react';
import { ModalTitle } from '../../../Finance/components/Modal';
import styles from './modal-header.module.css';
import { Button } from '../../../../../shared/Button';
import logo from '../../../../../assets/logo_icone.svg';
// import pattern from '../../../../../assets/img/modal-pattern.svg';

interface Props {
  handleClose: () => void;
  title: string;
}

export const ModalHeader = ({ handleClose, title }: Props) => (
  <header className={styles.header}>
    {/* <img src={pattern} alt="fundo" className={styles.pattern} /> */}
    <img src={logo} alt="Logo" className={styles.logo} />
    <ModalTitle titleClassName={styles.title}>{title}</ModalTitle>
    <Button
      handleClick={handleClose}
      variant="icon"
      icon={<XSquare size={30} weight="bold" color="#E53835" />}
    />
  </header>
);
