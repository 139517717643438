import React, { ReactNode, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import './styles.css';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { COLORS } from '../../styles/colors';

interface Props {
  children: ReactNode;
}

export const Carousel = ({ children }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    loop: false,
  });

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="embla">
      <button type="button" className="left" onClick={onPrevButtonClick}>
        <CaretLeft size={32} color={COLORS.GREEN} />
      </button>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">{children}</div>
      </div>
      <button type="button" className="right" onClick={onNextButtonClick}>
        <CaretRight size={32} color={COLORS.GREEN} />
      </button>
    </div>
  );
};
