import styles from './section-separator.module.css';

interface Props {
  title: string;
}
export const SectionSeparator = ({ title }: Props) => {
  return (
    <div className={styles['section-separator']}>
      <span>{title}</span>
      <hr />
    </div>
  );
};
