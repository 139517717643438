import * as yup from 'yup';
import {
  LinkFleetVehicle,
  createPreventiveMaintenance,
  deletePreventiveMaintenance,
  fetchFleetMaintenances,
  fetchFleetPreventiveMaintenance,
  fetchPreventiveMaintenanceByLicensePlate,
  fetchPreventiveMaintenanceTypes,
  findFleetVehicles,
  updateVehicleCurrentKm,
} from './model';
import { PreventiveMaintenanceInput } from './interface';

import message from '../../../utils/message';

export const handleSearchMaintenances = async (licensePlate: string) => {
  try {
    const formattedLicensePlate = licensePlate
      .replace(/[^a-zA-Z0-9]/g, '')
      .trim();

    const schema = yup
      .string()
      .trim()
      .required('A placa é obrigatória')
      .length(7, 'A placa deve ter 7 caracteres');

    await schema.validate(formattedLicensePlate, { abortEarly: false });

    const res = await fetchPreventiveMaintenanceByLicensePlate(
      formattedLicensePlate,
    );
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning'); // yup error message
    } else message(error, 'error'); // generic error message
  }
};
export const handleCreateMaintenances = async (
  preventive: PreventiveMaintenanceInput,
) => {
  try {
    const schema = yup.object({
      typeId: yup
        .string()
        .required('Não foi possível identificar o tipo da manutenção'),
      vehicleId: yup
        .string()
        .required('Não foi possível identificar o veículo da manutenção'),
      kmToActive: yup
        .number()
        .test(
          'averageKmDay',
          'Valor de km para próxima troca inválido',
          value => {
            if (value && value < 100) return false;
            if (value && value >= 200000) return false;
            return true;
          },
        ),
      // is_trigger_date: yup.boolean(),
      months: yup.number(),
      averageKmDay: yup
        .number()
        .required('A média de Quilometragem é obrigatória')
        .min(1, 'A média de Quilometragem deve ser maior que 0'),
      currentKm: yup
        .number()
        .required('A média de Quilometragem atual é obrigatória'),
      typeName: yup.string().nullable(),
    });
    await schema.validate(preventive, { abortEarly: false });
    return await createPreventiveMaintenance(preventive);
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning'); // yup error message
    } else {
      message(error, 'error');
    }
  }
};

export const handleDeleteMaintenances = async (id_preventive: string) => {
  if (id_preventive && id_preventive.length !== 0) {
    return deletePreventiveMaintenance(id_preventive);
  }
  message('Não foi possível remover essa manutenção', 'error');
};
export const handleSearchMaintenancesTypes = async () => {
  const response = await fetchPreventiveMaintenanceTypes();
  if (!response) return null;
  return response.sort((a, b) => a.name.localeCompare(b.name));
};
export const handleUpdateCurrentKm = async (
  vehicleId: string,
  currentKm: number,
) => {
  if (vehicleId && currentKm >= 100) {
    const response = await updateVehicleCurrentKm(vehicleId, currentKm);
    return response;
  }
  message('Não foi possível atualizar a quilometragem deste veículo', 'error');
};

export const LinkFleetVehicleController = async (vehicleId: string) => {
  try {
    await yup.string().required().validate(vehicleId);
    const res = await LinkFleetVehicle(vehicleId);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error, 'warning'); // yup error message
    } else message(error, 'error'); // generic error message
  }
};

export const fetchFleetPreventiveMaintenanceController = async (
  vehicleId: string,
) => {
  try {
    await yup.string().required().validate(vehicleId);
    const res = await fetchFleetPreventiveMaintenance(vehicleId);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error, 'warning'); // yup error message
    } else message(error, 'error'); // generic error message
  }
};

export const findFleetVehiclesController = async () => {
  try {
    const res = await findFleetVehicles();
    return res;
  } catch (error) {
    message(error, 'error');
  }
};

export const fetchFleetMaintenancesController = async (
  limit?: number,
  page?: number,
) => {
  try {
    const res = await fetchFleetMaintenances(limit, page);
    return res;
  } catch (error) {
    message(error, 'error');
  }
};
