import { ChangeEvent, useState } from 'react';
import { PlusSquare } from '@phosphor-icons/react';

import { ModalContent } from '../Modal';
import styles from './filter-modal.module.css';
import { CreateTransactionMessageModal } from '../CreateTransactionMessageModal';
import { TransactionCodeResponse } from '../../interfaces';
import { Select } from '../../../../../shared/Select';
import { transactionCodes as transactionTypes } from '../../pages/Transactions/utils/transactionCodes';
import { InputComponent } from '../../pages/Transactions/components/input';
import { ButtonComponent } from '../../pages/Transactions/components/Button';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../utils/monthBoundaries';

interface Props {
  isOpen: boolean;
  transactionsCodes: TransactionCodeResponse[];
  initialDate?: string | '';
  finalDate?: string | '';
  onClose: () => void;
  handleSetFilter: (
    type?: string,
    accountPlan?: string,
    initialDate?: string,
    finalDate?: string,
  ) => void;
}

export const FilterModal = ({
  isOpen,
  transactionsCodes,
  initialDate,
  finalDate,
  onClose,
  handleSetFilter,
}: Props) => {
  const title = 'Filtros';

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [filterCode, setFilterCode] = useState<string | ''>('');
  const [selectedTransactionCode, setSelectedTransactionCode] = useState<
    string | ''
  >('');

  const [currentInitialDate, setCurrentInitialDate] = useState(initialDate);
  const [currentFinalDate, setCurrentFinalDate] = useState(finalDate);

  const handleClose = () => {
    setShowSuccessModal(false);
    onClose();
  };

  const onSubmit = () => {
    handleSetFilter(
      filterCode,
      selectedTransactionCode,
      currentInitialDate,
      currentFinalDate,
    );
    handleClose();
  };

  const clearFields = () => {
    setFilterCode('');
    setSelectedTransactionCode('');

    setCurrentInitialDate(
      getFirstDayOfMonth(new Date()).toISOString().split('T')[0],
    );
    setCurrentFinalDate(
      getLastDayOfMonth(new Date()).toISOString().split('T')[0],
    );

    handleSetFilter();
  };

  const onChangeDateInitialDate = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentInitialDate(e.target.value);
  };

  const onChangeDateFinalDate = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentFinalDate(e.target.value);
  };

  return (
    <ModalContent
      open={isOpen}
      onClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      {showSuccessModal ? (
        <CreateTransactionMessageModal
          message="Sucesso!"
          onClose={() => {
            setShowSuccessModal(false);
          }}
        />
      ) : (
        <div className={styles['filter-modal']}>
          <div className={styles['modal-header']}>
            <span className={styles['modal-title']}>{title}</span>
            {/* <button
              className={styles['clear-fields']}
              type="button"
              onClick={clearFields}
            >
              Limpar todos os filtros
            </button> */}
          </div>

          <hr />
          <div className={styles['transaction-code-wrapper']}>
            <span>Período</span>
            <div className={styles['date-range']}>
              <span>De</span>
              <InputComponent
                label=""
                type="date"
                value={currentInitialDate}
                onChange={onChangeDateInitialDate}
              />
              <span>Até</span>
              <InputComponent
                label=""
                type="date"
                value={currentFinalDate}
                onChange={onChangeDateFinalDate}
              />
            </div>
          </div>
          <hr />
          <div className={styles['transaction-code-wrapper']}>
            <span>Tipo</span>
            <Select value={filterCode} setValue={setFilterCode}>
              <option value="">Financeiro</option>
              {transactionTypes.map(currentType => (
                <option key={currentType.name} value={currentType.value}>
                  {currentType.name}
                </option>
              ))}
            </Select>
          </div>
          <hr />
          <div className={styles['transaction-code-wrapper']}>
            <span>Plano de contas</span>
            <Select
              value={selectedTransactionCode}
              setValue={setSelectedTransactionCode}
            >
              <option value="">Escolha um plano</option>
              {transactionsCodes.map(code => (
                <option
                  key={code.id_transaction_code}
                  value={code.id_transaction_code}
                >
                  {code.code} - {code.name}
                </option>
              ))}
            </Select>
          </div>

          <div className={styles['buttons-wrapper']}>
            <ButtonComponent
              text="Limpar Filtros"
              variant="outline"
              type="button"
              onClick={clearFields}
            />
            <ButtonComponent
              text="Adicionar Filtros"
              Icon={PlusSquare}
              type="button"
              onClick={onSubmit}
            />
          </div>
        </div>
      )}
    </ModalContent>
  );
};
