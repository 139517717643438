import { useEffect, useState } from 'react';
import { Vehicle } from '../../../../../@types/interface';
import styles from './preventive-maintenance.module.css';
import { SearchBar } from '../SearchBar';
import { VehicleForm } from '../VehicleForm';
import { dateOffset } from '../../../../../utils/dateFunctions';
import { ListItems } from '../ListItems';
import { FeedbackModal } from '../../../../../shared/FeedbackModal';
import { ViewModal } from '../ViewModal';
import { DeleteModal } from '../DeleteModal';
import { NewPreventiveModal } from '../NewPreventiveModal';
import {
  fetchFleetPreventiveMaintenanceController,
  findFleetVehiclesController,
  handleCreateMaintenances,
  handleDeleteMaintenances,
  handleSearchMaintenancesTypes,
  handleUpdateCurrentKm,
} from '../../controller';
import message from '../../../../../utils/message';
import {
  Preventive,
  PreventiveMaintenanceInput,
  PreventiveMaintenanceType,
  PreventiveMaintenanceVehicle,
} from '../../interface';

export const PreventiveMaintenance = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isNewPreventiveModalOpen, setIsNewPreventiveModalOpen] =
    useState(false);
  const [selectedPreventive, setSelectedPreventive] =
    useState<Partial<Preventive> | null>(null);
  const [vehicle, setVehicle] = useState<PreventiveMaintenanceVehicle | null>(
    null,
  );
  const [currentKm, setCurrentKm] = useState(0);
  const [averageKm, setAverageKm] = useState(0);
  const [preventiveTypes, setPreventiveTypes] = useState<
    PreventiveMaintenanceType[]
  >([]);
  const [vehiclesList, setVehiclesList] = useState<Vehicle[]>([]);

  const fetchVehicles = async () => {
    const res = await findFleetVehiclesController();
    if (res) {
      setVehiclesList(res);
    }
  };

  const fetchTypes = async () => {
    setIsLoadingTypes(true);
    const response = await handleSearchMaintenancesTypes();
    if (response) setPreventiveTypes(response);
    setIsLoadingTypes(false);
  };

  useEffect(() => {
    fetchTypes();
    fetchVehicles();
  }, []);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);

  const [selectedVehicle, setSelectedVehicle] = useState<null | Vehicle>(null);

  const handleAddPreventive = async (
    name: string,
    option: 'km' | 'date',
    kmToActive?: number,
    trigger_date?: string,
  ) => {
    const OTHERS_ID = preventiveTypes.find(
      preventive => preventive.name.toLowerCase() === 'outros',
    )?.id_preventive_type;
    if (vehicle && vehicle.id_vehicle && OTHERS_ID) {
      const createInput: PreventiveMaintenanceInput = {
        averageKmDay: averageKm === 0 ? 30 : averageKm,
        currentKm,
        typeId: OTHERS_ID,
        vehicleId: vehicle.id_vehicle,
        typeName: name,
      };

      if (option === 'date') {
        createInput.trigger_date = trigger_date;
        // createInput.is_trigger_date = true;
      } else {
        createInput.kmToActive = kmToActive;
      }
      const response = await handleCreateMaintenances(createInput);

      if (response) {
        setVehicle(prevVehicle => {
          if (!prevVehicle) {
            return null;
          }
          const newPreventive = {
            ...response,
            active_at: dateOffset(new Date(response.active_at)),
          };
          const updatedVehicle = {
            ...prevVehicle,
            preventives: [...prevVehicle.preventives, newPreventive],
          };
          return updatedVehicle;
        });
      }
    } else {
      message('Nenhum veículo informado', 'warning');
    }
  };

  const preventives: Partial<Preventive>[] = preventiveTypes.reduce(
    (accumulator: Partial<Preventive>[], type) => {
      const isOtherType = type.name.toLowerCase() === 'outros';
      const vehiclePreventive =
        vehicle &&
        vehicle.preventives.filter(p => p.type_id === type.id_preventive_type);

      if (vehiclePreventive && vehiclePreventive.length > 0) {
        if (isOtherType) {
          accumulator.push(...vehiclePreventive);
        } else {
          accumulator.push(vehiclePreventive[0]);
        }
      } else if (!isOtherType) {
        accumulator.push({
          type_id: type.id_preventive_type,
          type_name: type.name,
          car_total_km_active: undefined,
          active_at: undefined,
        });
      }

      return accumulator;
    },
    [],
  );

  const handleOtherMaintenance = async (
    maintenance: PreventiveMaintenanceInput,
  ) => {
    const response = await handleCreateMaintenances({
      ...maintenance,
    });

    if (response && vehicle) {
      const existingMaintenanceIndex = vehicle.preventives.findIndex(
        preventive => preventive.type_name === response.type_name,
      );

      let updatedVehicle: Preventive[] = [];
      if (existingMaintenanceIndex !== -1) {
        updatedVehicle = vehicle.preventives.map((preventive, index) =>
          index === existingMaintenanceIndex ? response : preventive,
        );
      } else {
        updatedVehicle = [...vehicle.preventives, response];
      }

      setVehicle(previousVehicle => ({
        ...previousVehicle!,
        preventives: updatedVehicle,
      }));
    }

    return response;
  };

  const handleStandardMaintenance = async (
    maintenance: PreventiveMaintenanceInput,
  ) => {
    const response = await handleCreateMaintenances({
      ...maintenance,
    });

    if (response && vehicle) {
      setVehicle(previousVehicle => {
        if (!previousVehicle) return null;

        const existingMaintenanceIndex = previousVehicle.preventives.findIndex(
          preventive => preventive.type_id === response.type_id,
        );

        const updatedMaintenances =
          existingMaintenanceIndex !== -1
            ? previousVehicle.preventives.map((preventive, index) =>
                index === existingMaintenanceIndex ? response : preventive,
              )
            : [...previousVehicle.preventives, response];

        return {
          ...previousVehicle,
          preventives: updatedMaintenances,
        };
      });
    }

    return response;
  };

  const handleMaintenance = async (
    preventive: Preventive,
    option: 'km' | 'date',
    km?: number,
    trigger_date?: string,
  ) => {
    const OTHERS_ID = preventiveTypes.find(
      prev => prev.name.toLowerCase() === 'outros',
    )?.id_preventive_type;

    const maintenanceInput: PreventiveMaintenanceInput = {
      averageKmDay: averageKm,
      currentKm,
      typeId: preventive.type_id || '',
      vehicleId: vehicle?.id_vehicle || '',
    };

    let response = null;

    if (option === 'date') {
      // maintenanceInput.is_trigger_date = true;
      maintenanceInput.trigger_date = trigger_date;
    } else {
      maintenanceInput.kmToActive = km || 0;
    }

    if (OTHERS_ID === preventive.type_id) {
      response = await handleOtherMaintenance({
        ...maintenanceInput,
        typeName: preventive.type_name,
      });
    } else {
      response = await handleStandardMaintenance(maintenanceInput);
    }

    return response;
  };

  const handleDeleteMaintenance = async () => {
    if (vehicle && selectedPreventive && selectedPreventive.id_preventive) {
      const response = await handleDeleteMaintenances(
        selectedPreventive.id_preventive,
      );
      if (response) {
        const filteredVehiclePreventives =
          vehicle.preventives.filter(
            prev => prev.id_preventive !== selectedPreventive.id_preventive,
          ) || [];
        setVehicle({ ...vehicle, preventives: filteredVehiclePreventives });
      }
    } else {
      message('Erro ao deletar manutenção', 'error');
    }
    setIsDeleteModalOpen(false);
    setSelectedPreventive(null);
  };

  const fetchVehicleMaintenances = async () => {
    if (selectedVehicle) {
      const res = await fetchFleetPreventiveMaintenanceController(
        selectedVehicle.id_vehicle,
      );
      if (res) {
        setCurrentKm(res.current_km);
        setAverageKm(res.average_km_day);
        setVehicle(res);
      }
    }
  };

  const handleUpdateKm = async () => {
    if (vehicle) {
      const response = await handleUpdateCurrentKm(
        vehicle?.id_vehicle || '',
        currentKm,
      );
      if (response) {
        setVehicle({ ...vehicle, ...response });
        setAverageKm(response.average_km_day || 0);
      }
    }
  };

  useEffect(() => {
    fetchVehicleMaintenances();
  }, [selectedVehicle]);

  return (
    <div className={styles.viewport}>
      <FeedbackModal isOpen={isLoadingTypes} />

      <div className={styles['search-container']}>
        <div className={styles['search-vehicle']}>
          <form>
            <SearchBar
              vehiclesList={vehiclesList as any}
              setSelectedVehicle={setSelectedVehicle}
              setVehiclesList={setVehiclesList}
            />
            <div className={styles['vehicle-info']}>
              <div className={styles['input-control']}>
                <span>Veículo: </span>
                <input
                  type="text"
                  value={vehicle && vehicle.model ? vehicle.model : ''}
                  disabled
                />
              </div>
              <div className={styles['input-control']}>
                <span>Ano: </span>
                <input
                  type="text"
                  value={
                    vehicle && vehicle.year && vehicle.yearModel
                      ? `${vehicle.year}/${vehicle.yearModel}`
                      : ''
                  }
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
        <VehicleForm
          isDisabled={!vehicle?.km_day_editable}
          averageKm={averageKm}
          currentKm={currentKm}
          handleUpdateKm={handleUpdateKm}
          setAverageKm={setAverageKm}
          setCurrentKm={setCurrentKm}
        />
      </div>
      <ListItems
        setSelectedPreventive={setSelectedPreventive}
        vehicle={vehicle}
        items={preventives}
        handleAddPreventive={() => setIsNewPreventiveModalOpen(true)}
      />
      <ViewModal
        onClose={() => setSelectedPreventive(null)}
        preventive={selectedPreventive}
        handleCreatePreventive={handleMaintenance}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
      <DeleteModal
        preventive={selectedPreventive}
        isModalOpen={isDeleteModalOpen}
        handleDeleteMaintenance={handleDeleteMaintenance}
        onClose={() => {
          setSelectedPreventive(null);
          setIsDeleteModalOpen(false);
        }}
      />
      <NewPreventiveModal
        isOpen={isNewPreventiveModalOpen}
        onClose={() => {
          setIsNewPreventiveModalOpen(false);
          setIsDeleteModalOpen(false);
        }}
        handleCreatePreventive={handleAddPreventive}
      />
    </div>
  );
};
