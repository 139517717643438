import { useState } from 'react';
import { XCircle } from 'phosphor-react';
import { TransactionResponse } from '../../../../interfaces';
import { Table } from '../../../../components/Table';
import styles from './transactions-table.module.css';
import { TransactionItem } from './TransactionItem';

interface TransactionsTableProps {
  transactions: TransactionResponse[];
  handleUpdated: (data: TransactionResponse) => void;
  handleDelete: (id: string) => void;
  selectedMonth: string;
  selectedYear: string;
  handleTransactions: (res: TransactionResponse[]) => void;
}

export const TransactionsTable = ({
  transactions,
  handleUpdated,
  handleDelete,
  selectedMonth,
  selectedYear,
  handleTransactions,
}: TransactionsTableProps) => {
  const [rowActive, setRowActive] = useState<string>('');

  function handleActive(id: string) {
    return setRowActive(state => (id === state ? '' : id));
  }

  return (
    <Table.Root AutoResponsive>
      <Table.Row className={styles['table-header']}>
        <Table.Header
          value="Tipo"
          // hasFilter
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          // handleTransactions={handleTransactions}
        />
        <Table.Header
          value="Descrição"
          align="left"
          hasFilter
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          handleTransactions={handleTransactions}
        />
        <Table.Header
          value="Lançamento"
          align="left"
          hasFilter
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          handleTransactions={handleTransactions}
        />
        <Table.Header
          value="Vencimento"
          align="left"
          hasFilter
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          handleTransactions={handleTransactions}
        />
        <Table.Header
          value="Baixa"
          className={styles['column-discharge']}
          hasFilter
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          handleTransactions={handleTransactions}
        />
        <Table.Header
          value="Situação"
          className={styles['column-situation']}
          hasFilter
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          handleTransactions={handleTransactions}
        />
        <Table.Header
          value="Valor"
          hasFilter
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          handleTransactions={handleTransactions}
        />
      </Table.Row>
      {transactions.length > 0 ? (
        transactions.map(transaction => (
          <TransactionItem
            transaction={transaction}
            rowActive={rowActive}
            onActive={() => handleActive(transaction.id_transaction)}
            handleUpdated={handleUpdated}
            handleDelete={handleDelete}
            // handleTransactions={handleTransactions}
          />
        ))
      ) : (
        <div className={styles['no-transactions']}>
          <XCircle size={32} />
          <span>Nenhum Lançamento Encontrado</span>
        </div>
      )}
    </Table.Root>
  );
};
