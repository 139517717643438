import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from './reverse-value-form.module.css';
import { ButtonComponent } from '../../Button';
import {
  updateDischargeDateDTO,
  updateDischargeDateSchema,
} from '../../../utils/DTOs/updateDischargeDateDTO';
import { UpdateDischargeDate } from '../../../../../model';
import { InputComponent } from '../../input';
import { TransactionResponse } from '../../../../../interfaces';

interface ReverseValueProps {
  onClose?: () => void;
  transaction: TransactionResponse;
  handleUpdate: (response: TransactionResponse) => void;
}

export const ReverseValueForm = ({
  onClose,
  transaction,
  handleUpdate,
}: ReverseValueProps) => {
  const today = new Date().toLocaleDateString().split('/').reverse().join('-');
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<updateDischargeDateDTO>({
    resolver: yupResolver(updateDischargeDateSchema),
    defaultValues: {
      discharge_date: transaction.finished ? '' : today,
    },
  });

  async function onSubmit(data: updateDischargeDateDTO) {
    // http call post
    const response = await UpdateDischargeDate({
      due_date: transaction.due_date,
      discharge_date: data.discharge_date ? data.discharge_date : '',
      value: transaction.value,
      transactionId: transaction.id_transaction,
    });

    handleUpdate({
      ...response,
      description: transaction.description,
      name: transaction.name,
    });
    if (onClose) onClose();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      {!transaction.finished && (
        <InputComponent
          label="Data de baixa"
          type="date"
          {...register('discharge_date')}
        />
      )}
      <div className={styles.row}>
        <ButtonComponent text="Cancelar" variant="outline" onClick={onClose} />
        <ButtonComponent text="Confirmar" disabled={isSubmitting} />
      </div>
    </form>
  );
};
