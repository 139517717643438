/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../components/jsx/Container';
import { Section } from '../components/jsx/Section';
import { InfoRow } from '../components/jsx/InfoRow';
import { ServiceTable } from '../components/jsx/ServiceTable';
import { maskCpfCnpj, maskPhone } from '../../../services/helpers/mask';
import { dateOffset, formatDate, formatTime } from '../../dateFunctions';
import { Workshop } from '../../../@types/interface';
import {
  getOrderServiceDataController,
  getRepairPdfURLController,
} from './controller';
import styles from './order-service.module.css';
import { Repair } from '../../../@types/order-service';
import { ViewImagesModal } from './ViewImagesModal';
import { FeedbackModal } from '../../../shared/FeedbackModal';

interface Props {
  workshopData: Workshop | null;
}

export const OrderServiceReport = ({ workshopData }: Props) => {
  const [orderService, setOrderService] = useState<Repair | null>(null);
  const [viewImages, setViewImages] = useState(false);
  const [imageToView, setImageToView] = useState<null | string>(null);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const orderServiceId = queryParams.get('orderServiceId');

  const fetchRepair = useCallback(async () => {
    const res = await getOrderServiceDataController(orderServiceId || '');

    if (res) {
      setOrderService(res);
    } else {
      setOrderService(null);
    }
  }, [orderServiceId]);

  useEffect(() => {
    fetchRepair();
  }, [fetchRepair]);

  const handleClickToViewImages = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>,
      img: string,
    ) => {
      e.stopPropagation();
      setViewImages(previous => !previous);
      setImageToView(img);
    },
    [],
  );
  const handleDownloadPdf = async () => {
    setIsDownloadingPdf(true);
    try {
      const res = await getRepairPdfURLController(orderServiceId ?? '');

      if (res?.fileUrl) {
        const response = await fetch(res.fileUrl);

        if (!response.ok) {
          throw new Error('Falha ao baixar o PDF.');
        }

        const blob = await response.blob();

        // Gera o Base64 do PDF
        const base64 = await blobToBase64(blob);

        if (window.ReactNativeWebView) {
          // ✅ Envia para o app
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              name: 'pdfBase64',
              base64, // Ex: data:application/pdf;base64,...
              fileName: `O.S-${orderService?.OS}.pdf`,
            }),
          );
        } else {
          // Web normal: abre o PDF no navegador
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          const fileName = `O.S-${orderService?.OS}.pdf`;
          link.href = blobUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        }
      } else {
        console.error('URL do PDF não encontrada na resposta.');
      }
    } catch (error) {
      console.error('Erro ao baixar o PDF:', error);
    } finally {
      setIsDownloadingPdf(false);
    }
  };

  // Utilitário
  const blobToBase64 = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  const generateClientSection = () => {
    const items = [
      {
        name: 'Nome',
        value: orderService?.name ?? '-',
      },
      {
        name: 'Telefone',
        value: maskPhone(orderService?.phone ?? '-'),
      },
      {
        name: 'CPF/CNPJ',
        value: maskCpfCnpj(orderService?.document ?? '-'),
      },
    ];
    return (
      <Section title="Cliente">
        <InfoRow items={items} />
      </Section>
    );
  };

  const generateVehicleSection = () => {
    const km = orderService?.km ?? null;

    const kmFormatted = km !== null ? km.toLocaleString('pt-br') : '-';
    const items = [
      {
        name: 'Modelo',
        value: orderService?.vehicle?.name ?? '-',
      },
      {
        name: 'Placa',
        value: orderService?.vehicle?.license_plate ?? '-',
      },
      {
        name: 'km',
        value: kmFormatted,
      },
    ];
    return (
      <Section title="Veículo">
        <InfoRow items={items} />
      </Section>
    );
  };

  const generateServiceSection = () => {
    const discount = orderService?.discount ?? 0;
    const products =
      orderService?.child_services
        ?.filter(service => service.type === 'Produto')
        .map(service => ({
          name: service.name ?? '-',
          quantity: service.quantity ?? 0,
          unit: service.quantity ? (service?.total ?? 0) / service.quantity : 0,
        })) ?? [];
    const services =
      orderService?.child_services
        ?.filter(service => service.type !== 'Produto')
        .map(service => ({
          name: service.name ?? '-',
          quantity: service.quantity ?? 0,
          unit: service.quantity ? (service?.total ?? 0) / service.quantity : 0,
        })) ?? [];
    const initialDate =
      orderService?.date && orderService?.time
        ? dateOffset(new Date(`${orderService.date}T${orderService.time}Z`))
        : null;

    const finishDate =
      orderService?.finish_date && orderService?.finish_time
        ? dateOffset(
            new Date(
              `${orderService.finish_date}T${orderService.finish_time}Z`,
            ),
          )
        : null;

    const serviceTotal = services.reduce((acc, currentService) => {
      return acc + currentService.unit * currentService.quantity;
    }, 0);

    const productTotal = products.reduce((acc, currentProduct) => {
      return acc + currentProduct.unit * currentProduct.quantity;
    }, 0);

    const total = serviceTotal + productTotal - discount;

    const imageUrls = orderService?.image_urls ?? [];

    return (
      <Section title="Informações">
        <InfoRow
          items={[
            {
              name: 'Data de Início',
              value: initialDate
                ? `${formatDate(initialDate)} - ${formatTime(initialDate)}`
                : '-',
            },
            {
              name: 'Data de Término',
              value: finishDate
                ? `${formatDate(finishDate)} - ${formatTime(finishDate)}`
                : '-',
            },
          ]}
        />
        <InfoRow
          items={[
            {
              name: 'Status',
              value: orderService?.finished ? 'Finalizado' : 'Em Andamento',
            },
          ]}
        />

        {imageUrls && imageUrls.length > 0 && (
          <Section title="Imagens">
            <div className={styles['images-wrapper']}>
              {imageUrls.map(img => (
                <img
                  key={img}
                  src={img}
                  alt="imagem de OS"
                  onClick={e => handleClickToViewImages(e, img)}
                />
              ))}
            </div>
          </Section>
        )}

        {products.length !== 0 && (
          <Section title="Produtos">
            <ServiceTable
              discount={discount}
              services={products}
              noSummary
              type="product"
            />
          </Section>
        )}
        {services.length !== 0 && (
          <Section title="Serviços">
            <ServiceTable
              discount={discount}
              services={services}
              noSummary
              type="service"
            />
          </Section>
        )}
        <div className={styles.summary}>
          <div className={`${styles.info}`}>
            <span>Subtotal de produtos</span>
            <span>
              {productTotal.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className={`${styles.info}`}>
            <span>Subtotal de serviços</span>
            <span>
              {serviceTotal.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className={`${styles.info}`}>
            <span>Desconto</span>
            <span>
              {discount.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className={`${styles.info}`}>
            <span>Total</span>
            <span>
              {total.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
      </Section>
    );
  };

  const generateCommentSection = () => {
    if (orderService?.comment)
      return (
        <Section title="Observações">
          <p className={styles.comment}>{orderService?.comment ?? '-'}</p>
        </Section>
      );
    return null;
  };
  const generateChecklistSection = () => {
    if (orderService?.checklist_vehicle) {
      const checklistId = orderService.checklist_vehicle.id_checklist_vehicle;
      const link = `/${
        orderService.checklist_vehicle.draft
          ? 'checklist-draft'
          : 'checklist-view-report'
      }?checklistId=${checklistId}`;

      return (
        <Section title="Checklist">
          <p className={styles.comment}>
            <button
              type="button"
              onClick={() => navigate(link)}
              className={styles['redirect-link']}
            >
              clique aqui
            </button>
            para visualizar seu checklist
          </p>
        </Section>
      );
    }
    return null;
  };

  return (
    <Container
      handleDownloadPdf={orderService?.finished ? handleDownloadPdf : undefined}
      workshopName={workshopData?.fantasy_name ?? 'Oficina Não encontrada'}
      documentName={`Ordem de Serviço - ${
        orderService?.external_id || orderService?.OS || '-'
      }`}
      isDownloadingPdf={isDownloadingPdf}
    >
      <FeedbackModal variant="sending" isOpen={isDownloadingPdf} />

      {imageToView && (
        <ViewImagesModal
          isOpen={viewImages}
          onClose={() => setViewImages(false)}
          url={imageToView}
        />
      )}
      <InfoRow
        items={[
          {
            name: 'Data de Emissão',
            value: `${
              orderService?.created_at
                ? `${formatDate(
                    new Date(orderService.created_at),
                  )} - ${formatTime(new Date(orderService.created_at))}`
                : '-'
            }`,
          },
        ]}
      />
      {generateClientSection()}
      {generateVehicleSection()}
      {generateServiceSection()}
      {generateCommentSection()}
      {generateChecklistSection()}
    </Container>
  );
};
