import { useCallback, useMemo, useState } from 'react';
import { WhatsappLogo } from '@phosphor-icons/react';
import {
  AnswerQuotation,
  ItemQuotation,
} from '../../../../../../@types/interface';
import { QuotationList } from '../QuotationList';
import styles from './answer-quotation-items.module.css';
import { Button } from '../../../../../../shared/Button';
import { sendWhatsappMessage } from '../../../../../../utils/sendWhatsappMessage';

interface Props {
  answer: AnswerQuotation | null;
  isOwner: boolean;
}

export const AnswerQuotationItems = ({ answer = null, isOwner }: Props) => {
  const workshopName = useMemo(() => {
    if (isOwner)
      return answer?.workshop?.fantasy_name ?? 'Oficina não localizada';
    return (
      answer?.quotation?.workshop?.fantasy_name ?? 'Oficina não localizada'
    );
  }, [
    answer?.quotation?.workshop?.fantasy_name,
    answer?.workshop?.fantasy_name,
    isOwner,
  ]);

  const supplierName = useMemo(() => {
    if (isOwner)
      return (
        answer?.quotation?.workshop?.fantasy_name ?? 'Oficina não localizada'
      );
    return answer?.workshop?.fantasy_name ?? 'Oficina não localizada';
  }, [
    answer?.quotation?.workshop?.fantasy_name,
    answer?.workshop?.fantasy_name,
    isOwner,
  ]);

  const whatsappNumber = useMemo(() => {
    if (isOwner) return answer?.workshop?.whatsapp ?? '';
    return answer?.quotation?.workshop?.whatsapp ?? '';
  }, [
    answer?.quotation?.workshop?.whatsapp,
    answer?.workshop?.whatsapp,
    isOwner,
  ]);

  const [subTotal, setSubTotal] = useState(0);

  const handleWhatsappMessage = (quotation: AnswerQuotation | null) => {
    if (quotation && quotation.quotation?.QUOTATION && quotation.workshop) {
      const Text = `Olá, sou da ${
        supplierName ?? ''
      }. Poderia fornecer mais informações sobre a cotação de número ${
        quotation.quotation?.QUOTATION
      }?`;
      sendWhatsappMessage(Text, whatsappNumber);
    }
  };

  const quotation = useMemo(
    () => answer?.quotation ?? null,
    [answer?.quotation],
  );

  const itemsQuotation = useMemo(() => {
    return quotation?.items_quotation ?? [];
  }, [quotation?.items_quotation]);

  const getAnswerItems = useCallback(
    (itemQuotation: ItemQuotation) => {
      return (
        answer?.items_answer_quotation?.filter(
          itemAnswerQuotation =>
            itemAnswerQuotation.item_quotation_id ===
            itemQuotation.id_item_quotation,
        ) || []
      );
    },
    [answer?.items_answer_quotation],
  );

  const agentName = useMemo(() => {
    return answer?.agent?.user?.name || null;
  }, [answer?.agent?.user?.name]);

  const handleWithSubTotal = (totalItem: number, quantity: number) => {
    const currentItemValue = totalItem * quantity;

    setSubTotal(prev => {
      return prev + currentItemValue;
    });
  };

  const handleTotalWithTax = (total: number, tax: number) => {
    return total + tax;
  };

  return (
    <div className={styles.container}>
      <h1>Itens para cotação</h1>
      <QuotationList.Root>
        <div className={styles['quotation-list-content']}>
          <div className={styles['autoshop-info']}>
            <h3>
              {workshopName} {agentName && `- vendedor: ${agentName}`}
            </h3>
            {/* <button type="submit">Whatsapp</button> */}
            {whatsappNumber ? (
              <Button
                handleClick={() => handleWhatsappMessage(answer)}
                style={{
                  height: '1.5rem',
                  backgroundColor: '#007a37',
                }}
              >
                <WhatsappLogo size={24} weight="fill" />
                Whatsapp
              </Button>
            ) : (
              <div
                style={{
                  width: '100%',
                  textAlign: 'end',
                }}
              >
                Nenhum Contato Disponível
              </div>
            )}
          </div>
          <QuotationList.Header />
          {itemsQuotation.length > 0 &&
            itemsQuotation.map((itemQuotation, index) => {
              const itemsAnswerQuotation = getAnswerItems(itemQuotation);

              return (
                <QuotationList.Item
                  item={itemQuotation}
                  index={index + 1}
                  key={itemQuotation.id_item_quotation}
                  itemsAnswerQuotation={itemsAnswerQuotation}
                  isEmpty={itemsAnswerQuotation.length === 0}
                  answer={answer}
                  handleWithSubTotal={handleWithSubTotal}
                />
              );
            })}
        </div>
      </QuotationList.Root>
      {subTotal > 0 && (answer?.tax_value ?? 0) > 0 && (
        <div className={styles.subtotal}>
          <div className={styles['subtotal-info']}>
            <b>Total </b>
            <span>(Valor do frete incluso): </span>
          </div>
          <b>
            {handleTotalWithTax(
              subTotal,
              answer?.tax_value ?? 0,
            ).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </b>
        </div>
      )}
      {subTotal > 0 && (answer?.tax_value ?? 0) <= 0 && (
        <div className={styles.subtotal}>
          <b>Total: </b>
          <b>
            {subTotal.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </b>
        </div>
      )}
    </div>
  );
};
