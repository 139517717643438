import { Dispatch, SetStateAction } from 'react';

import styles from './inputDate.module.css';

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<Date | null>>;
}

export const InputDate = ({ setValue, value }: Props) => {
  return (
    <input
      type="date"
      value={value}
      className={styles.date}
      onChange={e => {
        setValue(e.target.value ? new Date(e.target.value) : null);
      }}
    />
  );
};
