import { useEffect, useRef, useState } from 'react';
import { MaintenanceItem } from '../MaintenanceItem';
import styles from './maintenance-list.module.css';
import { fetchFleetMaintenancesController } from '../../controller';
import { preventiveMaintenanceResponse } from '../../interface';
import { ListLoader } from '../../../../../shared/ListLoader';

const LIMIT_MAINTENANCES = 80;

export const MaintenancesList = () => {
  const [selectedMaintenance, setSelectedMaintenance] =
    useState<preventiveMaintenanceResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noMoreData, setNoMoreData] = useState(false);
  const [maintenances, setMaintenances] = useState<
    preventiveMaintenanceResponse[]
  >([]);
  const listMaintenancesRef = useRef<HTMLUListElement>(null);

  const fetchData = async (pageRequest: number) => {
    setIsLoading(true);
    const res = await fetchFleetMaintenancesController(
      LIMIT_MAINTENANCES,
      pageRequest,
    );
    if (res) {
      if (res.length === 0) setNoMoreData(true);
      setMaintenances(prev => {
        const newMaintenance = res.filter(newMaintencance => {
          return !prev.some(
            existingMaintenance =>
              existingMaintenance.id_preventive ===
              newMaintencance.id_preventive,
          );
        });
        return [...prev, ...newMaintenance];
      });
    } else {
      setNoMoreData(true);
    }
    setIsLoading(false);
  };

  const loadMoreData = () => {
    if (!noMoreData) {
      fetchData(page);
      setPage(prev => prev + 1);
    }
  };

  useEffect(() => {
    const listContent = listMaintenancesRef.current;

    const handleScroll = () => {
      if (
        listContent &&
        listContent.scrollTop + listContent.clientHeight >=
          listContent.scrollHeight * 0.9
      ) {
        loadMoreData();
      }
    };

    listContent?.addEventListener('scroll', handleScroll);
    return () => {
      listContent?.removeEventListener('scroll', handleScroll);
    };
  }, [page]);

  useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.desktop}>Nome do Veículo</span>
        <span>Placa</span>
        <span>Modelo</span>
        <span>Ultima modificação</span>
      </div>
      <div className={styles['maintenances-list-content-wrapper']}>
        <ul
          className={styles['maintenances-list-content']}
          ref={listMaintenancesRef}
        >
          {!isLoading && maintenances.length === 0 && (
            <p className={styles.empty}>Nenhuma Manutenção encontrada</p>
          )}
          {maintenances.length !== 0 &&
            maintenances.map(maintenance => (
              <MaintenanceItem
                key={maintenance.id_preventive}
                maintenance={maintenance}
                selected={
                  !!selectedMaintenance &&
                  selectedMaintenance.id_preventive ===
                    maintenance.id_preventive
                }
                handleClick={() => {
                  if (
                    selectedMaintenance &&
                    maintenance.id_preventive ===
                      selectedMaintenance.id_preventive
                  ) {
                    setSelectedMaintenance(null);
                  } else {
                    setSelectedMaintenance(maintenance);
                  }
                }}
              />
            ))}
          {isLoading && <ListLoader />}
        </ul>
      </div>
    </div>
  );
};
