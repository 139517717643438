import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './search-bar.module.css';
import caretDown from '../../../../../assets/icons/caret-down.svg';
import arrowRight from '../../../../../assets/icons/arrow-right.svg';
import plus from '../../../../../assets/icons/plus.svg';

import { RegisterVehicleModal } from '../RegisterVehicleModal';
import { Vehicle } from '../../../../../@types/interface';
import { PreventiveMaintenanceVehicle } from '../../interface';
import { maskLicensePlate } from '../../../../../services/helpers/mask';

interface Props {
  vehiclesList: Vehicle[];
  setSelectedVehicle: Dispatch<SetStateAction<Vehicle | null>>;
  setVehiclesList: Dispatch<SetStateAction<Vehicle[]>>;
}

export const SearchBar = ({
  vehiclesList,
  setSelectedVehicle,
  setVehiclesList,
}: Props) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [filteredList, setFilteredList] = useState<Vehicle[]>([]);
  const [licensePlate, setLicensePlate] = useState('');
  const [showRegisterModalOpen, setShowRegisterModalOpen] = useState(false);

  const handleOpenList = () => {
    setIsListOpen(!isListOpen);
  };

  const handleSelectOption = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle);
    setLicensePlate(vehicle.license_plate);
    setIsListOpen(false);
  };

  const handleRegister = () => {
    setShowRegisterModalOpen(prev => !prev);
  };

  useEffect(() => {
    if (licensePlate.length > 0) {
      const foundedVehicles = vehiclesList.filter(vehicle =>
        vehicle.license_plate.includes(licensePlate),
      );
      setFilteredList(foundedVehicles);
    } else {
      setFilteredList(vehiclesList);
    }
  }, [licensePlate, vehiclesList]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles['input-control']}>
          <input
            type="text"
            placeholder="Escolha um veículo ou cadastre um novo"
            onClick={handleOpenList}
            value={licensePlate}
            onChange={e => {
              const { value } = e.target;
              setLicensePlate(maskLicensePlate(value.toUpperCase()));
            }}
            maxLength={7}
          />
          <button
            type="button"
            className={styles['open-list']}
            onClick={handleOpenList}
          >
            <img
              src={caretDown}
              className={styles.icon}
              alt="abrir listagem de veículos"
            />
          </button>
        </div>
        <ul className={`${styles.options} ${isListOpen ? styles.open : ''}`}>
          {filteredList.map(vehicle => (
            <li className={styles.option} key={vehicle.id_vehicle}>
              <button type="button" onClick={() => handleSelectOption(vehicle)}>
                <div className={styles.info}>
                  <span>{vehicle.name}</span>
                  <span>
                    {vehicle.model} | {vehicle.license_plate}
                  </span>
                </div>
                <img src={arrowRight} alt="Selecionar carro" />
              </button>
            </li>
          ))}
          <button
            type="button"
            className={styles.register}
            onClick={handleRegister}
          >
            Adicionar novo veículo{' '}
            <img src={plus} alt="Adicionar novo veículo" />
          </button>
        </ul>
      </div>
      <RegisterVehicleModal
        isOpen={showRegisterModalOpen}
        licensePlate={licensePlate}
        onCloseModal={handleRegister}
        onRegister={(vehicle: Vehicle) => {
          setVehiclesList(prev => {
            return prev.concat(vehicle);
          });
        }}
      />
    </>
  );
};
